import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "plans-main-wrapper" }
const _hoisted_2 = { class: "plan-pay-info-wrapper" }
const _hoisted_3 = { class: "plan-pay-info-panel" }
const _hoisted_4 = { class: "plan-pay-info-panel-sum" }
const _hoisted_5 = { class: "plans-curr-plan-wrapper" }
const _hoisted_6 = {
  key: 0,
  class: "plans-loading"
}
const _hoisted_7 = {
  key: 1,
  class: "plans-curr-plan-wrapper"
}
const _hoisted_8 = {
  key: 0,
  class: "plans-wrapper"
}
const _hoisted_9 = {
  key: 1,
  class: "plans-empty"
}

import { onBeforeMount, reactive } from 'vue'
import { Organization, OrgPlan } from '../models'
import { eventBus } from '@/eventBus'
import { usePlansStore } from '@/stores/plans'
import { useOrganazationStore } from '@/stores/organization'


export default /*@__PURE__*/_defineComponent({
  __name: 'PlansView',
  emits: ['to-payments'],
  setup(__props, { emit: __emit }) {

const plansStore = usePlansStore()
const orgStore = useOrganazationStore()

const state = reactive({
  organization: {} as Organization,
  plans: [] as Array<OrgPlan>,
  loading: true,
  actDialog: false,
  choosenPlan: {} as OrgPlan,
  showDialog: false,
  toPayments: false
})

const dialogState = reactive({
  header: '',
  msgText: '',
  isLoadind: false,
  showButtons: true,
  cancelButton: false
})

const emits = __emit

onBeforeMount(async () => {
  state.organization = JSON.parse(sessionStorage.getItem('organization') || '{}')
  await getAvailiblePlans()
})

async function getAvailiblePlans () {
  await plansStore.getPlansAction()
  const plansTmp = plansStore.getPlans
  if (plansTmp.length > 0) {
    state.plans = plansTmp.filter((item) => item.planId !== state.organization.plan.planId)
  }
  state.loading = false
}

function choosePlan (plan: OrgPlan) {
  state.choosenPlan = plan
  state.actDialog = true
}

async function choosePlanAction (res: string) {
  state.actDialog = false
  if (res === 'ok') {
    state.showDialog = true
    dialogState.isLoadind = true
    dialogState.showButtons = false
    await orgStore.getOrganizationAction(state.organization.orgId)
    const orgCurr = orgStore.getOrganization
    if (orgCurr.balance < state.choosenPlan.price) {
      state.toPayments = true
      dialogState.isLoadind = false
      dialogState.showButtons = true
      dialogState.cancelButton = true
      dialogState.header = 'Ошибка'
      dialogState.msgText = 'Недостаточно средств на Вашем балансе.<br>Хотите пополнить?'
    } else {
      const result = await plansStore.buyPlan(state.organization.orgId, state.choosenPlan.planId)
      dialogState.isLoadind = false
      dialogState.showButtons = true
      state.toPayments = false
      dialogState.cancelButton = result!.status === 'assigned'
      dialogState.header = result!.status === 'assigned' ? 'Успех' : 'Ошибка'
      dialogState.msgText = result!.status !== 'assigned' ? 'Упс! Что-то пошло не так!' : result!.message
      if (result!.status === 'assigned') {
        await orgStore.getOrganizationAction(state.organization.orgId)
        const updOrganization = orgStore.getOrganization
        sessionStorage.setItem('organization', JSON.stringify(updOrganization))
        state.organization = updOrganization
        eventBus.emit('update-plan')
        await getAvailiblePlans()
      }
    }
  }
}

function dialogRes (res: string) {
  state.showDialog = false
  if (res === 'ok' && state.toPayments) {
    emits('to-payments')
  }
}

return (_ctx: any,_cache: any) => {
  const _component_u_plan = _resolveComponent("u-plan")!
  const _component_u_spinner = _resolveComponent("u-spinner")!
  const _component_u_choose_plan = _resolveComponent("u-choose-plan")!
  const _component_u_dialog = _resolveComponent("u-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _cache[1] || (_cache[1] = _createElementVNode("span", { class: "plan-pay-info-panel-info" }, "Ваш баланс", -1)),
          _createElementVNode("span", _hoisted_4, _toDisplayString(state.organization.balance) + " сом", 1)
        ]),
        _createElementVNode("div", {
          class: "plan-pay-info-btn button-click",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(eventBus).emit('to-payments')))
        }, _cache[2] || (_cache[2] = [
          _createElementVNode("span", null, "Пополнить", -1)
        ]))
      ]),
      _createElementVNode("div", _hoisted_5, [
        _cache[3] || (_cache[3] = _createElementVNode("span", { class: "plans-type" }, " Ваш тариф ", -1)),
        _createVNode(_component_u_plan, {
          plan: state.organization.plan,
          isCurrent: true,
          paidDays: String(state.organization.paidDays)
        }, null, 8, ["plan", "paidDays"])
      ]),
      (state.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_u_spinner)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _cache[4] || (_cache[4] = _createElementVNode("span", { class: "plans-type" }, " Другие тарифы ", -1)),
            (state.plans.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(state.plans, (plan) => {
                    return (_openBlock(), _createBlock(_component_u_plan, {
                      plan: plan,
                      key: plan.planId,
                      onClickBtn: ($event: any) => (choosePlan(plan))
                    }, null, 8, ["plan", "onClickBtn"]))
                  }), 128))
                ]))
              : (_openBlock(), _createElementBlock("span", _hoisted_9, " Для Вас нет актуальных тарифов. "))
          ]))
    ]),
    (state.actDialog)
      ? (_openBlock(), _createBlock(_component_u_choose_plan, {
          key: 0,
          plan: state.plans[0],
          onClickRes: choosePlanAction
        }, null, 8, ["plan"]))
      : _createCommentVNode("", true),
    (state.showDialog)
      ? (_openBlock(), _createBlock(_component_u_dialog, {
          key: 1,
          header: dialogState.header,
          msgText: dialogState.msgText,
          isLoadind: dialogState.isLoadind,
          showButtons: dialogState.showButtons,
          cancelButton: dialogState.cancelButton,
          onClickRes: dialogRes
        }, null, 8, ["header", "msgText", "isLoadind", "showButtons", "cancelButton"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})