import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "org-pay-info-wrapper" }
const _hoisted_2 = { class: "org-pay-info-panel" }
const _hoisted_3 = { class: "org-pay-info-panel-sum" }

import { eventBus } from '@/eventBus'


export default /*@__PURE__*/_defineComponent({
  __name: 'UOrgPayInfo',
  props: {
  sum: {
    type: Number,
    required: true
  }
},
  setup(__props) {

const props = __props

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createElementVNode("span", { class: "org-pay-info-panel-info" }, "Ваш баланс", -1)),
      _createElementVNode("span", _hoisted_3, _toDisplayString(props.sum) + " сом", 1)
    ]),
    _createElementVNode("div", {
      class: "org-pay-info-btn button-click",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(eventBus).emit('to-payments')))
    }, _cache[2] || (_cache[2] = [
      _createElementVNode("span", null, "Пополнить", -1)
    ]))
  ]))
}
}

})