<template>
    <transition name="udialog-fade">
        <div class="udialog-overlay" v-if="true">
            <div class="success-wrapper">
                <img class="success-img"
                    :src="success"
                />
                <span class="success-text">
                    {{ props.type === 'departments' ? 'Отдел' : 'Должность' }} успешно добавлен{{ props.type === 'departments' ? '' : 'а' }}!
                </span>
                <div class="success-btn button-click"
                    @click="emits('close')"
                >
                    <span>Понятно</span>
                </div>
            </div>
        </div>
    </transition>
</template>

<script setup lang="ts">
import success from '@/assets/icon/success.svg'

const emits = defineEmits(['close'])

const props = defineProps({
  type: {
    type: String,
    required: true
  }
})
</script>

<style>
.success-wrapper {
    display: flex;
    flex-direction: column;
    background: linear-gradient(90deg, #042447 0%, #2D4B6B 100%);
    padding: 3.71vh 2.344vw;
    border-radius: 3.71vh;
    gap: 2.315vh;
    width: 20.834vw;
    align-items: center;
}

.success-img {
    height: 4.63vh;
    width: auto;
}

.success-text {
    font: 700 2.04vh 'RedHatText-Bold', 'OpenSans', sans-serif;
    color: var(--primary-font-color);
}

.success-btn {
    margin-top: 2.315vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font: 700 1.482vh 'RedHatText-Bold', 'OpenSans', sans-serif;
    color: var(--primary-font-color);
    background-color: #042447;
    border-radius: 3.71vh;
    height: 4.7223vh;
    width: -webkit-fill-available;
}
</style>
