import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "widgets who-late" }
const _hoisted_2 = { class: "who-late-candeles-body" }
const _hoisted_3 = {
  key: 0,
  class: "candeles-body"
}
const _hoisted_4 = { class: "candele" }
const _hoisted_5 = { class: "candele-info" }
const _hoisted_6 = { class: "candele" }
const _hoisted_7 = { class: "candele-info" }
const _hoisted_8 = {
  key: 1,
  class: "who-late-spinner"
}

import { onBeforeMount, reactive, watch } from 'vue'
import { delay } from '@/globalFunctions'


export default /*@__PURE__*/_defineComponent({
  __name: 'UWhoLate',
  props: {
  employsQty: {
    type: Number,
    default: 0,
    required: true
  },
  late: {
    type: Number,
    default: 0,
    required: true
  },
  earlyLeave: {
    type: Number,
    default: 0,
    required: true
  },
  isLoading: {
    type: Boolean,
    default: true,
    required: true
  }
},
  emits: ['to-report'],
  setup(__props, { emit: __emit }) {

const props = __props

const emits = __emit

const state = reactive({
  late: 0,
  earlyLeave: 0
})

watch(() => props.late, async (newValue, oldValue) => {
  if (newValue !== oldValue) {
    await calcCandleSize()
  }
})

watch(() => props.earlyLeave, async (newValue, oldValue) => {
  if (newValue !== oldValue) {
    await calcCandleSize()
  }
})

onBeforeMount(async () => {
  await calcCandleSize()
})

async function calcCandleSize () {
  const minValue = 0.63
  const maxValue = 5.3
  const step = 0.05

  const maxCandleSize = Math.max(props.late, props.earlyLeave)
  let employs = props.employsQty
  if (maxCandleSize > props.employsQty) {
    employs = maxCandleSize
  } else {
    employs = props.employsQty
  }

  const onePercEmpl = employs / 100
  const onePercCandle = maxValue / 100

  const whoLateMax = props.late / onePercEmpl * onePercCandle
  const whoLeaveMax = props.earlyLeave / onePercEmpl * onePercCandle

  while (state.late < minValue) {
    state.late += step
    state.earlyLeave += step
    await delay(1)
  }
  state.late = 1
  state.earlyLeave = 1
  if (maxCandleSize !== 0 && employs !== 0) {
    while (state.late < whoLateMax + 1 || state.earlyLeave < whoLeaveMax + 1) {
      if (state.late < whoLateMax) {
        state.late += step
      }
      if (state.earlyLeave < whoLeaveMax) {
        state.earlyLeave += step
      }
      await delay(1)
    }
    state.late = whoLateMax + 1
    state.earlyLeave = whoLeaveMax + 1
  }
}

return (_ctx: any,_cache: any) => {
  const _component_u_widget_header = _resolveComponent("u-widget-header")!
  const _component_u_spinner = _resolveComponent("u-spinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_u_widget_header, {
      headerText: 'Фиксация',
      onIconClicked: _cache[0] || (_cache[0] = ($event: any) => (emits('to-report')))
    }),
    _createElementVNode("div", _hoisted_2, [
      (!props.isLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("span", _hoisted_5, _toDisplayString(props.late), 1),
              _createElementVNode("div", {
                id: "candele-late",
                style: _normalizeStyle({ height: state.late + 'vw' })
              }, null, 4)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("span", _hoisted_7, _toDisplayString(props.earlyLeave), 1),
              _createElementVNode("div", {
                id: "candele-early-leave",
                style: _normalizeStyle({ height: state.earlyLeave + 'vw' })
              }, null, 4)
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createVNode(_component_u_spinner)
          ])),
      _cache[1] || (_cache[1] = _createStaticVNode("<div class=\"info-rows\" data-v-4d3e1ef6><div class=\"info-row\" data-v-4d3e1ef6><div class=\"square\" id=\"who-late\" data-v-4d3e1ef6></div><span data-v-4d3e1ef6>Опоздали</span></div><div class=\"info-row\" data-v-4d3e1ef6><div class=\"square\" id=\"who-leave\" data-v-4d3e1ef6></div><span data-v-4d3e1ef6>Ушли раньше</span></div></div>", 1))
    ])
  ]))
}
}

})