import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import { useCookesStore } from '../stores/cookies'
import ReportView from '../views/ReportView.vue'
import MainView from '@/views/MainView.vue'
import AuthView from '@/views/AuthView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/auth',
    name: 'auth',
    component: AuthView
  },
  {
    path: '/',
    name: 'main',
    component: MainView
  },
  {
    path: '/report',
    name: 'report',
    component: ReportView
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach(async (to, from, next) => {
  const cookies = useCookesStore()
  await cookies.getCookie()
  const cookiesDict = cookies.getCookies
  const currentDate = new Date().getTime()
  let token = null
  let expDate = null
  try {
    token = cookies.getToken
    expDate = cookiesDict.expTime
  } catch {
    token = null
  }
  if (!token || !expDate || currentDate > expDate) {
    if (to.path !== '/auth') {
      next('/auth')
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
