import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pay-steps-wrapper" }

import { reactive } from 'vue'
import Terminal from '../components/widgets/pay_instructions/TerminalInstr.vue'
import Visa from '../components/widgets/pay_instructions/VisaPay.vue'
import O from '../components/widgets/pay_instructions/OPay.vue'

interface DialogArgs {
  error?: boolean;
  header?: string;
  body: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'PaySteps',
  props: {
  summ: {
    type: String,
    required: true
  },
  type: {
    type: String,
    required: true
  }
},
  emits: ['to-prev'],
  setup(__props, { emit: __emit }) {

const props = __props

const state = reactive({
  showDialog: false,
  dialogHeader: '',
  dialogBody: '',
  isLoader: false,
  error: false
})

const emits = __emit

function showLoader () {
  state.isLoader = true
  state.showDialog = true
}

function showDialog (args: DialogArgs) {
  state.error = args.error ?? false
  state.isLoader = false
  state.dialogHeader = args.header ?? state.error ? 'Ошибка' : 'Успех'
  state.dialogBody = args.body
  state.showDialog = true
}

function closeDialog () {
  state.showDialog = false
  if (state.error) {
    emits('to-prev')
  }
}

return (_ctx: any,_cache: any) => {
  const _component_u_dialog = _resolveComponent("u-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (['AsisNur', 'Qpay', 'elsom'].includes(props.type))
        ? (_openBlock(), _createBlock(Terminal, {
            key: 0,
            summ: props.summ,
            type: props.type,
            onShowLoader: _cache[0] || (_cache[0] = ($event: any) => (showLoader())),
            onCloseLoader: _cache[1] || (_cache[1] = ($event: any) => (state.showDialog = false)),
            onShowDialog: showDialog
          }, null, 8, ["summ", "type"]))
        : _createCommentVNode("", true),
      (props.type === 'visa')
        ? (_openBlock(), _createBlock(Visa, {
            key: 1,
            summ: props.summ,
            onShowLoader: _cache[2] || (_cache[2] = ($event: any) => (showLoader())),
            onCloseLoader: _cache[3] || (_cache[3] = ($event: any) => (state.showDialog = false)),
            onShowDialog: showDialog
          }, null, 8, ["summ"]))
        : _createCommentVNode("", true),
      (props.type === 'O')
        ? (_openBlock(), _createBlock(O, {
            key: 2,
            summ: props.summ,
            onShowLoader: _cache[4] || (_cache[4] = ($event: any) => (showLoader())),
            onCloseLoader: _cache[5] || (_cache[5] = ($event: any) => (state.showDialog = false)),
            onShowDialog: showDialog
          }, null, 8, ["summ"]))
        : _createCommentVNode("", true)
    ]),
    (state.showDialog)
      ? (_openBlock(), _createBlock(_component_u_dialog, {
          key: 0,
          header: state.dialogHeader,
          msgText: state.dialogBody,
          isLoadind: state.isLoader,
          onClickRes: _cache[6] || (_cache[6] = ($event: any) => (closeDialog()))
        }, null, 8, ["header", "msgText", "isLoadind"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})