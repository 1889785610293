import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, vShow as _vShow, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "rep-onworkplace-wrapper" }
const _hoisted_2 = { class: "rep-onworkplace-header" }
const _hoisted_3 = { class: "rep-onworkplace-table" }
const _hoisted_4 = { class: "rep-onworkplace-column-name rep-onworkplace-column-item" }
const _hoisted_5 = { class: "rep-onworkplace-column-is-on-place rep-onworkplace-column-item" }
const _hoisted_6 = { class: "rep-onworkplace-column-fine rep-onworkplace-column-item" }
const _hoisted_7 = { class: "rep-onworkplace-column-fine rep-onworkplace-column-item" }

import { onBeforeMount, reactive } from 'vue'
import { Employee, Fix, SubFilterMap } from '@/models'
import {
  unitTimestampConvert,
  convertToSubFilterMap,
  exportToExcel,
  dateConvert,
  ExcelData
} from '@/globalFunctions'
import { eventBus } from '@/eventBus'
import { useEmploysStore } from '@/stores/employs'


export default /*@__PURE__*/_defineComponent({
  __name: 'OnWorkplace',
  setup(__props) {

const state = reactive({
  persons: [] as Employee[],
  id: '',
  todayFixData: [] as Fix[],
  showFilter: false,
  orgId: '',
  filterParams: {} as SubFilterMap,
  filteredData: [] as ExcelData[],
  showDialog: false
})

const employs = useEmploysStore()

const filterSteps = [
  { type: 'all', text: 'Все' },
  { type: 'no', text: 'Нет' },
  { type: 'yes', text: 'Да' }
]

const filter = reactive({
  onPlace: filterSteps[0]
})

function filterSet (obj: SubFilterMap) {
  state.filterParams = obj
}

eventBus.on('showFilter', () => {
  state.showFilter = true
})

eventBus.on('reloadReport', async () => {
  await loadData(true)
})

eventBus.on('filter', (obj) => {
  const validObj = convertToSubFilterMap(obj)
  if (validObj !== null) {
    state.filterParams = validObj
  }
})

eventBus.on('excelExport', () => {
  prepareDataForExport()
  exportToExcel(state.filteredData, `on-workplace-${dateConvert(new Date(), true)}`)
})

onBeforeMount(async () => {
  const org = JSON.parse(sessionStorage.getItem('organization') || '{}')
  if (org.orgId) {
    state.orgId = org.orgId
    await loadData()
  }
})

async function loadData (reload: boolean = false) {
  if (reload) {
    state.showDialog = true
    await employs.getEmploysAction(state.orgId)
    sessionStorage.setItem('persons', JSON.stringify(state.persons))
    state.showDialog = false
  }
  state.persons = JSON.parse(sessionStorage.getItem('persons') || '[]')
  state.todayFixData = JSON.parse(sessionStorage.getItem('today-fix') || '[]')
}

function isOnPlace (id: string): boolean {
  const arriveFix = state.todayFixData.find(item => item.perId === id && item.type === 'arrive')
  const leaveFix = state.todayFixData.find(item => item.perId === id && item.type === 'leave')
  return !!arriveFix && !leaveFix
}

function filterWhoOnPlace (type: string) {
  const index = filterSteps.findIndex(item => item.type === type)
  filter.onPlace = filterSteps[filterSteps.length - 1 !== index ? index + 1 : 0]
}

function prepareDataForExport () {
  state.filteredData = state.persons.filter(showRow).map(person => {
    const arriveFix = state.todayFixData.find(item => item.perId === person.id && item.type === 'arrive')
    const leaveFix = state.todayFixData.find(item => item.perId === person.id && item.type === 'leave')
    const personName = state.persons.find(item => item.id === person.id)?.name
    return {
      Сотрудник: typeof personName === 'string' ? personName : 'Не указан',
      'На месте': isOnPlace(person.id) ? 'Да' : 'Нет',
      'Время прихода': arriveFix ? unitTimestampConvert(arriveFix.time, 'datetime') : '-',
      'Время ухода': leaveFix ? unitTimestampConvert(leaveFix.time, 'datetime') : '-'
    }
  })
}

function showRow (person: Employee) {
  if (Object.keys(state.filterParams).length > 0) {
    if (state.filterParams.person.length > 0 &&
        state.filterParams.person !== person.id) {
      return false
    }
    if (state.filterParams.department.length > 0 &&
        !person.depArr.includes(state.filterParams.department.toString())) {
      return false
    }
  }
  if (filter.onPlace.type !== 'all') {
    const onPlace = isOnPlace(person.id)
    if (filter.onPlace.type === 'no') {
      return !onPlace
    } else {
      return onPlace
    }
  }
  return true
}

return (_ctx: any,_cache: any) => {
  const _component_u_filters = _resolveComponent("u-filters")!
  const _component_u_dialog = _resolveComponent("u-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "rep-onworkplace-column-name rep-onworkplace-column-item" }, [
          _createElementVNode("span", null, "Имя")
        ], -1)),
        _createElementVNode("div", {
          class: "rep-onworkplace-column-is-on-place rep-onworkplace-column-item rep-onworkplace-header-block button-click",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (filterWhoOnPlace(filter.onPlace.type)))
        }, [
          _cache[2] || (_cache[2] = _createElementVNode("span", null, "На месте", -1)),
          _createElementVNode("span", {
            class: _normalizeClass([{'rep-onworkplace-row-on-place': filter.onPlace.type === 'no'}])
          }, _toDisplayString(filter.onPlace.text), 3)
        ]),
        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "rep-onworkplace-column-fine rep-onworkplace-column-item" }, [
          _createElementVNode("span", null, "Время прихода")
        ], -1)),
        _cache[5] || (_cache[5] = _createElementVNode("div", { class: "rep-onworkplace-column-fine rep-onworkplace-column-item" }, [
          _createElementVNode("span", null, "Время ухода")
        ], -1))
      ]),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(state.persons, (person) => {
          return _withDirectives((_openBlock(), _createElementBlock("div", {
            key: person.id
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(['rep-onworkplace-table-row button-click',
                    {
                        'rep-onworkplace-row-active': person.id === state.id,
                        'rep-onworkplace-row-on-place': !isOnPlace(person.id)
                    }])
            }, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("span", null, _toDisplayString(person.name.trim()), 1)
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("span", null, _toDisplayString(isOnPlace(person.id) ? 'Да' : 'Нет'), 1)
              ]),
              _createElementVNode("div", _hoisted_6, _toDisplayString(_unref(unitTimestampConvert)(state.todayFixData.find(item => item.perId === person.id && item.type === 'arrive')?.time ?? 0, 'datetime') !== ''
                            ? _unref(unitTimestampConvert)(state.todayFixData.find(item => item.perId === person.id && item.type === 'arrive')?.time ?? 0, 'datetime')
                            : '-'), 1),
              _createElementVNode("div", _hoisted_7, _toDisplayString(_unref(unitTimestampConvert)(state.todayFixData.find(item => item.perId === person.id && item.type === 'leave')?.time ?? 0, 'datetime') !== ''
                            ? _unref(unitTimestampConvert)(state.todayFixData.find(item => item.perId === person.id && item.type === 'leave')?.time ?? 0, 'datetime')
                            : '-'), 1)
            ], 2),
            _cache[6] || (_cache[6] = _createElementVNode("div", { class: "row-delimeter" }, null, -1))
          ])), [
            [_vShow, showRow(person)]
          ])
        }), 128))
      ])
    ]),
    (state.showFilter)
      ? (_openBlock(), _createBlock(_component_u_filters, {
          key: 0,
          onClose: _cache[1] || (_cache[1] = ($event: any) => (state.showFilter = false)),
          onFilterSet: filterSet,
          filters: { date: false, employs: true, department: true }
        }))
      : _createCommentVNode("", true),
    (state.showDialog)
      ? (_openBlock(), _createBlock(_component_u_dialog, {
          key: 1,
          header: '',
          showButtons: false,
          isLoadind: true
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})