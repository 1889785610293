import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "fix-rep-header" }
const _hoisted_2 = { class: "fix-rep-back-block" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "fix-rep-header-label" }
const _hoisted_5 = { class: "fix-rep-buttons-block" }
const _hoisted_6 = ["src"]
const _hoisted_7 = ["src"]
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "filters-wrapper" }

import back from '@/assets/icon/back.svg'
import reload from '@/assets/icon/reload.svg'
import excel from '@/assets/icon/excel-export.svg'
import { eventBus } from '@/eventBus'
import filter from '@/assets/icon/filter.svg'
import { reactive, inject } from 'vue'
import { Employee, Departments, SubFilterMap } from '@/models'
import { ArrayMap } from '@/components/UI/UDropdownFilter.vue'
import { unitTimestampConvert, convertToSubFilterMap } from '@/globalFunctions'


export default /*@__PURE__*/_defineComponent({
  __name: 'UReportsHeader',
  props: {
  reportName: {
    type: String,
    required: true
  },
  persons: {
    type: Array as () => Employee[],
    required: true
  },
  departments: {
    type: Array as () => Departments,
    required: true
  }
},
  emits: ['to-dashboard'],
  setup(__props, { emit: __emit }) {

const days: ArrayMap[] = inject('days', [])

const props = __props

const emits = __emit

const state = reactive({
  filterObj: {} as SubFilterMap
})

eventBus.on('filter', (obj) => {
  const validObj = convertToSubFilterMap(obj)
  if (validObj !== null) {
    state.filterObj = validObj
  }
})

function returnValue (key: string, value: string | Array<number>) {
  if (key === 'person') {
    const person = props.persons?.find(item => item.id === value)
    return person ? person.name : 'Неизвестно'
  }
  if (key === 'department') {
    const department = props.departments?.find(item => item.id === value)
    return department ? department.name : 'Неизвестно'
  }
  if (key === 'date') {
    if (typeof value === 'string') {
      const date = days.find(item => item.value === value)
      return date ? date.text : 'Неизвестно'
    } else {
      return `${unitTimestampConvert(value[0])} - ${unitTimestampConvert(value[1])}`
    }
  }
}

function delFilter (filterKey: string) {
  state.filterObj[filterKey] = ''
  eventBus.emit('filter', state.filterObj)
}

return (_ctx: any,_cache: any) => {
  const _component_u_filter_obj = _resolveComponent("u-filter-obj")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        class: "button-click filter-icon",
        src: _unref(back),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (emits('to-dashboard')))
      }, null, 8, _hoisted_3),
      _createElementVNode("span", _hoisted_4, _toDisplayString(props.reportName), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("img", {
        class: "button-click filter-icon",
        src: _unref(filter),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(eventBus).emit('showFilter')))
      }, null, 8, _hoisted_6),
      _createElementVNode("img", {
        class: "button-click filter-icon",
        src: _unref(reload),
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_unref(eventBus).emit('reloadReport')))
      }, null, 8, _hoisted_7),
      _createElementVNode("img", {
        class: "button-click filter-icon",
        src: _unref(excel),
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_unref(eventBus).emit('excelExport')))
      }, null, 8, _hoisted_8)
    ]),
    _createElementVNode("div", _hoisted_9, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys(state.filterObj), (filterKey, index) => {
        return (_openBlock(), _createElementBlock("div", { key: index }, [
          (state.filterObj[filterKey].length > 0 && state.filterObj[filterKey] !== 'empty')
            ? (_openBlock(), _createBlock(_component_u_filter_obj, {
                key: 0,
                label: `${returnValue(filterKey, state.filterObj[filterKey])}`,
                filterKey: filterKey,
                onDelFilter: delFilter
              }, null, 8, ["label", "filterKey"]))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ])
  ]))
}
}

})