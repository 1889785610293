import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'UIconButton',
  props: {
  type: String,
  isActive: {
    type: Boolean,
    default: false,
    required: false
  }
},
  emits: ['icon-btn-clicked'],
  setup(__props, { emit: __emit }) {

const props = __props

const emits = __emit

const isMobile = window.innerWidth < 800

return (_ctx: any,_cache: any) => {
  const _component_u_icon = _resolveComponent("u-icon")!

  return (!isMobile)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["icon-button-wrapper button-click", { 'icon-button': props.isActive }]),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (emits('icon-btn-clicked', props.type)))
      }, [
        _createVNode(_component_u_icon, {
          type: props.type,
          fontSize: 1.04,
          fontUnit: 'vw',
          iconPdRt: 0.83,
          iconPdUnit: "'vw'",
          showText: true,
          iconWidth: 1.30
        }, null, 8, ["type"])
      ], 2))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: _normalizeClass(["icon-button-wrapper button-click", { 'icon-button': props.isActive }]),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (emits('icon-btn-clicked', props.type)))
      }, [
        _createVNode(_component_u_icon, {
          type: props.type,
          fontSize: 4.07,
          fontUnit: 'vw',
          iconPdRt: 3.05,
          iconPdUnit: "'vw'",
          showText: true,
          iconWidth: 5.09
        }, null, 8, ["type"])
      ], 2))
}
}

})