import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "o-main-wrapper" }
const _hoisted_2 = { class: "o-pay-wrapper" }
const _hoisted_3 = { class: "pay-info-panel-wrapper-item" }
const _hoisted_4 = { class: "pay-info-panel-item-text" }
const _hoisted_5 = { class: "o-instruction-block" }
const _hoisted_6 = {
  key: 0,
  class: "visa-footer-btn-wrapper"
}

import { reactive, onBeforeMount } from 'vue'
import { PayOrder } from '@/classes'
import { Organization } from '@/models'
import { usePaymentStore } from '@/stores/pays'


export default /*@__PURE__*/_defineComponent({
  __name: 'OPay',
  props: {
  summ: {
    type: String,
    required: true
  }
},
  emits: ['show-loader', 'close-loader', 'show-dialog'],
  setup(__props, { emit: __emit }) {

const steps = [
  'Укажите номер телефона, который используется в приложении “Мой О!” и создайте счет',
  'Откройте в телефоне приложение “Мой О!”',
  'Откройте вкладку О! Деньги',
  'Выберите пункт “Неоплаченные счета” и оплатите. (Если пункта нет, обновите страницу)'
]

const payStore = usePaymentStore()

const state = reactive({
  organization: {} as Organization,
  phone: ''
})

const props = __props

const emits = __emit

onBeforeMount(() => {
  state.organization = JSON.parse(sessionStorage.getItem('organization') || '{}')
})

function inputPhone (login: string) {
  state.phone = login
}

async function getPayOrder () {
  emits('show-loader')
  const payBody = new PayOrder(state.organization.orgId, Number(props.summ), `996${state.phone}`)
  const result = await payStore.getOTPQpay('O', payBody)
  if (result.status === 'OK') {
    emits('close-loader')
    showDialog(false, 'Вам было выслано платежное поручение.')
  } else {
    showDialog(true, result.info)
  }
}

function showDialog (isError: boolean, body: string) {
  emits('show-dialog', { error: isError, body })
}

return (_ctx: any,_cache: any) => {
  const _component_u_instruction_step = _resolveComponent("u-instruction-step")!
  const _component_u_phone_number = _resolveComponent("u-phone-number")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[1] || (_cache[1] = _createElementVNode("span", { class: "pay-info-panel-item-header" }, " К оплате ", -1)),
        _createElementVNode("span", _hoisted_4, _toDisplayString(`${props.summ} сом`), 1)
      ]),
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "o-instruction-text" }, [
        _createElementVNode("span", null, "Инструкция по оплате")
      ], -1)),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(steps, (step, index) => {
          return _createVNode(_component_u_instruction_step, {
            key: index,
            step: String(index + 1),
            text: step,
            width: 27.7
          }, null, 8, ["step", "text"])
        }), 64))
      ]),
      _createVNode(_component_u_phone_number, {
        class: "o-input-phone",
        onInputPhone: inputPhone
      })
    ]),
    (state.phone.length === 9)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("div", {
            class: "visa-footer-btn button-click",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (getPayOrder()))
          }, _cache[3] || (_cache[3] = [
            _createElementVNode("span", null, "Создать счет", -1)
          ]))
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})