<template>
    <transition name="choose-plan-fade">
      <div class="choose-plan-overlay" v-if="true">
        <div class="choose-plan">
          <div class="choose-plan-header">
            <span>
              Выбрать тариф "{{ props.plan.name }}"?
            </span>
            <img :src="cancel"
              class="choose-plan-cancel-dialog button-click"
              @click="emits('click-res', 'cancel')"
            >
          </div>
          <u-plan :plan="props.plan" :hideActBtn="true"/>
          <div class="choose-plan-buttons"
          >
            <button @click="emits('click-res', 'ok')" class="ures-btn button-click" id="ok-btn">Ок</button>
            <button @click="emits('click-res', 'cancel')" class="ures-btn button-click" id="cancel-btn">Отмена</button>
          </div>
        </div>
      </div>
    </transition>
</template>

<script setup lang="ts">
import { PropType } from 'vue'
import { OrgPlan } from '@/models'
import cancel from '@/assets/icon/cancel-dialog.svg'

const props = defineProps({
  plan: {
    type: Object as PropType<OrgPlan>,
    required: true
  }
})

const emits = defineEmits(['click-res'])

</script>

<style scoped>
.choose-plan-overlay {
  position: fixed;
  z-index: 3000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}

.choose-plan {
  padding: 4.2vh 2.344vw;
  border-radius: 3.7vh;
  background-image: linear-gradient(245.9deg, #2D4B6B -40.66%, #042447 131.18%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5vh;
}

.choose-plan-buttons {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  width: -webkit-fill-available;
}

.ures-btn {
  font: 0.9rem 'RedHatText-Bold', sans-serif;
  color: var(--primary-font-color);
  padding: 0.8rem;
  border-radius: 5vw;
  border: none;
  width: -webkit-fill-available;
}

#ok-btn {
  background-color: #042447;
  color: var(--primary-font-color);
}

#cancel-btn {
  background-color: #FFFFFF1A;
  color: var(--primary-font-color-a70);
}

.choose-plan-header {
  font: 1.35rem 'RedHatText-Bold', sans-serif;
  color: var(--primary-font-color);
  display: flex;
  align-items: center;
  gap: 1vw;
}

.choose-plan-body {
  font: 0.8rem 'RedHatText-Regular', sans-serif;
  color: var(--primary-font-color);
}

.choose-plan-cancel-dialog {
  align-self: end;
}

@media screen and (max-width: 768px) {
  .choose-plan {
  width: 75vw;
  background-color: white;
  padding: 20px;
  border-radius: 8vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.choose-plan-buttons {
  display: flex;
  margin-top: 5vh;
  align-items: center;
  gap: 2vh;
}

.ures-btn {
  min-width: 30vw;
  font-family: 'RedHatText-SemiBold', 'OpenSans', sans-serif;
  font-size: 0.9em;
  color: #fff;
  padding: 2vw 3vw;
  border-radius: 5vw;
  width: fit-content;
  border: none;
}

.choose-plan-header {
  font-family: 'RedHatText-SemiBold', 'OpenSans', sans-serif;
  font-size: 1em;
  margin-bottom: 2vh;
}

.choose-plan-body {
  font-family: 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
  font-size: 0.8em;
}
}
</style>
