import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, vShow as _vShow, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "camera-wrapper" }
const _hoisted_2 = { class: "camera-header" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "camera" }
const _hoisted_5 = { class: "camera-loader" }
const _hoisted_6 = { class: "camera-actions" }
const _hoisted_7 = ["src"]

import { ref, onMounted, reactive } from 'vue'
import cancel from '@/assets/icon/cancel-dialog.svg'
import camera from '@/assets/icon/photo.svg'


export default /*@__PURE__*/_defineComponent({
  __name: 'UPhotoFrame',
  emits: ['getted-photo', 'dismiss'],
  setup(__props, { emit: __emit }) {

const video = ref<HTMLVideoElement | null>(null)
const canvas = ref<HTMLCanvasElement | null>(null)
const emits = __emit

const state = reactive({
  cameraReady: false
})

onMounted(() => {
  navigator.mediaDevices.getUserMedia({ video: true })
    .then(stream => {
      if (video.value) {
        video.value.srcObject = stream
      }
    })
    .catch(error => {
      console.error('Error accessing the camera:', error)
    })
})

function setCanvasSize () {
  state.cameraReady = true
  if (video.value && canvas.value) {
    canvas.value.width = video.value.videoWidth
    canvas.value.height = video.value.videoHeight
  }
}

function captureImage () {
  if (canvas.value && video.value) {
    const context = canvas.value.getContext('2d')
    if (context) {
      context.drawImage(video.value, 0, 0, canvas.value.width, canvas.value.height)
      const imageData = canvas.value.toDataURL('image/jpeg')
      stopCamera()
      emits('getted-photo', imageData)
    }
  }
}

function stopCamera () {
  if (video.value?.srcObject) {
    const tracks = (video.value.srcObject as MediaStream).getTracks()
    tracks.forEach(track => track.stop())
    video.value.srcObject = null
  }
}

function handleDismiss () {
  stopCamera()
  emits('dismiss')
}

return (_ctx: any,_cache: any) => {
  const _component_u_spinner = _resolveComponent("u-spinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[3] || (_cache[3] = _createElementVNode("span", { class: "add-person-header-text camera-header-text" }, " Сделать фото ", -1)),
      _createElementVNode("img", {
        src: _unref(cancel),
        class: "button-click",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (handleDismiss()))
      }, null, 8, _hoisted_3)
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_4, [
      _createElementVNode("video", {
        ref_key: "video",
        ref: video,
        autoplay: "",
        onLoadedmetadata: _cache[1] || (_cache[1] = ($event: any) => (setCanvasSize()))
      }, null, 544),
      _createElementVNode("canvas", {
        ref_key: "canvas",
        ref: canvas,
        style: {"display":"none"}
      }, null, 512)
    ], 512), [
      [_vShow, state.cameraReady]
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_5, [
      _createVNode(_component_u_spinner)
    ], 512), [
      [_vShow, !state.cameraReady]
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", {
        class: "camera-actions-btn button-click",
        id: "ok-btn",
        onClick: captureImage
      }, [
        _createElementVNode("img", { src: _unref(camera) }, null, 8, _hoisted_7),
        _cache[4] || (_cache[4] = _createElementVNode("span", { class: "camera-actions-btn-text" }, "Сделать фото", -1))
      ]),
      _createElementVNode("div", {
        class: "camera-actions-btn button-click",
        id: "cancel-btn",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (handleDismiss()))
      }, _cache[5] || (_cache[5] = [
        _createElementVNode("span", { class: "camera-actions-btn-text" }, "Вернуться", -1)
      ]))
    ])
  ]))
}
}

})