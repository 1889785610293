import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "auth-wrapper" }
const _hoisted_2 = { class: "bg-circles" }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "auth-content" }

import { reactive } from 'vue'
import circle1 from '@/assets/images/circle-1.svg'
import circle2 from '@/assets/images/circle-2.svg'
import circle3 from '@/assets/images/circle-3.svg'
import { Dialog } from '@/models'

export interface RegistrationParams {
  orgName: string,
  login: string,
  password: string,
  code: string
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AuthView',
  setup(__props) {

const dialogState = reactive({
  showDialog: false,
  dialogHeader: '',
  dialogMessage: '',
  dialogShowButtons: false,
  dialogLoading: false
})

const state = reactive({
  tab: 'auth',
  registrationParams: {} as RegistrationParams
})

function openDialog (dialogParams: Dialog) {
  const showButtons = dialogParams.showButtons ? dialogParams.showButtons : true
  const dialogMessage = dialogParams.message ? dialogParams.message : ''

  dialogState.dialogHeader = dialogParams.headerText
  dialogState.dialogMessage = dialogMessage
  dialogState.dialogShowButtons = showButtons
  dialogState.dialogLoading = dialogParams.loading
  dialogState.showDialog = true
}

function toCheckSMS (arg: RegistrationParams) {
  state.registrationParams = arg
  state.tab = 'sms'
}


return (_ctx: any,_cache: any) => {
  const _component_u_auth = _resolveComponent("u-auth")!
  const _component_u_registration = _resolveComponent("u-registration")!
  const _component_u_check_sms = _resolveComponent("u-check-sms")!
  const _component_u_pass_recovery = _resolveComponent("u-pass-recovery")!
  const _component_u_dialog = _resolveComponent("u-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("img", {
          src: _unref(circle1),
          alt: ""
        }, null, 8, _hoisted_3),
        _createElementVNode("img", {
          src: _unref(circle2),
          alt: ""
        }, null, 8, _hoisted_4),
        _createElementVNode("img", {
          src: _unref(circle3),
          alt: ""
        }, null, 8, _hoisted_5)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _withDirectives(_createVNode(_component_u_auth, {
          onOpenDialog: openDialog,
          onCloseDialog: _cache[0] || (_cache[0] = ($event: any) => (dialogState.showDialog = false)),
          onToRegistration: _cache[1] || (_cache[1] = ($event: any) => (state.tab = 'registration')),
          onToPassRecovery: _cache[2] || (_cache[2] = ($event: any) => (state.tab = 'passrecovery'))
        }, null, 512), [
          [_vShow, state.tab === 'auth']
        ]),
        _withDirectives(_createVNode(_component_u_registration, {
          onOpenDialog: openDialog,
          onToCheckNumber: toCheckSMS,
          onLogin: _cache[3] || (_cache[3] = ($event: any) => (state.tab = 'auth')),
          onCloseDialog: _cache[4] || (_cache[4] = ($event: any) => (dialogState.showDialog = false))
        }, null, 512), [
          [_vShow, state.tab === 'registration']
        ]),
        (state.tab === 'sms')
          ? (_openBlock(), _createBlock(_component_u_check_sms, {
              key: 0,
              onOpenDialog: openDialog,
              onCloseDialog: _cache[5] || (_cache[5] = ($event: any) => (dialogState.showDialog = false)),
              onToRegistration: _cache[6] || (_cache[6] = ($event: any) => (state.tab = 'registration')),
              registrationInfo: state.registrationParams
            }, null, 8, ["registrationInfo"]))
          : _createCommentVNode("", true),
        (state.tab === 'passrecovery')
          ? (_openBlock(), _createBlock(_component_u_pass_recovery, {
              key: 1,
              onOpenDialog: openDialog,
              onToAuth: _cache[7] || (_cache[7] = ($event: any) => (state.tab = 'auth')),
              onToCheckNumber: toCheckSMS,
              onCloseDialog: _cache[8] || (_cache[8] = ($event: any) => (dialogState.showDialog = false))
            }))
          : _createCommentVNode("", true)
      ])
    ]),
    (dialogState.showDialog)
      ? (_openBlock(), _createBlock(_component_u_dialog, {
          key: 0,
          header: dialogState.dialogHeader,
          msgText: dialogState.dialogMessage,
          showButtons: dialogState.dialogShowButtons,
          isLoadind: dialogState.dialogLoading,
          onClickRes: _cache[9] || (_cache[9] = ($event: any) => (dialogState.showDialog = false))
        }, null, 8, ["header", "msgText", "showButtons", "isLoadind"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})