<template>
  <div class="reports-list">
    <u-report-miniature  v-for="report in miniatureArr" :key="report" :type="report" @to-report="toReport"/>
  </div>
</template>

<script setup lang="ts">
const miniatureArr = ['fix', 'journal']

const emits = defineEmits(['to-report'])
function toReport (arg: string) {
  emits('to-report', arg)
}
</script>

<style>
.reports-list {
  display: flex;
  gap: 2.60vw;
}

@media (max-width: 950px) {
  .reports-list {
    padding-top: 0;
    flex-direction: column;
    flex-wrap: wrap;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
    max-height: 70vh;
  }
  .reports-list {
    /* padding-top: 7.8rem; */
    display: flex;
    gap: 5.09vw;
  }
}
</style>
