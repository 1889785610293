import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, vShow as _vShow, Transition as _Transition } from "vue"
import _imports_0 from '@/assets/icon/time.svg'
import _imports_1 from '@/assets/icon/vert-count-plus.svg'
import _imports_2 from '@/assets/icon/vert-count-minus.svg'


const _hoisted_1 = {
  key: 0,
  class: "udialog-overlay"
}
const _hoisted_2 = { class: "udialog" }
const _hoisted_3 = { class: "add-person-header" }
const _hoisted_4 = { class: "add-person-header-text" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "person-main" }
const _hoisted_7 = { class: "person-name-photo" }
const _hoisted_8 = ["src"]
const _hoisted_9 = {
  key: 0,
  class: "person-popup"
}
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "person-universal person-name" }
const _hoisted_12 = { class: "person-universal-wrapper" }
const _hoisted_13 = { class: "person-universal" }
const _hoisted_14 = ["src"]
const _hoisted_15 = {
  key: 0,
  class: "person-universal-list"
}
const _hoisted_16 = { class: "person-universal" }
const _hoisted_17 = ["src"]
const _hoisted_18 = {
  key: 1,
  class: "person-universal-list"
}
const _hoisted_19 = { class: "personal-timesets-wrapper" }
const _hoisted_20 = { class: "personal-timesets" }
const _hoisted_21 = { class: "input-slot-wrapper" }
const _hoisted_22 = ["onClick"]
const _hoisted_23 = { class: "personal-timesets" }
const _hoisted_24 = { class: "input-slot-wrapper" }
const _hoisted_25 = ["onClick"]
const _hoisted_26 = { class: "personal-timesets-wrapper" }
const _hoisted_27 = { class: "personal-timesets" }
const _hoisted_28 = { class: "input-slot-wrapper" }
const _hoisted_29 = { class: "personal-timesets" }
const _hoisted_30 = { class: "input-slot-wrapper" }
const _hoisted_31 = { class: "person-actions" }

import { Employee, Organization, Universal } from '@/models'
import { reactive, onBeforeMount, ref, PropType, watch } from 'vue'
import cancel from '@/assets/icon/cancel-dialog.svg'
import depIcon from '@/assets/icon/departments.svg'
import posIcon from '@/assets/icon/positions.svg'
import addPhoto from '@/assets/icon/person_add_photo.svg'
import editIcon from '@/assets/icon/org_item/edit.svg'
// import deleteIcon from '@/assets/icon/org_item/delete.svg'
import { UniversalResult } from '@/components/widgets/UUniversalSelector.vue'
import { v4 as uuidv4 } from 'uuid'
import {
  getTimeStampUTC,
  base64ToBlob,
  getHoursMinutesFromTimestamp
} from '@/globalFunctions'
import { useAzureStore } from '@/stores/azure'
import { useImagesYarosStore } from '@/stores/imagesYaros'
import { useEmploysStore } from '@/stores/employs'
import { cloneDeep, isEqual } from 'lodash'


export default /*@__PURE__*/_defineComponent({
  __name: 'UPersonData',
  props: {
  id: {
    type: String,
    default: ''
  },
  person: {
    type: Object as PropType<Employee>,
    default: null
  }
},
  emits: ['dismiss', 'show-loader', 'show-dialog', 'success-updated'],
  setup(__props, { emit: __emit }) {

const azureStore = useAzureStore()
const imageStore = useImagesYarosStore()
const personStore = useEmploysStore()

const state = reactive({
  person: {} as Employee,
  organization: {} as Organization,
  positions: [] as Universal[],
  departments: [] as Universal[],
  photoTmp: '',
  photo: '',
  showPhotoFrame: false,
  photoTypeIsOpen: false,
  getFileOpen: false,
  savePhotoDialogIsOpen: false,
  popupIsOpen: false,
  photoChanged: false
})

const universal = reactive({
  universalIsOpen: false,
  type: '',
  activeArray: [] as string[]
})

const redactData = reactive({
  data: {} as Employee
})

const props = __props

const emits = __emit
const startTime = ref({ hours: 9, minutes: 0 })
const endTime = ref({ hours: 18, minutes: 0 })
const lateTime = ref({ hours: 0, minutes: 0 })
const leaveTime = ref({ hours: 0, minutes: 0 })

onBeforeMount(async () => {
  if (props.person) {
    state.person = cloneDeep(props.person)
    const startTimeTmp = getHoursMinutesFromTimestamp(state.person.arrivalTime)
    startTime.value = { hours: startTimeTmp.hours, minutes: startTimeTmp.minutes }
    const endTimeTmp = getHoursMinutesFromTimestamp(state.person.leaveTime)
    endTime.value = { hours: endTimeTmp.hours, minutes: endTimeTmp.minutes }
    const lateTimeTmp = state.person.lateTime
    lateTime.value = { hours: Math.floor(lateTimeTmp / 60), minutes: lateTimeTmp % 60 }
    const leaveTimeTmp = state.person.earlyLeave
    leaveTime.value = { hours: Math.floor(leaveTimeTmp / 60), minutes: leaveTimeTmp % 60 }
  } else {
    state.person.orgId = state.organization.orgId
  }
  if (props.person && props.person.photo && props.person.photo !== '') {
    const cachedPhoto = sessionStorage.getItem(state.person.photo)
    if (cachedPhoto) {
      state.photo = `data:image/jpeg;base64, ${cachedPhoto}`
    } else {
      const result = await imageStore.getImage(state.organization.photoStorage, state.person.photo)
      if (result) {
        sessionStorage.setItem(state.person.photo, result)
      }
      state.photo = `data:image/jpeg;base64, ${result}` ?? ''
    }
  }
  state.organization = JSON.parse(sessionStorage.getItem('organization') || '{}')
  state.positions = JSON.parse(sessionStorage.getItem('positions') || '[]').positions
  state.departments = JSON.parse(sessionStorage.getItem('departments') || '[]')
})

function popupAction (action: string) {
  if (action === 'red') {
    state.popupIsOpen = false
    state.photoTypeIsOpen = true
  }
}

function getPhoto (base64: string) {
  state.photoTmp = base64
  state.showPhotoFrame = false
  state.savePhotoDialogIsOpen = true
}

function takePictureAgain () {
  state.savePhotoDialogIsOpen = false
  state.photoTypeIsOpen = true
}

function calcTime (timeType: string, actionType: string) {
  let timeObject
  if (timeType === 'lateTime') {
    timeObject = lateTime
  } else if (timeType === 'leaveTime') {
    timeObject = leaveTime
  } else {
    return
  }

  let minutesTotal = timeObject.value.hours * 60 + timeObject.value.minutes
  if (actionType === 'inc') {
    minutesTotal += 1
  } else if (actionType === 'dec') {
    minutesTotal -= 1
  }

  if (minutesTotal < 0) {
    minutesTotal = 0
  }

  timeObject.value = { hours: Math.floor(minutesTotal / 60), minutes: minutesTotal % 60 }
}

function choosePhotoType (type: string) {
  if (type === 'photo') {
    state.showPhotoFrame = true
  } else {
    state.getFileOpen = true
  }
  state.photoTypeIsOpen = false
}

function saveTakedPicture () {
  state.photo = state.photoTmp
  state.savePhotoDialogIsOpen = false
  state.photoChanged = true
}

function openUniversal (type: string) {
  universal.type = type
  const tmpArr = type === 'departments' ? state.person.depArr : state.person.posArr
  universal.activeArray = tmpArr ?? []
  universal.universalIsOpen = true
}

function saveUniversal (obj: UniversalResult) {
  const arrType = universal.type === 'departments' ? 'depArr' : 'posArr'
  const stringType = universal.type === 'departments' ? 'depId' : 'posOrg'
  universal.universalIsOpen = false
  if (obj.added.length > 0) {
    state.person[arrType] = [...state.person[arrType] ?? [], ...obj.added]
  }
  if (obj.removed.length > 0) {
    for (const id of obj.removed) {
      state.person[arrType] = state.person[arrType] ?? [].filter(item => item !== id)
    }
  }
  if (!state.person[stringType] || !state.person[arrType].includes(state.person[stringType])) {
    state.person[stringType] = state.person[arrType][0]
  }
}

function showDialog (header: string, message: string) {
  emits('show-dialog', { header, message })
}

async function savePerson () {
  if (!state.person.name || state.person.name === '') {
    showDialog('Ошибка', 'Укажите имя сотрудника.')
    return
  }
  if (!state.photo || state.photo === '') {
    showDialog('Ошибка', 'Необходимо сфотографировать сотрудника.')
    return
  }
  emits('show-loader')
  if (!props.person || props.person === null) {
    if (!state.person.id || state.person.id === '') {
      state.person.id = uuidv4()
    }
    const personResponse = await azureStore.addPersonCompanyAzure(
      state.organization.orgId,
      state.person.id,
      state.person.name
    )
    const azureId = personResponse?.personId
    if (!azureId) {
      showDialog('Ошибка', 'Упс! Что-то пошло не так!')
      return
    }
    const blob = await base64ToBlob(state.photo)
    const azurePhotoResponse = await azureStore.addPhotoPersonAzure(
      state.organization.orgId, azureId!, blob
    )
    const persistedFaceId = azurePhotoResponse?.persistedFaceId
    if (!persistedFaceId) {
      showDialog('Ошибка', 'Упс! Что-то пошло не так!')
      return
    }
    const imageId = await imageStore.postImageAction(blob)
    state.person.azureId = azureId!
    state.person.faceId = persistedFaceId!
    state.person.photo = imageId!
    const result = await personStore.registrationPerson(state.organization.orgId, state.person)
    if (result?.data.status === 'created') {
      await updPersonCache()
      const trainResult = await azureStore.trainGroupAzure(state.organization.orgId)
      if (trainResult !== 'success') {
        showDialog('Ошибка', 'Упс! Что-то пошло не так!')
        return
      }
      showDialog('Успех', result?.data.message)
      emits('success-updated')
    } else {
      showDialog('Ошибка', result?.data.message ?? 'Упс! Что-то пошло не так!')
    }
  } else {
    for (const key of Object.keys(state.person)) {
      if (!['id', 'orgId'].includes(key)) {
        if (state.person[key as keyof typeof state.person] !==
          props.person[key as keyof typeof props.person]) {
          if (!['posArr', 'depArr'].includes(key)) {
            if (['arrivalTime', 'leaveTime'].includes(key)) {
              if (getHoursMinutesFromTimestamp(Number(state.person[key as keyof typeof state.person])) !==
                getHoursMinutesFromTimestamp(Number(props.person[key as keyof typeof props.person]))) {
                redactData.data = { ...redactData.data, ...{ [key]: state.person[key as keyof typeof state.person] } }
              }
            }
            redactData.data = { ...redactData.data, ...{ [key]: state.person[key as keyof typeof state.person] } }
          } else {
            if (!isEqual(state.person[key as keyof typeof state.person],
              props.person[key as keyof typeof props.person])) {
              redactData.data = { ...redactData.data, ...{ [key]: state.person[key as keyof typeof state.person] } }
            }
          }
        }
      }
    }
    if (state.photoChanged) {
      const blob = await base64ToBlob(state.photo)
      const azurePhotoResponse = await azureStore.addPhotoPersonAzure(
        state.organization.orgId, state.person.azureId, blob
      )
      const persistedFaceId = azurePhotoResponse?.persistedFaceId
      if (!persistedFaceId) {
        showDialog('Ошибка', 'Упс! Что-то пошло не так!')
        return
      }
      const imageId = await imageStore.postImageAction(blob)
      if (!imageId) {
        showDialog('Ошибка', 'Упс! Что-то пошло не так!')
        return
      }
      redactData.data = {
        ...redactData.data,
        ...{
          faceId: persistedFaceId!,
          photo: imageId!
        }
      }
    }
    if (Object.keys(redactData.data).length > 0) {
      redactData.data = { ...redactData.data, ...{ id: state.person.id } }
      const result = await personStore.patchPerson(state.organization.orgId, [redactData.data])
      if (result?.status === 'complete') {
        await updPersonCache()
        if (state.photoChanged) {
          const trainResult = await azureStore.trainGroupAzure(state.organization.orgId)
          if (trainResult !== 'success') {
            showDialog('Ошибка', 'Упс! Что-то пошло не так!')
            return
          }
        }
        showDialog('Успех', 'Данные успешно обновлены')
        emits('success-updated')
      } else {
        showDialog('Ошибка', 'Упс! Что-то пошло не так!')
      }
    }
  }
}

async function updPersonCache () {
  await personStore.getEmploysAction(state.organization.orgId)
  const persons = personStore.getEmploys
  sessionStorage.setItem('persons', JSON.stringify(persons))
}

function getImage () {
  return state.photo === '' ? addPhoto : state.photo
}

watch(() => startTime.value, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    state.person.arrivalTime = getTimeStampUTC(newValue.hours, newValue.minutes, state.organization.timezone)
  }
})

watch(() => endTime.value, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    state.person.leaveTime = getTimeStampUTC(newValue.hours, newValue.minutes, state.organization.timezone)
  }
})

watch(() => lateTime.value, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    state.person.lateTime = newValue.hours * 60 + newValue.minutes
  }
})

watch(() => leaveTime.value, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    state.person.earlyLeave = newValue.hours * 60 + newValue.minutes
  }
})

return (_ctx: any,_cache: any) => {
  const _component_u_photo_frame = _resolveComponent("u-photo-frame")!
  const _component_u_universal_row = _resolveComponent("u-universal-row")!
  const _component_VueDatePicker = _resolveComponent("VueDatePicker")!
  const _component_u_choose_photo_type = _resolveComponent("u-choose-photo-type")!
  const _component_u_save_photo_dialog = _resolveComponent("u-save-photo-dialog")!
  const _component_u_universal_selector = _resolveComponent("u-universal-selector")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_Transition, { name: "udialog-fade" }, {
      default: _withCtx(() => [
        true
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              (state.showPhotoFrame)
                ? (_openBlock(), _createBlock(_component_u_photo_frame, {
                    key: 0,
                    onDismiss: _cache[0] || (_cache[0] = ($event: any) => (state.showPhotoFrame = false)),
                    onGettedPhoto: getPhoto
                  }))
                : _createCommentVNode("", true),
              _withDirectives(_createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("span", _hoisted_4, _toDisplayString(props.id != '' ? 'Редактировать' : 'Добавить') + " сотрудника ", 1),
                  _createElementVNode("img", {
                    src: _unref(cancel),
                    class: "button-click",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (emits('dismiss')))
                  }, null, 8, _hoisted_5)
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", null, [
                      _createElementVNode("div", {
                        class: "person-add-photo-wrapper button-click",
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (state.popupIsOpen = true))
                      }, [
                        _createElementVNode("img", {
                          class: _normalizeClass(["person-add-photo-icon", {'person-add-photo-base64': state.photo !== ''}]),
                          src: getImage()
                        }, null, 10, _hoisted_8)
                      ]),
                      (state.popupIsOpen)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                            _createElementVNode("div", {
                              class: "person-popup-row button-click",
                              onClick: _cache[3] || (_cache[3] = ($event: any) => (popupAction('red')))
                            }, [
                              _createElementVNode("img", { src: _unref(editIcon) }, null, 8, _hoisted_10),
                              _cache[20] || (_cache[20] = _createElementVNode("span", { class: "person-popup-text" }, "Редактировать", -1))
                            ])
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_11, [
                      _cache[21] || (_cache[21] = _createElementVNode("span", { class: "person-label" }, "Имя", -1)),
                      _withDirectives(_createElementVNode("input", {
                        class: "person-name-input",
                        type: "text",
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((state.person.name) = $event)),
                        placeholder: "Введите имя"
                      }, null, 512), [
                        [_vModelText, state.person.name]
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("div", _hoisted_13, [
                      _cache[23] || (_cache[23] = _createElementVNode("span", { class: "person-label" }, "Отдел", -1)),
                      _createElementVNode("div", {
                        class: "person-universal-btn",
                        onClick: _cache[5] || (_cache[5] = ($event: any) => (openUniversal('departments')))
                      }, [
                        _createElementVNode("img", {
                          src: _unref(depIcon),
                          class: "person-universal-icon"
                        }, null, 8, _hoisted_14),
                        _cache[22] || (_cache[22] = _createElementVNode("span", null, "Выбрать", -1))
                      ])
                    ]),
                    (state.person.depArr && state.person.depArr.length > 0)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(state.person.depArr ?? [], (dep) => {
                            return (_openBlock(), _createBlock(_component_u_universal_row, {
                              key: dep,
                              item: state.departments.find(item => item.id === dep),
                              onDelUniversal: ($event: any) => (state.person.depArr = state.person.depArr.filter(id => id !== dep))
                            }, null, 8, ["item", "onDelUniversal"]))
                          }), 128))
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_16, [
                      _cache[25] || (_cache[25] = _createElementVNode("span", { class: "person-label" }, "Должность", -1)),
                      _createElementVNode("div", {
                        class: "person-universal-btn",
                        onClick: _cache[6] || (_cache[6] = ($event: any) => (openUniversal('positions')))
                      }, [
                        _createElementVNode("img", {
                          src: _unref(posIcon),
                          class: "person-universal-icon"
                        }, null, 8, _hoisted_17),
                        _cache[24] || (_cache[24] = _createElementVNode("span", null, "Выбрать", -1))
                      ])
                    ]),
                    (state.person.posArr && state.person.posArr.length > 0)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(state.person.posArr ?? [], (pos) => {
                            return (_openBlock(), _createBlock(_component_u_universal_row, {
                              key: pos,
                              item: state.positions.find(item => item.id === pos),
                              onDelUniversal: ($event: any) => (state.person.posArr = state.person.posArr.filter(id => id !== pos))
                            }, null, 8, ["item", "onDelUniversal"]))
                          }), 128))
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_19, [
                    _createElementVNode("div", _hoisted_20, [
                      _cache[26] || (_cache[26] = _createElementVNode("span", { class: "person-label" }, "Начало рабочего дня", -1)),
                      _createVNode(_component_VueDatePicker, {
                        "time-picker": "",
                        format: "HH:mm",
                        modelValue: startTime.value,
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((startTime).value = $event)),
                        locale: "ru",
                        "cancel-text": "Отмена",
                        "select-text": "Ок",
                        class: "vue-datepicker personal-timepicker"
                      }, {
                        "clear-icon": _withCtx(({ open }) => [
                          _createElementVNode("div", _hoisted_21, [
                            _createElementVNode("img", {
                              class: "input-slot-image",
                              src: _imports_0,
                              onClick: open
                            }, null, 8, _hoisted_22)
                          ])
                        ]),
                        _: 1
                      }, 8, ["modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_23, [
                      _cache[27] || (_cache[27] = _createElementVNode("span", { class: "person-label" }, "Конец рабочего дня", -1)),
                      _createVNode(_component_VueDatePicker, {
                        "time-picker": "",
                        format: "HH:mm",
                        modelValue: endTime.value,
                        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((endTime).value = $event)),
                        locale: "ru",
                        "cancel-text": "Отмена",
                        "select-text": "Ок",
                        class: "vue-datepicker personal-timepicker"
                      }, {
                        "clear-icon": _withCtx(({ open }) => [
                          _createElementVNode("div", _hoisted_24, [
                            _createElementVNode("img", {
                              class: "input-slot-image",
                              src: _imports_0,
                              onClick: open
                            }, null, 8, _hoisted_25)
                          ])
                        ]),
                        _: 1
                      }, 8, ["modelValue"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_26, [
                    _createElementVNode("div", _hoisted_27, [
                      _cache[28] || (_cache[28] = _createElementVNode("span", { class: "person-label" }, "Возможные мин. опоздания", -1)),
                      _createVNode(_component_VueDatePicker, {
                        "time-picker": "",
                        format: lateTime.value.hours > 0 ? 'HH ч. mm м.' : 'm м.',
                        modelValue: lateTime.value,
                        "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((lateTime).value = $event)),
                        locale: "ru",
                        "cancel-text": "Отмена",
                        "select-text": "Ок",
                        class: "vue-datepicker personal-timepicker"
                      }, {
                        "clear-icon": _withCtx(() => [
                          _createElementVNode("div", _hoisted_28, [
                            _createElementVNode("img", {
                              class: "input-slot-inc",
                              src: _imports_1,
                              onClick: _cache[9] || (_cache[9] = _withModifiers(($event: any) => (calcTime('lateTime', 'inc')), ["stop"]))
                            }),
                            _createElementVNode("img", {
                              class: "input-slot-dec",
                              src: _imports_2,
                              onClick: _cache[10] || (_cache[10] = _withModifiers(($event: any) => (calcTime('lateTime', 'dec')), ["stop"]))
                            })
                          ])
                        ]),
                        _: 1
                      }, 8, ["format", "modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_29, [
                      _cache[29] || (_cache[29] = _createElementVNode("span", { class: "person-label" }, "Возможные мин. раннего ухода", -1)),
                      _createVNode(_component_VueDatePicker, {
                        "time-picker": "",
                        format: leaveTime.value.hours > 0 ? 'HH ч. mm м.' : 'm м.',
                        modelValue: leaveTime.value,
                        "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((leaveTime).value = $event)),
                        locale: "ru",
                        "cancel-text": "Отмена",
                        "select-text": "Ок",
                        class: "vue-datepicker personal-timepicker"
                      }, {
                        "clear-icon": _withCtx(() => [
                          _createElementVNode("div", _hoisted_30, [
                            _createElementVNode("img", {
                              class: "input-slot-inc",
                              src: _imports_1,
                              onClick: _cache[12] || (_cache[12] = _withModifiers(($event: any) => (calcTime('leaveTime', 'inc')), ["stop"]))
                            }),
                            _createElementVNode("img", {
                              class: "input-slot-dec",
                              src: _imports_2,
                              onClick: _cache[13] || (_cache[13] = _withModifiers(($event: any) => (calcTime('leaveTime', 'dec')), ["stop"]))
                            })
                          ])
                        ]),
                        _: 1
                      }, 8, ["format", "modelValue"])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_31, [
                  _createElementVNode("div", {
                    class: "person-actions-btn button-click",
                    id: "ok-btn",
                    onClick: _cache[15] || (_cache[15] = ($event: any) => (savePerson()))
                  }, _cache[30] || (_cache[30] = [
                    _createElementVNode("span", { class: "person-actions-btn-text" }, "Сохранить", -1)
                  ])),
                  _createElementVNode("div", {
                    class: "person-actions-btn button-click",
                    id: "cancel-btn",
                    onClick: _cache[16] || (_cache[16] = ($event: any) => (emits('dismiss')))
                  }, _cache[31] || (_cache[31] = [
                    _createElementVNode("span", { class: "person-actions-btn-text" }, "Отмена", -1)
                  ]))
                ])
              ], 512), [
                [_vShow, !state.showPhotoFrame]
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (state.photoTypeIsOpen)
      ? (_openBlock(), _createBlock(_component_u_choose_photo_type, {
          key: 0,
          onDismiss: _cache[17] || (_cache[17] = ($event: any) => (state.photoTypeIsOpen = false)),
          onResult: choosePhotoType,
          onGettedPhoto: getPhoto
        }))
      : _createCommentVNode("", true),
    (state.savePhotoDialogIsOpen)
      ? (_openBlock(), _createBlock(_component_u_save_photo_dialog, {
          key: 1,
          photo: state.photoTmp,
          onSave: saveTakedPicture,
          onDismiss: _cache[18] || (_cache[18] = ($event: any) => (takePictureAgain()))
        }, null, 8, ["photo"]))
      : _createCommentVNode("", true),
    (universal.universalIsOpen)
      ? (_openBlock(), _createBlock(_component_u_universal_selector, {
          key: 2,
          type: universal.type,
          dataArray: universal.type === 'departments' ? state.departments : state.positions,
          activeArray: universal.activeArray,
          onDismiss: _cache[19] || (_cache[19] = ($event: any) => (universal.universalIsOpen = false)),
          onSave: saveUniversal
        }, null, 8, ["type", "dataArray", "activeArray"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})