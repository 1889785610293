import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "rep-onworkplace-wrapper" }
const _hoisted_2 = { class: "rep-onworkplace-header" }
const _hoisted_3 = { class: "rep-onworkplace-table" }
const _hoisted_4 = { class: "rep-onworkplace-column-name rep-onworkplace-column-item" }
const _hoisted_5 = { class: "rep-onworkplace-column-is-on-place rep-onworkplace-column-item" }
const _hoisted_6 = { class: "rep-onworkplace-column-fine rep-onworkplace-column-item" }
const _hoisted_7 = { class: "rep-onworkplace-column-is-on-place rep-onworkplace-column-item" }
const _hoisted_8 = { class: "rep-onworkplace-column-fine rep-onworkplace-column-item" }

import { onBeforeMount, reactive } from 'vue'
import { Employee, FeeList, Fix } from '@/models'
import { unitTimestampConvert } from '@/globalFunctions'


export default /*@__PURE__*/_defineComponent({
  __name: 'WhoLate',
  setup(__props) {

const state = reactive({
  persons: [] as Employee[],
  id: '',
  todayFixData: [] as Fix[],
  fee: [] as FeeList,
  startOfDay: 0
})

const filterSteps = [{ type: 'all', text: 'Все' }, { type: 'no', text: 'Нет' }, { type: 'yes', text: 'Да' }]

const filter = reactive({
  onPlace: filterSteps[0]
})

onBeforeMount(async () => {
  const now = new Date()
  state.startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime()
  state.persons = JSON.parse(sessionStorage.getItem('persons') || '[]')
  state.todayFixData = JSON.parse(sessionStorage.getItem('today-fix') || '[]')
  state.fee = JSON.parse(sessionStorage.getItem('fee') || '[]')
  console.log(getAloudLateTimeMS('5ad3711b-aed6-4b3a-b111-c3fdcd819383'))
})

function isOnPlace (id: string) {
  const index = state.todayFixData.findIndex(item => item.perId === id)
  return index > -1
}

function filterWhoOnPlace (type: string) {
  const index = filterSteps.findIndex(item => item.type === type)
  filter.onPlace = filterSteps[filterSteps.length - 1 !== index ? index + 1 : 0]
}

function showRow (id: string) {
  if (filter.onPlace.type === 'all') {
    return true
  } else {
    const onPlace = isOnPlace(id)
    if (filter.onPlace.type === 'no') {
      return !onPlace
    } else {
      return onPlace
    }
  }
}

function timeFine (id: string, type: string) {
  const item = state.todayFixData.filter(fix => fix.perId === id)
  const totalTime = item.find(fix => fix.type === type)?.time
  if (type === 'arrive') {
    const aloudLate = (state.fee.find(fee => fee.subjectId === id && fee.subjectType === 'per')?.lateTime ?? 0) * 60 * 1000
    const perStartDay = state.persons.find(per => per.id === id)?.arrivalTime ?? 0
    const timeToArrive = state.startOfDay + aloudLate + perStartDay
    if (!totalTime || totalTime === 0) {
      return Math.ceil((new Date().getTime() - timeToArrive) / 6000)
    } else {
      return Math.ceil((totalTime - timeToArrive) / 6000)
    }
  }
  if (type === 'leave') {
    const aloudLeave = state.fee.find(fee => fee.subjectId === id && fee.subjectType === 'per')?.earlyLeave ?? 0 * 60 * 1000
    const perEndDay = state.persons.find(per => per.id === id)?.leaveTime ?? 0
    const timeToLeave = state.startOfDay + perEndDay - aloudLeave
    if (!totalTime || totalTime === 0) {
      return 0
    } else {
      return Math.ceil((totalTime - timeToLeave))
    }
  }
}

function getAloudLateTimeMS (id: string) {
//   const perInfo = state.persons.find(per => per.id === id)
  const freeTime = 24 * 60 * 61 * 1000
  const perFee = state.fee.find(fee => fee.subjectId === id)
  if (perFee) {
    if (perFee.free) {
      return freeTime
    } else if (perFee.lateTime) {
      return perFee.lateTime
    }
  }
//   const perPos = perInfo?.posArr ?? []
//   if (perPos.length > 0) {
//     const posFee = state.fee.find(fee => fee.subjectId === id)
//     for (const pos)
//   }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "rep-onworkplace-column-name rep-onworkplace-column-item" }, [
        _createElementVNode("span", null, "Имя")
      ], -1)),
      _createElementVNode("div", {
        class: "rep-onworkplace-column-is-on-place rep-onworkplace-column-item rep-onworkplace-header-block",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (filterWhoOnPlace(filter.onPlace.type)))
      }, [
        _cache[2] || (_cache[2] = _createElementVNode("span", null, "Опоздали", -1)),
        _createElementVNode("span", {
          class: _normalizeClass([{'rep-onworkplace-row-on-place': filter.onPlace.type === 'no'}])
        }, _toDisplayString(filter.onPlace.text), 3)
      ]),
      _cache[5] || (_cache[5] = _createElementVNode("div", { class: "rep-onworkplace-column-fine rep-onworkplace-column-item" }, [
        _createElementVNode("span", null, "Время прихода")
      ], -1)),
      _createElementVNode("div", {
        class: "rep-onworkplace-column-is-on-place rep-onworkplace-column-item rep-onworkplace-header-block",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (filterWhoOnPlace(filter.onPlace.type)))
      }, [
        _cache[3] || (_cache[3] = _createElementVNode("span", null, "Ушли раньше", -1)),
        _createElementVNode("span", {
          class: _normalizeClass([{'rep-onworkplace-row-on-place': filter.onPlace.type === 'no'}])
        }, _toDisplayString(filter.onPlace.text), 3)
      ]),
      _cache[6] || (_cache[6] = _createElementVNode("div", { class: "rep-onworkplace-column-fine rep-onworkplace-column-item" }, [
        _createElementVNode("span", null, "Время ухода")
      ], -1))
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(state.persons, (person) => {
        return _withDirectives((_openBlock(), _createElementBlock("div", {
          key: person.id
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(['rep-onworkplace-table-row button-click', { 'rep-onworkplace-row-active': person.id === state.id, 'rep-onworkplace-row-on-place': !isOnPlace(person.id) }])
          }, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("span", null, _toDisplayString(person.name.trim()), 1)
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("span", null, _toDisplayString(timeFine(person.id, 'arrive') ?? 0 <= 0 ? '-' : timeFine(person.id, 'arrive')), 1)
            ]),
            _createElementVNode("div", _hoisted_6, _toDisplayString(isOnPlace(person.id)
                            ? _unref(unitTimestampConvert)(
                              state.todayFixData?.find(item => item.perId === person.id && item.type === 'arrive')?.time,
                              'datetime')
                            : '-'), 1),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("span", null, _toDisplayString(timeFine(person.id, 'leave') ?? 0 < 0 ? '-' : timeFine(person.id, 'leave')), 1)
            ]),
            _createElementVNode("div", _hoisted_8, _toDisplayString(isOnPlace(person.id)
                            ? _unref(unitTimestampConvert)(
                              state.todayFixData.find(item => item.perId === person.id && item.type === 'leave')?.time,
                              'datetime')
                            : '-'), 1)
          ], 2),
          _cache[7] || (_cache[7] = _createElementVNode("div", { class: "row-delimeter" }, null, -1))
        ])), [
          [_vShow, showRow(person.id)]
        ])
      }), 128))
    ])
  ]))
}
}

})