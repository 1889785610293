import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "udialog-overlay"
}
const _hoisted_2 = { class: "choose-person-wrapper" }
const _hoisted_3 = { class: "choose-person-header" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "choose-person-column" }
const _hoisted_6 = { class: "choose-person-actions" }

import { reactive, onBeforeMount, watch } from 'vue'
import { Employee, Organization, Positions } from '@/models'
import { useImagesYarosStore } from '@/stores/imagesYaros'
import { Photo } from '@/classes'
import cancel from '@/assets/icon/cancel-dialog.svg'
import { useEmploysStore } from '@/stores/employs'
import { eventBus } from '@/eventBus'

export interface ImagesState {
  [key: string]: Photo;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'UChoosePersons',
  props: {
  type: {
    type: String,
    required: true
  },
  id: {
    type: String,
    required: true
  }
},
  emits: ['dismiss', 'show-loader', 'show-dialog'],
  setup(__props, { emit: __emit }) {

const props = __props

const state = reactive({
  organization: {} as Organization,
  persons: [] as Employee[],
  positions: [] as Positions,
  images: reactive({} as ImagesState),
  added: [] as string[],
  removed: [] as string[]
})

const imagesStore = useImagesYarosStore()
const employsStore = useEmploysStore()

const emits = __emit

onBeforeMount(async () => {
  state.organization = JSON.parse(sessionStorage.getItem('organization') || '{}')
  state.persons = JSON.parse(sessionStorage.getItem('persons') || '[]')
  state.positions = JSON.parse(sessionStorage.getItem('positions') || '[]').positions
})

watch(() => state.persons, (newValue, oldValue) => {
  if (newValue !== oldValue && newValue.length > 0) {
    loadingImages()
  }
}, { deep: true })

async function loadingImages () {
  for (const person of state.persons) {
    if (person.photo && !(person.photo in state.images)) {
      let photo = new Photo()
      const photoId = person.photo
      try {
        const cachedPhoto = sessionStorage.getItem(person.photo)
        if (cachedPhoto) {
          photo = new Photo(cachedPhoto, false, false)
        } else {
          const result = await imagesStore.getImage(state.organization.fixStorage, photoId)
          if (result) {
            sessionStorage.setItem(person.photo, result)
            photo = new Photo(result, false, false)
          }
        }
      } catch (error) {
        console.error(`Failed to load image ${photoId}:`, error)
        photo = new Photo('', false, true)
      } finally {
        state.images = { ...state.images, ...{ [photoId]: photo } }
      }
    }
  }
}

function isChoosen (person: Employee) {
  let isActive = isChoosenInAction(person)
  if (!isActive && !state.removed.includes(person.id)) {
    if (props.type === 'positions' && person.posArr.includes(props.id)) {
      isActive = true
    } else if (props.type === 'departments' && person.depArr.includes(props.id)) {
      isActive = true
    }
  }
  return isActive
}

function isChoosenInAction (person: Employee) {
  return state.added.includes(person.id) && !state.removed.includes(person.id)
}

function addOrRemove (perId: string) {
  if (!state.removed.includes(perId)) {
    if (props.type === 'positions' &&
        state.persons.find(person => person.id === perId)?.posArr.includes(props.id)) {
      state.removed.push(perId)
      return
    } else if (props.type === 'departments' &&
        state.persons.find(person => person.id === perId)?.depArr.includes(props.id)) {
      state.removed.push(perId)
      return
    }
  }

  if (state.added.includes(perId)) {
    state.added = state.added.filter(item => item !== perId)
    state.removed.push(perId)
  } else if (state.removed.includes(perId)) {
    state.removed = state.removed.filter(item => item !== perId)
  } else {
    state.added.push(perId)
  }
}

async function saveClick () {
  const result = []
  const arrToPatch = props.type === 'departments' ? 'depArr' : 'posArr'
  const itemToPatch = props.type === 'departments' ? 'depId' : 'posOrg'
  for (const id of state.added) {
    result.push(
      {
        id,
        [arrToPatch]: [...state.persons.find(person => person.id === id)![arrToPatch], props.id],
        [itemToPatch]: props.id
      }
    )
  }
  for (const id of state.removed) {
    const tmpArr = state.persons.find(person => person.id === id)![arrToPatch].filter(item => item !== props.id)
    result.push(
      {
        id,
        [arrToPatch]: tmpArr,
        [itemToPatch]: tmpArr.length > 0 ? tmpArr[0] : ''
      }
    )
  }
  if (result.length > 0) {
    emits('show-loader')
    const patchResult = await employsStore.patchPerson(state.organization.orgId, result)
    if (patchResult!.status === 'complete') {
      await employsStore.getEmploysAction(state.organization.orgId)
      state.persons = employsStore.getEmploys
      sessionStorage.setItem('persons', JSON.stringify(state.persons))
      eventBus.emit('updatePersons')
      showDialog('Успех', 'Данные успешно обновлены', true)
      emits('dismiss')
    } else {
      showDialog('Ошибка', 'Упс! Что-то пошло не так!', false)
    }
  }
}

function showDialog (header: string, text: string, isSuccess: boolean) {
  emits('show-dialog', { header, text, isSuccess })
}

return (_ctx: any,_cache: any) => {
  const _component_u_person_row = _resolveComponent("u-person-row")!

  return (_openBlock(), _createBlock(_Transition, { name: "udialog-fade" }, {
    default: _withCtx(() => [
      true
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _cache[3] || (_cache[3] = _createElementVNode("span", { class: "choose-person-header-text" }, " Выберите сотрудников ", -1)),
                _createElementVNode("img", {
                  src: _unref(cancel),
                  class: "button-click",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (emits('dismiss')))
                }, null, 8, _hoisted_4)
              ]),
              _createElementVNode("div", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(state.persons, (person) => {
                  return (_openBlock(), _createBlock(_component_u_person_row, {
                    key: person.id,
                    person: person,
                    isChoosen: isChoosen(person),
                    posName: state.positions.find(item => item.id === person.posArr[0])?.name,
                    imgSrc: state.images[person.photo]?.imageSrc?? '',
                    onRowClick: ($event: any) => (addOrRemove(person.id))
                  }, null, 8, ["person", "isChoosen", "posName", "imgSrc", "onRowClick"]))
                }), 128))
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", {
                  class: "choose-person-actions-btn button-click",
                  id: "ok-btn",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (saveClick()))
                }, _cache[4] || (_cache[4] = [
                  _createElementVNode("span", { class: "choose-person-actions-btn-text" }, "Сохранить", -1)
                ])),
                _createElementVNode("div", {
                  class: "choose-person-actions-btn button-click",
                  id: "cancel-btn",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (emits('dismiss')))
                }, _cache[5] || (_cache[5] = [
                  _createElementVNode("span", { class: "choose-person-actions-btn-text" }, "Отмена", -1)
                ]))
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})