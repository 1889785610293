import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "organization-header" }
const _hoisted_2 = { class: "organization-header-tabs" }
const _hoisted_3 = { class: "organization-tabs-controller" }
const _hoisted_4 = ["id"]
const _hoisted_5 = ["id"]
const _hoisted_6 = ["id"]
const _hoisted_7 = { class: "organization-header-search" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "org-add-search-wrapper" }
const _hoisted_10 = ["src"]
const _hoisted_11 = ["src"]
const _hoisted_12 = { class: "organization-wrapper" }
const _hoisted_13 = { class: "no-results-main-wrapper" }
const _hoisted_14 = {
  key: 0,
  class: "no-results-wrapper"
}
const _hoisted_15 = ["src"]

import { Universal, Employee, Organization } from '@/models'
import { reactive, onBeforeMount, watch, computed } from 'vue'
import add from '@/assets/icon/org_item/add.svg'
import filter from '@/assets/icon/filters.svg'
import search from '@/assets/icon/search.svg'
import { UniversalRedArgs } from '@/components/widgets/UOrganizationItem.vue'
import { useUniversalStore, PostUniversal } from '@/stores/universal'
import { useEmploysStore } from '@/stores/employs'
import { useOrganazationStore } from '@/stores/organization'
import { eventBus } from '@/eventBus'
import { useAzureStore } from '@/stores/azure'
import { FiltersData } from '@/components/widgets/UFiltersView.vue'
import { cloneDeep } from 'lodash'
import noResults from '@/assets/icon/no_results.svg'

export interface UniversalSaveData {
  type: string
  data: PostUniversal
}

interface DialogInfo {
  header: string
  text: string
  isSuccess: boolean
}

interface PatchData {
  [key: string]: string[] | string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'OrganizationView',
  setup(__props) {

const universalStore = useUniversalStore()
const employsStore = useEmploysStore()
const orgStore = useOrganazationStore()
const azureStore = useAzureStore()

const state = reactive({
  tab: 'persons',
  organization: {} as Organization,
  departments: [] as Universal[],
  positions: [] as Universal[],
  persons: [] as Employee[],
  addUniversalIsOpen: false,
  addUniversalToPersonIsOpen: false,
  isSuccess: false,
  operType: '',
  filtersIsOpen: false,
  searchString: '',
  filtersOrg: {} as FiltersData
})

const dialogState = reactive({
  dialogHeader: '',
  dialogText: '',
  dialogShowBtns: true,
  dialogCancelBtn: false,
  loadingDialog: false,
  showDialog: false
})

const personData = reactive({
  addPersonIsOpen: false,
  person: null as Employee | null,
  id: ''
})

function personDataDismiss () {
  personData.addPersonIsOpen = false
  personData.person = null
  personData.id = ''
}

const unversalAddState = reactive({
  name: '',
  id: '',
  perId: ''
})

onBeforeMount(() => {
  state.organization = JSON.parse(sessionStorage.getItem('organization') || '{}')
  state.departments = JSON.parse(sessionStorage.getItem('departments') || '[]')
  state.positions = JSON.parse(sessionStorage.getItem('positions') || '[]').positions
  state.persons = JSON.parse(sessionStorage.getItem('persons') || '[]')
  state.filtersOrg.departments = state.departments.map(item => item.id)
  state.filtersOrg.positions = state.positions.map(item => item.id)
  state.filtersOrg.persons = state.persons.map(item => item.id)
})

function updatePersons () {
  state.persons = JSON.parse(sessionStorage.getItem('persons') || '[]')
  state.filtersOrg.positions = state.positions.map(item => item.id)
  personDataDismiss()
}

function addFilters (filters: FiltersData) {
  state.filtersOrg = cloneDeep(filters)
}

const visibleItemsCount = computed(() => {
  if (state.tab === 'persons') {
    return state.persons.filter(item => searchPerson(item)).length
  } else {
    const items = state.tab === 'departments' ? state.departments : state.positions
    return items.filter(item =>
      item.name.toLowerCase().includes(state.searchString.toLowerCase()) &&
      filterUniversal(item)
    ).length
  }
})

function openUnversalAdd (name: string = '', id: string = '') {
  if (['departments', 'positions'].includes(state.tab)) {
    unversalAddState.name = name
    unversalAddState.id = id
    state.addUniversalIsOpen = true
  } else {
    personData.addPersonIsOpen = true
  }
}

function itemAction (args: UniversalRedArgs) {
  state.operType = args.type
  if (args.type === 'red') {
    openUnversalAdd(args.name, args.id)
  } else {
    unversalAddState.id = args.id
    openDialog('Удалить?',
    `Вы уверены, что хотите удалить ${args.name}?`,
    true, true
    )
  }
}

function personDataDialog (args: Record<string, string>) {
  state.persons = JSON.parse(sessionStorage.getItem('persons') || '[]')
  openDialog(args.header, args.message, true, false)
}

function calcPersons (id: string) {
  let count = 0
  for (const person of state.persons) {
    switch (state.tab) {
      case 'departments':
        if (person.depArr.includes(id)) {
          count += 1
        }
        break
      case 'positions':
        if (person.posArr.includes(id)) {
          count += 1
        }
        break
      default:
        count += 0
    }
  }
  return count
}

async function saveUniversal (data: UniversalSaveData) {
  if (data.type === 'post') {
    showLoader()
    const result = await universalStore.postUniversal(state.organization.orgId, state.tab, data.data)
    if (result && result.status === 'created') {
      await updateOrgData()
      closeDialog()
      state.addUniversalIsOpen = false
      state.isSuccess = true
    }
  }
}

async function dialogResult (res: string) {
  try {
    if (res === 'cancel') {
      closeDialog()
    } else {
      showLoader()
      if (state.operType === 'del') {
        const res = await universalStore.delUniversal(state.organization.orgId, state.tab, unversalAddState.id)
        if (res?.status === 'updated') {
          await updateOrgData()
          openDialog('Успех', res.message, true, false)
        } else {
          openDialog('Ошибка', 'Упс! Что-то пошло не так!', true, false)
        }
      } else if (state.operType === 'deleteUniversalPerson') {
        const tmpArrName = state.tab === 'departments' ? 'depArr' : 'posArr'
        const tmpArgName = state.tab === 'departments' ? 'depId' : 'posOrg'
        const tmpArr = state.persons.find(person => unversalAddState.perId === person.id)![tmpArrName].filter(unId => unId !== unversalAddState.id)
        let patchData: PatchData = {
          id: unversalAddState.perId,
          [tmpArrName]: tmpArr
        }
        if (tmpArr.length === 0) {
          patchData = { ...patchData, ...{ [tmpArgName]: '' } }
        }
        const res = await employsStore.patchPerson(state.organization.orgId, [patchData])
        if (res?.status === 'complete') {
          await updateOrgData(true)
          eventBus.emit('updatePersons')
          openDialog('Успех', res.message, true, false)
        } else {
          openDialog('Ошибка', 'Упс! Что-то пошло не так!', true, false)
        }
      } else if (state.operType === 'deletePerson') {
        const result = await employsStore.deletePerson(state.organization.orgId, personData.id)
        await azureStore.delPersonCompanyAzure(personData.person!)
        await employsStore.getEmploysAction(state.organization.orgId)
        state.persons = employsStore.getEmploys
        sessionStorage.setItem('persons', JSON.stringify(state.persons))
        if (result!.status === 'delete') {
          openDialog('Успех', result?.message, true, false)
        } else {
          openDialog('Ошибка', 'Упс! Что-то пошло не так!', true, false)
        }
      } else {
        closeDialog()
        personData.id = ''
        personData.person = null
      }
    }
  } catch {
    openDialog('Ошибка', 'Упс! Что-то пошло не так!', true, false)
  } finally {
    state.operType = ''
  }
}

function showLoader () {
  openDialog('', '', false, false, true)
}

function openDialog (
  header: string,
  text: string = '',
  showBtns: boolean = true,
  showCancelBtn: boolean = true,
  isLoadind: boolean = false
) {
  dialogState.dialogHeader = header
  dialogState.dialogText = text
  dialogState.dialogShowBtns = showBtns
  dialogState.dialogCancelBtn = showCancelBtn
  dialogState.loadingDialog = isLoadind
  dialogState.showDialog = true
}

async function showResDialog (res: DialogInfo) {
  if (res.isSuccess) {
    state.persons = JSON.parse(sessionStorage.getItem('persons') || '[]')
  }
  openDialog(res.header, res.text, true, false)
}

function closeDialog () {
  dialogState.showDialog = false
}

async function updateOrgData (withPersons: boolean = false) {
  await orgStore.getDepartmentsAction(state.organization.orgId)
  state.departments = orgStore.getDepartments
  sessionStorage.setItem('departments', JSON.stringify(state.departments))
  await employsStore.getPositionsAction(state.organization.orgId)
  sessionStorage.setItem('positions', JSON.stringify(employsStore.getPositions))
  state.departments = JSON.parse(sessionStorage.getItem('departments') || '[]')
  state.positions = JSON.parse(sessionStorage.getItem('positions') || '[]').positions
  if (withPersons) {
    await employsStore.getEmploysAction(state.organization.orgId)
    state.persons = employsStore.getEmploys
    sessionStorage.setItem('persons', JSON.stringify(state.persons))
  }
}

async function closeSuccessDialog () {
  state.isSuccess = false
}

function delPerson (perId: string) {
  state.operType = 'deleteUniversalPerson'
  unversalAddState.perId = perId
  let text = ''
  if (state.tab === 'departments') {
    text = `исключить ${state.persons.find(person => person.id === perId)?.name} из ${state.departments.find(dep => dep.id === unversalAddState.id)?.name}?`
  } else if (state.tab === 'positions') {
    text = `убрать ${state.departments.find(pos => pos.id === unversalAddState.id)?.name} из должностей ${state.persons.find(person => person.id === perId)?.name}?`
  }
  openDialog('Исключить?', `Уверены, что хотите ${text}`)
}

function personAction (args: Record<string, string>) {
  personData.person = state.persons.find(per => per.id === args.id)!
  if (args.type === 'red') {
    personData.addPersonIsOpen = true
  } else {
    state.operType = 'deletePerson'
    personData.id = args.id
    openDialog('Удалить?', `Уверены, что хотите удалить ${state.persons.find(per => per.id === args.id)?.name}?`)
  }
}

watch(() => state.tab, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    state.searchString = ''
  }
})

function filterUniversal (item: Universal) {
  return state.filtersOrg[state.tab as keyof typeof state.filtersOrg].includes(item.id)
}

function searchPerson (person: Employee) {
  if (!state.filtersOrg.persons.includes(person.id)) {
    return false
  }
  let i = 0
  for (const dep of person.depArr) {
    if (!state.filtersOrg.departments.includes(dep)) {
      i += 1
    }
  }
  if (person.depArr.length === i) {
    return false
  }
  i = 0
  for (const dep of person.posArr) {
    if (!state.filtersOrg.positions.includes(dep)) {
      i += 1
    }
  }
  if (person.posArr.length === i) {
    return false
  }
  if (state.searchString === '' || person.name.toLowerCase().indexOf(state.searchString.toLocaleLowerCase()) > -1) {
    return true
  }
  for (const dep of person.depArr) {
    if (!state.filtersOrg.departments.includes(dep)) {
      i += 1
    }
    const depName = state.departments.find(item => item.id === dep)?.name
    if (depName && depName
      .toLowerCase()
      .indexOf(state.searchString.toLowerCase()) > -1
    ) {
      return true
    }
  }
  for (const pos of person.posArr) {
    const posName = state.positions.find(item => item.id === pos)?.name
    if (posName && posName
      .toLowerCase()
      .indexOf(state.searchString.toLowerCase()) > -1
    ) {
      return true
    }
  }
  return false
}

return (_ctx: any,_cache: any) => {
  const _component_u_org_pay_info = _resolveComponent("u-org-pay-info")!
  const _component_u_organization_item = _resolveComponent("u-organization-item")!
  const _component_u_person_item = _resolveComponent("u-person-item")!
  const _component_u_add_universal = _resolveComponent("u-add-universal")!
  const _component_u_person_data = _resolveComponent("u-person-data")!
  const _component_u_choose_persons = _resolveComponent("u-choose-persons")!
  const _component_u_success_added = _resolveComponent("u-success-added")!
  const _component_u_dialog = _resolveComponent("u-dialog")!
  const _component_u_filters_view = _resolveComponent("u-filters-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("span", {
              class: "button-click",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (state.tab = 'persons')),
              id: state.tab === 'persons' ? 'organization-tabs-controller-active' : ''
            }, "Сотрудники", 8, _hoisted_4),
            _createElementVNode("span", {
              class: "button-click",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (state.tab = 'departments')),
              id: state.tab === 'departments' ? 'organization-tabs-controller-active' : ''
            }, "Отделы", 8, _hoisted_5),
            _createElementVNode("span", {
              class: "button-click",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (state.tab = 'positions')),
              id: state.tab === 'positions' ? 'organization-tabs-controller-active' : ''
            }, "Должности", 8, _hoisted_6)
          ]),
          _createVNode(_component_u_org_pay_info, {
            sum: state.organization.balance
          }, null, 8, ["sum"])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", {
            class: "org-add-btn button-click",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (openUnversalAdd()))
          }, [
            _createElementVNode("img", { src: _unref(add) }, null, 8, _hoisted_8),
            _cache[13] || (_cache[13] = _createElementVNode("span", { class: "org-add-btn-text" }, "Добавить", -1))
          ]),
          _createElementVNode("div", _hoisted_9, [
            _withDirectives(_createElementVNode("input", {
              class: "org-add-search-string",
              placeholder: "Поиск...",
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((state.searchString) = $event))
            }, null, 512), [
              [_vModelText, state.searchString]
            ]),
            _createElementVNode("img", {
              class: "org-add-search-img",
              src: _unref(search)
            }, null, 8, _hoisted_10)
          ]),
          _createElementVNode("div", {
            class: "org-add-filters",
            onClick: _cache[5] || (_cache[5] = ($event: any) => (state.filtersIsOpen = true))
          }, [
            _createElementVNode("img", { src: _unref(filter) }, null, 8, _hoisted_11)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_12, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(state.tab === 'departments' ? state.departments : state.positions, (item) => {
          return _withDirectives((_openBlock(), _createBlock(_component_u_organization_item, {
            onAction: itemAction,
            key: item.id,
            type: state.tab,
            name: item.name,
            id: item.id,
            qty: calcPersons(item.id)
          }, null, 8, ["type", "name", "id", "qty"])), [
            [_vShow, ['departments', 'positions'].includes(state.tab) &&
              (state.searchString === '' ||
              item.name.toLocaleLowerCase().indexOf(state.searchString.toLocaleLowerCase()) > -1) &&
              filterUniversal(item)]
          ])
        }), 128)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(state.persons, (item) => {
          return _withDirectives((_openBlock(), _createBlock(_component_u_person_item, {
            key: item.id,
            imageStorage: state.organization.photoStorage,
            person: item,
            positions: state.positions ?? [],
            departments: state.departments ?? [],
            onAction: personAction
          }, null, 8, ["imageStorage", "person", "positions", "departments"])), [
            [_vShow, state.tab === 'persons' &&
              searchPerson(item)]
          ])
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_13, [
        (visibleItemsCount.value === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
              _createElementVNode("img", {
                class: "no-results-wrapper-img",
                src: _unref(noResults)
              }, null, 8, _hoisted_15),
              _cache[14] || (_cache[14] = _createElementVNode("span", { class: "no-results-wrapper-header" }, " Нет результатов ", -1)),
              _cache[15] || (_cache[15] = _createElementVNode("span", { class: "no-results-wrapper-body" }, " Удостоверьтесь в правильности введенных вами данных или сформулируйте запрос иначе. ", -1))
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    (state.addUniversalIsOpen)
      ? (_openBlock(), _createBlock(_component_u_add_universal, {
          key: 0,
          type: state.tab,
          text: unversalAddState.name,
          id: unversalAddState.id,
          onOpenChoosePerson: _cache[6] || (_cache[6] = ($event: any) => (state.addUniversalToPersonIsOpen = true)),
          onDismiss: _cache[7] || (_cache[7] = ($event: any) => (state.addUniversalIsOpen = false)),
          onSave: saveUniversal,
          onDelPerson: delPerson
        }, null, 8, ["type", "text", "id"]))
      : _createCommentVNode("", true),
    (personData.addPersonIsOpen)
      ? (_openBlock(), _createBlock(_component_u_person_data, {
          key: 1,
          onDismiss: personDataDismiss,
          onShowDialog: personDataDialog,
          onShowLoader: _cache[8] || (_cache[8] = ($event: any) => (showLoader())),
          onSuccessUpdated: _cache[9] || (_cache[9] = ($event: any) => (updatePersons())),
          person: personData.person
        }, null, 8, ["person"]))
      : _createCommentVNode("", true),
    (state.addUniversalToPersonIsOpen)
      ? (_openBlock(), _createBlock(_component_u_choose_persons, {
          key: 2,
          onDismiss: _cache[10] || (_cache[10] = ($event: any) => (state.addUniversalToPersonIsOpen = false)),
          onShowLoader: _cache[11] || (_cache[11] = ($event: any) => (showLoader())),
          onShowDialog: showResDialog,
          type: state.tab,
          id: unversalAddState.id
        }, null, 8, ["type", "id"]))
      : _createCommentVNode("", true),
    (state.isSuccess)
      ? (_openBlock(), _createBlock(_component_u_success_added, {
          key: 3,
          type: state.tab,
          onClose: closeSuccessDialog
        }, null, 8, ["type"]))
      : _createCommentVNode("", true),
    (dialogState.showDialog)
      ? (_openBlock(), _createBlock(_component_u_dialog, {
          key: 4,
          header: dialogState.dialogHeader,
          msgText: dialogState.dialogText,
          cancelButton: dialogState.dialogCancelBtn,
          showButtons: dialogState.dialogShowBtns,
          isLoadind: dialogState.loadingDialog,
          onClickRes: dialogResult
        }, null, 8, ["header", "msgText", "cancelButton", "showButtons", "isLoadind"]))
      : _createCommentVNode("", true),
    _withDirectives(_createVNode(_component_u_filters_view, {
      onDismiss: _cache[12] || (_cache[12] = ($event: any) => (state.filtersIsOpen = false)),
      onConfirm: addFilters
    }, null, 512), [
      [_vShow, state.filtersIsOpen]
    ])
  ], 64))
}
}

})