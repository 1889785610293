<template>
    <div class="phone-input inputs">
          <u-dropdown class="inp-dropdown" @dropdown-choosen="countryCodeChoosen"/>
          <div class="inp-delimeter"></div>
          <input type="tel" v-model="state.login" @input="formatPhoneNumber" placeholder="Номер телефона">
        </div>
</template>

<script setup lang="ts">
import { reactive, watch } from 'vue'
import { Country } from '@/models'

const state = reactive({
  login: '',
  country: {} as Country,
  rawPhoneNumber: ''
})

const emits = defineEmits(['change-country', 'input-phone'])

function countryCodeChoosen (country: Country) {
  state.country = country
  emits('change-country', state.country)
}

function formatPhoneNumber () {
  const numbers = state.rawPhoneNumber.replace(/\D/g, '')
  const clippedNumbers = numbers.slice(0, state.country.country === 'kgz' ? 9 : 10)

  state.login = clippedNumbers
    .split('')
    .reduce((acc, num) => {
      return `${acc}${num}`
    }, '')
}

watch(() => state.login, (newValue) => {
  state.rawPhoneNumber = newValue.replace(/\D/g, '')
  emits('input-phone', state.rawPhoneNumber)
})
</script>

<style>
.phone-input {
  position: relative;
  width: 20vw;
  padding: 1.20vw 1.82vw;
  margin-bottom: 1.04vw;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  border-radius: 20vw;
  background-color: var(--main-color);
}

.phone-input input {
  margin-left: 5vw;
  border-left: 1px solid rgba(255, 255, 255, 0.50);
  padding-left: 0.8vw;
}

.inp-dropdown {
  position: absolute!important;
  z-index: 2!important;
}

.inp-delimeter {
  border-right: 1px;
}

.phone-input input:-webkit-autofill,
.phone-input input:-webkit-autofill:hover,
.phone-input input:-webkit-autofill:focus,
.phone-input input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0px 1000px var(--main-color) inset !important;
    box-shadow: 0 0 0px 1000px var(--main-color) inset !important;
    -webkit-text-fill-color: white !important;
    transition: background-color 5000s ease-in-out 0s;
}

@media screen and (max-width: 950px) {
  .phone-input {
    width: 75vw;
    padding: 4.5vw 6.62vw;
    margin-bottom: 2.54vw;
    border-radius: 20vw;
}

  .phone-input input {
    margin-left: 20vw;
    border-left: 1px solid rgba(255, 255, 255, 0.50);
    padding-left: 2.5vw;
  }
}
</style>
