import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "org-info-wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "org-info-plan-icon-wrapper"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "org-info-plan-name" }
const _hoisted_5 = {
  key: 0,
  class: "org-info-empty-img"
}
const _hoisted_6 = {
  key: 1,
  class: "org-info-img"
}
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "org-info-org-name" }

import flame from '@/assets/icon/flame.svg'
import { useImagesYarosStore } from '@/stores/imagesYaros'
import { ref, watch, onBeforeMount, computed, reactive } from 'vue'
import noProfile from '@/assets/images/no_profile.svg'
import { Organization } from '@/models'


export default /*@__PURE__*/_defineComponent({
  __name: 'UOrgInfo',
  setup(__props) {

const imagesStore = useImagesYarosStore()

const imageSrc = ref('')

const state = reactive({
  organization: {} as Organization
})

onBeforeMount(() => {
  state.organization = JSON.parse(sessionStorage.getItem('organization') || '{}')
})

const gradientStyle = computed(() => {
  if (state.organization.plan) {
    const { gradientLow, gradientTop } = state.organization.plan
    return `linear-gradient(90deg, rgba(${gradientLow}, 1) 0%, rgba(${gradientTop}, 1) 100%)`
  }
  return ''
})

const loadImage = async () => {
  const cachedPhoto = localStorage.getItem(state.organization.logo)
  if (cachedPhoto) {
    return cachedPhoto
  }
  try {
    const result = await imagesStore.getImage(state.organization.photoStorage, state.organization.logo)
    if (result) {
      localStorage.setItem(state.organization.logo, result)
    }
    return result
  } catch (error) {
    return ''
  }
}

watch(() => state.organization.logo, (newValue) => {
  if (newValue.length > 0) {
    loadImage().then(result => { imageSrc.value = result || '' })
  }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "org-info-plan",
      style: _normalizeStyle({background: gradientStyle.value})
    }, [
      (state.organization.plan && state.organization.plan.planId !== '60a65616-7b11-11eb-80e9-000c29c2272a')
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _cache[0] || (_cache[0] = _createElementVNode("div", { class: "org-info-plan-ellipse" }, null, -1)),
            _createElementVNode("div", null, [
              _createElementVNode("img", {
                src: _unref(flame),
                class: "org-info-plan-icon"
              }, null, 8, _hoisted_3)
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("span", _hoisted_4, _toDisplayString(state.organization.plan.name), 1)
    ], 4),
    (state.organization.logo === '' || state.organization.photoStorage === '')
      ? (_openBlock(), _createElementBlock("div", _hoisted_5))
      : (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("img", {
            src: imageSrc.value == '' ? _unref(noProfile) : `data:image/jpeg;base64, ${imageSrc.value}`
          }, null, 8, _hoisted_7)
        ])),
    _createElementVNode("span", _hoisted_8, _toDisplayString(state.organization.name), 1)
  ]))
}
}

})