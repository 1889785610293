import { defineStore } from 'pinia'
import { AxiosError } from 'axios'
import { axiosMain } from '@/axios-setup'

export interface PostUniversal {
    id: string
    name: string
}

interface Result {
    status: string
    message: string
}

export const useUniversalStore = defineStore('universal', {
  state: () => ({
  }),
  actions: {
    async postUniversal (orgId: string, type: string, data: PostUniversal): Promise<Result | undefined> {
      try {
        const response = await axiosMain.post<Result>(`/${type === 'departments' ? 'Dep' : 'Pos'}/${orgId}`, data)
        return response.data
      } catch (error) {
        const axiosError = error as AxiosError
        console.error(axiosError.message)
        return undefined
      }
    },
    async delUniversal (orgId: string, type: string, id: string): Promise<Result | undefined> {
      try {
        const response = await axiosMain.delete<Result>(`/${type === 'departments' ? 'Dep' : 'Pos'}/${orgId}`,
          { params: { id } }
        )
        return response.data
      } catch (error) {
        const axiosError = error as AxiosError
        console.error(axiosError.message)
        return undefined
      }
    }
  },
  getters: {
  }
})
