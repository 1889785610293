<template>
    <transition name="udialog-fade">
      <div class="udialog-overlay" v-if="true">
        <div class="universal-wrapper">
            <div class="add-person-header">
                <span class="add-person-header-text">
                    {{ `Выберите ${props.type === 'departments' ? 'отдел' : 'должность'}` }}
                </span>
                <img :src="cancel" class="button-click" @click="emits('dismiss')">
            </div>
            <div class="universal-body">
                <div class="universal-row button-click"
                    @click="addOrRemove(item.id)"
                    v-for="item in props.dataArray" :key="item.id">
                    <div class="button-click universal-row-check">
                        <img :src="isChoosen(item.id) ? checkActive : checkNonActive" />
                    </div>
                    <span>{{ item.name }}</span>
                </div>
            </div>
            <div class="photo-result">
              <div class="photo-result-btn button-click"
                id="ok-btn"
                @click="emits('save')"
              >
                <span class="photo-result-btn-text"
                    @click="save()"
                >
                    Сохранить
                </span>
              </div>
              <div class="photo-result-btn button-click" id="cancel-btn"
                @click="emits('dismiss')"
              >
                <span class="photo-result-btn-text">Отмена</span>
              </div>
            </div>
        </div>
      </div>
    </transition>
</template>

<script setup lang="ts">
import { PropType, reactive, onBeforeMount } from 'vue'
import { Universal } from '@/models'
import checkActive from '@/assets/icon/check-box/active.svg'
import checkNonActive from '@/assets/icon/check-box/not-active.svg'
import cancel from '@/assets/icon/cancel-dialog.svg'
import { cloneDeep } from 'lodash'

export interface UniversalResult {
  added: string[],
  removed: string[]
}

const props = defineProps({
  type: {
    type: String,
    required: true
  },
  dataArray: {
    type: Array as PropType<Universal[]>,
    required: true
  },
  activeArray: {
    type: Array as PropType<string[]>,
    required: true
  }
})

const emits = defineEmits(['dismiss', 'save'])

const state = reactive({
  added: [] as string[],
  removed: [] as string[]
})

onBeforeMount(() => {
  state.added = cloneDeep(props.activeArray)
})

function addOrRemove (id: string) {
  if (state.added.includes(id)) {
    state.added = state.added.filter(item => item !== id)
    if (props.activeArray.includes(id)) {
      state.removed.push(id)
    }
  } else {
    if (state.removed.includes(id)) {
      state.removed = state.removed.filter(item => item !== id)
    }
    state.added.push(id)
  }
}

function isChoosen (id: string) {
  return state.added.includes(id) && !state.removed.includes(id)
}

function save () {
  for (const addId of props.activeArray) {
    if (state.added.includes(addId)) {
      state.added = state.added.filter(item => item !== addId)
    }
  }
  emits('save', { added: state.added, removed: state.removed })
}
</script>

<style>
.universal-wrapper {
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(245.9deg, #2D4B6B -40.66%, #042447 131.18%);
  padding: 3.71vh 2.345vw;
  border-radius: 3.71vh;
  align-items: flex-start;
  width: 20.831vw;
}
.universal-row {
  display: flex;
  font: 500 1.3889vh 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
  color: var(--primary-font-color);
  gap: 0.521vw;
  height: 3.334vh;
  align-items: center;
  padding: 0 0.7813vw;
  width: max-content;
}

.universal-row:hover {
  background-color: var(--primary-font-color-a10);
  border-radius: 9.3vh;
}

.universal-row-check {
  display: flex;
  align-items: center;
}

.universal-body {
  display: flex;
  flex-direction: column;
  gap: 0.925vh;
}
</style>
