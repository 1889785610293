import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, unref as _unref, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "fix-rep-wrapper" }
const _hoisted_2 = { class: "report-wrapper" }
const _hoisted_3 = { class: "fix-rep-left" }
const _hoisted_4 = { class: "fix-rep-legend" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "fix-rep-loading" }
const _hoisted_7 = {
  key: 1,
  class: "fix-report"
}
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "fix-report-timestamp" }
const _hoisted_10 = { class: "fix-report-row" }
const _hoisted_11 = {
  key: 0,
  class: "fix-report-row-dep"
}
const _hoisted_12 = { class: "fix-report-row org-pos org-pos-row" }
const _hoisted_13 = { class: "fix-report-row-org" }
const _hoisted_14 = {
  key: 0,
  class: "fix-report-row-dep"
}
const _hoisted_15 = { class: "fix-report-row act-type act-type-row" }
const _hoisted_16 = { class: "fix-report-row-org" }
const _hoisted_17 = { class: "fix-report-row-dep" }
const _hoisted_18 = {
  key: 0,
  class: "row-delimeter"
}
const _hoisted_19 = {
  key: 0,
  class: "fix-empty-after-filter"
}
const _hoisted_20 = { key: 4 }
const _hoisted_21 = { class: "fix-rep-loading" }
const _hoisted_22 = { class: "fix-rep-right" }
const _hoisted_23 = { class: "fix-rep-photo-block" }
const _hoisted_24 = {
  key: 0,
  class: "fix-rep-photo"
}
const _hoisted_25 = { key: 0 }
const _hoisted_26 = { class: "fix-rep-photo-error" }
const _hoisted_27 = ["src"]

import { reactive, onBeforeMount, watch, computed } from 'vue'
import {
  unitTimestampConvert,
  getMonthStartEnd,
  getUnixTimestamps,
  convertToSubFilterMap,
  exportToExcel,
  ExcelData
} from '@/globalFunctions'
import {
  Organization,
  Departments,
  Positions,
  Fix,
  Employee,
  SubFilterMap
} from '@/models'
import { Photo } from '@/classes'
import { useFixesStore } from '@/stores/fixes'
import { useCookesStore } from '@/stores/cookies'
import { eventBus } from '@/eventBus'
import { DatesMap } from '@/components/widgets/UFilters.vue'
import { useImagesYarosStore } from '@/stores/imagesYaros'

type ActionType = 'arrive' | 'leave'


export default /*@__PURE__*/_defineComponent({
  __name: 'FixRep',
  setup(__props) {

const state = reactive({
  reportData: [] as Fix[],
  orgName: '',
  organization: {} as Organization,
  departments: [] as Departments,
  positions: [] as Positions,
  imageRef: '',
  rowIndex: -1,
  actionLabel: 'Приход/Уход',
  actionSortArg: 'all',
  dataLoaded: false,
  periodStr: '',
  prevMonth: '',
  orgId: '',
  screenWidth: 0,
  prevDate: [] as Array<number>,
  isLoading: false,
  persons: [] as Employee[],
  showFilter: false,
  filterParams: {} as SubFilterMap,
  daysTimestamps: {} as DatesMap,
  filteredData: [] as ExcelData[],
  photo: new Photo()
})

const imagesStore = useImagesYarosStore()
let imageSrc: Map<string, Photo> = new Map<string, Photo>()

const typeActMap: Record<ActionType, string> = {
  arrive: 'Приход',
  leave: 'Уход'
}

const fixes = useFixesStore()
const cookies = useCookesStore()

eventBus.on('filter', (obj) => {
  const validObj = convertToSubFilterMap(obj)
  if (validObj !== null) {
    state.filterParams = validObj
  }
})

eventBus.on('reloadReport', async () => {
  await reloadFix()
})

eventBus.on('excelExport', () => {
  prepareDataForExport()
  exportToExcel(state.filteredData, 'fixes')
})

const filteredData = computed(() => {
  const filteredFixes = state.reportData.filter(item => filter(item))
  return filteredFixes
})

const hasFilteredData = computed(() => {
  return filteredData.value.length > 0
})

function prepareDataForExport () {
  state.filteredData = state.reportData
    .filter(filter)
    .map(item => {
      return {
        Период: item.time ? unitTimestampConvert(item.time) : '',
        Подразделение: state.departments.find(dep => dep.id === item.depId)?.name || '',
        'Физическое лицо': item.perName,
        Должность: state.positions.find(pos => pos.id === item.posId)?.name || '',
        'Приход / Уход': typeActMap[item.type as ActionType],
        Время: item.time ? unitTimestampConvert(item.time, 'time') : ''
      }
    })
}

eventBus.on('showFilter', () => {
  state.showFilter = true
})

onBeforeMount(async () => {
  imageSrc.set('null', new Photo())
  state.screenWidth = window.innerWidth
  await loadingData()
  state.daysTimestamps = getUnixTimestamps()
})

watch(() => state.prevDate, (newValue) => {
  if (newValue.length > 0) {
    state.prevMonth = `${newValue[0].toString().padStart(2, '0')}.${newValue[1]}`
  }
})

watch(() => state.reportData, (newValue, oldValue) => {
  if (newValue !== oldValue && newValue.length > 0) {
    loadingImages()
  }
}, { deep: true })

async function loadingData () {
  state.reportData = JSON.parse(sessionStorage.getItem('fixes') || '[]')
  state.organization = JSON.parse(sessionStorage.getItem('organization') || '{}')
  state.orgName = state.organization.name
  const departmentsData = sessionStorage.getItem('departments')
  if (departmentsData) {
    state.departments = JSON.parse(departmentsData)
  }
  const positionsData = sessionStorage.getItem('positions')
  if (positionsData) {
    state.positions = JSON.parse(positionsData).positions
  }
  const persons = sessionStorage.getItem('persons')
  if (persons) {
    state.persons = JSON.parse(persons)
  }
  preparePeriod()
  state.dataLoaded = true
  if (state.reportData.length > 0) {
    rowClick(0, state.reportData[0].photo)
  }
}

async function loadingImages () {
  for (const item of state.reportData) {
    if (item.photo && !(item.photo in imageSrc)) {
      let photo = new Photo()
      const photoId = item.photo
      try {
        const cachedPhoto = sessionStorage.getItem(item.photo)
        if (cachedPhoto) {
          photo = new Photo(cachedPhoto, false, false)
        } else {
          const result = await imagesStore.getImage(state.organization.fixStorage, photoId)
          if (result) {
            sessionStorage.setItem(item.photo, result)
            photo = new Photo(result, false, false)
          }
        }
      } catch (error) {
        console.error(`Failed to load image ${photoId}:`, error)
        photo = new Photo('', false, true)
      } finally {
        imageSrc = { ...imageSrc, ...{ [photoId]: photo } }
      }
    }
  }
  // @ts-expect-error - here is nothing to see
  state.photo = imageSrc[state.reportData[state.rowIndex].photo as keyof typeof imageSrc]
}

function preparePeriod () {
  const currentDate = new Date()
  const currMonth = currentDate.getMonth()
  const currYear = currentDate.getFullYear()
  const prevMonth = currMonth === 0 ? 11 : currMonth - 1
  const prevYear = currMonth === 0 ? currYear - 1 : currYear
  state.prevDate = [prevMonth + 1, prevYear]
  state.periodStr = `${prevMonth.toString().padStart(2, '0')}.${prevYear}-${(currMonth + 1).toString().padStart(2, '0')}.${currYear}`
}

function rowClick (index: number, photo: string) {
  if (photo && state.imageRef !== photo) {
    state.rowIndex = index
    getDataFromMap(photo)
  }
}

function actionTypeSort () {
  const steps = ['all', 'arrive', 'leave']
  const stepsLen = steps.length
  const currStep = steps.findIndex(step => step === state.actionSortArg)
  if (stepsLen - 1 === currStep) {
    state.actionSortArg = steps[0]
    state.actionLabel = 'Приход/Уход'
  } else {
    state.actionSortArg = steps[currStep + 1]
    state.actionLabel = typeActMap[state.actionSortArg as ActionType]
  }
}

async function loadPrevMonth () {
  state.dataLoaded = false
  if (state.orgId === '') {
    await cookies.getCookie()
    state.orgId = cookies.getCookies.orgId
  }
  const prevMonth = state.prevDate[0]
  const year = state.prevDate[1]
  const prevMonthTimeStamp = getMonthStartEnd(prevMonth, year)
  await fixes.getFixesAction(state.orgId, null, prevMonthTimeStamp[0], prevMonthTimeStamp[1])
  state.reportData = [...state.reportData, ...fixes.getFixes]
  sessionStorage.setItem('fixes', JSON.stringify(state.reportData))
  if (prevMonth - 1 === 0) {
    state.prevDate = [12, year - 1]
  } else {
    state.prevDate = [prevMonth - 1, year]
  }
  if (state.reportData.length > 0) {
    rowClick(0, state.reportData[0].photo)
  }
  state.dataLoaded = true
}

async function reloadFix () {
  state.isLoading = false
  state.imageRef = ''
  const timestamps = getMonthStartEnd()
  if (state.orgId === '') {
    await cookies.getCookie()
    state.orgId = cookies.getCookies.orgId
  }
  await fixes.getFixesAction(state.orgId, null, timestamps[0], timestamps[1])
  sessionStorage.setItem('fixes', JSON.stringify(fixes.getFixes))
  await loadingData()
}

function filterSet (obj: SubFilterMap) {
  state.filterParams = obj
}

function filter (item: Fix) {
  if (Object.keys(state.filterParams).length === 0) {
    return true
  }
  if (state.filterParams.department.length > 0 &&
    item.depId !== state.filterParams.department) {
    return false
  }
  if (state.filterParams.person.length > 0 &&
    item.perId !== state.filterParams.person) {
    return false
  }
  if (state.filterParams.date.length > 0) {
    let datesDiap : Array<number>
    if (typeof state.filterParams.date === 'string') {
      datesDiap = state.daysTimestamps[state.filterParams.date]
    } else {
      datesDiap = state.filterParams.date
    }
    if (datesDiap[0] > item.time ||
        datesDiap[1] < item.time) {
      return false
    }
  }
  return true
}

function getDataFromMap (photoId: string) {
  const photo = imageSrc[photoId as keyof typeof imageSrc]
  if (photo) {
  // @ts-expect-error - here is nothing to see
    state.photo = photo
  } else {
    state.photo = new Photo()
  }
}

return (_ctx: any,_cache: any) => {
  const _component_u_spinner = _resolveComponent("u-spinner")!
  const _component_u_filters = _resolveComponent("u-filters")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _cache[4] || (_cache[4] = _createElementVNode("span", { class: "fix-rep-legend-text fix-report-timestamp" }, " Период ", -1)),
            _cache[5] || (_cache[5] = _createElementVNode("div", { class: "fix-rep-legend-block org-dep" }, [
              _createElementVNode("span", null, "Подразделение")
            ], -1)),
            _cache[6] || (_cache[6] = _createElementVNode("div", { class: "fix-rep-legend-block org-pos" }, [
              _createElementVNode("span", null, "Физическое лицо"),
              _createElementVNode("span", null, "Должность")
            ], -1)),
            _createElementVNode("div", {
              class: "fix-rep-legend-block act-type button-click",
              onClick: actionTypeSort
            }, [
              _createElementVNode("span", null, _toDisplayString(state.actionLabel), 1),
              _cache[3] || (_cache[3] = _createElementVNode("span", null, "Время", -1))
            ])
          ]),
          (!state.dataLoaded && state.reportData.length === 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_u_spinner),
                  _cache[7] || (_cache[7] = _createElementVNode("span", null, "Загружаем данные", -1))
                ])
              ]))
            : _createCommentVNode("", true),
          (state.reportData.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredData.value, (item, index) => {
                  return (_openBlock(), _createElementBlock("div", { key: index }, [
                    _withDirectives(_createElementVNode("div", {
                      class: _normalizeClass(['fix-rep-row-position', { 'fix-rep-row-margin-top': index !== 0 }]),
                      onClick: ($event: any) => (rowClick(index, item.photo))
                    }, [
                      _createElementVNode("div", {
                        class: _normalizeClass(['fix-rep-row fix-report-text button-click', { 'fix-rep-row-active': index === state.rowIndex }])
                      }, [
                        _createElementVNode("span", _hoisted_9, _toDisplayString(_unref(unitTimestampConvert)(item.time)), 1),
                        _createElementVNode("div", _hoisted_10, [
                          (state.departments.find(org => org.id === item.depId)?.name)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(state.departments.find(org => org.id === item.depId)?.name), 1))
                            : _createCommentVNode("", true)
                        ]),
                        _createElementVNode("div", _hoisted_12, [
                          _createElementVNode("span", _hoisted_13, _toDisplayString(item.perName), 1),
                          (state.positions.find(pos => pos.id === item.posId)?.name)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(state.positions.find(pos => pos.id === item.posId)?.name), 1))
                            : _createCommentVNode("", true)
                        ]),
                        _createElementVNode("div", _hoisted_15, [
                          _createElementVNode("span", _hoisted_16, _toDisplayString(typeActMap[item.type as ActionType]), 1),
                          _createElementVNode("span", _hoisted_17, _toDisplayString(_unref(unitTimestampConvert)(item.time, 'time')), 1)
                        ])
                      ], 2),
                      (hasFilteredData.value)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_18))
                        : _createCommentVNode("", true)
                    ], 10, _hoisted_8), [
                      [_vShow, state.actionSortArg === 'all' || state.actionSortArg === item.type]
                    ])
                  ]))
                }), 128)),
                (!hasFilteredData.value)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_19, _cache[8] || (_cache[8] = [
                      _createElementVNode("span", null, "Нет данных, соответствующих выбранным фильтрам", -1)
                    ])))
                  : _createCommentVNode("", true)
              ]))
            : (_openBlock(), _createElementBlock("div", {
                key: 2,
                class: "fix-rep-loading fix-rep-empty button-click",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (loadPrevMonth()))
              }, [
                _createElementVNode("span", null, _toDisplayString(`Нет данных за период ${state.periodStr}`), 1),
                _createElementVNode("span", null, _toDisplayString(`Нажмите для загрузки ${state.prevMonth}`), 1)
              ])),
          (state.reportData.length > 0 && state.dataLoaded)
            ? (_openBlock(), _createElementBlock("div", {
                key: 3,
                class: "fix-rep-loading fix-rep-empty button-click",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (loadPrevMonth()))
              }, [
                _createElementVNode("span", null, _toDisplayString(`Нажмите для загрузки ${state.prevMonth}`), 1)
              ]))
            : (!state.dataLoaded && state.reportData.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                  _createElementVNode("div", _hoisted_21, [
                    _createVNode(_component_u_spinner),
                    _cache[9] || (_cache[9] = _createElementVNode("span", null, "Загружаем данные", -1))
                  ])
                ]))
              : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_22, [
          _createElementVNode("div", _hoisted_23, [
            _cache[10] || (_cache[10] = _createElementVNode("div", { class: "fix-rep-photo-block-label" }, [
              _createElementVNode("span", { class: "" }, "Фотофиксация")
            ], -1)),
            (state.reportData.length > 0 && hasFilteredData.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                  (state.photo.isError || state.photo.isLoading)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                        _withDirectives(_createVNode(_component_u_spinner, null, null, 512), [
                          [_vShow, state.photo.isLoading]
                        ]),
                        _createElementVNode("div", _hoisted_26, [
                          _withDirectives(_createElementVNode("span", null, " Произошла ошибка при загрузке фото ", 512), [
                            [_vShow, state.photo.isError]
                          ])
                        ])
                      ]))
                    : (_openBlock(), _createElementBlock("img", {
                        key: 1,
                        class: "fix-rep-photo-image",
                        src: `data:image/jpeg;base64, ${state.photo.imageSrc}`
                      }, null, 8, _hoisted_27))
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ]),
    (state.showFilter)
      ? (_openBlock(), _createBlock(_component_u_filters, {
          key: 0,
          onClose: _cache[2] || (_cache[2] = ($event: any) => (state.showFilter = false)),
          onFilterSet: filterSet
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})