import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-header-wrapper"
}
const _hoisted_2 = { class: "w-header-text" }
const _hoisted_3 = {
  key: 1,
  class: "w-header-month"
}
const _hoisted_4 = {
  key: 1,
  class: "w-header-wrapper"
}
const _hoisted_5 = { class: "w-header-text" }
const _hoisted_6 = {
  key: 1,
  class: "w-header-month"
}


export default /*@__PURE__*/_defineComponent({
  __name: 'UWidgetHeader',
  props: {
  headerText: {
    type: String,
    default: '',
    required: false
  },
  showMonthStartDate: {
    type: Boolean,
    default: false,
    required: false
  }
},
  emits: ['icon-clicked'],
  setup(__props, { emit: __emit }) {

const props = __props

const emits = __emit

const isMobile = window.innerWidth < 800

function getCurrentDate () {
  const date = new Date()
  const currentMonth = date.getMonth() + 1
  const currentDay = date.getDate()
  return `${currentDay < 10 ? `0${currentDay}` : currentDay}/${currentMonth < 10 ? `0${currentMonth}` : currentMonth}`
}


return (_ctx: any,_cache: any) => {
  const _component_u_icon_rounded = _resolveComponent("u-icon-rounded")!

  return (!isMobile)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(props.headerText), 1),
        (!props.showMonthStartDate)
          ? (_openBlock(), _createBlock(_component_u_icon_rounded, {
              key: 0,
              type: 'more',
              iconWidth: 0.68,
              iconUnit: 'vw',
              iconMnUnit: 'vw',
              onClick: _cache[0] || (_cache[0] = ($event: any) => (emits('icon-clicked')))
            }))
          : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(getCurrentDate()), 1))
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_4, [
        _createElementVNode("span", _hoisted_5, _toDisplayString(props.headerText), 1),
        (!props.showMonthStartDate)
          ? (_openBlock(), _createBlock(_component_u_icon_rounded, {
              key: 0,
              type: 'more',
              iconWidth: 3.31,
              iconUnit: 'vw',
              iconMnUnit: 'vw',
              onClick: _cache[1] || (_cache[1] = ($event: any) => (emits('icon-clicked')))
            }))
          : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(getCurrentDate()), 1))
      ]))
}
}

})