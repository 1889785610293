import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "universal-row-wrapper"
}
const _hoisted_2 = ["src"]

import { PropType } from 'vue'
import { Universal } from '@/models'
import del from '@/assets/icon/del.svg'


export default /*@__PURE__*/_defineComponent({
  __name: 'UUniversalRow',
  props: {
  item: {
    type: Object as PropType<Universal> | undefined,
    required: true
  }
},
  emits: ['del-universal'],
  setup(__props, { emit: __emit }) {

const props = __props

const emits = __emit

return (_ctx: any,_cache: any) => {
  return (props.item)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("span", null, _toDisplayString(props.item.name), 1),
        _createElementVNode("div", {
          class: "button-click universal-del-wrapper",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (emits('del-universal')))
        }, [
          _createElementVNode("img", { src: _unref(del) }, null, 8, _hoisted_2)
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})