import { defineStore } from 'pinia'
import { AxiosError } from 'axios'
import { axiosMain } from '@/axios-setup'
import { OrgPlan } from '@/models'

export interface BuyPlan {
  status: string,
  message: string
}

export const usePlansStore = defineStore('plans', {
  state: () => ({
    plans: {} as Array<OrgPlan>
  }),
  actions: {
    async getPlansAction () {
      try {
        const response = await axiosMain.get<Array<OrgPlan>>('/TPlans')
        this.plans = response.data
      } catch (error) {
        const axiosError = error as AxiosError
        console.error(axiosError.message)
      }
    },
    async buyPlan (orgId: string, planId: string) {
      try {
        const response = await axiosMain.post<BuyPlan>('/TPlans', {
          orgId,
          planId
        })
        return response.data
      } catch (error) {
        return { status: 'error', message: '' } as BuyPlan
      }
    }
  },
  getters: {
    getPlans: (state) => state.plans
  }
})
