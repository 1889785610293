import { defineStore } from 'pinia'
import { PayOrder } from '@/classes'
import { axiosMain } from '@/axios-setup'

export const usePaymentStore = defineStore('payment', {
  state: () => ({
    paymentLink: '' as string
  }),
  actions: {
    async getOTPQpay (serviceName: string, body: PayOrder) {
      try {
        const response = await axiosMain.post(`/@otp/${serviceName}`, body)
        return response.data
      } catch (error) {
        console.error(error)
      }
    }
  }
})
