import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "dropdwn-filter-main-wrapper" }
const _hoisted_2 = {
  class: "dropdown-choosen-item",
  id: "choosen-item"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 0,
  class: "dropdown-opened"
}
const _hoisted_5 = ["onMouseenter", "onClick"]
const _hoisted_6 = { class: "dropdown-choosen-item" }

import { reactive } from 'vue'
import dropDown from '@/assets/icon/dropdown-open.svg'

export interface ArrayMap {
  text: string,
  value: string | number
}


export default /*@__PURE__*/_defineComponent({
  __name: 'UDropdownFilter',
  props: {
  array: {
    type: Array as () => ArrayMap[],
    required: true
  },
  selected: {
    type: Number,
    required: true
  },
  isOpen: {
    type: Boolean,
    required: true
  }
},
  emits: [
  'click-event',
  'choose-row'
],
  setup(__props, { emit: __emit }) {

const emits = __emit

const props = __props

const state = reactive({
  itemIndex: -1,
  array: [
    { text: 'Не выбран', value: 'empty' }, ...props.array
  ] as ArrayMap[]
})

function chooseRow (index: number) {
  if (state.array[index].value === 'empty') {
    emits('choose-row', -1)
  } else emits('choose-row', index)
  emits('click-event')
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "dropdwn-filter-wrapper",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (emits('click-event')))
    }, [
      _createElementVNode("span", _hoisted_2, _toDisplayString(state.array[props.selected === -1 ? 0 : props.selected]?.text), 1),
      _createElementVNode("img", {
        src: _unref(dropDown),
        class: "button-click"
      }, null, 8, _hoisted_3)
    ]),
    (props.isOpen)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(state.array, (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(["dropdown-row button-click", {
                    'dropdown-row-focused': state.itemIndex === index,
                    }]),
              style: _normalizeStyle({
                    color: (props.selected === -1 ? 0 : props.selected) === index ?
                    '#16FAFC' :
                    state.itemIndex === index ? '#FFFFFF' : '#FFFFFF80'
                    }),
              key: index,
              onMouseenter: ($event: any) => (state.itemIndex = index),
              onClick: ($event: any) => (chooseRow(index))
            }, [
              _createElementVNode("span", _hoisted_6, _toDisplayString(item.text), 1)
            ], 46, _hoisted_5))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})