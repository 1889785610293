<template>
    <transition name="ureview-fade">
        <div class="ureview-overlay" v-if="true">
            <div class="ureview-wrapper">
                <img :src="cancel"
                    class="ureview-close-review button-click"
                    @click="emits('close-review')"
                >
                <span class="ureview-header">
                    Пожалуйста, оставьте свой отзыв / комментарий
                </span>
                <textarea class="ureview-comment" ref="comment"
                    @focus="setCursor" v-model="state.comment"
                    @input="inputController"
                >
                </textarea>
                <div class="ureview-progressive-bar-wrapper">
                    <div class="ureview-progressive-bar"></div>
                    <div class="ureview-progressive-bar"
                        :style="{ width: state.widthPercentage + '%', backgroundColor: state.backgroundColor }"
                        id="ureview-active-bar">
                    </div>
                </div>
                <div class="ureview-counter">
                    <span>{{ `${state.comment.length}/${state.maxLength}` }}</span>
                </div>
                <div class="ureview-btn-group">
                    <button class="ureview-btn button-click" id="save-btn"
                        @click="sendReview"
                        >
                        Отправить
                    </button>
                    <button class="ureview-btn button-click" id="cancel-btn"
                        @click="emits('close-review')"
                        >Отмена
                    </button>
                </div>
            </div>
        </div>
    </transition>
    <u-dialog v-if="dialogStates.showDialog"
      :header="dialogStates.dialogHeader"
      :msgText="dialogStates.dialogBody"
      :showButtons="dialogStates.dialogShowBtns"
      :isLoadind="dialogStates.dialogLoading"
      @click-res="closeDialog"/>
</template>

<script setup lang="ts">
import { ref, Ref, reactive } from 'vue'
import { delay } from '@/globalFunctions'
import cancel from '@/assets/icon/cancel-dialog.svg'
import { useFeedbackStore } from '@/stores/feedback'

const comment = ref(null)

const style = getComputedStyle(document.documentElement)

const state = reactive({
  comment: '',
  maxLength: 512,
  widthPercentage: 0,
  backgroundColor: style.getPropertyValue('--primary-font-color').trim()
})

const dialogStates = reactive({
  showDialog: false,
  dialogHeader: '',
  dialogBody: '',
  dialogLoading: false,
  dialogShowBtns: true,
  closeReview: false
})

const props = defineProps({
  orgId: {
    type: String,
    default: '',
    required: true
  },
  activeForm: {
    type: String,
    default: '',
    required: true
  }
})

const emits = defineEmits(['close-review'])
const feedback = useFeedbackStore()

const myTextarea: Ref<HTMLTextAreaElement | null> = ref(null)

const setCursor = () => {
  const textarea = myTextarea.value
  if (textarea) {
    const textLength = textarea.value.length
    if (textarea.value === '') {
      textarea.setSelectionRange(0, 0)
    } else {
      textarea.setSelectionRange(textLength, textLength)
    }
    textarea.focus()
  }
}

const inputController = () => {
  if (state.comment.length > state.maxLength) {
    state.comment = state.comment.substring(0, state.maxLength)
  }
  modeProgressBar()
}

async function renderProgressiveBar (min: number, max: number) {
  const step = 1
  if (min < max) {
    state.widthPercentage = min
    while (state.widthPercentage < max) {
      state.widthPercentage += step
      await delay(1)
    }
    state.widthPercentage = max
  } else {
    state.widthPercentage = max
    while (state.widthPercentage > 0) {
      state.widthPercentage -= step
      await delay(1)
    }
    state.widthPercentage = 0
  }
}

async function modeProgressBar () {
  if (state.comment.length === 0 && state.backgroundColor !== style.getPropertyValue('--primary-font-color').trim()) {
    await renderProgressiveBar(33, 0)
    state.backgroundColor = style.getPropertyValue('--primary-font-color').trim()
    return
  }
  if (state.comment.length <= 10 && state.backgroundColor !== style.getPropertyValue('--primary-font-color-error').trim()) {
    state.backgroundColor = style.getPropertyValue('--primary-font-color-error').trim()
    await renderProgressiveBar(0, 33)
  } else if (state.comment.length > 10 && state.comment.length <= 128 && state.backgroundColor !== 'orange') {
    state.backgroundColor = 'orange'
    await renderProgressiveBar(33, 66)
  } else if (state.comment.length > 128 && state.backgroundColor !== style.getPropertyValue('--primary-font-color-blue').trim()) {
    state.backgroundColor = style.getPropertyValue('--primary-font-color-blue').trim()
    await renderProgressiveBar(66, 100)
  }
}

function openDialog (header: string, body: string = '', showBtns: boolean = true, loading: boolean = false) {
  dialogStates.closeReview = false
  dialogStates.dialogHeader = header
  dialogStates.dialogBody = body
  dialogStates.dialogShowBtns = showBtns
  dialogStates.dialogLoading = loading
  dialogStates.showDialog = true
}

async function sendReview () {
  if (state.comment.length < 10) {
    openDialog('Короткий комментарий', 'Пожалуйста, введите более развернутый комментарий.')
  } else {
    openDialog('Пожалуйста, подождите', '', false, true)
    await feedback.sendFeedback(props.activeForm, props.orgId, state.comment)
    const feedbackResponse = feedback.getFeedbackResponse
    if (feedbackResponse.status === 'registered') {
      openDialog('Комментарий отправлен', 'Спасибо большое за Ваш отзыв.</br>Наши специалисты обязательно с ним ознакомятся.')
      dialogStates.closeReview = true
    } else {
      openDialog('Ошибка', 'Попробуйте позже!')
    }
  }
}

function closeDialog () {
  dialogStates.showDialog = false
  if (dialogStates.closeReview) {
    emits('close-review')
  }
}
</script>

<style>
.ureview-overlay {
  position: fixed;
  z-index: 3000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}

.ureview-wrapper {
  width: 20.5rem;
  background-image: linear-gradient(245.9deg, #2D4B6B -40.66%, #042447 131.18%);
  padding: 1.5rem 2rem;
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.ureview-close-review {
  align-self: end;
}

.ureview-header {
  font: 0.76rem 'RedHatText-Bold', 'OpenSans', sans-serif;
  color: var(--primary-font-color);
}

.ureview-comment {
  font: 0.76rem 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
  width: 80%;
  height: 10rem;
  border-radius: 2rem;
  padding: 1rem 2rem;
  resize: none;
}

.ureview-btn-group {
  display: flex;
  gap: 1rem;
}

.ureview-btn {
  font: 0.76rem 'RedHatText-Bold', 'OpenSans', sans-serif;
  width: 7rem;
  padding: 0.375rem 0.7rem 0.375rem 0.7rem;
  border-radius: 20px;
  border: none;
  align-self: end;
}

#save-btn {
    background-color: #042447;
    color: var(--primary-font-color);
}

#cancel-btn {
    background-color: #FFFFFF1A;
    color: var(--primary-font-color-a70);
}

.ureview-counter {
    font: 0.76rem 'RedHatText-Bold', 'OpenSans', sans-serif;
    color: var(--primary-font-color);
    align-self: end;
}

.ureview-progressive-bar-wrapper {
    position: relative;
    width: 100%;
}

.ureview-progressive-bar {
    width: 100%;
    height: 0.76rem;
    background-color: var(--primary-font-color);
    border-radius: 2rem;
    align-self: baseline;
    position: relative;
    z-index: 1;
}

#ureview-active-bar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 0.76rem;
  border-radius: 2rem;
  z-index: 2;
}
@media screen and (max-width: 850px) {
  .ureview-wrapper {
    width: 75vw;
  }
}
</style>
