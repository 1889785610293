import logOut from '@/assets/icon/logout.svg'
import dashboard from '@/assets/icon/dashboard.svg'
import reports from '@/assets/icon/reports.svg'
import settings from '@/assets/icon/settings.svg'
import organization from '@/assets/icon/organization.svg'
import plan from '@/assets/icon/plan.svg'

export const iconMap = {
  logOut: { text: 'Выйти', iconRef: logOut },
  dashboard: { text: 'Дашборд', iconRef: dashboard },
  reports: { text: 'Отчеты', iconRef: reports },
  settings: { text: 'Настройки', iconRef: settings },
  organization: { text: 'Организация', iconRef: organization },
  plans: { text: 'Тарифы', iconRef: plan }
}

export const subjects = {
  departments: { text: 'Подразделения' },
  positions: { text: 'Должности' },
  persons: { text: 'Сотрудники' },
  organization: { text: 'Организация' },
  plans: { text: 'Тарифы' }
}
