<template>
  <div v-if="!isMobile" class="icon-button-wrapper button-click" :class="{ 'icon-button': props.isActive }" @click="emits('icon-btn-clicked', props.type)">
    <u-icon :type="props.type" :fontSize="1.04" :fontUnit="'vw'" :iconPdRt="0.83" iconPdUnit="'vw'" :showText="true" :iconWidth="1.30"/>
  </div>
  <div v-else class="icon-button-wrapper button-click" :class="{ 'icon-button': props.isActive }" @click="emits('icon-btn-clicked', props.type)">
    <u-icon :type="props.type" :fontSize="4.07" :fontUnit="'vw'" :iconPdRt="3.05" iconPdUnit="'vw'" :showText="true" :iconWidth="5.09"/>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  type: String,
  isActive: {
    type: Boolean,
    default: false,
    required: false
  }
})

const emits = defineEmits(['icon-btn-clicked'])

const isMobile = window.innerWidth < 800
</script>

<style>
.icon-button-wrapper {
  padding: 0.3vw 1.30vw;
  border: none;
  border-radius: 1.04vw;
}

.icon-button {
  background-color: #2D4B6B;
}
@media screen and (max-width: 850px) {
  .icon-button-wrapper {
    padding: 4vw 6.36vw;
    border: none;
    border-radius: 5.09vw;
    margin-bottom: 3.82vw;
  }
}
</style>
