import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "reports-list" }


export default /*@__PURE__*/_defineComponent({
  __name: 'ReportView',
  emits: ['to-report'],
  setup(__props, { emit: __emit }) {

const miniatureArr = ['fix', 'journal']

const emits = __emit
function toReport (arg: string) {
  emits('to-report', arg)
}

return (_ctx: any,_cache: any) => {
  const _component_u_report_miniature = _resolveComponent("u-report-miniature")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(miniatureArr, (report) => {
      return _createVNode(_component_u_report_miniature, {
        key: report,
        type: report,
        onToReport: toReport
      }, null, 8, ["type"])
    }), 64))
  ]))
}
}

})