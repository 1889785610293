import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "pay-main-wrapper" }
const _hoisted_2 = { class: "pay-wrapper" }
const _hoisted_3 = { class: "pay-info-panel-wrapper" }
const _hoisted_4 = { class: "pay-info-panel-wrapper-item" }
const _hoisted_5 = { class: "pay-info-panel-item-text" }
const _hoisted_6 = { class: "pay-info-panel-wrapper-item" }
const _hoisted_7 = { class: "pay-info-panel-item-text" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "pay-instruction-wrapper" }
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "pay-instruction-stepper" }
const _hoisted_12 = ["src"]
const _hoisted_13 = { class: "pay-instruction-label" }
const _hoisted_14 = ["src"]
const _hoisted_15 = { class: "instr-dots-wrapper" }

import { reactive, onBeforeMount } from 'vue'
import copy from '@/assets/icon/copy.svg'
import next from '@/assets/icon/back.svg'
// @ts-expect-error - here is nothing to see
import terminal1 from '@/assets/images/terminal/pay/terminal1.png'
// @ts-expect-error - here is nothing to see
import terminal2 from '@/assets/images/terminal/pay/terminal2.png'
// @ts-expect-error - here is nothing to see
import terminal3 from '@/assets/images/terminal/pay/terminal3.png'
// @ts-expect-error - here is nothing to see
import qpay1 from '@/assets/images/terminal/quickpay/qpay1.webp'
// @ts-expect-error - here is nothing to see
import qpay2 from '@/assets/images/terminal/quickpay/qpay2.webp'
// @ts-expect-error - here is nothing to see
import qpay3 from '@/assets/images/terminal/quickpay/qpay3.webp'
// @ts-expect-error - here is nothing to see
import elsom1 from '@/assets/images/elsom/elsom1.webp'
// @ts-expect-error - here is nothing to see
import elsom2 from '@/assets/images/elsom/elsom2.webp'
// @ts-expect-error - here is nothing to see
import elsom3 from '@/assets/images/elsom/elsom3.webp'
import { usePaymentStore } from '@/stores/pays'
import { PayOrder } from '@/classes'
import { Organization } from '@/models'


export default /*@__PURE__*/_defineComponent({
  __name: 'TerminalInstr',
  props: {
  summ: {
    type: String,
    required: true
  },
  type: {
    type: String,
    required: true
  }
},
  emits: ['show-loader', 'close-loader', 'show-dialog'],
  setup(__props, { emit: __emit }) {

const payStore = usePaymentStore()

const props = __props

const state = reactive({
  organization: {} as Organization,
  code: '',
  index: 0,
  popupMessage: false
})

const instructionsText = [
  'Перейдите в раздел “Интернет магазины”',
  'Выберите BaBabay',
  'Введите разовый код оплаты и совершите платеж'
]

const elsomInstructionsText = [
  'Зайдите в приложение Элсом и в списке операций выберите “Покупка”',
  'Выберите “ОТП-код”',
  'Введите разовый код оплаты'
]

const instructionImagesPay = [terminal1, terminal2, terminal3]
const instructionImagesQuickPay = [qpay1, qpay2, qpay3]
const instructionImagesElsom = [elsom1, elsom2, elsom3]

const emits = __emit

onBeforeMount(async () => {
  emits('show-loader')
  state.organization = JSON.parse(sessionStorage.getItem('organization') || '{}')
  const payBody = new PayOrder(state.organization.orgId, Number(props.summ))
  const result = await payStore.getOTPQpay(props.type, payBody)
  if (result.status === 'OK') {
    state.code = result.otp
    emits('close-loader')
  } else {
    showDialog(result.info)
  }
})

function changeStep (action: string) {
  if (action === 'prev') {
    if (state.index !== 0) {
      state.index -= 1
    }
  } else {
    if (state.index + 1 !== instructionsText.length) {
      state.index += 1
    }
  }
}

function showDialog (body: string) {
  emits('show-dialog', { error: true, body })
}

function copyCode () {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(state.code).then(() => {
      state.popupMessage = true
    }).catch(err => {
      alert('Ошибка при копировании текста: ' + err)
    })
  } else {
    alert('Буфер обмена не доступен')
  }
}

return (_ctx: any,_cache: any) => {
  const _component_u_popup_message = _resolveComponent("u-popup-message")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _cache[4] || (_cache[4] = _createElementVNode("span", { class: "pay-info-panel-item-header" }, " К оплате ", -1)),
            _createElementVNode("span", _hoisted_5, _toDisplayString(`${props.summ} сом`), 1)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _cache[5] || (_cache[5] = _createElementVNode("span", { class: "pay-info-panel-item-header" }, " Разовый код оплаты ", -1)),
            _createElementVNode("div", {
              class: "pay-info-panel-item-row button-click",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (copyCode()))
            }, [
              _createElementVNode("span", _hoisted_7, _toDisplayString(state.code), 1),
              _createElementVNode("img", { src: _unref(copy) }, null, 8, _hoisted_8)
            ])
          ])
        ]),
        _cache[6] || (_cache[6] = _createElementVNode("span", { class: "pay-instruction-label" }, " Инструкция по оплате ", -1)),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("img", {
            class: "pay-instruction-img",
            src: props.type === 'qpay' ?
          instructionImagesQuickPay[state.index] :
          props.type === 'elsom' ?
          instructionImagesElsom[state.index] :
          instructionImagesPay[state.index]
          }, null, 8, _hoisted_10),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", {
              class: "pay-instruction-btn button-click",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (changeStep('prev')))
            }, [
              _createElementVNode("img", {
                class: "pay-instruction-btn-icon",
                src: _unref(next)
              }, null, 8, _hoisted_12)
            ]),
            _createElementVNode("span", _hoisted_13, _toDisplayString(props.type === 'elsom' ?
              elsomInstructionsText[state.index] :
              instructionsText[state.index]), 1),
            _createElementVNode("div", {
              class: "pay-instruction-btn button-click",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (changeStep('next')))
            }, [
              _createElementVNode("img", {
                class: "pay-instruction-btn-icon",
                style: {transform: 'rotate(180deg)'},
                src: _unref(next)
              }, null, 8, _hoisted_14)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_15, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(instructionsText.length, (index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "instr-dot",
              style: _normalizeStyle({
            backgroundColor: state.index + 1 === index ? '#16FAFC' : '#38526B'
          })
            }, null, 4))
          }), 128))
        ])
      ])
    ]),
    (state.popupMessage)
      ? (_openBlock(), _createBlock(_component_u_popup_message, {
          key: 0,
          onClosed: _cache[3] || (_cache[3] = ($event: any) => (state.popupMessage = false)),
          text: 'Разовый код скопирован в буфер обмена!'
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})