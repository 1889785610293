import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "auth-wrapper" }
const _hoisted_2 = { class: "auth-form" }
const _hoisted_3 = { class: "pass-input inputs" }
const _hoisted_4 = { class: "auth-texts" }
const _hoisted_5 = { class: "logo" }
const _hoisted_6 = ["src"]

import { reactive } from 'vue'
import { useRouter } from 'vue-router'
import { Organization, Country } from '@/models'
import { useOrganizationStore } from '@/stores/auth'
import { useCookesStore } from '@/stores/cookies'
import logo from '@/assets/images/logo.svg'


export default /*@__PURE__*/_defineComponent({
  __name: 'UAuthView',
  emits: ['open-dialog', 'close-dialog', 'to-registration', 'to-pass-recovery'],
  setup(__props, { emit: __emit }) {

const authStore = useOrganizationStore()
const cookies = useCookesStore()
const router = useRouter()

const state = reactive({
  password: '',
  organization: {} as Organization | undefined | null,
  country: {} as Country,
  login: ''
})

const emits = __emit

async function auth () {
  if (state.login.length === 0) {
    emits('open-dialog', { headerText: 'Ошибка', message: 'Логин не может быть пустым' })
    return
  } else if (state.login.length !== (state.country.country === 'kgz' ? 9 : 10)) {
    emits('open-dialog', { headerText: 'Ошибка', message: 'Логин введен не верно' })
    return
  }
  if (state.password.length === 0) {
    emits('open-dialog', { headerText: 'Ошибка', message: 'Пароль не может быть пустым' })
    return
  }
  emits('open-dialog', { headerText: 'Загрузка', message: 'Проверяем логин и пароль<br>Пожалуйста, подождите!', showButtons: false, loading: true })
  await authStore.checkNumberAction(state.country.code + state.login)
  const result = authStore.getCheckNumberResult
  if (!result || result?.status !== 'registered') {
    emits('open-dialog', { headerText: 'Ошибка', message: result?.message ?? 'Упс! Что-то пошло не так!' })
    return
  }
  const type = await authStore.sendAuthRequest(state.country.code + state.login, state.password)
  if (!type || type === 'error') {
    const result = authStore.getCheckNumberResult
    emits('open-dialog', { headerText: 'Ошибка', message: result?.status === 'unauthorized' ? 'Неверный пароль' : 'Упс! Что-то пошло не так!' })
    return
  } else {
    state.organization = authStore.getOrganization
  }

  if (state.organization?.orgId && state.organization?.orgId !== '') {
    const currentDate = new Date().getTime()
    await cookies.setCookie({
      token: state.organization?.orgId,
      orgId: state.organization?.orgId,
      expTime: currentDate + (24 * 60 * 60 * 1000)
    })
    router.push({ name: 'main' })
  } else {
    emits('close-dialog')
    emits('open-dialog', { headerText: 'Ошибка', message: 'Упс! Что-то пошло не так!' })
  }
}

function changeCountry (country: Country) {
  state.country = country
}

function inputPhone (login: string) {
  state.login = login
}

return (_ctx: any,_cache: any) => {
  const _component_u_phone_number = _resolveComponent("u-phone-number")!
  const _component_u_button = _resolveComponent("u-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[4] || (_cache[4] = _createElementVNode("span", { class: "auth-form-header" }, "Авторизация", -1)),
      _createVNode(_component_u_phone_number, {
        onChangeCountry: changeCountry,
        onInputPhone: inputPhone
      }),
      _createElementVNode("div", _hoisted_3, [
        _withDirectives(_createElementVNode("input", {
          type: "password",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((state.password) = $event)),
          placeholder: "Пароль"
        }, null, 512), [
          [_vModelText, state.password]
        ])
      ]),
      _createVNode(_component_u_button, {
        class: "auth-btn button-click",
        onClick: auth,
        btnText: 'Авторизация'
      }),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", null, [
          _cache[3] || (_cache[3] = _createElementVNode("span", { class: "auth-text-act" }, "Нет аккаунта? ", -1)),
          _createElementVNode("span", {
            class: "auth-text-act-btn button-click",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (emits('to-registration')))
          }, " Зарегистрироваться ")
        ]),
        _createElementVNode("span", {
          class: "auth-text-act-btn button-click",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (emits('to-pass-recovery')))
        }, " Забыли пароль? ")
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("img", { src: _unref(logo) }, null, 8, _hoisted_6)
    ])
  ]))
}
}

})