export interface HoursItem {
  country: string
  city: string
  offsetString: string
  offsetNumeric: number
}

export const hours = [
  {
    country: 'Кыргызстан',
    city: 'Бишкек',
    offsetString: '+6',
    offsetNumeric: 6
  },
  {
    country: 'Россия',
    city: 'Москва',
    offsetString: '+3',
    offsetNumeric: 3
  },
  {
    country: 'Украина',
    city: 'Киев',
    offsetString: '+2',
    offsetNumeric: 2
  },
  {
    country: 'Беларусь',
    city: 'Минск',
    offsetString: '+3',
    offsetNumeric: 3
  },
  {
    country: 'Казахстан',
    city: 'Нур-Султан',
    offsetString: '+6',
    offsetNumeric: 6
  },
  {
    country: 'Таджикистан',
    city: 'Душанбе',
    offsetString: '+5',
    offsetNumeric: 5
  },
  {
    country: 'Латвия',
    city: 'Рига',
    offsetString: '+2',
    offsetNumeric: 2
  },
  {
    country: 'Литва',
    city: 'Вильнюс',
    offsetString: '+2',
    offsetNumeric: 2
  },
  {
    country: 'Эстония',
    city: 'Таллинн',
    offsetString: '+2',
    offsetNumeric: 2
  },
  {
    country: 'Молдавия',
    city: 'Кишинёв',
    offsetString: '+2',
    offsetNumeric: 2
  },
  {
    country: 'Соединённые Штаты',
    city: 'Нью-Йорк',
    offsetString: '-5',
    offsetNumeric: -5
  },
  {
    country: 'Великобритания',
    city: 'Лондон',
    offsetString: '0',
    offsetNumeric: 0
  },
  {
    country: 'Франция',
    city: 'Париж',
    offsetString: '+1',
    offsetNumeric: 1
  },
  {
    country: 'Германия',
    city: 'Берлин',
    offsetString: '+1',
    offsetNumeric: 1
  },
  {
    country: 'Италия',
    city: 'Рим',
    offsetString: '+1',
    offsetNumeric: 1
  },
  {
    country: 'Испания',
    city: 'Мадрид',
    offsetString: '+1',
    offsetNumeric: 1
  },
  {
    country: 'Китай',
    city: 'Пекин',
    offsetString: '+8',
    offsetNumeric: 8
  },
  {
    country: 'Япония',
    city: 'Токио',
    offsetString: '+9',
    offsetNumeric: 9
  },
  {
    country: 'Австралия',
    city: 'Сидней',
    offsetString: '+10',
    offsetNumeric: 10
  },
  {
    country: 'Индия',
    city: 'Нью-Дели',
    offsetString: '+5:30',
    offsetNumeric: 5.5
  },
  {
    country: 'Бразилия',
    city: 'Бразилиа',
    offsetString: '-3',
    offsetNumeric: -3
  },
  {
    country: 'Аргентина',
    city: 'Буэнос-Айрес',
    offsetString: '-3',
    offsetNumeric: -3
  },
  {
    country: 'Южная Корея',
    city: 'Сеул',
    offsetString: '+9',
    offsetNumeric: 9
  },
  {
    country: 'Индонезия',
    city: 'Джакарта',
    offsetString: '+7',
    offsetNumeric: 7
  },
  {
    country: 'ЮАР',
    city: 'Кейптаун',
    offsetString: '+2',
    offsetNumeric: 2
  },
  {
    country: 'Египет',
    city: 'Каир',
    offsetString: '+2',
    offsetNumeric: 2
  },
  {
    country: 'Кения',
    city: 'Найроби',
    offsetString: '+3',
    offsetNumeric: 3
  },
  {
    country: 'Мексика',
    city: 'Мехико',
    offsetString: '-6',
    offsetNumeric: -6
  }
]
