import axios from 'axios'

export const axiosMain = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 20000,
  withCredentials: true
})

axiosMain.interceptors.response.use(
  response => response,
  error => {
    console.error('AxiosMain request failed:', error)
    return Promise.reject(error)
  }
)

export const axiosAzure = axios.create({
  baseURL: process.env.VUE_APP_AZURE_URL,
  timeout: 20000,
  withCredentials: false,
  headers: {
    'Ocp-Apim-Subscription-Key': process.env.VUE_APP_AZURE_TOKEN
  }
})

axiosAzure.interceptors.response.use(
  response => response,
  error => {
    console.error('AxiosAzure request failed:', error)
    return Promise.reject(error)
  }
)
