import { defineComponent as _defineComponent } from 'vue'
import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref } from "vue"

const _hoisted_1 = { class: "counter-wrapper" }
const _hoisted_2 = { class: "counter-info" }
const _hoisted_3 = ["disabled"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 0,
  class: "counter-buttons"
}
const _hoisted_6 = ["src"]
const _hoisted_7 = ["src"]

import plus from '@/assets/icon/vert-count-plus.svg'
import minus from '@/assets/icon/vert-count-minus.svg'
import { reactive, watch } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'UCounter',
  props: {
  num: {
    type: Number,
    default: 0,
    required: true
  },
  showCurr: {
    type: Boolean,
    default: true,
    required: false
  },
  type: {
    type: String,
    default: '',
    required: true
  },
  step: {
    type: Number,
    default: 1,
    required: false
  },
  id: {
    type: String,
    default: '',
    required: false
  },
  isActive: {
    type: Boolean,
    default: true,
    required: false
  },
  subType: {
    type: String,
    default: '',
    required: false
  }
},
  emits: ['counter-clicked'],
  setup(__props, { emit: __emit }) {

const emits = __emit

const props = __props

const state = reactive({
  num: props.num,
  needCallback: false
})

function clickBtn (act: string) {
  state.needCallback = true
  if (act === 'plus') {
    state.num = state.num + props.step
  } else {
    state.num = state.num - props.step
  }
}

const handleInput = (event: Event) => {
  const input = event.target as HTMLInputElement
  let value = input.value
  if (!/^\d+$/.test(input.value)) {
    value = value.slice(0, value.length - 1)
    state.num = Number(value)
    return
  }
  if (value.startsWith('0')) {
    value = value.slice(1)
  }
  state.num = Number(value)
  state.needCallback = true
}

watch(() => props.num, (newValue) => {
  state.num = newValue
  state.needCallback = false
})

watch(() => state.num, (newValue) => {
  if (state.num < 0) {
    state.num = 0
  } else {
    state.num = newValue
  }
  if (state.needCallback) {
    emits('counter-clicked', { [props.type]: state.num, id: props.id, subType: props.subType })
  }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("input", {
        type: "text",
        class: "counter-input",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((state.num) = $event)),
        onInput: handleInput,
        disabled: !props.isActive
      }, null, 40, _hoisted_3), [
        [_vModelText, state.num]
      ]),
      (props.showCurr)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, "сом."))
        : _createCommentVNode("", true)
    ]),
    (props.isActive)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("img", {
            class: "button-click",
            src: _unref(plus),
            onClick: _cache[1] || (_cache[1] = ($event: any) => (clickBtn('plus')))
          }, null, 8, _hoisted_6),
          _createElementVNode("img", {
            class: "button-click",
            src: _unref(minus),
            onClick: _cache[2] || (_cache[2] = ($event: any) => (clickBtn('minus')))
          }, null, 8, _hoisted_7)
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})