import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "u-filter-obj" }
const _hoisted_2 = ["src"]

import cancel from '@/assets/icon/cancel-icon.svg'


export default /*@__PURE__*/_defineComponent({
  __name: 'UFilterObj',
  props: {
  label: {
    type: String,
    required: true
  },
  filterKey: {
    type: String,
    required: true
  }
},
  emits: ['del-filter'],
  setup(__props, { emit: __emit }) {

const emits = __emit

const props = __props

function delFilter () {
  emits('del-filter', props.filterKey)
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", null, _toDisplayString(props.label), 1),
    _createElementVNode("img", {
      src: _unref(cancel),
      class: "button-click",
      onClick: delFilter
    }, null, 8, _hoisted_2)
  ]))
}
}

})