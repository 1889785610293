import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "person-item-wrapper" }
const _hoisted_2 = { class: "person-item-header" }
const _hoisted_3 = { class: "person-item-image-name" }
const _hoisted_4 = { class: "person-item-image" }
const _hoisted_5 = ["src"]
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "person-item-info-wrapper" }
const _hoisted_8 = { class: "person-item-info-name" }
const _hoisted_9 = { class: "person-item-info-pos" }
const _hoisted_10 = ["src"]
const _hoisted_11 = {
  key: 0,
  class: "person-popup"
}
const _hoisted_12 = ["src"]
const _hoisted_13 = ["src"]
const _hoisted_14 = { class: "person-item-body" }
const _hoisted_15 = { class: "person-item-infobloc" }
const _hoisted_16 = { class: "person-item-info-multiple" }
const _hoisted_17 = { class: "person-item-infobloc" }
const _hoisted_18 = { class: "person-item-info-multiple" }
const _hoisted_19 = { class: "person-item-infobloc" }
const _hoisted_20 = { class: "person-item-info-data" }
const _hoisted_21 = { class: "person-item-infobloc" }
const _hoisted_22 = { class: "person-item-info-data" }
const _hoisted_23 = ["src"]

import { reactive, PropType, onBeforeMount } from 'vue'
import menu from '@/assets/icon/kebab.svg'
import ellipse from '@/assets/images/ellipse_5.svg'
import { Employee, Universal } from '@/models'
import { useImagesYarosStore } from '@/stores/imagesYaros'
import { unitTimestampConvert } from '@/globalFunctions'
import noPhoto from '@/assets/images/no_profile.svg'
import editIcon from '@/assets/icon/org_item/edit.svg'
import deleteIcon from '@/assets/icon/org_item/delete.svg'


export default /*@__PURE__*/_defineComponent({
  __name: 'UPersonItem',
  props: {
  person: {
    type: Object as PropType<Employee>,
    required: true
  },
  positions: {
    type: Array as PropType<Universal[]>,
    required: true
  },
  departments: {
    type: Array as PropType<Universal[]>,
    required: true
  },
  imageStorage: {
    type: String,
    required: true
  }
},
  emits: ['action'],
  setup(__props, { emit: __emit }) {

const emits = __emit

const props = __props

const imagesStore = useImagesYarosStore()

const state = reactive({
  popupIsOpen: false,
  imageSrc: '',
  positionsArray: [] as string[],
  departmentsArray: [] as string[]
})

onBeforeMount(async () => {
  for (const pos of props.person.posArr) {
    const tmpName = props.positions.find(position => position.id === pos)?.name
    if (tmpName) {
      state.positionsArray.push(tmpName)
    }
  }
  for (const dep of props.person.depArr) {
    const tmpName = props.departments.find(department => department.id === dep)?.name
    if (tmpName) {
      state.departmentsArray.push(tmpName)
    }
  }
  await loadImage()
})

function clickOutside () {
  if (state.popupIsOpen) {
    state.popupIsOpen = false
  }
}

async function loadImage () {
  try {
    const cachedPhoto = sessionStorage.getItem(props.person.photo)
    if (cachedPhoto) {
      state.imageSrc = cachedPhoto
    } else {
      const result = await imagesStore.getImage(props.imageStorage, props.person.photo)
      if (result) {
        sessionStorage.setItem(props.person.photo, result)
        state.imageSrc = result
      }
    }
  } catch (error) {
    console.error(`Failed to load image ${props.person.photo}:`, error)
    state.imageSrc = ''
  }
}

function action (type: string) {
  state.popupIsOpen = false
  emits('action', { type, id: props.person.id })
}

return (_ctx: any,_cache: any) => {
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            (state.imageSrc && state.imageSrc != '')
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  src: `data:image/jpeg;base64, ${state.imageSrc}`
                }, null, 8, _hoisted_5))
              : (_openBlock(), _createElementBlock("img", {
                  key: 1,
                  src: _unref(noPhoto)
                }, null, 8, _hoisted_6))
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("span", _hoisted_8, _toDisplayString(props.person.name), 1),
            _createElementVNode("span", _hoisted_9, _toDisplayString(_unref(unitTimestampConvert)(props.person.arrivalTime, 'time')) + " - " + _toDisplayString(_unref(unitTimestampConvert)(props.person.leaveTime, 'time')), 1)
          ])
        ]),
        _createElementVNode("div", {
          class: "click-area",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (state.popupIsOpen = !state.popupIsOpen))
        }, [
          _createElementVNode("img", {
            class: "person-item-header-menu-btn button-click",
            src: _unref(menu)
          }, null, 8, _hoisted_10)
        ])
      ])), [
        [_directive_click_outside, clickOutside]
      ]),
      (state.popupIsOpen)
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
            _createElementVNode("div", {
              class: "person-popup-row button-click",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (action('red')))
            }, [
              _createElementVNode("img", { src: _unref(editIcon) }, null, 8, _hoisted_12),
              _cache[3] || (_cache[3] = _createElementVNode("span", { class: "person-popup-text" }, "Редактировать", -1))
            ]),
            _createElementVNode("div", {
              class: "person-popup-row button-click",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (action('del')))
            }, [
              _createElementVNode("img", { src: _unref(deleteIcon) }, null, 8, _hoisted_13),
              _cache[4] || (_cache[4] = _createElementVNode("span", { class: "person-popup-text" }, "Удалить", -1))
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_14, [
      _createElementVNode("div", _hoisted_15, [
        _cache[5] || (_cache[5] = _createElementVNode("span", { class: "person-item-info-pos" }, "Должность", -1)),
        _createElementVNode("div", _hoisted_16, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(state.positionsArray, (pos, index) => {
            return (_openBlock(), _createElementBlock("span", {
              key: index,
              class: "person-item-info-data"
            }, _toDisplayString(`${pos}${index + 1 == state.positionsArray.length ? '' : ', '}`), 1))
          }), 128))
        ])
      ]),
      _createElementVNode("div", _hoisted_17, [
        _cache[6] || (_cache[6] = _createElementVNode("span", { class: "person-item-info-pos" }, "Отдел", -1)),
        _createElementVNode("div", _hoisted_18, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(state.departmentsArray, (dep, index) => {
            return (_openBlock(), _createElementBlock("span", {
              key: index,
              class: "person-item-info-data"
            }, _toDisplayString(`${dep}${index + 1 == state.departmentsArray.length ? '' : ', '}`), 1))
          }), 128))
        ])
      ]),
      _createElementVNode("div", _hoisted_19, [
        _cache[7] || (_cache[7] = _createElementVNode("span", { class: "person-item-info-pos" }, "Возможные мин. опоздания", -1)),
        _createElementVNode("span", _hoisted_20, _toDisplayString(__props.person.lateTime), 1)
      ]),
      _createElementVNode("div", _hoisted_21, [
        _cache[8] || (_cache[8] = _createElementVNode("span", { class: "person-item-info-pos" }, "Возможные мин. раннего ухода", -1)),
        _createElementVNode("span", _hoisted_22, _toDisplayString(__props.person.earlyLeave), 1)
      ])
    ]),
    _createElementVNode("img", {
      class: "person-item-ellipse",
      src: _unref(ellipse)
    }, null, 8, _hoisted_23)
  ]))
}
}

})