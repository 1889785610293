import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/icon/flame.svg'


const _hoisted_1 = { class: "curr-plan-wrapper" }
const _hoisted_2 = { class: "curr-plan-name-row-wrapper" }
const _hoisted_3 = { class: "curr-plan-name-info" }
const _hoisted_4 = { class: "curr-plan-name-first-row" }
const _hoisted_5 = { class: "curr-plan-name" }
const _hoisted_6 = {
  key: 0,
  class: "curr-plan-info"
}
const _hoisted_7 = { class: "curr-plan-text-rest" }
const _hoisted_8 = {
  key: 1,
  class: "curr-plan-info"
}
const _hoisted_9 = { class: "curr-plan-text" }
const _hoisted_10 = ["innerHTML"]

import { PropType } from 'vue'
import { OrgPlan } from '../../models'


export default /*@__PURE__*/_defineComponent({
  __name: 'UPlan',
  props: {
  plan: {
    type: Object as PropType<OrgPlan>,
    required: true
  },
  isCurrent: {
    type: Boolean,
    default: false
  },
  paidDays: {
    type: String,
    default: '0'
  },
  hideActBtn: {
    type: Boolean,
    default: false
  }
},
  emits: ['click-btn'],
  setup(__props, { emit: __emit }) {

const props = __props

const emits = __emit

function formattedDescription (text: string) {
  return text.replace(/\n/g, '<br>')
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "curr-plan-icon",
        style: _normalizeStyle({background: `linear-gradient(90deg, rgba(${props.plan.gradientLow}, 1) 0%, rgba(${props.plan.gradientTop}, 1) 100%)`})
      }, _cache[1] || (_cache[1] = [
        _createElementVNode("img", { src: _imports_0 }, null, -1)
      ]), 4),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", _hoisted_5, _toDisplayString(props.plan.name), 1)
        ]),
        (props.isCurrent)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _cache[2] || (_cache[2] = _createElementVNode("span", { class: "curr-plan-text" }, " Осталось ", -1)),
              _createElementVNode("span", _hoisted_7, _toDisplayString(props.paidDays) + " дней ", 1)
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createElementVNode("span", _hoisted_9, _toDisplayString(props.plan.price), 1),
              _cache[3] || (_cache[3] = _createElementVNode("span", { class: "curr-plan-text-rest" }, " сом / мес ", -1))
            ]))
      ])
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("span", {
        innerHTML: formattedDescription(props.plan.description),
        class: "curr-plan-description"
      }, null, 8, _hoisted_10)
    ]),
    (!props.isCurrent && !props.hideActBtn)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "curr-plan-btn button-click",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (emits('click-btn')))
        }, _cache[4] || (_cache[4] = [
          _createElementVNode("span", null, "Выбрать", -1)
        ])))
      : _createCommentVNode("", true)
  ]))
}
}

})