import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "instr-step-rounded-step" }
const _hoisted_2 = { class: "instr-step-info" }
const _hoisted_3 = { class: "instr-step-info-label" }
const _hoisted_4 = { class: "instr-step-info-text" }


export default /*@__PURE__*/_defineComponent({
  __name: 'UInstructionStep',
  props: {
  step: {
    type: String,
    required: true
  },
  text: {
    type: String,
    required: true
  },
  width: {
    type: Number,
    default: 0
  }
},
  setup(__props) {

const props = __props

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "instr-step-wrapper",
    style: _normalizeStyle({width: props.width > 0 ? `${props.width}vw` : '21.69vw'})
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", null, _toDisplayString(__props.step), 1)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(`Шаг ${__props.step}`), 1),
      _createElementVNode("span", _hoisted_4, _toDisplayString(props.text), 1)
    ])
  ], 4))
}
}

})