<template>
    <div>
        <transition name="fade">
            <div v-if="state.isVisible" class="popup"
                @click="emits('closed')"
            >
                <span>{{ props.text }}</span>
            </div>
        </transition>
    </div>
</template>

<script lang="ts" setup>
import { reactive, onUnmounted, onBeforeMount } from 'vue'

const state = reactive({
  isVisible: false,
  timer: null as ReturnType<typeof setTimeout> | null
})

const emits = defineEmits(['closed'])

const props = defineProps({
  text: {
    type: String,
    required: true
  }
})

onBeforeMount(() => {
  showPopup()
  state.isVisible = true
})

onUnmounted(() => {
  if (state.timer) clearTimeout(state.timer)
})

function showPopup () {
  state.isVisible = true
  clearTimeout(state.timer as ReturnType<typeof setTimeout>)
  state.timer = setTimeout(() => {
    state.isVisible = false
    emits('closed')
  }, 3000)
}
</script>

<style scoped>
.fade-enter-active {
    transition: opacity 1s;
    transition-delay: 2s;
}

.fade-leave-active {
    transition: opacity 1s;
}

.fade-enter-from, .fade-leave-to {
    opacity: 0;
}

.fade-enter-to, .fade-leave-from {
    opacity: 1;
}

.popup {
    position: absolute;
    left: 50%;
    top: 90%;
    transform: translate(-50%, -50%);
    padding: 1.8vh 1vw;
    border-radius: 9.4vh;
    background-color: #2D4B6B;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    font: 500 1.852vh 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
    color: var(--primary-font-color);
}
</style>
