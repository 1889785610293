<template>
  <div class="icon-container button-click">
    <img :style="{
      width: props.iconWidth + props.iconUnit,
      paddingTop: props.iconPdTp + props.iconPdUnit,
      paddingRight: props.iconPdRt + props.iconPdUnit,
      paddingBottom: props.iconPdBt + props.iconPdUnit,
      paddingLeft: props.iconPdLt + props.iconPdUnit
    }" :src="state.iconRef">
    <span v-if="props.showText" :style="{
      fontSize: props.fontSize + props.fontUnit
    }"> {{ state.text }} </span>
  </div>
</template>

<script setup lang="ts">
import { reactive, onBeforeMount } from 'vue'
import { iconMap } from '@/maps'

const props = defineProps({
  type: String,
  showText: {
    type: Boolean,
    default: false,
    required: false
  },
  iconWidth: {
    type: Number,
    default: 0,
    required: false
  },
  iconUnit: {
    type: String,
    default: 'vw',
    required: false
  },
  isEmpty: {
    type: Boolean,
    default: true,
    required: false
  },
  iconPdTp: {
    type: Number,
    default: 0,
    required: false
  },
  iconPdRt: {
    type: Number,
    default: 0,
    required: false
  },
  iconPdBt: {
    type: Number,
    default: 0,
    required: false
  },
  iconPdLt: {
    type: Number,
    default: 0,
    required: false
  },
  iconPdUnit: {
    type: String,
    default: 'rem',
    required: false
  },
  fontUnit: {
    type: String,
    default: 'rem',
    required: false
  },
  fontSize: {
    type: Number,
    default: 1,
    required: false
  }
})

const state = reactive({
  iconRef: '',
  text: '',
  activeColor: ''
})

onBeforeMount(async () => {
  const icon = iconMap[props.type as keyof typeof iconMap]
  if (icon) {
    state.text = icon.text
    state.iconRef = icon.iconRef
  }
})

</script>

<style scoped>
  .icon-container{
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 0.5rem;
    width: 3rem;
    height: 3rem;
  }
  .icon-container img {
    width: max-content;
  }

  @media screen and (max-width: 768px) {
    .icon-container {
      width: 9vw;
      height: 9vw;
    }
  }
</style>
