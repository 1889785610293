<template>
    <div class="dropdown">
        <div class='dropdown-header' @click="state.isOpen = !state.isOpen">
            <img :src="state.country.img">
            <span class="country-code">{{ state.country.code }}</span>
        </div>
        <div v-if="state.isOpen" class="dropdown-content">
            <div v-for="country in countries" :key="country.country" @click="selectOption(country)">
                <div class="dropdown-images">
                    <img :src="country.img">
                    <span>{{ country.code }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { reactive, onBeforeMount } from 'vue'
import { Country } from '@/models'
import { countriesList } from '@/objects'

const emits = defineEmits(['dropdown-choosen'])

const countries = countriesList

const state = reactive({
  isOpen: false,
  country: countries[0]
})

onBeforeMount(async () => {
  emits('dropdown-choosen', state.country)
})

function selectOption (option: Country) {
  state.country = option
  state.isOpen = false
  emits('dropdown-choosen', option)
}
</script>

<style>
.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    position: absolute;
    background-color: rgb(255, 255, 255, 0.9);
    min-width: 100px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 12px 16px;
    z-index: 1;
    border-radius: 1rem;
    font: 0.94vw 'RedHatText-SemiBold', 'OpenSans', sans-serif;
    color: #333;
    margin-top: 0.25rem;
}

.dropdown-images {
    width: fit-content;
    padding: 10px;
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 0.5rem;
}

.dropdown-images:hover {
    background-color: #fff;
    border-radius: 0.5rem;
}
.dropdown img {
    width: 1.67vw;
}
.dropdown-header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 0.5rem;
}
.country-code {
    font: 0.94vw 'RedHatText-SemiBold', 'OpenSans', sans-serif;
    color: #fff;
}

@media screen and (max-width: 950px) {
  .dropdown-content {
    padding: 12px 16px;
    font-size: 3.82vw;
  }
  .dropdown img {
    width: 7vw;
  }
  .country-code {
    font-size: 3.82vw;
  }
}
</style>
