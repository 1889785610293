import { defineStore } from 'pinia'
import { AxiosError } from 'axios'
import { axiosMain } from '@/axios-setup'
import { Fixes, Fix } from '../models'

export const useFixesStore = defineStore('fixes', {
  state: () => ({
    fixResponse: {} as Fixes,
    fixes: [] as Fix[]
  }),
  actions: {
    async getFixesAction (orgId: string,
      period: number | null,
      dateFrom: number | null,
      dateTo: number | null) {
      try {
        const response = await axiosMain.get<Fixes>('/Fix', {
          params: {
            orgId,
            period,
            dateFrom,
            dateTo
          }
        })
        this.fixResponse = response.data
        this.fixes = this.fixResponse.fix
      } catch (error) {
        const axiosError = error as AxiosError
        console.error(axiosError.message)
      }
    }
  },
  getters: {
    getFixes: (state) => state.fixes
  }
})
