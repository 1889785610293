import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, unref as _unref } from "vue"

const _hoisted_1 = { class: "auth-wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "auth-form"
}
const _hoisted_3 = {
  key: 1,
  class: "auth-form"
}
const _hoisted_4 = { class: "pass-input inputs" }
const _hoisted_5 = { class: "pass-input inputs" }
const _hoisted_6 = { class: "logo" }
const _hoisted_7 = ["src"]

import { reactive } from 'vue'
import { Country } from '@/models'
import logo from '@/assets/images/logo.svg'
import { useSMSStore } from '@/stores/sms'
// import { useOrganizationStore } from '@/stores/auth';


export default /*@__PURE__*/_defineComponent({
  __name: 'UPassRecovery',
  emits: ['to-check-number', 'open-dialog', 'to-auth', 'close-dialog'],
  setup(__props, { emit: __emit }) {

const smsStore = useSMSStore()
// const organizationStore = useOrganizationStore()

const emits = __emit

const state = reactive({
  step: 'passSendSMS',
  login: '',
  country: {} as Country,
  passwordFirst: '',
  passwordSecond: ''
})

function changeCountry (country: Country) {
  state.country = country
}

function inputPhone (login: string) {
  state.login = login
}

async function sendSMS () {
  if (!state.login || state.login.length < 9) {
    emits('open-dialog', { headerText: 'Ошибка', message: 'Введите Ваш номер телефона.' })
    return
  }
  emits('open-dialog', { headerText: 'Отправляем СМС', message: 'Пожалуйста, подождите!', showButtons: false, loading: true })

  const isTest = process.env.VUE_APP_SMS_IS_TEST === 'true'

  const reqBody = {
    sender: process.env.VUE_APP_SMS_SENDER,
    text: '',
    phone_number: [state.login],
    secret_code_generate: true,
    is_test: isTest
  }

  await smsStore.sendSMSRequest(reqBody)
  const smsResponse = smsStore.getSMSResponse
  emits('close-dialog')

  if (smsResponse?.status === 'success' && smsResponse?.code.length === 4) {
    localStorage.setItem('lasttimeSMSSend', Math.floor(Date.now() / 1000).toString())
    emits('to-check-number', { login: `${state.country.code}${state.login}`, sms: smsResponse?.code })
  } else {
    emits('open-dialog', { headerText: 'Ошибка', message: 'Попробуйте позже!' })
  }
}

async function checkPass () {
  if (state.passwordFirst.length >= 6 && state.passwordFirst === state.passwordSecond) {
    // const result = await organizationStore.changePassword(1111, state.passwordFirst)
    // if (result.status === ) {

    // }
  } else if (state.passwordFirst.length <= 6) {
    emits('open-dialog', { headerText: 'Ошибка', message: 'Пароль должен быть минимум 6 символов.' })
  } else if (state.passwordFirst !== state.passwordSecond) {
    emits('open-dialog', { headerText: 'Ошибка', message: 'Введенные пароли не совпадают.' })
  }
}

return (_ctx: any,_cache: any) => {
  const _component_u_phone_number = _resolveComponent("u-phone-number")!
  const _component_u_button = _resolveComponent("u-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (state.step === 'passSendSMS')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _cache[4] || (_cache[4] = _createElementVNode("span", { class: "auth-form-header" }, "Забыли пароль?", -1)),
          _cache[5] || (_cache[5] = _createElementVNode("span", { class: "sms-info sms-info-text" }, "Мы отправим код на ваш номер телефона.", -1)),
          _createVNode(_component_u_phone_number, {
            onChangeCountry: changeCountry,
            onInputPhone: inputPhone
          }),
          _createVNode(_component_u_button, {
            class: "auth-btn button-click",
            onClick: sendSMS,
            btnText: 'Восстановить пароль'
          }),
          _createElementVNode("div", null, [
            _createElementVNode("span", {
              class: "back-login-btn",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (emits('to-auth')))
            }, "Вернуться ко входу")
          ])
        ]))
      : _createCommentVNode("", true),
    (state.step === 'passConfirm')
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _cache[6] || (_cache[6] = _createElementVNode("span", { class: "auth-form-header" }, "Новый пароль", -1)),
          _cache[7] || (_cache[7] = _createElementVNode("span", { class: "sms-info sms-info-text" }, "Пароль должен содержать минимум 6 символов", -1)),
          _createElementVNode("div", _hoisted_4, [
            _withDirectives(_createElementVNode("input", {
              type: "password",
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((state.passwordFirst) = $event)),
              placeholder: "Новый пароль"
            }, null, 512), [
              [_vModelText, state.passwordFirst]
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _withDirectives(_createElementVNode("input", {
              type: "password",
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((state.passwordSecond) = $event)),
              placeholder: "Подтверждение пароля"
            }, null, 512), [
              [_vModelText, state.passwordSecond]
            ])
          ]),
          _createVNode(_component_u_button, {
            class: "auth-btn button-click",
            onClick: checkPass,
            btnText: 'Сменить пароль'
          }),
          _createElementVNode("div", null, [
            _createElementVNode("span", {
              class: "back-login-btn",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (emits('to-auth')))
            }, "Вернуться ко входу")
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("img", { src: _unref(logo) }, null, 8, _hoisted_7)
    ])
  ]))
}
}

})