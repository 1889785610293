import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, unref as _unref, vModelDynamic as _vModelDynamic, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createBlock as _createBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "settings-wrapper" }
const _hoisted_2 = { class: "settings-header" }
const _hoisted_3 = { class: "settings-tabs-controller" }
const _hoisted_4 = ["id"]
const _hoisted_5 = ["id"]
const _hoisted_6 = { class: "settings-main settings-main-main" }
const _hoisted_7 = { class: "settings-main-row-block" }
const _hoisted_8 = { class: "input-icon-container" }
const _hoisted_9 = ["readonly"]
const _hoisted_10 = {
  key: 0,
  class: "img-block input-icon"
}
const _hoisted_11 = ["src"]
const _hoisted_12 = ["src"]
const _hoisted_13 = ["src"]
const _hoisted_14 = { class: "settings-main-row-block" }
const _hoisted_15 = { class: "input-icon-container" }
const _hoisted_16 = ["readonly"]
const _hoisted_17 = {
  key: 0,
  class: "img-block input-icon"
}
const _hoisted_18 = ["src"]
const _hoisted_19 = ["src"]
const _hoisted_20 = ["src"]
const _hoisted_21 = { class: "settings-main-row-block" }
const _hoisted_22 = { class: "input-icon-container" }
const _hoisted_23 = ["readonly"]
const _hoisted_24 = {
  key: 0,
  class: "img-block input-icon"
}
const _hoisted_25 = ["src"]
const _hoisted_26 = ["src"]
const _hoisted_27 = ["src"]
const _hoisted_28 = { class: "settings-main-row-block" }
const _hoisted_29 = { class: "input-icon-container" }
const _hoisted_30 = ["type", "readonly"]
const _hoisted_31 = {
  key: 0,
  class: "img-block input-icon"
}
const _hoisted_32 = ["src"]
const _hoisted_33 = ["src"]
const _hoisted_34 = ["src"]
const _hoisted_35 = { class: "settings-main-row-block" }
const _hoisted_36 = { class: "setting-control-wrapper" }
const _hoisted_37 = { class: "settings-main settings-main-table" }
const _hoisted_38 = { class: "advanced-fines-settings-tabs-controller settings-tabs-controller" }
const _hoisted_39 = ["id"]
const _hoisted_40 = ["id"]
const _hoisted_41 = { class: "advanced-fines-settings-tabs-controller settings-tabs-controller" }
const _hoisted_42 = ["onClick", "id"]
const _hoisted_43 = { class: "advanced-fines-settings" }
const _hoisted_44 = { class: "advanced-fines-settings-header" }
const _hoisted_45 = { class: "advanced-fines-settings-column-dep advanced-fines-settings-item" }
const _hoisted_46 = { class: "advanced-fines-settings-table" }
const _hoisted_47 = ["onClick"]
const _hoisted_48 = { class: "advanced-fines-settings-column-dep advanced-fines-settings-item" }
const _hoisted_49 = { class: "advanced-fines-settings-column-fine advanced-fines-settings-item" }
const _hoisted_50 = { class: "advanced-fines-settings-column-fine advanced-fines-settings-item" }
const _hoisted_51 = { class: "advanced-fines-settings-column-is-active advanced-fines-settings-item" }
const _hoisted_52 = ["src", "onClick"]
const _hoisted_53 = { class: "settings-action-btns" }

import { reactive, onMounted, watch } from 'vue'
import edit from '@/assets/icon/edit.svg'
import ok from '@/assets/icon/ok.svg'
import cancel from '@/assets/icon/cancel.svg'
import {
  Employee,
  Departments,
  Positions,
  FeeList,
  FeeListMap,
  FeePatchList,
  FeePatchMap,
  Cookies,
  Organization
} from '@/models'
import checkBoxActive from '@/assets/icon/check-box/active.svg'
import checkBoxNotActive from '@/assets/icon/check-box/not-active.svg'
import { subjects } from '@/maps'
import { useSettingsStore } from '@/stores/settings'
import { useCookesStore } from '@/stores/cookies'
import { useOrganazationStore } from '@/stores/organization'

interface PreparedDict {
  settings: { [key: string] : string },
  advansedSettings: FeePatchList
}

interface Org {
  id: string,
  name: string,
  orgId: string
}

interface AssociativeMap {
  [key: string]: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'SettingsView',
  setup(__props) {

const state = reactive({
  tab: 'main',
  preparedDict: {} as PreparedDict,
  advancedSettings: [] as FeePatchList,
  aloudLateTime: 0,
  lateFine: 0,
  earlyLeaveFine: 0,
  operation: '',
  depChoosedName: '',
  depChoosedId: '',
  choosenId: '',
  subTab: 'late-fine',
  subject: 'organization',
  subjectName: '',
  cookies: {} as Cookies,
  orgId: '',
  showContolTab: false,
  organization: {} as Organization,
  showOperationPanel: false
})

const dialogStates = reactive({
  dialogHeader: '',
  dialogMsg: '',
  showDialog: false,
  dialogCancelBtn: false,
  showSpinner: false
})

const dataSets = reactive({
  persons: [] as Employee[],
  departments: [] as Departments,
  positions: [] as Positions,
  organization: [] as Org[]
})

const settingsState = reactive({
  feeList: [] as FeeList
})

const subTabsList = [
  { code: 'organization', text: 'Организация' },
  { code: 'departments', text: 'Подразделение' },
  { code: 'positions', text: 'Должность' },
  { code: 'persons', text: 'Сотрудники' }
]

const mainSettingsStates = reactive({
  mainInput: '',
  telegram: '',
  email: '',
  password: '',
  name: '',
  lockPassword: '',
  timezone: null as number | null
})

const settingStore = useSettingsStore()
const cookies = useCookesStore()
const organization = useOrganazationStore()

const associativeMap: AssociativeMap = {
// значение фронт: значение API
  'late-fine': 'late',
  'early-leave-fine': 'leave',
  persons: 'per',
  departments: 'dep',
  positions: 'pos',
  organization: 'org',
  lateTime: 'lateTime',
  earlyLeave: 'earlyLeave'
}

watch(() => state.subject, (newValue: string) => {
  state.subjectName = subjects[newValue as keyof typeof subjects]?.text
})

watch(() => state.preparedDict, (newValue) => {
  if ((newValue && Object.keys(newValue).length === 0) ||
    (newValue.advansedSettings?.length + (newValue.settings ? Object.keys(newValue.settings).length : 0) === 0)) {
    state.advancedSettings = []
    state.showOperationPanel = false
  } else {
    state.showOperationPanel = true
  }
}, { deep: true })

watch(() => state.organization, (newValue) => {
  if (newValue && Object.keys(newValue).length > 0) {
    dataSets.organization.push({
      id: newValue.orgId,
      name: newValue.name,
      orgId: newValue.orgId
    })
    state.showContolTab = newValue.options.feeControl
  }
})

const handleInput = (event: Event) => {
  const input = event.target as HTMLInputElement
  let value = input.value
  if (!/^\d+$/.test(input.value)) {
    value = value.slice(0, value.length - 1)
    mainSettingsStates.telegram = value
  }
}

onMounted(async () => {
  openDialog('Пожалуйста подождите!', 'Загружаем настройки', false, true)
  await cookies.getCookie()
  state.organization = JSON.parse(sessionStorage.getItem('organization') || '{}')
  state.orgId = cookies.getCookies.orgId
  await getMainSettings()
  state.subjectName = subjects[state.subject as keyof typeof subjects]?.text
  dataSets.persons = JSON.parse(sessionStorage.getItem('persons') || '[]')
  dataSets.departments = JSON.parse(sessionStorage.getItem('departments') || '[]')
  dataSets.positions = JSON.parse(sessionStorage.getItem('positions') || '[]').positions
  settingsState.feeList = JSON.parse(sessionStorage.getItem('fees') || '[]')
  closeDialog('cancel')
})

function updateTimezone (timezone: number) {
  mainSettingsStates.timezone = timezone
  state.preparedDict = { ...state.preparedDict, ...{ timezone } }
}

async function getFees () {
  await settingStore.getFeeAction(state.orgId)
  sessionStorage.setItem('fees', JSON.stringify(settingStore.getFeeList))
  settingsState.feeList = settingStore.getFeeList
}

async function getMainSettings (needToRebuildOrg: boolean = false) {
  if (needToRebuildOrg) {
    await organization.getOrganizationAction(state.orgId)
    state.organization = organization.getOrganization
    sessionStorage.setItem('organization', JSON.stringify(state.organization))
  }
  if (state.organization.chatId?.trim().length > 0) {
    mainSettingsStates.telegram = state.organization.chatId.slice(1)
  } else {
    mainSettingsStates.telegram = ''
  }
  mainSettingsStates.name = state.organization.name
  mainSettingsStates.email = state.organization.email
  mainSettingsStates.password = state.organization.lockPassword
  mainSettingsStates.lockPassword = state.organization.lockPassword
  mainSettingsStates.timezone = state.organization.timezone
}

function saveTmpResult (arg: string) {
  if (arg !== '') {
    let isError = false
    if (arg === 'name') {
      if (mainSettingsStates.name.trim().length === 0) {
        openDialog('Ошибка', 'Название организации не может быть пустым')
        isError = true
      } else {
        state.preparedDict.settings = { ...state.preparedDict.settings, ...{ name: mainSettingsStates.name } }
      }
    }
    if (arg === 'telegram') {
      if (mainSettingsStates.telegram.length < 6) {
        openDialog('Ошибка', 'ID Telegram должен минимум 6 символов')
        isError = true
      } else {
        state.preparedDict.settings = { ...state.preparedDict.settings, ...{ chatId: `-${mainSettingsStates.telegram}` } }
      }
    }
    if (arg === 'email') {
      if (!isValidEmail(mainSettingsStates.email.trim())) {
        openDialog('Ошибка', 'Адрес электронной почты введен не верно')
        isError = true
      } else {
        state.preparedDict.settings = { ...state.preparedDict.settings, ...{ email: mainSettingsStates.email } }
      }
    }
    if (arg === 'password') {
      state.preparedDict.settings = { ...state.preparedDict.settings, ...{ lockPassword: mainSettingsStates.password.trim() } }
    }
    if (!isError) {
      mainSettingsStates.mainInput = ''
    }
  }
}

function openDialog (dialogHeader: string, dialogText: string, cancelBtn: boolean = false, showSpinner: boolean = false) {
  dialogStates.dialogHeader = dialogHeader
  dialogStates.dialogMsg = dialogText
  dialogStates.showDialog = true
  dialogStates.dialogCancelBtn = cancelBtn
  dialogStates.showSpinner = showSpinner
}

async function closeDialog (res: string) {
  if (res === 'ok') {
    if (state.operation === 'clearTmpDict') {
      state.preparedDict = { settings: {}, advansedSettings: [] }
    }
    dialogStates.showDialog = false
    if (state.operation === 'saveTmpDict') {
      openDialog('Пожалуйста подождите!', '', false, true)
      let resText = ''
      let isError = false
      if (state.preparedDict.advansedSettings && state.preparedDict.advansedSettings.length > 0) {
        const result = await settingStore.updateFeeAction(state.orgId, state.preparedDict.advansedSettings)
        await getFees()
        resText = result?.message ? result?.message : ''
        isError = !result || result.status !== 'updated'
      }
      if (state.preparedDict.settings && Object.keys(state.preparedDict.settings).length > 0) {
        const result = await settingStore.updateMainSettingsAction(state.orgId, state.preparedDict.settings)
        await getMainSettings(true)
        resText = result?.message ? result?.message : ''
        isError = !result || result.status !== 'updated'
      }
      if (isError) {
        openDialog('Произошла ошибка!', resText)
      } else {
        openDialog('Настройки сохранены', resText)
        state.preparedDict = { settings: {}, advansedSettings: [] }
      }
    }
  } else {
    dialogStates.showDialog = false
  }
  state.operation = ''
}

function isValidEmail (email: string): boolean {
  return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email)
}

function modeTmpDict (id: string, type: string, value: unknown) {
  const moddedRow = {
    subjectId: id,
    feeType: associativeMap[state.subTab],
    amount: type === 'amount'
      ? Number(value)
      : Number((serachMutatedData(id, 'amount') ? serachMutatedData(id, 'amount') : getCurrValue(id, 'amount'))),
    free: type === 'free'
      ? Boolean(value)
      : Boolean((serachMutatedData(id, 'free') ? serachMutatedData(id, 'free') : getCurrValue(id, 'free'))),
    lateTime: type === 'late-fine'
      ? Number(value)
      : Number((serachMutatedData(id, 'lateTime') ? serachMutatedData(id, 'lateTime') : getCurrValue(id, 'lateTime'))),
    earlyLeave: type === 'early-leave-fine'
      ? Number(value)
      : Number((serachMutatedData(id, 'earlyLeave') ? serachMutatedData(id, 'earlyLeave') : getCurrValue(id, 'earlyLeave')))
  }
  const index = state.advancedSettings.findIndex(item => item.subjectId === moddedRow.subjectId)
  if (index === -1) {
    state.advancedSettings.push(moddedRow)
  } else {
    state.advancedSettings[index] = moddedRow
  }
  state.preparedDict = { ...state.preparedDict, ...{ advansedSettings: state.advancedSettings } }
}

function serachMutatedData (id: string, type: string) {
  const mutatedData = state.advancedSettings.find(item => item.subjectId === id &&
      item.feeType === associativeMap[state.subTab]) as undefined | FeePatchMap
  if (mutatedData) {
    return mutatedData[type as keyof typeof mutatedData]
  }
}

function preSaveTmpDict (res: string) {
  if (res === 'cancel') {
    state.operation = 'clearTmpDict'
    openDialog('Отменить изменения?', 'Все не сохраненные изменения будут отменены.', true)
  }
  if (res === 'save') {
    state.operation = 'saveTmpDict'
    openDialog('Сохранить изменения?', 'Внесенные изменения будут сохранены.', true)
  }
}

function toModeTmpDict (arg: { [key: string] : string | number }) {
  const subTypeTmp = arg[Object.keys(arg)[2]]
  modeTmpDict(arg[Object.keys(arg)[1]].toString(),
    subTypeTmp === '' ? 'amount' : state.subTab,
    arg[Object.keys(arg)[0]])
}

function fineSum (id: string, type: string): number | string | boolean | null {
  const res = settingsState.feeList.find(item => item.subjectId === id &&
    item.feeType === associativeMap[state.subTab] &&
    item.subjectType === associativeMap[state.subject]) as undefined | FeeListMap
  if (!res) {
    if (type === 'amount' || type === 'lateTime' || type === 'earlyLeave') {
      return 0
    } else {
      return false
    }
  }
  return res[type as keyof typeof res]
}

function getCurrValue (id: string, type: string) {
  let perRes = null
  if (type === 'lateTime' || type === 'earlyLeave') {
    perRes = state.advancedSettings.find(item => item.subjectId === id && item[type as keyof typeof item] === associativeMap[type])
  } else {
    perRes = state.advancedSettings.find(item => item.subjectId === id && item.feeType === associativeMap[state.subTab])
  }
  if (perRes) {
    return perRes[type as keyof typeof perRes]
  } else {
    return fineSum(id, type)
  }
}

function closeInput () {
  if (mainSettingsStates.mainInput === 'name') {
    mainSettingsStates.name = state.organization.name.trim()
  }
  if (mainSettingsStates.mainInput === 'telegram') {
    mainSettingsStates.telegram = state.organization.chatId.slice(1)
  }
  if (mainSettingsStates.mainInput === 'email') {
    mainSettingsStates.email = state.organization.email
  }
  if (mainSettingsStates.mainInput === 'password') {
    mainSettingsStates.password = state.organization.lockPassword
  }
  mainSettingsStates.mainInput = ''
}

return (_ctx: any,_cache: any) => {
  const _component_u_dropdown_hours = _resolveComponent("u-dropdown-hours")!
  const _component_u_counter = _resolveComponent("u-counter")!
  const _component_u_dialog = _resolveComponent("u-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", {
            class: "button-click",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (state.tab = 'main')),
            id: state.tab === 'main' ? 'settings-tabs-controller-active' : ''
          }, "Основные", 8, _hoisted_4),
          (state.showContolTab)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: "button-click",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (state.tab = 'control')),
                id: state.tab === 'control' ? 'settings-tabs-controller-active' : ''
              }, "Контроль", 8, _hoisted_5))
            : _createCommentVNode("", true)
        ])
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _cache[20] || (_cache[20] = _createElementVNode("div", { class: "settings-main-row-label-block" }, [
            _createElementVNode("span", { class: "settings-main-row-header" }, "Организация"),
            _createElementVNode("span", { class: "settings-main-row-info" }, "Название организации")
          ], -1)),
          _createElementVNode("div", _hoisted_8, [
            _withDirectives(_createElementVNode("input", {
              class: _normalizeClass(["settings-main-input", mainSettingsStates.mainInput === 'name' ? 'input-active' : 'input-unactive']),
              type: "text",
              readonly: mainSettingsStates.mainInput !== 'name',
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((mainSettingsStates.name) = $event))
            }, null, 10, _hoisted_9), [
              [_vModelText, mainSettingsStates.name]
            ]),
            (mainSettingsStates.mainInput === 'name')
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createElementVNode("img", {
                    src: _unref(ok),
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (saveTmpResult('name')))
                  }, null, 8, _hoisted_11),
                  _createElementVNode("img", {
                    src: _unref(cancel),
                    onClick: closeInput
                  }, null, 8, _hoisted_12)
                ]))
              : (_openBlock(), _createElementBlock("img", {
                  key: 1,
                  class: "input-icon",
                  src: _unref(edit),
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (mainSettingsStates.mainInput = 'name'))
                }, null, 8, _hoisted_13))
          ])
        ]),
        _createElementVNode("div", _hoisted_14, [
          _cache[21] || (_cache[21] = _createElementVNode("div", { class: "settings-main-row-label-block" }, [
            _createElementVNode("span", { class: "settings-main-row-header" }, "ID Telegram"),
            _createElementVNode("span", { class: "settings-main-row-info" }, "Идентификатор Telegram для рассылок")
          ], -1)),
          _createElementVNode("div", _hoisted_15, [
            _withDirectives(_createElementVNode("input", {
              class: _normalizeClass(["settings-main-input", mainSettingsStates.mainInput === 'telegram' ? 'input-active' : 'input-unactive']),
              type: "text",
              readonly: mainSettingsStates.mainInput !== 'telegram',
              onInput: handleInput,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((mainSettingsStates.telegram) = $event))
            }, null, 42, _hoisted_16), [
              [_vModelText, mainSettingsStates.telegram]
            ]),
            (mainSettingsStates.mainInput === 'telegram')
              ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                  _createElementVNode("img", {
                    src: _unref(ok),
                    onClick: _cache[6] || (_cache[6] = ($event: any) => (saveTmpResult('telegram')))
                  }, null, 8, _hoisted_18),
                  _createElementVNode("img", {
                    src: _unref(cancel),
                    onClick: closeInput
                  }, null, 8, _hoisted_19)
                ]))
              : (_openBlock(), _createElementBlock("img", {
                  key: 1,
                  class: "input-icon",
                  src: _unref(edit),
                  onClick: _cache[7] || (_cache[7] = ($event: any) => (mainSettingsStates.mainInput = 'telegram'))
                }, null, 8, _hoisted_20))
          ])
        ]),
        _createElementVNode("div", _hoisted_21, [
          _cache[22] || (_cache[22] = _createElementVNode("div", { class: "settings-main-row-label-block" }, [
            _createElementVNode("span", { class: "settings-main-row-header" }, "E-mail"),
            _createElementVNode("span", { class: "settings-main-row-info" }, "Ваша почта для рассылок и прочего")
          ], -1)),
          _createElementVNode("div", _hoisted_22, [
            _withDirectives(_createElementVNode("input", {
              class: _normalizeClass(["settings-main-input", mainSettingsStates.mainInput === 'email' ? 'input-active' : 'input-unactive']),
              type: "text",
              readonly: mainSettingsStates.mainInput !== 'email',
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((mainSettingsStates.email) = $event))
            }, null, 10, _hoisted_23), [
              [_vModelText, mainSettingsStates.email]
            ]),
            (mainSettingsStates.mainInput === 'email')
              ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                  _createElementVNode("img", {
                    src: _unref(ok),
                    onClick: _cache[9] || (_cache[9] = ($event: any) => (saveTmpResult('email')))
                  }, null, 8, _hoisted_25),
                  _createElementVNode("img", {
                    src: _unref(cancel),
                    onClick: _cache[10] || (_cache[10] = ($event: any) => (mainSettingsStates.mainInput = ''))
                  }, null, 8, _hoisted_26)
                ]))
              : (_openBlock(), _createElementBlock("img", {
                  key: 1,
                  class: "input-icon",
                  src: _unref(edit),
                  onClick: _cache[11] || (_cache[11] = ($event: any) => (mainSettingsStates.mainInput = 'email'))
                }, null, 8, _hoisted_27))
          ])
        ]),
        _createElementVNode("div", _hoisted_28, [
          _cache[23] || (_cache[23] = _createElementVNode("div", { class: "settings-main-row-label-block" }, [
            _createElementVNode("span", { class: "settings-main-row-header" }, "Пароль"),
            _createElementVNode("span", { class: "settings-main-row-info" }, "Пароль для доступа к настройкам")
          ], -1)),
          _createElementVNode("div", _hoisted_29, [
            _withDirectives(_createElementVNode("input", {
              class: _normalizeClass(["settings-main-input", mainSettingsStates.mainInput === 'lockPassword' ? 'input-active' : 'input-unactive']),
              type: mainSettingsStates.mainInput !== 'lockPassword' ? 'password' : 'text',
              readonly: mainSettingsStates.mainInput !== 'lockPassword',
              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((mainSettingsStates.password) = $event))
            }, null, 10, _hoisted_30), [
              [_vModelDynamic, mainSettingsStates.password]
            ]),
            (mainSettingsStates.mainInput === 'lockPassword')
              ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                  _createElementVNode("img", {
                    src: _unref(ok),
                    onClick: _cache[13] || (_cache[13] = ($event: any) => (saveTmpResult('password')))
                  }, null, 8, _hoisted_32),
                  _createElementVNode("img", {
                    src: _unref(cancel),
                    onClick: _cache[14] || (_cache[14] = ($event: any) => (mainSettingsStates.mainInput = ''))
                  }, null, 8, _hoisted_33)
                ]))
              : (_openBlock(), _createElementBlock("img", {
                  key: 1,
                  class: "input-icon",
                  src: _unref(edit),
                  onClick: _cache[15] || (_cache[15] = ($event: any) => (mainSettingsStates.mainInput = 'lockPassword'))
                }, null, 8, _hoisted_34))
          ])
        ]),
        _createElementVNode("div", _hoisted_35, [
          _cache[24] || (_cache[24] = _createElementVNode("div", { class: "settings-main-row-label-block-timezone" }, [
            _createElementVNode("span", { class: "settings-main-row-header" }, "Часовой пояс"),
            _createElementVNode("span", { class: "settings-main-row-info" }, "Время, используемое в приложении")
          ], -1)),
          _createVNode(_component_u_dropdown_hours, {
            timezone: mainSettingsStates.timezone,
            onChangeTimezone: updateTimezone
          }, null, 8, ["timezone"])
        ])
      ], 512), [
        [_vShow, state.tab === 'main']
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_36, [
        _createElementVNode("div", _hoisted_37, [
          _createElementVNode("div", _hoisted_38, [
            _createElementVNode("span", {
              class: "button-click",
              onClick: _cache[16] || (_cache[16] = ($event: any) => (state.subTab = 'late-fine')),
              id: state.subTab === 'late-fine' ? 'settings-tabs-controller-active' : ''
            }, "Штрафы за опоздание", 8, _hoisted_39),
            _createElementVNode("span", {
              class: "button-click",
              onClick: _cache[17] || (_cache[17] = ($event: any) => (state.subTab = 'early-leave-fine')),
              id: state.subTab === 'early-leave-fine' ? 'settings-tabs-controller-active' : ''
            }, "Штрафы за ранний уход", 8, _hoisted_40)
          ]),
          _createElementVNode("div", _hoisted_41, [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(subTabsList, (subTab) => {
              return _createElementVNode("span", {
                key: subTab.code,
                class: "button-click",
                onClick: ($event: any) => (state.subject = subTab.code),
                id: state.subject === subTab.code ? 'settings-tabs-controller-active' : ''
              }, _toDisplayString(subTab.text), 9, _hoisted_42)
            }), 64))
          ]),
          _createElementVNode("div", _hoisted_43, [
            _createElementVNode("div", _hoisted_44, [
              _createElementVNode("div", _hoisted_45, [
                _createElementVNode("span", null, _toDisplayString(state.subjectName), 1)
              ]),
              _cache[25] || (_cache[25] = _createStaticVNode("<div class=\"vert-delimeter\" data-v-1622fe25></div><div class=\"advanced-fines-settings-column-fine advanced-fines-settings-item\" data-v-1622fe25><span data-v-1622fe25>Сумма штрафа</span></div><div class=\"vert-delimeter\" data-v-1622fe25></div><div class=\"advanced-fines-settings-column-fine advanced-fines-settings-item\" data-v-1622fe25><span data-v-1622fe25>Допустимое время</span></div><div class=\"vert-delimeter\" data-v-1622fe25></div><div class=\"advanced-fines-settings-column-is-active advanced-fines-settings-item\" data-v-1622fe25><span data-v-1622fe25>Освобожден</span></div>", 6))
            ]),
            _createElementVNode("div", _hoisted_46, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dataSets[state.subject as keyof typeof dataSets], (subj) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: subj.id
                }, [
                  _createElementVNode("div", {
                    onClick: ($event: any) => (state.choosenId = subj.id),
                    class: _normalizeClass(['advanced-fines-settings-table-row button-click',
                            { 'advanced-fines-settings-row-active': subj.id === state.choosenId }])
                  }, [
                    _createElementVNode("div", _hoisted_48, [
                      _createElementVNode("span", null, _toDisplayString(subj.name), 1)
                    ]),
                    _cache[26] || (_cache[26] = _createElementVNode("div", { class: "vert-delimeter" }, null, -1)),
                    _createElementVNode("div", _hoisted_49, [
                      _createVNode(_component_u_counter, {
                        type: state.subTab,
                        num: getCurrValue(subj.id, 'amount'),
                        showCurr: false,
                        step: 10,
                        id: subj.id,
                        onCounterClicked: toModeTmpDict,
                        isActive: !getCurrValue(subj.id, 'free')
                      }, null, 8, ["type", "num", "id", "isActive"])
                    ]),
                    _cache[27] || (_cache[27] = _createElementVNode("div", { class: "vert-delimeter" }, null, -1)),
                    _createElementVNode("div", _hoisted_50, [
                      _createVNode(_component_u_counter, {
                        type: state.subTab,
                        num: getCurrValue(subj.id, state.subTab === 'late-fine' ? 'lateTime' : 'earlyLeave'),
                        showCurr: false,
                        step: 10,
                        id: subj.id,
                        subType: 'time',
                        onCounterClicked: toModeTmpDict,
                        isActive: !getCurrValue(subj.id, 'free')
                      }, null, 8, ["type", "num", "id", "isActive"])
                    ]),
                    _cache[28] || (_cache[28] = _createElementVNode("div", { class: "vert-delimeter" }, null, -1)),
                    _createElementVNode("div", _hoisted_51, [
                      _createElementVNode("img", {
                        src: getCurrValue(subj.id, 'free') ? _unref(checkBoxActive) : _unref(checkBoxNotActive),
                        class: "button-click",
                        onClick: ($event: any) => (modeTmpDict(subj.id, 'free', !getCurrValue(subj.id, 'free')))
                      }, null, 8, _hoisted_52)
                    ])
                  ], 10, _hoisted_47),
                  _cache[29] || (_cache[29] = _createElementVNode("div", { class: "row-delimeter" }, null, -1))
                ]))
              }), 128))
            ])
          ])
        ])
      ], 512), [
        [_vShow, state.tab === 'control']
      ]),
      (dialogStates.showDialog)
        ? (_openBlock(), _createBlock(_component_u_dialog, {
            key: 0,
            header: dialogStates.dialogHeader,
            msgText: dialogStates.dialogMsg,
            onClickRes: closeDialog,
            cancelButton: dialogStates.dialogCancelBtn,
            showButtons: dialogStates.showSpinner ? false : true,
            isLoadind: dialogStates.showSpinner
          }, null, 8, ["header", "msgText", "cancelButton", "showButtons", "isLoadind"]))
        : _createCommentVNode("", true)
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_53, [
      _createElementVNode("button", {
        class: "settings-action-btn button-click",
        id: "cancel-btn",
        onClick: _cache[18] || (_cache[18] = ($event: any) => (preSaveTmpDict('cancel')))
      }, " Отменить "),
      _createElementVNode("button", {
        class: "settings-action-btn button-click",
        id: "save-btn",
        onClick: _cache[19] || (_cache[19] = ($event: any) => (preSaveTmpDict('save')))
      }, " Сохранить ")
    ], 512), [
      [_vShow, state.showOperationPanel]
    ])
  ], 64))
}
}

})