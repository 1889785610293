<template>
  <div class="auth-wrapper">
    <div class="bg-circles">
      <img :src="circle1" alt="">
      <img :src="circle2" alt="">
      <img :src="circle3" alt="">
    </div>
    <div class="auth-content">
      <u-auth @open-dialog="openDialog"
        @close-dialog="dialogState.showDialog = false"
        @to-registration="state.tab = 'registration'"
        @to-pass-recovery="state.tab = 'passrecovery'"
        v-show="state.tab === 'auth'"
      />
      <u-registration @open-dialog="openDialog"
        @to-check-number="toCheckSMS"
        @login="state.tab = 'auth'"
        @close-dialog="dialogState.showDialog = false"
        v-show="state.tab === 'registration'"
      />
      <u-check-sms @open-dialog="openDialog"
        @close-dialog="dialogState.showDialog = false"
        @to-registration="state.tab = 'registration'"
        v-if="state.tab === 'sms'"
        :registrationInfo="state.registrationParams"
      />
      <u-pass-recovery @open-dialog="openDialog"
        @to-auth="state.tab = 'auth'"
        @to-check-number="toCheckSMS"
        @close-dialog="dialogState.showDialog = false"
        v-if="state.tab === 'passrecovery'"
      />
    </div>
  </div>
  <u-dialog
    v-if="dialogState.showDialog"
    :header="dialogState.dialogHeader"
    :msgText="dialogState.dialogMessage"
    :showButtons="dialogState.dialogShowButtons"
    :isLoadind="dialogState.dialogLoading"
    @click-res="dialogState.showDialog = false"
  />
</template>

<script setup lang="ts">
import { reactive } from 'vue'
import circle1 from '@/assets/images/circle-1.svg'
import circle2 from '@/assets/images/circle-2.svg'
import circle3 from '@/assets/images/circle-3.svg'
import { Dialog } from '@/models'

export interface RegistrationParams {
  orgName: string,
  login: string,
  password: string,
  code: string
}

const dialogState = reactive({
  showDialog: false,
  dialogHeader: '',
  dialogMessage: '',
  dialogShowButtons: false,
  dialogLoading: false
})

const state = reactive({
  tab: 'auth',
  registrationParams: {} as RegistrationParams
})

function openDialog (dialogParams: Dialog) {
  const showButtons = dialogParams.showButtons ? dialogParams.showButtons : true
  const dialogMessage = dialogParams.message ? dialogParams.message : ''

  dialogState.dialogHeader = dialogParams.headerText
  dialogState.dialogMessage = dialogMessage
  dialogState.dialogShowButtons = showButtons
  dialogState.dialogLoading = dialogParams.loading
  dialogState.showDialog = true
}

function toCheckSMS (arg: RegistrationParams) {
  state.registrationParams = arg
  state.tab = 'sms'
}

</script>

<style>
.auth-wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  justify-items: space-between;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

body {
  max-width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, #2D4B6B 0%, #042447 100%);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
}

.bg-circles {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100vw;
  height: 100vh;
}

.bg-circles img {
  position: absolute;
}

.bg-circles :nth-child(1) {
  top: 0;
  left: 0;
  width: 25vw;
}

.bg-circles :nth-child(2) {
  top: 0;
  right: 0;
  margin: 8vw 20vw 0 0;
}

.bg-circles :nth-child(3) {
  top: 65vh;
  right: 0;
  width: 20vw;
}

.auth-content {
  z-index: 2;
}

.inputs input {
  width: 100%;
  font: 0.94vw 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
  color: var(--primary-font-color);
  border: none;
  background-color: transparent;
}

.inputs input::placeholder {
  font: 0.94vw 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
  color: rgba(255, 255, 255, 0.50);
}

.inputs input:focus {
  outline: none;
  color: var(--primary-font-color);
}

.inputs input:-webkit-autofill,
.inputs input:-webkit-autofill:hover,
.inputs input:-webkit-autofill:focus,
.inputs input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0px 1000px var(--main-color) inset !important;
    box-shadow: 0 0 0px 1000px var(--main-color) inset !important;
    -webkit-text-fill-color: white !important;
    transition: background-color 5000s ease-in-out 0s;
}

.pass-input {
  width: 20vw;
  padding: 1.20vw 1.82vw;
  margin-bottom: 1.56vw;
  display: flex;
  flex-flow: row nowrap;
  border-radius: 20vw;
  background-color: var(--main-color);
  border-width: 1px;
  border-color: 1px solid linear-gradient(90deg, rgba(25,211,229,0) 0%, rgba(25,211,229,1) 100%);
  transition: 0.3s ease-in-out;
}
.auth-btn {
  width: 23.8vw;
  padding: 1.25vw 1.82vw;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  border: none;
  font: 1.04vw 'RedHatText-SemiBold', 'OpenSans', sans-serif;
  color: var(--primary-font-color);
  border-radius: 20vw;
  background-color: var(--main-color-dark);
  margin-bottom: 1.56vw;
}
.logo {
  width: 15.63vw;
  margin-bottom: 5vh;
}

.auth-form {
  display: flex;
  flex-direction: column;
  max-width: 40%;
  justify-content: center;
  align-items: center;
  margin-top: 25vh;
}

.auth-form-header {
  font: 2.08vw 'RedHatText-SemiBold', 'OpenSans', sans-serif;
  color: #fff;
  margin-bottom: 1.56vw;
}

.auth-text-act, .auth-text-act-btn {
  font: 0.83vw 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
}

.auth-text-act {
  color: var(--primary-font-color);
}

.auth-text-act-btn {
  color: var(--primary-font-color-blue);
}

.auth-texts {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.52vw;
}

@media (max-width: 950px) {
  .bg-circles :nth-child(1) {
    top: -8vw;
    left: -20vw;
    width: 50vw;
  }

  .bg-circles :nth-child(2) {
    top: 35vw;
    right: 4vw;
    width: 17.81vw;
    margin: 0;
  }

  .bg-circles :nth-child(3) {
    top: 88vh;
    right: 0;
    width: 27vw;
  }

  .logo {
    width: 38.17vw;
    margin-bottom: 2vh;
  }

  .logo img {
    width: 100%;
  }

  .auth-form {
    max-width: 90%;
    margin-top: 30vh;
  }

  .auth-form-header {
    font-size: 5.09vw;
    margin-bottom: 5.09vw;
  }

  .inputs input {
    font-size: 3.82vw;
  }

  .inputs input::placeholder {
    font-size: 3.82vw;
  }

  .pass-input {
    width: 75vw;
    padding: 4.5vw 6.62vw;
    margin-bottom: 3.82vw;
  }

  .auth-btn {
    font-size: 4.33vw;
    width: 89vw;
    padding: 4.5vw 6.62vw;
    margin-bottom: 5.09vw;
  }

  .auth-text-act, .auth-text-act-btn {
    font-size: 3.05vw;
  }
}
</style>
