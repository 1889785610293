<template>
    <div class="curr-plan-wrapper">
        <div class="curr-plan-name-row-wrapper">
            <div class="curr-plan-icon"
                :style="{background: `linear-gradient(90deg, rgba(${props.plan.gradientLow}, 1) 0%, rgba(${props.plan.gradientTop}, 1) 100%)`}"
            >
                <img src="@/assets/icon/flame.svg">
            </div>
            <div class="curr-plan-name-info">
                <div class="curr-plan-name-first-row">
                    <span class="curr-plan-name">
                        {{ props.plan.name }}
                    </span>
                </div>
                <div class="curr-plan-info" v-if="props.isCurrent">
                    <span class="curr-plan-text">
                        Осталось
                    </span>
                    <span class="curr-plan-text-rest">
                        {{ props.paidDays }} дней
                    </span>
                </div>
                <div class="curr-plan-info" v-else>
                    <span class="curr-plan-text">
                        {{ props.plan.price }}
                    </span>
                    <span class="curr-plan-text-rest">
                        сом / мес
                    </span>
                </div>
            </div>
        </div>
        <div>
            <span v-html="formattedDescription(props.plan.description)"
                class="curr-plan-description"
            ></span>
        </div>
        <div class="curr-plan-btn button-click"
            v-if="!props.isCurrent && !props.hideActBtn"
            @click="emits('click-btn')"
        >
            <span>Выбрать</span>
        </div>
    </div>
</template>

<script setup lang="ts">
import { PropType } from 'vue'
import { OrgPlan } from '../../models'

const props = defineProps({
  plan: {
    type: Object as PropType<OrgPlan>,
    required: true
  },
  isCurrent: {
    type: Boolean,
    default: false
  },
  paidDays: {
    type: String,
    default: '0'
  },
  hideActBtn: {
    type: Boolean,
    default: false
  }
})

const emits = defineEmits(['click-btn'])

function formattedDescription (text: string) {
  return text.replace(/\n/g, '<br>')
}
</script>

<style>
.curr-plan-name-row-wrapper {
    display: flex;
    gap: 0.63vw;
}

.curr-plan-name-first-row {
    display: flex;
}

.curr-plan-wrapper {
    background-color: #2D4B6B;
    padding: 2.32vh;
    width: 15.57vw;
    border-radius: 2.32vh;
    display: flex;
    flex-direction: column;
    gap: 2.78vh;
}

.curr-plan-icon {
    height: 3.704vh;
    width: 3.704vh;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1.39vh;
}

.curr-plan-icon img {
    height: 2.32vh;
}

.curr-plan-name-info {
    display: flex;
    flex-direction: column;
}

.curr-plan-name {
    font: 600 1.86vh 'RedHatText-Bold', 'OpenSans', sans-serif;
    color: var(--primary-font-color);
}

.curr-plan-info {
    display: flex;
    font: 400 1.112vh 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
    gap: 0.4vh;
    align-items: flex-end;
}

.curr-plan-text {
    color: var(--primary-font-color-a70);
}

.curr-plan-text-rest {
    color: var(--primary-font-color);
}

.curr-plan-description {
    font: 600 0.93vh 'RedHatText-SemiBold', 'OpenSans', sans-serif;
    color: var(--primary-font-color);
}

.curr-plan-btn {
    font: 700 1.482vh 'RedHatText-Bold', 'OpenSans', sans-serif;
    color: var(--primary-font-color);
    background: linear-gradient(90deg, #ECAD0B 0%, #EC690B 100%);
    display: flex;
    height: 4.723vh;
    align-items: center;
    justify-content: center;
    border-radius: 3.7vh;
}
</style>
