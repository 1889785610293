import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "org-item-wrapper" }
const _hoisted_2 = { class: "org-item-header" }
const _hoisted_3 = { class: "org-item-icon-wrapper" }
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "org-info-wrapper" }
const _hoisted_7 = { class: "org-info-name" }
const _hoisted_8 = { class: "org-info-count" }
const _hoisted_9 = ["src"]
const _hoisted_10 = {
  key: 0,
  class: "org-popup"
}
const _hoisted_11 = ["src"]
const _hoisted_12 = ["src"]

import { reactive } from 'vue'
import depIcon from '@/assets/icon/departments.svg'
import posIcon from '@/assets/icon/positions.svg'
import editIcon from '@/assets/icon/org_item/edit.svg'
import deleteIcon from '@/assets/icon/org_item/delete.svg'
import menu from '@/assets/icon/kebab.svg'
import ellipse from '@/assets/images/ellipse_5.svg'

export interface UniversalRedArgs {
    type: string
    id: string
    name: string
}


export default /*@__PURE__*/_defineComponent({
  __name: 'UOrganizationItem',
  props: {
  type: {
    type: String,
    required: true
  },
  name: {
    type: String,
    required: true
  },
  id: {
    type: String,
    required: true
  },
  qty: {
    type: Number,
    required: true
  }
},
  emits: ['action'],
  setup(__props, { emit: __emit }) {

const props = __props

const emits = __emit

const state = reactive({
  popupIsOpen: false
})

function getQtyText () {
  switch (props.qty) {
    case 1:
      return 'сотрудник'
    case 2 || 3 || 4:
      return 'сотрудника'
    default:
      return 'сотрудников'
  }
}

function action (actType: string) {
  state.popupIsOpen = false
  emits('action', { type: actType, id: props.id, name: props.name })
}

// function clickOutside () {
//   if (state.popupIsOpen) {
//     state.popupIsOpen = false
//   }
// }

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          src: props.type === 'departments' ? _unref(depIcon) : _unref(posIcon)
        }, null, 8, _hoisted_4)
      ]),
      _createElementVNode("img", {
        src: _unref(menu),
        class: "button-click",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (state.popupIsOpen = !state.popupIsOpen))
      }, null, 8, _hoisted_5)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("span", _hoisted_7, _toDisplayString(props.name), 1),
      _createElementVNode("span", _hoisted_8, _toDisplayString(props.qty) + " " + _toDisplayString(getQtyText()), 1)
    ]),
    _createElementVNode("img", {
      class: "org-item-ellipse",
      src: _unref(ellipse)
    }, null, 8, _hoisted_9),
    (state.popupIsOpen)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createElementVNode("div", {
            class: "org-popup-row button-click",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (action('red')))
          }, [
            _createElementVNode("img", { src: _unref(editIcon) }, null, 8, _hoisted_11),
            _cache[3] || (_cache[3] = _createElementVNode("span", { class: "org-popup-text" }, "Редактировать", -1))
          ]),
          _createElementVNode("div", {
            class: "org-popup-row button-click",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (action('del')))
          }, [
            _createElementVNode("img", { src: _unref(deleteIcon) }, null, 8, _hoisted_12),
            _cache[4] || (_cache[4] = _createElementVNode("span", { class: "org-popup-text" }, "Удалить", -1))
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})