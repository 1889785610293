<template>
    <div class="o-main-wrapper">
        <div class="o-pay-wrapper">
            <div class="pay-info-panel-wrapper-item">
            <span class="pay-info-panel-item-header">
                К оплате
            </span>
            <span class="pay-info-panel-item-text">
                {{ `${props.summ} сом` }}
            </span>
            </div>
            <div class="o-instruction-text">
                <span>Инструкция по оплате</span>
            </div>
            <div class="o-instruction-block">
                <u-instruction-step
                    v-for="(step, index) in steps" :key="index"
                    :step="String(index + 1)"
                    :text="step"
                    :width="27.7"
                />
            </div>
            <u-phone-number class="o-input-phone"
                @input-phone="inputPhone"
            />
        </div>
        <div v-if="state.phone.length === 9"
            class="visa-footer-btn-wrapper"
        >
            <div class="visa-footer-btn button-click"
                @click="getPayOrder()"
            >
                <span>Создать счет</span>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { reactive, onBeforeMount } from 'vue'
import { PayOrder } from '@/classes'
import { Organization } from '@/models'
import { usePaymentStore } from '@/stores/pays'

const steps = [
  'Укажите номер телефона, который используется в приложении “Мой О!” и создайте счет',
  'Откройте в телефоне приложение “Мой О!”',
  'Откройте вкладку О! Деньги',
  'Выберите пункт “Неоплаченные счета” и оплатите. (Если пункта нет, обновите страницу)'
]

const payStore = usePaymentStore()

const state = reactive({
  organization: {} as Organization,
  phone: ''
})

const props = defineProps({
  summ: {
    type: String,
    required: true
  }
})

const emits = defineEmits(['show-loader', 'close-loader', 'show-dialog'])

onBeforeMount(() => {
  state.organization = JSON.parse(sessionStorage.getItem('organization') || '{}')
})

function inputPhone (login: string) {
  state.phone = login
}

async function getPayOrder () {
  emits('show-loader')
  const payBody = new PayOrder(state.organization.orgId, Number(props.summ), `996${state.phone}`)
  const result = await payStore.getOTPQpay('O', payBody)
  if (result.status === 'OK') {
    emits('close-loader')
    showDialog(false, 'Вам было выслано платежное поручение.')
  } else {
    showDialog(true, result.info)
  }
}

function showDialog (isError: boolean, body: string) {
  emits('show-dialog', { error: isError, body })
}
</script>

<style>
.o-pay-wrapper {
    display: flex;
    flex-direction: column;
}

.o-instruction-block {
    display: flex;
    column-gap: 2.68vw;
    flex-wrap: wrap;
    row-gap: 4.63vh;
}

.o-instruction-text {
    font: 500 1.852vh 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
    color: var(--primary-font-color);
    display: flex;
    justify-content: center;
    margin-bottom: 2.78vh;
    margin-top: 4.63vh;
}

.o-input-phone {
    margin-top: 4.63vh;
}

.o-main-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}
</style>
