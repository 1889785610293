<template>
    <div class="org-item-wrapper">
        <div class="org-item-header">
            <div class="org-item-icon-wrapper">
                <img :src="props.type === 'departments' ? depIcon : posIcon">
            </div>
            <img :src="menu" class="button-click"
                @click="state.popupIsOpen = !state.popupIsOpen"
            >
        </div>
        <div class="org-info-wrapper">
            <span class="org-info-name">{{ props.name }}</span>
            <span class="org-info-count">{{ props.qty }} {{ getQtyText() }}</span>
        </div>
        <img class="org-item-ellipse" :src="ellipse">
        <div class="org-popup" v-if="state.popupIsOpen"
        >
            <div class="org-popup-row button-click"
                @click="action('red')"
            >
                <img :src="editIcon">
                <span class="org-popup-text">Редактировать</span>
            </div>
            <div class="org-popup-row button-click"
                @click="action('del')"
            >
                <img :src="deleteIcon">
                <span class="org-popup-text">Удалить</span>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { reactive } from 'vue'
import depIcon from '@/assets/icon/departments.svg'
import posIcon from '@/assets/icon/positions.svg'
import editIcon from '@/assets/icon/org_item/edit.svg'
import deleteIcon from '@/assets/icon/org_item/delete.svg'
import menu from '@/assets/icon/kebab.svg'
import ellipse from '@/assets/images/ellipse_5.svg'

const props = defineProps({
  type: {
    type: String,
    required: true
  },
  name: {
    type: String,
    required: true
  },
  id: {
    type: String,
    required: true
  },
  qty: {
    type: Number,
    required: true
  }
})

const emits = defineEmits(['action'])

const state = reactive({
  popupIsOpen: false
})

function getQtyText () {
  switch (props.qty) {
    case 1:
      return 'сотрудник'
    case 2 || 3 || 4:
      return 'сотрудника'
    default:
      return 'сотрудников'
  }
}

export interface UniversalRedArgs {
    type: string
    id: string
    name: string
}

function action (actType: string) {
  state.popupIsOpen = false
  emits('action', { type: actType, id: props.id, name: props.name })
}

// function clickOutside () {
//   if (state.popupIsOpen) {
//     state.popupIsOpen = false
//   }
// }
</script>

<style scoped>
.org-item-wrapper,
.org-item-header {
    display: flex;
    justify-content: space-between;
}
.org-item-wrapper {
    flex-direction: column;
    width: calc(18.105vw - 2.315vh * 2);
    height: 9.074vh;
    background-color: #2D4B6B;
    border-radius: 2.5vh;
    padding: 2.315vh;
    position: relative;
}

.org-info-wrapper {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.925vh;
    z-index: 2;
}

.org-info-name {
    font: 600 1.851vh 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
    color: var(--primary-font-color);
}

.org-info-count {
    font: 400 1.112vh 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
    color: var(--primary-font-color-a70);
}

.org-popup-text {
    font: 400 1.386vh 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
    color: var(--primary-font-color);
}

.org-item-icon-wrapper {
    background-color: var(--primary-font-color-a5);
    padding: 0.926vh 0.426vw;
    border-radius: 0.926vh;
    height: 1.3vh;
    width: 1.3vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.org-item-ellipse {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;
}

.org-popup-row {
    display: flex;
    gap: 0.64vw;
    height: 4.63vh;
    width: 9.59vw;
    align-items: center;
    padding-left: 1.851vh;
}

.org-popup {
    display: flex;
    flex-direction: column;
    background-color: #2D4B6B;
    border-radius: 2.5vh;
    padding: 1.389vh 0.534vw;
    width: fit-content;
    z-index: 3;
    position: absolute;
    right: 2.315vh;
    top: 5.2vh;
    box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.15);
}
</style>
