<template>
    <transition name="pin-input-fade">
        <div class="pin-input-overlay" v-if="true">
            <div class="pin-input-pad">
                <img :src="cancel"
                    class="pin-input-close button-click"
                    @click="emits('close-pin-input')"
                >
                <div class="pin-working-pad">
                    <span class="pin-input-header">
                        Подтверждение
                    </span>
                    <span :class="state.isError ? 'pin-input-error' : 'pin-input-info'">
                        {{ state.isError ? state.errorText : state.infoText }}
                    </span>
                    <input type="password" placeholder="Введите пароль"
                        v-model="state.pin"
                        :class="state.pin.length > 0 ? 'pin-is-not-empty' : 'pin-input-password'"
                    >
                    <button class="pin-input-button button-click"
                        @click="checkPin">
                        Подтвердить
                    </button>
                </div>
            </div>
        </div>
    </transition>
</template>

<script setup lang="ts">
import { reactive, onBeforeMount } from 'vue'
import cancel from '@/assets/icon/cancel-dialog.svg'

const emits = defineEmits(['close-pin-input', 'to-settings'])

const state = reactive({
  pin: '',
  isError: false,
  infoText: 'Доступ к настройкам ограничен. Для перехода  на страницу введите пароль.',
  errorText: 'Пароль не верный!'
})

const props = defineProps({
  lockPassword: {
    type: String,
    default: '',
    required: false
  }
})

onBeforeMount(() => {
  if (props.lockPassword.length === 0) {
    emits('to-settings')
  }
})

function checkPin () {
  if (state.pin === props.lockPassword) {
    emits('to-settings')
  } else {
    state.isError = true
  }
}
</script>

<style>
.pin-input-overlay {
  position: fixed;
  z-index: 3000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}

.pin-input-pad {
  width: 20.5rem;
  background-image: linear-gradient(245.9deg, #2D4B6B -40.66%, #042447 131.18%);
  padding: 1rem 1rem 3rem 3rem;
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
}

.pin-input-header {
  font: 1.37rem 'RedHatText-Bold', 'OpenSans', sans-serif;
  font-weight: 700;
  color: var(--primary-font-color);
}

.pin-input-info, .pin-input-error {
  font: 0.76rem 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
  color: var(--primary-font-color);
  text-align: center;
  margin-bottom: 1rem;
}

.pin-input-error {
  color: var(--primary-font-color-error);
}

.pin-input-password, .pin-is-not-empty {
  height: 2.8rem;
  align-self: center;
  width: 100%;
  border-radius: 2rem;
  background-color: #2D4B6B;
  box-shadow: none;
  outline: none;
  border: none;
  flex-grow: 1;
  font: 0.76rem 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
  text-align: center;
  caret-color: var(--primary-font-color)
}

.pin-is-not-empty {
  letter-spacing: 0.2rem;
  color: var(--primary-font-color);
}

.pin-input-button {
  background-color: #042447;
  color: var(--primary-font-color);
  font: 0.9rem 'RedHatText-Bold', 'OpenSans', sans-serif;
  font-weight: 700;
  width: 100%;
  padding: 1rem 1.5rem;
  border-radius: 2rem;
  border: none;
}

.pin-input-close {
  align-self: end;
}

.pin-working-pad {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-right: 2rem;
}
@media screen and (max-width: 850px) {
  .pin-input-pad {
    width: 70vw;
    padding: 3vw 3vw 10vw 12vw;
    border-radius: 5vw;
  }
  .pin-working-pad {
    margin-right: 8vw;
  }
  .pin-input-header {
    font-size: 5vw;
  }
  .pin-input-info {
    font-size: 3vw;
    margin-bottom: 2vw;
  }
}
</style>
