import terminal from '@/assets/payments/terminal.svg'
// import balance from '@/assets/payments/balance.svg'
import elsom from '@/assets/payments/elsom.svg'
import o from '@/assets/payments/o.svg'
import visa from '@/assets/payments/visa.svg'
// import elcard from '@/assets/payments/elcard.svg'
// import sber from '@/assets/payments/sber.svg'
// import tinkoff from '@/assets/payments/tinkoff.svg'

export interface Payment {
  type: string
  label: string
  icon: string
  code: string
}

export const payments: Payment[] = [
  { type: 'Наличными', label: 'Платежный терминал Pay24', icon: terminal, code: 'AsisNur' },
  { type: 'Наличными', label: 'Платежный терминал QuickPay', icon: terminal, code: 'Qpay' },
  { type: 'Платежная карта', label: 'Visa/MasterCard', icon: visa, code: 'visa' },
  { type: 'Приложение', label: 'Элсом', icon: elsom, code: 'elsom' },
  { type: 'Приложение', label: 'О! Деньги', icon: o, code: 'O' }
  // { type: 'Приложение', label: 'Balance.kg', icon: balance, code: 'balance' },
  // { type: 'Приложение', label: 'Элкарт Мобайл', icon: elcard, code: 'elcard' },
  // { type: 'Платежная система', label: 'Тинькофф', icon: tinkoff, code: 'tinkoff' },
  // { type: 'Платежная система', label: 'Сбер', icon: sber, code: 'sber' }
]
