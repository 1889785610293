<template>
  <div class="pay-main-wrapper">
    <div class="pay-wrapper">
      <div class="pay-info-panel-wrapper">
        <div class="pay-info-panel-wrapper-item">
          <span class="pay-info-panel-item-header">
            К оплате
          </span>
          <span class="pay-info-panel-item-text">
            {{ `${props.summ} сом` }}
          </span>
        </div>
        <div class="pay-info-panel-wrapper-item">
          <span class="pay-info-panel-item-header">
            Разовый код оплаты
          </span>
          <div class="pay-info-panel-item-row button-click"
            @click="copyCode()"
          >
            <span class="pay-info-panel-item-text">
              {{ state.code }}
            </span>
            <img :src="copy">
          </div>
        </div>
      </div>
      <span class="pay-instruction-label">
        Инструкция по оплате
      </span>
      <div class="pay-instruction-wrapper">
        <img class="pay-instruction-img"
        :src="props.type === 'qpay' ?
          instructionImagesQuickPay[state.index] :
          props.type === 'elsom' ?
          instructionImagesElsom[state.index] :
          instructionImagesPay[state.index]"
        >
        <div class="pay-instruction-stepper">
          <div class="pay-instruction-btn button-click"
            @click="changeStep('prev')"
          >
            <img class="pay-instruction-btn-icon" :src="next">
          </div>
          <span class="pay-instruction-label">
            {{ props.type === 'elsom' ?
              elsomInstructionsText[state.index] :
              instructionsText[state.index]
            }}
          </span>
          <div class="pay-instruction-btn button-click"
            @click="changeStep('next')"
          >
            <img class="pay-instruction-btn-icon"
              :style="{transform: 'rotate(180deg)'}"
              :src="next"
            >
          </div>
        </div>
      </div>
      <div class="instr-dots-wrapper">
        <div v-for="index in instructionsText.length" :key="index"
          class="instr-dot"
          :style="{
            backgroundColor: state.index + 1 === index ? '#16FAFC' : '#38526B'
          }"
        >
        </div>
      </div>
    </div>
  </div>
  <u-popup-message v-if="state.popupMessage"
    @closed="state.popupMessage = false"
    :text="'Разовый код скопирован в буфер обмена!'"
  />
</template>

<script setup lang="ts">
import { reactive, onBeforeMount } from 'vue'
import copy from '@/assets/icon/copy.svg'
import next from '@/assets/icon/back.svg'
// @ts-expect-error - here is nothing to see
import terminal1 from '@/assets/images/terminal/pay/terminal1.png'
// @ts-expect-error - here is nothing to see
import terminal2 from '@/assets/images/terminal/pay/terminal2.png'
// @ts-expect-error - here is nothing to see
import terminal3 from '@/assets/images/terminal/pay/terminal3.png'
// @ts-expect-error - here is nothing to see
import qpay1 from '@/assets/images/terminal/quickpay/qpay1.webp'
// @ts-expect-error - here is nothing to see
import qpay2 from '@/assets/images/terminal/quickpay/qpay2.webp'
// @ts-expect-error - here is nothing to see
import qpay3 from '@/assets/images/terminal/quickpay/qpay3.webp'
// @ts-expect-error - here is nothing to see
import elsom1 from '@/assets/images/elsom/elsom1.webp'
// @ts-expect-error - here is nothing to see
import elsom2 from '@/assets/images/elsom/elsom2.webp'
// @ts-expect-error - here is nothing to see
import elsom3 from '@/assets/images/elsom/elsom3.webp'
import { usePaymentStore } from '@/stores/pays'
import { PayOrder } from '@/classes'
import { Organization } from '@/models'

const payStore = usePaymentStore()

const props = defineProps({
  summ: {
    type: String,
    required: true
  },
  type: {
    type: String,
    required: true
  }
})

const state = reactive({
  organization: {} as Organization,
  code: '',
  index: 0,
  popupMessage: false
})

const instructionsText = [
  'Перейдите в раздел “Интернет магазины”',
  'Выберите BaBabay',
  'Введите разовый код оплаты и совершите платеж'
]

const elsomInstructionsText = [
  'Зайдите в приложение Элсом и в списке операций выберите “Покупка”',
  'Выберите “ОТП-код”',
  'Введите разовый код оплаты'
]

const instructionImagesPay = [terminal1, terminal2, terminal3]
const instructionImagesQuickPay = [qpay1, qpay2, qpay3]
const instructionImagesElsom = [elsom1, elsom2, elsom3]

const emits = defineEmits(['show-loader', 'close-loader', 'show-dialog'])

onBeforeMount(async () => {
  emits('show-loader')
  state.organization = JSON.parse(sessionStorage.getItem('organization') || '{}')
  const payBody = new PayOrder(state.organization.orgId, Number(props.summ))
  const result = await payStore.getOTPQpay(props.type, payBody)
  if (result.status === 'OK') {
    state.code = result.otp
    emits('close-loader')
  } else {
    showDialog(result.info)
  }
})

function changeStep (action: string) {
  if (action === 'prev') {
    if (state.index !== 0) {
      state.index -= 1
    }
  } else {
    if (state.index + 1 !== instructionsText.length) {
      state.index += 1
    }
  }
}

function showDialog (body: string) {
  emits('show-dialog', { error: true, body })
}

function copyCode () {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(state.code).then(() => {
      state.popupMessage = true
    }).catch(err => {
      alert('Ошибка при копировании текста: ' + err)
    })
  } else {
    alert('Буфер обмена не доступен')
  }
}
</script>

<style>
.instr-dot {
  border-radius: 50%;
  height: 1.343vh;
  width: 1.343vh;
}

.instr-dots-wrapper {
  display: flex;
  gap: 1.343vh;
  margin-top: 2.315vh;
}

.pay-info-panel-wrapper {
  display: flex;
  gap: 2.0834vw;
  margin-bottom: 4.63vh;
}

.pay-info-panel-wrapper-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pay-instruction-img {
  height: 37.0371vh;
  border-radius: 2.315vh;
  margin-top: 2.778vh;
  margin-bottom: 2.315vh;
  width: fit-content;
}

.pay-info-panel-item-header {
  font: 500 1.389vh 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
  color: var(--primary-font-color);
}

.pay-info-panel-item-text {
  font: 700 2.315vh 'RedHatText-Bold', 'OpenSans', sans-serif;
  color: var(--primary-font-color);
}

.pay-info-panel-item-row {
  display: flex;
  gap: 0.261vw;
}

.pay-instruction-label {
  font: 500 1.852vh 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
  color: var(--primary-font-color);
  text-align: center;
}

.pay-wrapper {
  display: flex;
  flex-direction: column;
  width: 39.0625vw;
  align-items: center;
}

.pay-main-wrapper {
  display: flex;
  justify-content: center;
}

.pay-instruction-stepper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: -webkit-fill-available;
}

.pay-instruction-btn {
  border-radius: 50%;
  background-color: #2D4B6B;
  height: 3.704vh;
  width: 3.704vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pay-instruction-btn-icon {
  height: 1.482vh;
}

.pay-instruction-wrapper {
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
  align-items: center;
}
</style>
