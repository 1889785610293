<template>
    <div>
        <div class="payments-pay-wrapper">
            <div class="payments-pay-info-panel">
                <span class="plan-pay-info-panel-info">Ваш баланс</span>
                <span class="plan-pay-info-panel-sum">{{ state.organization.balance }} сом</span>
            </div>
            <div class="payments-input-summ-wrapper">
                <span class="plan-pay-info-panel-info">Введите сумму</span>
                <input class="payments-input" placeholder="0"
                    type="number" v-model="state.summ"
                >
                <div class="payments-input-summs">
                    <div class="payments-input-summ-btn button-click"
                        v-for="summ in summs" :key="summ"
                        @click="state.summ = summ"
                    >
                        <span>{{ summ }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="payments-items-wrapper">
            <div v-for="(item, index) in payments" :key="index"
                class="payments-item button-click"
                :class="{'payments-item-choosen': state.choosen === index}"
                @click="choosePayType(index)"
            >
                <img :src="state.choosen === index ? radioAct : radio">
                <img class="payments-item-icon" :src="item.icon">
                <div class="payments-info-block">
                    <span class="payments-info-block-type">
                        {{ item.type }}
                    </span>
                    <span class="payments-info-block-label">
                        {{ item.label }}
                    </span>
                </div>
            </div>
        </div>
        <div class="payments-footer">
            <div class="payments-footer-btn button-click"
                v-if="state.choosen > -1"
                @click="toPay()"
            >
                <span>Продолжить</span>
            </div>
        </div>
    </div>
    <u-dialog v-if="state.showDialog"
        :header="'Ошибка'" :msgText="'Введите сумму платежа'"
        @click-res="state.showDialog = false"
    />
</template>

<script setup lang="ts">
import { reactive, onBeforeMount } from 'vue'
import { Organization } from '@/models'
import { payments } from '../payments'
import radio from '@/assets/icon/radio.svg'
import radioAct from '@/assets/icon/radio-act.svg'
import { eventBus } from '@/eventBus'

const state = reactive({
  organization: {} as Organization,
  summ: '',
  choosen: -1,
  showDialog: false
})

const summs = ['1000', '2000', '3000', '5000']

onBeforeMount(() => {
  state.organization = JSON.parse(sessionStorage.getItem('organization') || '{}')
})

function choosePayType (index: number) {
  state.choosen = index
}

function toPay () {
  if (state.summ === '' || state.summ === '0') {
    state.showDialog = true
    return
  }
  eventBus.emit('to-pay-steps', {
    routText: payments[state.choosen].label,
    summ: state.summ,
    type: payments[state.choosen].code
  })
}
</script>

<style>
.payments-item {
    display: flex;
    border: solid 1px;
    height: 6.852vh;
    width: 23.854vw;
    align-items: center;
    padding-left: 1.3025vw;
    border-radius: 9.3vh;
    border-color: var(--primary-font-color-a70);
}

.payments-item-choosen {
    border-color: var(--primary-font-color-blue);
    background-color: var(--primary-font-color-a10);
}

.payments-info-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.payments-info-block-type {
    font: 400 1.389vh 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
    color: var(--primary-font-color-a70);
}

.payments-info-block-label {
    font: 400 1.667vh 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
    color: var(--primary-font-color);
}

.payments-item-icon {
    height: 4.167vh;
    margin-left: 1.852vh;
    margin-right: 1.389vh;
}

.payments-items-wrapper {
    display: flex;
    flex-wrap: wrap;
    row-gap: 1.852vh;
    height: 48vh;
    overflow-y: scroll;
    align-content: flex-start;
    column-gap: 1.6vw;
}

.payments-pay-info-panel {
    display: flex;
    flex-direction: column;
    gap: 0.463vh;
}

.payments-input-summ-wrapper {
    display: flex;
    flex-direction: column;
}

.payments-input-summs {
    display: flex;
    justify-content: space-between;
}

.payments-input-summ-btn {
    background-color: #042447;
    font: 700 1.482vh 'RedHatText-Bold', 'OpenSans', sans-serif;
    color: var(--primary-font-color);
    padding: 0.88vh 1.303vw;
    border-radius: 3.8vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.payments-pay-wrapper {
    display: flex;
    flex-direction: column;
    width: 20.84vw;
    margin-top: 1.49vh;
    margin-bottom: 4.6vh;
    gap: 2.778vh;
}

.payments-input {
    background-color: #2D4B6B;
    border: none;
    border-radius: 13.89vh;
    padding-left: 1.0417vw;
    padding-right: 3vw;
    font: 400 1.389vh 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
    color: var(--primary-font-color);
    height: 4.63vh;
    margin-bottom: 1.852vh;
    margin-top: 1.389vh;
}

.payments-footer-btn {
    background-color: #031C38;
    font: 700 1.482vh 'RedHatText-Bold', 'OpenSans', sans-serif;
    color: var(--primary-font-color);
    padding: 1.343vh 1.823vw;
    border-radius: 3.8vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.payments-footer {
    position: absolute;
    bottom: 1.3vw;
}
</style>
