import { AxiosError } from 'axios'
import { axiosMain } from '@/axios-setup'
import { defineStore } from 'pinia'
import { SMSRequest, SMSResponse, CheckSMS } from '../models'

export const useSMSStore = defineStore('sms', {
  state: () => ({
    SMSResponse: {} as SMSResponse | undefined | null
  }),
  actions: {
    async sendSMSRequest (body: SMSRequest) {
      try {
        const response = await axiosMain.post<SMSResponse>('/send_sms', body)
        this.SMSResponse = response.data
        return response.data
      } catch (error) {
        const axiosError = error as AxiosError
        console.error(axiosError.message)
      }
    },
    async checkSMS (number: string, code: string) {
      try {
        const response = await axiosMain.get<CheckSMS>('/is_verified', {
          params: {
            phone_number: number,
            code
          }
        })
        return response.data
      } catch (error) {
        const axiosError = error as AxiosError
        console.error(axiosError.message)
      }
    }
  },
  getters: {
    getSMSResponse: (state) => state.SMSResponse
  }
})
