<template>
    <div class="u-dropdown-filters-wrapper"
        v-click-outside.stop="clickOutside"
    >
        <div class="u-dropdown-filters-body">
            <span class="u-dropdown-filters-label">{{ props.label }}</span>
            <div class="u-dropdown-filter" @click="state.popupIsOpen = !state.popupIsOpen">
                <span>
                    {{ filterString() }}
                </span>
                <img class="u-dropdown-icon" :src="back">
            </div>
        </div>
        <div v-show="state.popupIsOpen"
            class="u-dropdown-universal-wrapper"
        >
            <div class="u-dropdown-universal-row button-click"
                v-for="item in props.type === 'persons' ? props.personsVars : props.universalVars" :key="item.id"
                @click="addOrRemove(item.id)"
            >
                <img :src="state.choosenList.includes(item.id) ?
                    checkActive : checkNonActive"
                />
                <div class="u-dropdown-universal-row-img"
                    v-if="props.type === 'persons'">
                    <img v-if="!state.images[(item as Employee).photo]?.imageSrc || state.images[(item as Employee).photo]?.imageSrc === ''"
                        :src="noPhoto"
                    >
                    <img v-else
                        :src="`data:image/jpeg;base64, ${state.images[(item as Employee).photo]!.imageSrc}`"
                    >
                </div>
                <span>{{ item.name }}</span>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { reactive, PropType, watch } from 'vue'
import { Universal, Employee } from '@/models'
import back from '@/assets/icon/back.svg'
import checkActive from '@/assets/icon/check-box/active.svg'
import checkNonActive from '@/assets/icon/check-box/not-active.svg'
import noPhoto from '@/assets/images/no_profile.svg'
import { ImagesState } from '@/components/widgets/UChoosePersons.vue'

const props = defineProps({
  label: {
    type: String,
    required: true
  },
  type: {
    type: String,
    required: true
  },
  universalVars: {
    type: Array as PropType<Universal[]>,
    default: () => []
  },
  personsVars: {
    type: Array as PropType<Employee[]>,
    default: () => []
  },
  choosenIds: {
    type: Array as PropType<string[]>,
    required: true
  },
  images: {
    type: Object as PropType<ImagesState>,
    default: () => ({})
  }
})

const state = reactive({
  popupIsOpen: false,
  choosenList: props.choosenIds,
  images: reactive({} as ImagesState)
})

const emits = defineEmits(['filter-act'])

watch(() => props.images, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    state.images = newValue
  }
})

watch(() => state.choosenList, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    emits('filter-act', { type: props.type, arr: newValue })
  }
})

function clickOutside () {
  if (state.popupIsOpen) {
    state.popupIsOpen = false
  }
}

function addOrRemove (id: string) {
  if (state.choosenList.includes(id)) {
    state.choosenList = state.choosenList.filter(item => item !== id)
  } else {
    state.choosenList.push(id)
  }
  emits('filter-act')
}

function filterString () {
  let resultString = 'Все'
  const tmpArr = props.type === 'persons' ? props.personsVars : props.universalVars
  if (state.choosenList.length === tmpArr.length) {
    return resultString
  } else if (state.choosenList.length === 0) {
    return 'Не выбрано'
  } else {
    resultString = ''
    for (const id of state.choosenList) {
      resultString += `${tmpArr.find(item => item.id === id)?.name}, `
    }
    return resultString.trim().slice(0, -1)
  }
}
</script>

<style scoped>
.u-dropdown-filters-wrapper,
.u-dropdown-filters-body {
    display: flex;
    flex-direction: column;
}

.u-dropdown-filters-wrapper {
    position: relative;
}

.u-dropdown-filters-label {
    font: 500 1.39vh 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
    color: var(--primary-font-color);
}

.u-dropdown-filter {
    display: flex;
    width: -webkit-fill-available;
    height: 4.63vh;
    background-color: #2D4B6B;
    border-radius: 13.89vh;
    padding: 0 1.0417vw;
    font: 400 1.389vh 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
    color: var(--primary-font-color-a50);
    align-items: center;
    justify-content: space-between;
}

.u-dropdown-icon {
    width: 0.34vw;
    transform: rotate(270deg)
}

.u-dropdown-filters-body {
    gap: 1.39vh;
}

.u-dropdown-universal-row {
    display: flex;
    gap: 0.521vw;
    padding: 0.741vh 0.782vw;
    align-items: center;
    font: 500 1.389vh 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
    color: var(--primary-font-color);
}

.u-dropdown-universal-row:hover {
    background-color: var(--primary-font-color-a10);
    border-radius: 9.3vh;
}

.u-dropdown-universal-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 8vh;
    z-index: 5;
    margin-top: 0.93vh;
    display: flex;
    flex-direction: column;
    gap: 0.93vh;
    background-color: #2D4B6B;
    border-radius: 2.315vh;
    padding: 1.86vh 0.521vw;
}

.u-dropdown-filter span {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.u-dropdown-universal-row-img {
    width: 3.241vh;
    height: 3.241vh;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.u-dropdown-universal-row-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
</style>
