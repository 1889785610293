import { defineStore } from 'pinia'
import { Cookies } from '../models'

export const useCookesStore = defineStore('cookes', {
  state: () => ({
    token: '',
    cookies: {} as Cookies
  }),
  actions: {
    async getCookie () {
      const name = `${process.env.VUE_APP_COOKIES_NAME}=`
      const decodedCookie = decodeURIComponent(document.cookie)
      const cookieArray = decodedCookie.split(';')
      for (let i = 0; i < cookieArray.length; i++) {
        const cookie = cookieArray[i].trim()
        if (cookie.indexOf(name) === 0) {
          this.cookies = JSON.parse(cookie.substring(name.length, cookie.length))
        }
      }
      if (this.cookies && this.cookies.token) {
        this.token = this.cookies.token
      } else {
        this.token = ''
      }
    },
    async setCookie (cookieValue: Cookies) {
      const serializedValue = JSON.stringify(cookieValue)
      const domain = process.env.VUE_APP_DOMAIN
      const path = process.env.VUE_APP_PATH
      const secureFlag = location.protocol === 'https:' ? 'Secure;' : ''
      const tomorrow = new Date()
      tomorrow.setTime(tomorrow.getTime() + (24 * 60 * 60 * 1000))
      const cookie = `${process.env.VUE_APP_COOKIES_NAME}=${serializedValue}; 'expires='${tomorrow.toUTCString()} domain=${domain}; path=${path}; ${secureFlag}`
      document.cookie = cookie
    },
    async deleteCookie () {
      const date = new Date()
      date.setTime(date.getTime() - 1)
      const expires = 'expires=' + date.toUTCString()
      const domain = process.env.VUE_APP_DOMAIN
      const path = process.env.VUE_APP_PATH
      const cookie = `${process.env.VUE_APP_COOKIES_NAME}=; ${expires}; domain=${domain}; path=${path}`
      document.cookie = cookie
    }
  },
  getters: {
    getToken: (state) => state.token,
    getCookies: (state) => state.cookies
  }
})
