import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "udialog-overlay"
}
const _hoisted_2 = { class: "photo-result-wrapper" }
const _hoisted_3 = { class: "add-person-header" }
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "photo-result" }

import cancel from '@/assets/icon/cancel-dialog.svg'


export default /*@__PURE__*/_defineComponent({
  __name: 'USavePhotoDialog',
  props: {
  photo: {
    type: String,
    required: true
  }
},
  emits: ['dismiss', 'save'],
  setup(__props, { emit: __emit }) {

const emits = __emit

const props = __props

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Transition, { name: "udialog-fade" }, {
    default: _withCtx(() => [
      true
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _cache[3] || (_cache[3] = _createElementVNode("span", { class: "add-person-header-text" }, " Сохранить изображение? ", -1)),
                _createElementVNode("img", {
                  src: _unref(cancel),
                  class: "button-click",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (emits('dismiss')))
                }, null, 8, _hoisted_4)
              ]),
              _createElementVNode("img", {
                class: "photo-result-base64",
                src: props.photo
              }, null, 8, _hoisted_5),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", {
                  class: "photo-result-btn button-click",
                  id: "ok-btn",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (emits('save')))
                }, _cache[4] || (_cache[4] = [
                  _createElementVNode("span", { class: "photo-result-btn-text" }, "Сохранить", -1)
                ])),
                _createElementVNode("div", {
                  class: "photo-result-btn button-click",
                  id: "cancel-btn",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (emits('dismiss')))
                }, _cache[5] || (_cache[5] = [
                  _createElementVNode("span", { class: "photo-result-btn-text" }, "Отмена", -1)
                ]))
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})