import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "choose-plan-overlay"
}
const _hoisted_2 = { class: "choose-plan" }
const _hoisted_3 = { class: "choose-plan-header" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "choose-plan-buttons" }

import { PropType } from 'vue'
import { OrgPlan } from '@/models'
import cancel from '@/assets/icon/cancel-dialog.svg'


export default /*@__PURE__*/_defineComponent({
  __name: 'UChoosePlan',
  props: {
  plan: {
    type: Object as PropType<OrgPlan>,
    required: true
  }
},
  emits: ['click-res'],
  setup(__props, { emit: __emit }) {

const props = __props

const emits = __emit


return (_ctx: any,_cache: any) => {
  const _component_u_plan = _resolveComponent("u-plan")!

  return (_openBlock(), _createBlock(_Transition, { name: "choose-plan-fade" }, {
    default: _withCtx(() => [
      true
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("span", null, " Выбрать тариф \"" + _toDisplayString(props.plan.name) + "\"? ", 1),
                _createElementVNode("img", {
                  src: _unref(cancel),
                  class: "choose-plan-cancel-dialog button-click",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (emits('click-res', 'cancel')))
                }, null, 8, _hoisted_4)
              ]),
              _createVNode(_component_u_plan, {
                plan: props.plan,
                hideActBtn: true
              }, null, 8, ["plan"]),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("button", {
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (emits('click-res', 'ok'))),
                  class: "ures-btn button-click",
                  id: "ok-btn"
                }, "Ок"),
                _createElementVNode("button", {
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (emits('click-res', 'cancel'))),
                  class: "ures-btn button-click",
                  id: "cancel-btn"
                }, "Отмена")
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})