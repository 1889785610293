<template>
    <div class="plans-main-wrapper">
        <div class="plan-pay-info-wrapper">
            <div class="plan-pay-info-panel">
                <span class="plan-pay-info-panel-info">Ваш баланс</span>
                <span class="plan-pay-info-panel-sum">{{ state.organization.balance }} сом</span>
            </div>
            <div class="plan-pay-info-btn button-click" @click="eventBus.emit('to-payments')">
                <span>Пополнить</span>
            </div>
        </div>
        <div class="plans-curr-plan-wrapper">
            <span class="plans-type">
                Ваш тариф
            </span>
            <u-plan :plan="state.organization.plan"
                :isCurrent="true"
                :paidDays="String(state.organization.paidDays)"
            />
        </div>
        <div v-if="state.loading" class="plans-loading">
            <u-spinner/>
        </div>
        <div v-else class="plans-curr-plan-wrapper">
            <span class="plans-type">
                Другие тарифы
            </span>
            <div class=plans-wrapper
                v-if="state.plans.length > 0"
            >
                <u-plan :plan="plan"
                    v-for="plan in state.plans"
                    :key="plan.planId"
                    @click-btn="choosePlan(plan)"
                />
            </div>
            <span class="plans-empty" v-else>
                Для Вас нет актуальных тарифов.
            </span>
        </div>
    </div>
    <u-choose-plan v-if="state.actDialog"
        :plan="state.plans[0]"
        @click-res="choosePlanAction"
    />
    <u-dialog v-if="state.showDialog"
        :header="dialogState.header"
        :msgText="dialogState.msgText"
        :isLoadind="dialogState.isLoadind"
        :showButtons="dialogState.showButtons"
        :cancelButton="dialogState.cancelButton"
        @click-res="dialogRes"
    />
</template>

<script setup lang="ts">
import { onBeforeMount, reactive } from 'vue'
import { Organization, OrgPlan } from '../models'
import { eventBus } from '@/eventBus'
import { usePlansStore } from '@/stores/plans'
import { useOrganazationStore } from '@/stores/organization'

const plansStore = usePlansStore()
const orgStore = useOrganazationStore()

const state = reactive({
  organization: {} as Organization,
  plans: [] as Array<OrgPlan>,
  loading: true,
  actDialog: false,
  choosenPlan: {} as OrgPlan,
  showDialog: false,
  toPayments: false
})

const dialogState = reactive({
  header: '',
  msgText: '',
  isLoadind: false,
  showButtons: true,
  cancelButton: false
})

const emits = defineEmits(['to-payments'])

onBeforeMount(async () => {
  state.organization = JSON.parse(sessionStorage.getItem('organization') || '{}')
  await getAvailiblePlans()
})

async function getAvailiblePlans () {
  await plansStore.getPlansAction()
  const plansTmp = plansStore.getPlans
  if (plansTmp.length > 0) {
    state.plans = plansTmp.filter((item) => item.planId !== state.organization.plan.planId)
  }
  state.loading = false
}

function choosePlan (plan: OrgPlan) {
  state.choosenPlan = plan
  state.actDialog = true
}

async function choosePlanAction (res: string) {
  state.actDialog = false
  if (res === 'ok') {
    state.showDialog = true
    dialogState.isLoadind = true
    dialogState.showButtons = false
    await orgStore.getOrganizationAction(state.organization.orgId)
    const orgCurr = orgStore.getOrganization
    if (orgCurr.balance < state.choosenPlan.price) {
      state.toPayments = true
      dialogState.isLoadind = false
      dialogState.showButtons = true
      dialogState.cancelButton = true
      dialogState.header = 'Ошибка'
      dialogState.msgText = 'Недостаточно средств на Вашем балансе.<br>Хотите пополнить?'
    } else {
      const result = await plansStore.buyPlan(state.organization.orgId, state.choosenPlan.planId)
      dialogState.isLoadind = false
      dialogState.showButtons = true
      state.toPayments = false
      dialogState.cancelButton = result!.status === 'assigned'
      dialogState.header = result!.status === 'assigned' ? 'Успех' : 'Ошибка'
      dialogState.msgText = result!.status !== 'assigned' ? 'Упс! Что-то пошло не так!' : result!.message
      if (result!.status === 'assigned') {
        await orgStore.getOrganizationAction(state.organization.orgId)
        const updOrganization = orgStore.getOrganization
        sessionStorage.setItem('organization', JSON.stringify(updOrganization))
        state.organization = updOrganization
        eventBus.emit('update-plan')
        await getAvailiblePlans()
      }
    }
  }
}

function dialogRes (res: string) {
  state.showDialog = false
  if (res === 'ok' && state.toPayments) {
    emits('to-payments')
  }
}
</script>

<style>
.plans-main-wrapper {
    display: flex;
    flex-direction: column;
    gap: 4.63vh;
    height: -webkit-fill-available;
    overflow-y: scroll;
}

.plan-pay-info-wrapper {
    display: flex;
    gap: 1.5625vw;
    align-items: center;
}

.plan-pay-info-panel {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.plan-pay-info-btn {
    display: flex;
    background: linear-gradient(90deg, #16FAFC 0%, #246DA9 100%);
    font: 600 1.4815vh 'RedHatText-Bold', 'OpenSans', sans-serif;
    color: var(--primary-font-color);
    align-items: center;
    justify-content: center;
    height: 4.7225vh;
    width: 10.417vw;
    border-radius: 3.71vh;
}

.plan-pay-info-panel-info {
    font: 500 1.389vh 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
    color: var(--primary-font-color);
}

.plan-pay-info-panel-sum {
    font: 700 2.315vh 'RedHatText-Bold', 'OpenSans', sans-serif;
    color: var(--primary-font-color);
}

.plans-loading {
    height: -webkit-fill-available;
    display: flex;
    align-items: center;
    justify-content: center;
}

.plans-type {
    font: 500 1.85vh 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
    color: var(--primary-font-color);
}

.plans-curr-plan-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2.78vh;
}

.plans-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    column-gap: 2.1vw;
    row-gap: 2.78vh;
}

.plans-empty {
    font: 400 1.85vh 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
    color: var(--primary-font-color-a70);
}
</style>
