import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "widgets" }
const _hoisted_2 = {
  key: 0,
  class: "doughnut-wrapper"
}
const _hoisted_3 = {
  key: 0,
  class: "doughnut-container"
}
const _hoisted_4 = { class: "doughnut-counter" }
const _hoisted_5 = { id: "not-on-place-count" }
const _hoisted_6 = { id: "on-place-count" }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 1 }

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { reactive, watchEffect } from 'vue'
import { Doughnut } from 'vue-chartjs'
import { delay } from '@/globalFunctions'


export default /*@__PURE__*/_defineComponent({
  __name: 'UOnWorkplace',
  props: {
  onPlace: {
    type: Number,
    default: 0,
    required: true
  },
  notOnPlace: {
    type: Number,
    default: 0,
    required: true
  },
  total: {
    type: Number,
    default: 0,
    required: true
  },
  isLoading: {
    type: Boolean,
    default: true,
    required: true
  }
},
  emits: ['to-report'],
  setup(__props, { emit: __emit }) {

ChartJS.register(ArcElement, Tooltip, Legend)

const props = __props

const emits = __emit

const reactiveVars = reactive({
  spacing: 1,
  notOnPlace: 0,
  onPlace: 0,
  refreshed: false
})

const state = reactive({
  options: {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      }
    },
    cutout: '75%'
  },
  data: {
    labels: ['Отсутствуют', 'На месте'],
    datasets: [
      {
        backgroundColor: ['#FFFFFFB2', '#2D4B6B', '#16FAFC', '#2D4B6B'],
        data: [props.notOnPlace, reactiveVars.spacing, props.onPlace, reactiveVars.spacing],
        borderRadius: 10,
        borderWidth: 0
      }
    ]
  }
})

watchEffect(async () => {
  reactiveVars.notOnPlace = props.notOnPlace
  reactiveVars.onPlace = props.onPlace
  if (reactiveVars.notOnPlace * reactiveVars.onPlace !== 0) {
    reactiveVars.spacing = (props.notOnPlace + props.onPlace) / 50
    state.data.datasets[0].backgroundColor = ['#FFFFFFB2', '#2D4B6B', '#16FAFC', '#2D4B6B']
    state.data.datasets[0].data = [reactiveVars.notOnPlace, reactiveVars.spacing, reactiveVars.onPlace, reactiveVars.spacing]
    state.data.datasets[0].borderRadius = 10
  } else {
    reactiveVars.spacing = 0
    state.data.datasets[0].backgroundColor = ['#FFFFFFB2', '#16FAFC']
    state.data.datasets[0].data = [reactiveVars.notOnPlace, reactiveVars.onPlace]
    state.data.datasets[0].borderRadius = 0
  }
  reactiveVars.refreshed = false
  await delay(1)
  reactiveVars.refreshed = true
})

return (_ctx: any,_cache: any) => {
  const _component_u_widget_header = _resolveComponent("u-widget-header")!
  const _component_u_spinner = _resolveComponent("u-spinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (reactiveVars.notOnPlace + props.onPlace > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_u_widget_header, {
            headerText: 'На месте',
            onIconClicked: _cache[0] || (_cache[0] = ($event: any) => (emits('to-report')))
          }),
          (!props.isLoading && reactiveVars.refreshed)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("span", _hoisted_5, _toDisplayString(`${reactiveVars.notOnPlace}/`), 1),
                  _createElementVNode("span", _hoisted_6, _toDisplayString(props.onPlace), 1)
                ]),
                _createVNode(_unref(Doughnut), {
                  data: state.data,
                  options: state.options
                }, null, 8, ["data", "options"])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createVNode(_component_u_spinner)
              ])),
          _cache[1] || (_cache[1] = _createStaticVNode("<div class=\"info-rows\"><div class=\"info-row\"><div class=\"square\" id=\"not-on-place\"></div><span>Отсутствуют</span></div><div class=\"info-row\"><div class=\"square\" id=\"on-place\"></div><span>На месте</span></div></div>", 1))
        ]))
      : (_openBlock(), _createElementBlock("span", _hoisted_8, "Нет данных"))
  ]))
}
}

})