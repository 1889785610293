import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "udialog-overlay"
}
const _hoisted_2 = { class: "u-filter-view-wrapper" }
const _hoisted_3 = { class: "u-filter-header" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "u-filter-view-body" }
const _hoisted_6 = { class: "u-filter-actions" }

import { reactive, onBeforeMount, watch } from 'vue'
import { Photo } from '@/classes'
import cancel from '@/assets/icon/cancel-dialog.svg'
import { Employee, Organization, Universal } from '@/models'
import { ImagesState } from '@/components/widgets/UChoosePersons.vue'
import { useImagesYarosStore } from '@/stores/imagesYaros'

export interface FiltersData {
  departments: string[],
  positions: string[],
  persons: string[]
}


export default /*@__PURE__*/_defineComponent({
  __name: 'UFiltersView',
  emits: ['dismiss', 'confirm'],
  setup(__props, { emit: __emit }) {

const emits = __emit
const imagesStore = useImagesYarosStore()

const state = reactive({
  positions: [] as Universal[],
  departments: [] as Universal[],
  organization: {} as Organization,
  persons: [] as Employee[],
  filters: {} as FiltersData,
  images: reactive({} as ImagesState)
})

onBeforeMount(() => {
  state.organization = JSON.parse(sessionStorage.getItem('organization') || '{}')
  state.departments = JSON.parse(sessionStorage.getItem('departments') || '[]')
  state.positions = JSON.parse(sessionStorage.getItem('positions') || '[]').positions
  state.persons = JSON.parse(sessionStorage.getItem('persons') || '[]')
  state.filters.departments = state.departments.map(item => item.id)
  state.filters.positions = state.positions.map(item => item.id)
  state.filters.persons = state.persons.map(item => item.id)
})

watch(() => state.persons, (newValue, oldValue) => {
  if (newValue !== oldValue && newValue.length > 0) {
    loadingImages()
  }
}, { deep: true })

function confirmFilters () {
  emits('confirm', state.filters)
  emits('dismiss')
}

function changeFilterData (args: Record<string, Array<string>>) {
  try {
    // @ts-expect-error - here is nothing to see
    state.filters[args.type as keyof typeof state.filters] = args.arr
  } catch {
  }
}

async function loadingImages () {
  for (const person of state.persons) {
    if (person.photo && !(person.photo in state.images)) {
      let photo = new Photo()
      const photoId = person.photo
      try {
        const cachedPhoto = sessionStorage.getItem(person.photo)
        if (cachedPhoto) {
          photo = new Photo(cachedPhoto, false, false)
        } else {
          const result = await imagesStore.getImage(state.organization.fixStorage, photoId)
          if (result) {
            sessionStorage.setItem(person.photo, result)
            photo = new Photo(result, false, false)
          }
        }
      } catch (error) {
        console.error(`Failed to load image ${photoId}:`, error)
        photo = new Photo('', false, true)
      } finally {
        state.images = { ...state.images, ...{ [photoId]: photo } }
      }
    }
  }
}

return (_ctx: any,_cache: any) => {
  const _component_u_drop_filters = _resolveComponent("u-drop-filters")!

  return (_openBlock(), _createBlock(_Transition, { name: "udialog-fade" }, {
    default: _withCtx(() => [
      true
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _cache[2] || (_cache[2] = _createElementVNode("span", { class: "add-universal-header-text" }, " Фильтры ", -1)),
                _createElementVNode("img", {
                  src: _unref(cancel),
                  class: "button-click",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (emits('dismiss')))
                }, null, 8, _hoisted_4)
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_u_drop_filters, {
                  label: 'Отдел',
                  type: 'departments',
                  universalVars: state.departments,
                  choosenIds: state.filters.departments,
                  onFilterAct: changeFilterData
                }, null, 8, ["universalVars", "choosenIds"]),
                _createVNode(_component_u_drop_filters, {
                  label: 'Должность',
                  type: 'positions',
                  universalVars: state.positions,
                  choosenIds: state.filters.positions,
                  onFilterAct: changeFilterData
                }, null, 8, ["universalVars", "choosenIds"]),
                _createVNode(_component_u_drop_filters, {
                  label: 'Сотрудник',
                  type: 'persons',
                  personsVars: state.persons,
                  choosenIds: state.filters.persons,
                  images: state.images,
                  onFilterAct: changeFilterData
                }, null, 8, ["personsVars", "choosenIds", "images"])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", {
                  class: "u-filter-actions-btn button-click",
                  id: "ok-btn",
                  onClick: confirmFilters
                }, _cache[3] || (_cache[3] = [
                  _createElementVNode("span", { class: "u-filter-actions-btn-text" }, "Применить", -1)
                ])),
                _createElementVNode("div", {
                  class: "u-filter-actions-btn button-click",
                  id: "cancel-btn",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (emits('dismiss')))
                }, _cache[4] || (_cache[4] = [
                  _createElementVNode("span", { class: "u-filter-actions-btn-text" }, "Отмена", -1)
                ]))
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})