<template>
    <div class="universal-row-wrapper"
      v-if="props.item"
    >
      <span>{{ props.item.name }}</span>
      <div class="button-click universal-del-wrapper"
        @click="emits('del-universal')"
      >
        <img :src="del">
      </div>
    </div>
</template>

<script setup lang="ts">
import { PropType } from 'vue'
import { Universal } from '@/models'
import del from '@/assets/icon/del.svg'

const props = defineProps({
  item: {
    type: Object as PropType<Universal> | undefined,
    required: true
  }
})

const emits = defineEmits(['del-universal'])
</script>

<style>
.universal-row-wrapper {
  display: flex;
  gap: 0.93vh;
  background-color: var(--primary-font-color-a10);
  border-radius: 1.85vh;
  font: 400 1.3889vh 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
  color: var(--primary-font-color);
  padding: 0.741vh 0.782vw;
  width: max-content;
  align-items: center;
}

.universal-del-wrapper {
  display: flex;
  align-items: center;
}
</style>
