<template>
  <transition name="udialog-fade">
    <div class="udialog-overlay" v-show="true">
      <div class="choose-photo-type">
        <div class="add-person-header">
          <span class="add-person-header-text">
            Добавить изображение профиля
          </span>
          <img :src="cancel" class="button-click" @click="emits('dismiss')">
        </div>
        <div class="choose-photo-type-actions">
            <div class="choose-photo-type-btn button-click"
                @click="triggerFileInput"
            >
                <img class="choose-photo-type-img" :src="galery">
                <div class="upload-button">
                    <span>Выбрать из галереи</span>
                </div>
                <input type="file" @change="handleFileChange" ref="fileInput"
                    style="display: none;"
                >
            </div>
            <div class="choose-photo-type-btn button-click"
                @click="emits('result', 'photo')"
            >
                <img class="choose-photo-type-img" :src="photo">
                <span>Сфотографировать</span>
            </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
import cancel from '@/assets/icon/cancel-dialog.svg'
import galery from '@/assets/icon/galery.svg'
import photo from '@/assets/icon/photo.svg'
import { ref, Ref } from 'vue'

const emits = defineEmits(['dismiss', 'result', 'getted-photo'])
const fileInput = ref() as Ref<HTMLInputElement>

function triggerFileInput () {
  fileInput.value?.click()
}

function handleFileChange (event: Event) {
  const input = event.target as HTMLInputElement
  if (input.files && input.files[0]) {
    const file = input.files[0]
    const reader = new FileReader()
    reader.onload = (e) => {
      const imageData = e.target?.result
      if (imageData) {
        emits('getted-photo', imageData)
      }
    }
    reader.readAsDataURL(file)
  }
}
</script>

<style>
.choose-photo-type {
  background-image: linear-gradient(245.9deg, #2D4B6B -40.66%, #042447 131.18%);
  padding: 3.71vh 2.345vw;
  width: 23.5395vw;
  border-radius: 3.71vh;
}

.choose-photo-type-actions {
  display: flex;
  gap: 1.0417vw;
  justify-content: center;
}

.choose-photo-type-btn {
  display: flex;
  gap: 0.926vh;
  height: 3.704vh;
  width: 10.7295vw;
  background-color: #042447;
  border-radius: 13.89vh;
  align-items: center;
  justify-content: center;
  font: 400 1.389vh 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
  color: var(--primary-font-color);
}

.choose-photo-type-img {
  width: 0.99vw;
}

.upload-button {
  display: flex;
  font: 400 1.389vh 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
  color: var(--primary-font-color);
  align-items: center;
}
</style>
