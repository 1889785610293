import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "dropdown-timezone" }
const _hoisted_2 = { class: "hours-info" }
const _hoisted_3 = { class: "hours-info-city" }
const _hoisted_4 = {
  key: 0,
  class: "hours-info-country"
}
const _hoisted_5 = { key: 1 }
const _hoisted_6 = ["src"]
const _hoisted_7 = {
  key: 0,
  class: "dropdown-timezone-content"
}
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "dropdown-timezone-items" }
const _hoisted_10 = { class: "hours-info-city" }
const _hoisted_11 = { class: "hours-info-country" }

import { reactive, watch, computed } from 'vue'
import dropDown from '@/assets/icon/dropdown-open.svg'
import { hours, HoursItem } from '@/stores/hours'


export default /*@__PURE__*/_defineComponent({
  __name: 'UDropDownHours',
  props: {
  timezone: {
    type: Number,
    required: false
  }
},
  emits: ['change-timezone'],
  setup(__props, { emit: __emit }) {

const emits = __emit

const sortedHours = computed(() => {
  return [...hours].sort((a, b) => a.offsetNumeric - b.offsetNumeric)
})

const state = reactive({
  isOpen: false,
  clientTimezone: null as HoursItem | null | undefined
})

const props = __props

watch(() => props.timezone, () => {
  state.clientTimezone = getClientTimezone()
})

function getClientTimezone () {
  if (props.timezone) {
    return hours.find(item => item.offsetNumeric === props.timezone)
  } else return null
}

function selectOption (item: HoursItem) {
  state.isOpen = false
  emits('change-timezone', item.offsetNumeric)
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "dropdown-timezone-header",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (state.isOpen = !state.isOpen))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(state.clientTimezone?.city), 1),
        (state.clientTimezone)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(`${state.clientTimezone?.country}, GMT ${state.clientTimezone?.offsetString}`), 1))
          : (_openBlock(), _createElementBlock("span", _hoisted_5))
      ]),
      _createElementVNode("img", {
        src: _unref(dropDown),
        class: "button-click"
      }, null, 8, _hoisted_6)
    ]),
    (state.isOpen)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sortedHours.value, (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              onClick: ($event: any) => (selectOption(item))
            }, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("span", _hoisted_10, _toDisplayString(item.city), 1),
                _createElementVNode("span", _hoisted_11, _toDisplayString(`${item.country}, GMT ${item.offsetString}`), 1)
              ])
            ], 8, _hoisted_8))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})