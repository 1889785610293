<template>
    <div class="dropdwn-filter-main-wrapper">
        <div class="dropdwn-filter-wrapper" @click="emits('click-event')">
            <span class="dropdown-choosen-item" id="choosen-item">
                {{ state.array[props.selected === -1 ? 0 : props.selected]?.text }}
            </span>
            <img :src="dropDown" class="button-click">
        </div>
        <div class="dropdown-opened" v-if="props.isOpen">
            <div class="dropdown-row button-click"
                :class="{
                    'dropdown-row-focused': state.itemIndex === index,
                    }"
                :style="{
                    color: (props.selected === -1 ? 0 : props.selected) === index ?
                    '#16FAFC' :
                    state.itemIndex === index ? '#FFFFFF' : '#FFFFFF80'
                    }"
                v-for="(item, index) in state.array"
                :key="index"
                @mouseenter="state.itemIndex = index"
                @click="chooseRow(index)"
            >
                <span class="dropdown-choosen-item">
                    {{ item.text }}
                </span>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { reactive } from 'vue'
import dropDown from '@/assets/icon/dropdown-open.svg'

export interface ArrayMap {
  text: string,
  value: string | number
}

const emits = defineEmits([
  'click-event',
  'choose-row'
])

const props = defineProps({
  array: {
    type: Array as () => ArrayMap[],
    required: true
  },
  selected: {
    type: Number,
    required: true
  },
  isOpen: {
    type: Boolean,
    required: true
  }
})

const state = reactive({
  itemIndex: -1,
  array: [
    { text: 'Не выбран', value: 'empty' }, ...props.array
  ] as ArrayMap[]
})

function chooseRow (index: number) {
  if (state.array[index].value === 'empty') {
    emits('choose-row', -1)
  } else emits('choose-row', index)
  emits('click-event')
}
</script>

<style>
.dropdwn-filter-wrapper {
  display: flex;
  background-color: #2D4B6B;
  border-radius: 2rem;
  padding: 1rem 1.5rem;
  align-items: center;
  justify-content: space-between;
}

.dropdwn-filter-main-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.dropdown-choosen-item {
  font: 0.7rem 'RedHatText-SemiBold', 'OpenSans', sans-serif;
  font-weight: 500;
}

.dropdown-opened {
  background-color: #2D4B6B;
  display: flex;
  flex-direction: column;
  border-radius: 2rem;
  padding: 0.4rem 0.5rem;
  border-radius: 2rem;
  position: absolute;
  top: 120%;
  left: 0;
  z-index: 3100;
  width: -webkit-fill-available;
}

.dropdown-row {
  padding: 0.6rem 1rem;
  border-radius: 2rem;
}

.dropdown-row-focused {
  background-color: #385473;
}

#choosen-item {
  color: #FFFFFF80;
}
</style>
