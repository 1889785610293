<template>
  <div v-if="!isMobile" class="w-header-wrapper">
    <span class="w-header-text">{{ props.headerText }}</span>
    <u-icon-rounded v-if="!props.showMonthStartDate"
      :type="'more'"
      :iconWidth="0.68"
      :iconUnit="'vw'"
      :iconMnUnit="'vw'"
      @click="emits('icon-clicked')"/>
    <span v-else class="w-header-month">{{ getCurrentDate() }}</span>
  </div>
  <div v-else class="w-header-wrapper">
    <span class="w-header-text">{{ props.headerText }}</span>
    <u-icon-rounded v-if="!props.showMonthStartDate"
      :type="'more'"
      :iconWidth="3.31"
      :iconUnit="'vw'"
      :iconMnUnit="'vw'"
      @click="emits('icon-clicked')"/>
    <span v-else class="w-header-month">{{ getCurrentDate() }}</span>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  headerText: {
    type: String,
    default: '',
    required: false
  },
  showMonthStartDate: {
    type: Boolean,
    default: false,
    required: false
  }
})

const emits = defineEmits(['icon-clicked'])

const isMobile = window.innerWidth < 800

function getCurrentDate () {
  const date = new Date()
  const currentMonth = date.getMonth() + 1
  const currentDay = date.getDate()
  return `${currentDay < 10 ? `0${currentDay}` : currentDay}/${currentMonth < 10 ? `0${currentMonth}` : currentMonth}`
}

</script>

<style>
.w-header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.56vw;
}

.w-header-text {
  font: 1.04vw 'RedHatText-Bold', 'OpenSans', sans-serif;
  color: var(--primary-font-color);
}

.w-header-month {
  font: 1.04vw 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
  color: var(--primary-font-color-blue);
}

@media screen and (max-width: 850px) {
  .w-header-wrapper {
    margin-bottom: 8.40vw;
  }
  .w-header-text {
    font-size: 5.09vw;
  }
  .w-header-month {
    font-size: 5.09vw;
  }
}
</style>
