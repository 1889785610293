import { defineComponent as _defineComponent } from 'vue'
import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "icon-container button-click" }
const _hoisted_2 = ["src"]

import { reactive, onBeforeMount } from 'vue'
import { iconMap } from '@/maps'


export default /*@__PURE__*/_defineComponent({
  __name: 'UIcon',
  props: {
  type: String,
  showText: {
    type: Boolean,
    default: false,
    required: false
  },
  iconWidth: {
    type: Number,
    default: 0,
    required: false
  },
  iconUnit: {
    type: String,
    default: 'vw',
    required: false
  },
  isEmpty: {
    type: Boolean,
    default: true,
    required: false
  },
  iconPdTp: {
    type: Number,
    default: 0,
    required: false
  },
  iconPdRt: {
    type: Number,
    default: 0,
    required: false
  },
  iconPdBt: {
    type: Number,
    default: 0,
    required: false
  },
  iconPdLt: {
    type: Number,
    default: 0,
    required: false
  },
  iconPdUnit: {
    type: String,
    default: 'rem',
    required: false
  },
  fontUnit: {
    type: String,
    default: 'rem',
    required: false
  },
  fontSize: {
    type: Number,
    default: 1,
    required: false
  }
},
  setup(__props) {

const props = __props

const state = reactive({
  iconRef: '',
  text: '',
  activeColor: ''
})

onBeforeMount(async () => {
  const icon = iconMap[props.type as keyof typeof iconMap]
  if (icon) {
    state.text = icon.text
    state.iconRef = icon.iconRef
  }
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      style: _normalizeStyle({
      width: props.iconWidth + props.iconUnit,
      paddingTop: props.iconPdTp + props.iconPdUnit,
      paddingRight: props.iconPdRt + props.iconPdUnit,
      paddingBottom: props.iconPdBt + props.iconPdUnit,
      paddingLeft: props.iconPdLt + props.iconPdUnit
    }),
      src: state.iconRef
    }, null, 12, _hoisted_2),
    (props.showText)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          style: _normalizeStyle({
      fontSize: props.fontSize + props.fontUnit
    })
        }, _toDisplayString(state.text), 5))
      : _createCommentVNode("", true)
  ]))
}
}

})