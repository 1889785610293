<template>
    <div class="payments-header-wrapper button-click"
        @click="emits('to-prev')"
    >
        <img :src="back" >
        <div>
            <span :class="{'payments-header-a70': props.currentRout !== ''}">
                Пополнение баланса
            </span>
            <span v-if="props.currentRout !== ''">
                {{ ` / ${props.currentRout}` }}
            </span>
        </div>
    </div>
</template>

<script setup lang="ts">
import back from '@/assets/icon/back.svg'

const props = defineProps({
  currentRout: {
    type: String,
    default: ''
  }
})

const emits = defineEmits(['to-prev'])
</script>

<style>
.payments-header-wrapper {
    display: flex;
    font: 400 1.8519vh 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
    color: var(--primary-font-color);
    gap: 1.042vw;
    align-items: center;
}

.payments-header-wrapper img {
    height: 1.482vh;
}

.payments-header-a70 {
    color: var(--primary-font-color-a70);
}
</style>
