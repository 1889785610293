<template>
  <div class="widgets fines-widget">
    <u-widget-header class="fine-widget-header" :headerText="'Штрафы'" :showMonthStartDate="true"/>
    <div class="fines-body" v-if="!props.isLoading">
      <div class="fines-widget-block" id="fines-widget-block-top">
        <span class="fine-sum">{{ props.fineSum }}KGS</span>
        <span class="fine-info">
          Сэкономлено на штрафах за текущий месяц
        </span>
      </div>
      <div class="fines-widget-block">
        <div class="fine-info-panel">
          <div class="fine-info-up-low-percent">
            <span class="fine-up-low-percent">{{ props.periodPerc }}%</span>
            <img :src="props.periodPerc >= 0 ? fineUp : fineLow">
          </div>
          <div class="delimeter"></div>
          <div class="fine-percent-part">
            <span class="fine-percent-number" :id="props.percent >= 0 ? 'percent-up' : 'percent-low'">
              {{ `${props.percent >= 0 ? '+' : '-'} ${props.percent}%` }}
            </span>
            <span class="fine-label">С прошлого месяца</span>
          </div>
        </div>
        <button class="button-click report-button"
          @click="emits('to-report', 'finesrep')">
          Отчет
        </button>
      </div>
    </div>
    <div class="fines-spinner-wrapper" v-else>
      <u-spinner />
    </div>
  </div>
</template>

<script setup lang="ts">
import fineLow from '@/assets/icon/fine_low.svg'
import fineUp from '@/assets/icon/fine_up.svg'

const props = defineProps({
  fineSum: {
    type: Number,
    default: 0,
    required: true
  },
  percent: {
    type: Number,
    default: 0,
    required: true
  },
  periodPerc: {
    type: Number,
    default: 0,
    required: true
  },
  isLoading: {
    type: Boolean,
    default: true,
    required: true
  }
})

const emits = defineEmits(['to-report'])
</script>

<style>
.fine-sum {
  font: 1.67vw 'RedHatText-SemiBold', 'OpenSans', sans-serif;
  color: var(--primary-font-color);
}

.fines-widget {
  display: flex;
  flex-direction: column;
}

.fine-widget-header {
  margin-top: 0.5vw;
}

.fine-info {
  font: 0.63vw 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
  color: #C6C6C6;
}
.fine-label {
  font: 0.5vw 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
  color: #C6C6C6;
}

.fine-up-low-percent {
  font: 2.2vw 'RedHatDisplay-SemiBold', 'OpenSans', sans-serif;
  color: var(--primary-font-color);
  margin-right: 0.3vw;
}

.fine-info-up-low-percent img {
  width: 0.99vw;
}

.fine-info-panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1vw;
}

.fine-percent-part {
  font-family: 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.fine-percent-number {
  font: 0.78vw 'RedHatDisplay-SemiBold', 'OpenSans', sans-serif;
}

#percent-up {
  color: var(--primary-font-color-blue);
}

#percent-low {
  color: var(--primary-font-color-error);
}

.report-button {
  font: 0.52vw 'RedHatDisplay-Regular', 'OpenSans Regular', sans-serif;
  color: var(--primary-font-color-blue);
  background-color: #ffffff0d;
  padding: 0.36vw 0.78vw;
  border-radius: 20vw;
  width: fit-content;
  border: none;
  align-self: end;
}

.fines-widget-block, .fines-body {
  display: flex;
  flex-direction: column;
}

.fines-body {
  height: -webkit-fill-available;
  justify-content: space-between
}

#fines-widget-block-top {
  gap: 0.35vw;
}

.delimeter {
  border-left: 1px #C0C0C0 solid;
  height: 1.98vw;
}

.fine-info-up-low-percent {
  display: flex;
  align-items: center;
}

.fines-spinner-wrapper {
  height: 60%;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 850px) {
  .fine-sum {
    font-size: 8.14vw;
  }
  .fine-info {
    font-size: 3.05vw;
  }
  .fine-up-low-percent {
    font-size: 12.21vw;
  }
  .delimeter {
    height: 10vw;
  }
  .fine-info-up-low-percent img {
    width: 4.83vw;
  }
  .fine-percent-number {
    font-size: 3.82vw;
  }
  .fine-label {
    font-size: 2.54vw;
  }
  .report-button {
    font-size: 2.54vw;
    padding: 1.5vw 3.5vw;
  }
}
</style>
