<template>
    <div class="pay-steps-wrapper">
        <Terminal v-if="['AsisNur', 'Qpay', 'elsom'].includes(props.type)"
          :summ="props.summ" :type="props.type"
          @show-loader="showLoader()"
          @close-loader="state.showDialog = false"
          @show-dialog="showDialog"
        />
        <Visa v-if="props.type === 'visa'" :summ="props.summ"
          @show-loader="showLoader()"
          @close-loader="state.showDialog = false"
          @show-dialog="showDialog"
        />
        <O v-if="props.type === 'O'"
          :summ="props.summ"
          @show-loader="showLoader()"
          @close-loader="state.showDialog = false"
          @show-dialog="showDialog"
        />
    </div>
    <u-dialog v-if="state.showDialog"
      :header="state.dialogHeader"
      :msgText="state.dialogBody"
      :isLoadind="state.isLoader"
      @click-res="closeDialog()"
    />
</template>

<script setup lang="ts">
import { reactive } from 'vue'
import Terminal from '../components/widgets/pay_instructions/TerminalInstr.vue'
import Visa from '../components/widgets/pay_instructions/VisaPay.vue'
import O from '../components/widgets/pay_instructions/OPay.vue'

const props = defineProps({
  summ: {
    type: String,
    required: true
  },
  type: {
    type: String,
    required: true
  }
})

const state = reactive({
  showDialog: false,
  dialogHeader: '',
  dialogBody: '',
  isLoader: false,
  error: false
})

const emits = defineEmits(['to-prev'])

function showLoader () {
  state.isLoader = true
  state.showDialog = true
}

interface DialogArgs {
  error?: boolean;
  header?: string;
  body: string;
}

function showDialog (args: DialogArgs) {
  state.error = args.error ?? false
  state.isLoader = false
  state.dialogHeader = args.header ?? state.error ? 'Ошибка' : 'Успех'
  state.dialogBody = args.body
  state.showDialog = true
}

function closeDialog () {
  state.showDialog = false
  if (state.error) {
    emits('to-prev')
  }
}
</script>

<style>
.pay-steps-wrapper {
  height: -webkit-fill-available;
}
</style>
