import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "report-miniature-wrapper" }
const _hoisted_2 = { class: "report-miniature-actions" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "report-name" }
const _hoisted_5 = ["src"]

import fixReport from '@/assets/icon/fix-report.svg'
import journalReport from '@/assets/icon/journal-report.svg'
import ellipse from '@/assets/images/ellipse.svg'


export default /*@__PURE__*/_defineComponent({
  __name: 'UReportMiniature',
  props: {
  type: {
    type: String,
    default: '',
    required: true
  }
},
  emits: ['to-report'],
  setup(__props, { emit: __emit }) {

const emits = __emit

const props = __props

const reportsMap = {
  fix: { img: fixReport, text: 'Фикс-отчет', code: 'fix' },
  journal: { img: journalReport, text: 'Журналы', code: 'journal' }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        class: "report-icon button-click",
        src: reportsMap[props.type as keyof typeof reportsMap]?.img
      }, null, 8, _hoisted_3),
      _createElementVNode("span", _hoisted_4, _toDisplayString(reportsMap[props.type as keyof typeof reportsMap]?.text), 1),
      _createElementVNode("button", {
        class: "to-report-btn button-click",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (emits('to-report', reportsMap[props.type as keyof typeof reportsMap]?.code)))
      }, " Перейти к отчету ")
    ]),
    _createElementVNode("img", {
      src: _unref(ellipse),
      class: "ellipse"
    }, null, 8, _hoisted_5)
  ]))
}
}

})