import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, vShow as _vShow, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "fines-rep-main-wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "fines-rep-wrapper"
}
const _hoisted_3 = { class: "fines-rep-row-dep-pos" }
const _hoisted_4 = { class: "fines-rep-legend-block" }
const _hoisted_5 = { class: "fines-rep-row-dep-type" }
const _hoisted_6 = { class: "fines-rep-body" }
const _hoisted_7 = { class: "fines-rep-row" }
const _hoisted_8 = { class: "fines-rep-row-date" }
const _hoisted_9 = { class: "fines-rep-row-name" }
const _hoisted_10 = { class: "fines-rep-row-dep-pos-data" }
const _hoisted_11 = { class: "fines-rep-row-dep-amount" }
const _hoisted_12 = { class: "fines-rep-row-dep-type" }
const _hoisted_13 = { key: 0 }

import { onBeforeMount, reactive, watch, ref } from 'vue'
import {
  FeeStatisticList,
  FeeStatisticMap,
  Department,
  Position,
  Employee,
  SubFilterMap
} from '@/models'
import {
  unitTimestampConvert,
  getMonthStartEnd,
  getPreviousMonthInfo
} from '@/globalFunctions'
import { useFinesStore } from '@/stores/fines'
import { useCookesStore } from '@/stores/cookies'
import cloneDeep from 'lodash/cloneDeep'


export default /*@__PURE__*/_defineComponent({
  __name: 'FinesRep',
  setup(__props) {

const finesStore = useFinesStore()
const cookies = useCookesStore()

const subFilterMap: SubFilterMap = {
  pos: 'Должность',
  dep: 'Подразделение',
  default: 'Сортировка'
}

const dates = reactive({
  dateBegin: 0,
  dateEnd: 0,
  startDates: [0, 0]
})

const state = reactive({
  orgId: '',
  isLoaded: false,
  reportData: [] as FeeStatisticList,
  depPos: 'default',
  subPosDepFirstRow: subFilterMap.dep,
  subPosDepSecondRow: subFilterMap.pos,
  id: '',
  reason: '',
  prevMonth: 0,
  prevYear: 0,
  prevMonthStr: '',
  loading: false,
  filteredData: [] as FeeStatisticList
})

const date = ref([new Date(), new Date()])

const dataSetsMap = reactive({
  persons: [] as Employee[],
  departments: [] as Department[],
  positions: [] as Position[]
})

const feeTypeMap: { [key: string]: string } = {
  late: 'Опоздание',
  leave: 'Ранний уход'
}

// const columns = [
//   { label: 'Дата', field: 'date', dataFormat: (val: number) => unitTimestampConvert(val, 'datetime') },
//   { label: 'Сотрудник', field: 'perId', dataFormat: (id: string) => findName(id, 'persons') },
//   { label: 'Подразделение', field: 'depId', dataFormat: (id: string) => findName(id, 'departments') },
//   { label: 'Должность', field: 'posId', dataFormat: (id: string) => findName(id, 'positions') },
//   { label: 'Сумма', field: 'amount' },
//   { label: 'Причина', field: 'feeType', dataFormat: (type: string) => feeTypeMap[type] }
// ]

onBeforeMount(async () => {
  await cookies.getCookie()
  state.orgId = cookies.getCookies.orgId
  const currYear = new Date().getFullYear()
  const prevMonth = getPreviousMonthInfo()[1]
  state.prevMonth = prevMonth - 1 === 0 ? 12 : prevMonth - 1
  state.prevYear = state.prevMonth === 12 ? currYear - 1 : currYear
  const prevMonthTimestamps = getMonthStartEnd(prevMonth, state.prevYear)
  const timestamps = getMonthStartEnd()
  dates.dateBegin = prevMonthTimestamps[0]
  dates.dateEnd = timestamps[1]
  dates.startDates = [dates.dateBegin, dates.dateEnd]
  date.value = [new Date(dates.dateBegin), new Date(dates.dateEnd)]
  const personsData = sessionStorage.getItem('persons')
  if (personsData) {
    dataSetsMap.persons = JSON.parse(personsData)
  }
  const departmentsData = sessionStorage.getItem('departments')
  if (departmentsData) {
    dataSetsMap.departments = JSON.parse(departmentsData)
  }
  const positionsData = sessionStorage.getItem('positions')
  if (positionsData) {
    dataSetsMap.positions = JSON.parse(positionsData).positions
  }
  state.reportData = JSON.parse(sessionStorage.getItem('fee-statistic') || '[]')
  state.filteredData = cloneDeep(state.reportData)
  state.isLoaded = true
})

function findName (id: string, type: string) {
  const item = dataSetsMap[type as keyof typeof dataSetsMap].find(item => item.id === id)
  return item ? item.name : 'Неизвестно'
}

watch(() => state.depPos, (newValue) => {
  state.id = ''
  if (newValue !== 'default') {
    state.subPosDepFirstRow = subFilterMap[newValue]
    state.subPosDepSecondRow = 'Все'
  } else {
    state.subPosDepFirstRow = subFilterMap.dep
    state.subPosDepSecondRow = subFilterMap.pos
  }
})

watch(() => date.value, (newValue) => {
  if (!newValue) {
    date.value = [new Date(dates.startDates[0]), new Date(dates.startDates[1])]
    dates.dateBegin = date.value[0].getTime()
    dates.dateEnd = date.value[1].getTime()
    return
  }
  if (!newValue[0]) {
    date.value[0] = new Date()
  }
  if (!newValue[1]) {
    if (date.value[0] > new Date()) {
      date.value[1] = date.value[0]
    } else {
      date.value[1] = new Date()
    }
  }
  if (!(dates.dateBegin === newValue[0].getTime() &&
    dates.dateEnd === newValue[1].getTime())) {
    dates.dateBegin = newValue[0].getTime()
    dates.dateEnd = newValue[1].getTime()
  }
})

function reasonFilter () {
  if (state.reason === '') {
    state.reason = Object.keys(feeTypeMap)[0]
  } else {
    const keysArr = Object.keys(feeTypeMap)
    const currIndex = keysArr.findIndex(item => item === state.reason)
    if (currIndex === keysArr.length - 1) {
      state.reason = ''
    } else {
      state.reason = Object.keys(feeTypeMap)[currIndex + 1]
    }
  }
}

function checkConditions (item: FeeStatisticMap) {
  // const datesRange = item.date >= dates.dateBegin && item.date <= dates.dateEnd
  const reasonConditon = state.reason === '' ? true : item.feeType === state.reason
  return reasonConditon
}

async function loadMoreData () {
  state.loading = true
  const timestamps = getMonthStartEnd(state.prevMonth, state.prevYear)
  await finesStore.getFeeAction(state.orgId, timestamps[0], timestamps[1])
  date.value[0] = new Date(timestamps[0])
  state.reportData = [...finesStore.getFeeStatisticList, ...state.reportData]
  state.prevMonth = state.prevMonth - 1 === 0 ? 12 : state.prevMonth - 1
  state.loading = false
}

watch(() => state.prevMonth, (newValue) => {
  if (state.isLoaded && newValue === 12) {
    state.prevYear = state.prevYear - 1
  }
  state.prevMonthStr = `${state.prevMonth.toString().padStart(2, '0')}.${state.prevYear}`
})

function handleHeaderClick () {
  filterDataAct()
}
function filterDataAct () {
  state.filteredData = state.reportData.filter(item => checkConditions(item))
}

return (_ctx: any,_cache: any) => {
  const _component_u_spinner = _resolveComponent("u-spinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (state.isLoaded)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", {
            class: "fines-rep-header",
            onClick: handleHeaderClick
          }, [
            _cache[1] || (_cache[1] = _createElementVNode("div", { class: "fines-rep-row-date" }, [
              _createElementVNode("span", null, " Дата ")
            ], -1)),
            _cache[2] || (_cache[2] = _createElementVNode("div", { class: "fines-rep-row-name" }, [
              _createElementVNode("span", null, " Сотрудник ")
            ], -1)),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("span", null, _toDisplayString(state.subPosDepFirstRow), 1),
                _createElementVNode("span", null, _toDisplayString(state.subPosDepSecondRow), 1)
              ])
            ]),
            _cache[3] || (_cache[3] = _createElementVNode("div", { class: "fines-rep-row-dep-amount" }, [
              _createElementVNode("span", null, " Сумма ")
            ], -1)),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", {
                class: "fines-rep-legend-block button-click",
                onClick: reasonFilter
              }, [
                _cache[0] || (_cache[0] = _createElementVNode("span", null, " Причина ", -1)),
                _createElementVNode("span", null, _toDisplayString(state.reason === '' ? 'Все' : feeTypeMap[state.reason]), 1)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(state.reportData, (item, index) => {
              return (_openBlock(), _createElementBlock("div", { key: index }, [
                _withDirectives(_createElementVNode("div", null, [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("span", null, _toDisplayString(_unref(unitTimestampConvert)(item.date, 'datetime')), 1)
                    ]),
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("span", null, _toDisplayString(findName(item.perId, 'persons')), 1)
                    ]),
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("span", null, _toDisplayString(findName(item.depId, 'departments')), 1),
                      _createElementVNode("span", null, _toDisplayString(findName(item.posId, 'positions')), 1)
                    ]),
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("span", null, _toDisplayString(item.amount), 1)
                    ]),
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("span", null, _toDisplayString(feeTypeMap[item.feeType]), 1)
                    ])
                  ]),
                  _cache[4] || (_cache[4] = _createElementVNode("div", { class: "row-delimeter" }, null, -1))
                ], 512), [
                  [_vShow, item.date && checkConditions(item)]
                ])
              ]))
            }), 128))
          ]),
          _createElementVNode("div", {
            class: "fines-rep-load-prev-month button-click",
            onClick: loadMoreData
          }, [
            (!state.loading)
              ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(`Загрузить данные за ${state.prevMonthStr}`), 1))
              : (_openBlock(), _createBlock(_component_u_spinner, { key: 1 }))
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})