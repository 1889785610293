import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "ureview-overlay"
}
const _hoisted_2 = { class: "ureview-wrapper" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "ureview-progressive-bar-wrapper" }
const _hoisted_5 = { class: "ureview-counter" }
const _hoisted_6 = { class: "ureview-btn-group" }

import { ref, Ref, reactive } from 'vue'
import { delay } from '@/globalFunctions'
import cancel from '@/assets/icon/cancel-dialog.svg'
import { useFeedbackStore } from '@/stores/feedback'


export default /*@__PURE__*/_defineComponent({
  __name: 'UReview',
  props: {
  orgId: {
    type: String,
    default: '',
    required: true
  },
  activeForm: {
    type: String,
    default: '',
    required: true
  }
},
  emits: ['close-review'],
  setup(__props, { emit: __emit }) {

const comment = ref(null)

const style = getComputedStyle(document.documentElement)

const state = reactive({
  comment: '',
  maxLength: 512,
  widthPercentage: 0,
  backgroundColor: style.getPropertyValue('--primary-font-color').trim()
})

const dialogStates = reactive({
  showDialog: false,
  dialogHeader: '',
  dialogBody: '',
  dialogLoading: false,
  dialogShowBtns: true,
  closeReview: false
})

const props = __props

const emits = __emit
const feedback = useFeedbackStore()

const myTextarea: Ref<HTMLTextAreaElement | null> = ref(null)

const setCursor = () => {
  const textarea = myTextarea.value
  if (textarea) {
    const textLength = textarea.value.length
    if (textarea.value === '') {
      textarea.setSelectionRange(0, 0)
    } else {
      textarea.setSelectionRange(textLength, textLength)
    }
    textarea.focus()
  }
}

const inputController = () => {
  if (state.comment.length > state.maxLength) {
    state.comment = state.comment.substring(0, state.maxLength)
  }
  modeProgressBar()
}

async function renderProgressiveBar (min: number, max: number) {
  const step = 1
  if (min < max) {
    state.widthPercentage = min
    while (state.widthPercentage < max) {
      state.widthPercentage += step
      await delay(1)
    }
    state.widthPercentage = max
  } else {
    state.widthPercentage = max
    while (state.widthPercentage > 0) {
      state.widthPercentage -= step
      await delay(1)
    }
    state.widthPercentage = 0
  }
}

async function modeProgressBar () {
  if (state.comment.length === 0 && state.backgroundColor !== style.getPropertyValue('--primary-font-color').trim()) {
    await renderProgressiveBar(33, 0)
    state.backgroundColor = style.getPropertyValue('--primary-font-color').trim()
    return
  }
  if (state.comment.length <= 10 && state.backgroundColor !== style.getPropertyValue('--primary-font-color-error').trim()) {
    state.backgroundColor = style.getPropertyValue('--primary-font-color-error').trim()
    await renderProgressiveBar(0, 33)
  } else if (state.comment.length > 10 && state.comment.length <= 128 && state.backgroundColor !== 'orange') {
    state.backgroundColor = 'orange'
    await renderProgressiveBar(33, 66)
  } else if (state.comment.length > 128 && state.backgroundColor !== style.getPropertyValue('--primary-font-color-blue').trim()) {
    state.backgroundColor = style.getPropertyValue('--primary-font-color-blue').trim()
    await renderProgressiveBar(66, 100)
  }
}

function openDialog (header: string, body: string = '', showBtns: boolean = true, loading: boolean = false) {
  dialogStates.closeReview = false
  dialogStates.dialogHeader = header
  dialogStates.dialogBody = body
  dialogStates.dialogShowBtns = showBtns
  dialogStates.dialogLoading = loading
  dialogStates.showDialog = true
}

async function sendReview () {
  if (state.comment.length < 10) {
    openDialog('Короткий комментарий', 'Пожалуйста, введите более развернутый комментарий.')
  } else {
    openDialog('Пожалуйста, подождите', '', false, true)
    await feedback.sendFeedback(props.activeForm, props.orgId, state.comment)
    const feedbackResponse = feedback.getFeedbackResponse
    if (feedbackResponse.status === 'registered') {
      openDialog('Комментарий отправлен', 'Спасибо большое за Ваш отзыв.</br>Наши специалисты обязательно с ним ознакомятся.')
      dialogStates.closeReview = true
    } else {
      openDialog('Ошибка', 'Попробуйте позже!')
    }
  }
}

function closeDialog () {
  dialogStates.showDialog = false
  if (dialogStates.closeReview) {
    emits('close-review')
  }
}

return (_ctx: any,_cache: any) => {
  const _component_u_dialog = _resolveComponent("u-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_Transition, { name: "ureview-fade" }, {
      default: _withCtx(() => [
        true
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("img", {
                  src: _unref(cancel),
                  class: "ureview-close-review button-click",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (emits('close-review')))
                }, null, 8, _hoisted_3),
                _cache[4] || (_cache[4] = _createElementVNode("span", { class: "ureview-header" }, " Пожалуйста, оставьте свой отзыв / комментарий ", -1)),
                _withDirectives(_createElementVNode("textarea", {
                  class: "ureview-comment",
                  ref_key: "comment",
                  ref: comment,
                  onFocus: setCursor,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((state.comment) = $event)),
                  onInput: inputController
                }, "\n                ", 544), [
                  [_vModelText, state.comment]
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _cache[3] || (_cache[3] = _createElementVNode("div", { class: "ureview-progressive-bar" }, null, -1)),
                  _createElementVNode("div", {
                    class: "ureview-progressive-bar",
                    style: _normalizeStyle({ width: state.widthPercentage + '%', backgroundColor: state.backgroundColor }),
                    id: "ureview-active-bar"
                  }, null, 4)
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("span", null, _toDisplayString(`${state.comment.length}/${state.maxLength}`), 1)
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("button", {
                    class: "ureview-btn button-click",
                    id: "save-btn",
                    onClick: sendReview
                  }, " Отправить "),
                  _createElementVNode("button", {
                    class: "ureview-btn button-click",
                    id: "cancel-btn",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (emits('close-review')))
                  }, "Отмена ")
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (dialogStates.showDialog)
      ? (_openBlock(), _createBlock(_component_u_dialog, {
          key: 0,
          header: dialogStates.dialogHeader,
          msgText: dialogStates.dialogBody,
          showButtons: dialogStates.dialogShowBtns,
          isLoadind: dialogStates.dialogLoading,
          onClickRes: closeDialog
        }, null, 8, ["header", "msgText", "showButtons", "isLoadind"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})