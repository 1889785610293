<template>
  <div class="camera-wrapper">
    <div class="camera-header">
      <span class="add-person-header-text camera-header-text">
        Сделать фото
      </span>
      <img :src="cancel" class="button-click" @click="handleDismiss()">
    </div>
    <div class="camera" v-show="state.cameraReady">
      <video ref="video" autoplay @loadedmetadata="setCanvasSize()"></video>
      <canvas ref="canvas" style="display: none;"></canvas>
    </div>
    <div class="camera-loader" v-show="!state.cameraReady">
      <u-spinner />
    </div>
    <div class="camera-actions">
      <div class="camera-actions-btn button-click" id="ok-btn"
        @click="captureImage"
      >
        <img :src="camera" >
        <span class="camera-actions-btn-text">Сделать фото</span>
      </div>
      <div class="camera-actions-btn button-click" id="cancel-btn"
        @click="handleDismiss()"
      >
        <span class="camera-actions-btn-text">Вернуться</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, reactive } from 'vue'
import cancel from '@/assets/icon/cancel-dialog.svg'
import camera from '@/assets/icon/photo.svg'

const video = ref<HTMLVideoElement | null>(null)
const canvas = ref<HTMLCanvasElement | null>(null)
const emits = defineEmits(['getted-photo', 'dismiss'])

const state = reactive({
  cameraReady: false
})

onMounted(() => {
  navigator.mediaDevices.getUserMedia({ video: true })
    .then(stream => {
      if (video.value) {
        video.value.srcObject = stream
      }
    })
    .catch(error => {
      console.error('Error accessing the camera:', error)
    })
})

function setCanvasSize () {
  state.cameraReady = true
  if (video.value && canvas.value) {
    canvas.value.width = video.value.videoWidth
    canvas.value.height = video.value.videoHeight
  }
}

function captureImage () {
  if (canvas.value && video.value) {
    const context = canvas.value.getContext('2d')
    if (context) {
      context.drawImage(video.value, 0, 0, canvas.value.width, canvas.value.height)
      const imageData = canvas.value.toDataURL('image/jpeg')
      stopCamera()
      emits('getted-photo', imageData)
    }
  }
}

function stopCamera () {
  if (video.value?.srcObject) {
    const tracks = (video.value.srcObject as MediaStream).getTracks()
    tracks.forEach(track => track.stop())
    video.value.srcObject = null
  }
}

function handleDismiss () {
  stopCamera()
  emits('dismiss')
}
</script>

<style scoped>
.camera,
.camera-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.camera-loader {
  width: 39.584vw;
  height: 52.78vh;
  justify-content: center;
}

video, canvas {
  width: 39.584vw;
  height: auto;
}

.camera-wrapper {
  background: linear-gradient(90deg, #042447 0%, #2D4B6B 100%);
  padding: 3.71vh 2.344vw;
  border-radius: 3.71vh;
}

.camera-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.778vh;
}

.camera-header-text {
  flex: 1;
  text-align: center;
}

.camera-actions {
  display: flex;
  gap: 0.799vw;
  margin-top: 3.241vh;
  width: -webkit-fill-available;
  justify-content: center;
}

.camera-actions-btn {
  height: 4.7223vh;
  padding: 0 1.823vw;
  border-radius: 5vw;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.525vw;
}

.camera-actions-btn-text {
  font: 700 1.49vh 'RedHatText-Regular', 'OpenSans', sans-serif;
  color: var(--primary-font-color);
}
</style>
