<template>
  <div class="counter-wrapper">
    <div class="counter-info">
      <input type="text" class="counter-input"
        v-model="state.num"
        @input="handleInput"
        :disabled="!props.isActive"
        >
      <span v-if="props.showCurr">сом.</span>
    </div>
    <div class="counter-buttons"
    v-if="props.isActive">
        <img class="button-click" :src="plus" @click="clickBtn('plus')"/>
        <img class="button-click" :src="minus" @click="clickBtn('minus')">
    </div>
  </div>
</template>

<script setup lang="ts">
import plus from '@/assets/icon/vert-count-plus.svg'
import minus from '@/assets/icon/vert-count-minus.svg'
import { reactive, watch } from 'vue'

const emits = defineEmits(['counter-clicked'])

const props = defineProps({
  num: {
    type: Number,
    default: 0,
    required: true
  },
  showCurr: {
    type: Boolean,
    default: true,
    required: false
  },
  type: {
    type: String,
    default: '',
    required: true
  },
  step: {
    type: Number,
    default: 1,
    required: false
  },
  id: {
    type: String,
    default: '',
    required: false
  },
  isActive: {
    type: Boolean,
    default: true,
    required: false
  },
  subType: {
    type: String,
    default: '',
    required: false
  }
})

const state = reactive({
  num: props.num,
  needCallback: false
})

function clickBtn (act: string) {
  state.needCallback = true
  if (act === 'plus') {
    state.num = state.num + props.step
  } else {
    state.num = state.num - props.step
  }
}

const handleInput = (event: Event) => {
  const input = event.target as HTMLInputElement
  let value = input.value
  if (!/^\d+$/.test(input.value)) {
    value = value.slice(0, value.length - 1)
    state.num = Number(value)
    return
  }
  if (value.startsWith('0')) {
    value = value.slice(1)
  }
  state.num = Number(value)
  state.needCallback = true
}

watch(() => props.num, (newValue) => {
  state.num = newValue
  state.needCallback = false
})

watch(() => state.num, (newValue) => {
  if (state.num < 0) {
    state.num = 0
  } else {
    state.num = newValue
  }
  if (state.needCallback) {
    emits('counter-clicked', { [props.type]: state.num, id: props.id, subType: props.subType })
  }
})
</script>

<style>
.counter-wrapper {
  display: flex;
  height: 2.8rem;
  border-radius: 1.4rem;
  padding-left: 1rem;
  padding-right: 1rem;
  gap: 0.5rem;
  align-items: center;
  font: 0.76rem 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
  color: var(--primary-font-color);
  background-color: #2D4B6B;
}

.counter-info {
  display: flex;
  gap: 0.2rem;
  align-items: center;
}

.counter-buttons {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  width: 1rem;
}

.counter-input {
  width: 2.2rem;
  box-shadow: none;
  border: none;
  background-color: #2D4B6B;
  font: 0.76rem 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
  color: var(--primary-font-color);
}
</style>
