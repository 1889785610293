import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "udialog-overlay"
}
const _hoisted_2 = { class: "success-wrapper" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "success-text" }

import success from '@/assets/icon/success.svg'


export default /*@__PURE__*/_defineComponent({
  __name: 'USuccessAdded',
  props: {
  type: {
    type: String,
    required: true
  }
},
  emits: ['close'],
  setup(__props, { emit: __emit }) {

const emits = __emit

const props = __props

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Transition, { name: "udialog-fade" }, {
    default: _withCtx(() => [
      true
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("img", {
                class: "success-img",
                src: _unref(success)
              }, null, 8, _hoisted_3),
              _createElementVNode("span", _hoisted_4, _toDisplayString(props.type === 'departments' ? 'Отдел' : 'Должность') + " успешно добавлен" + _toDisplayString(props.type === 'departments' ? '' : 'а') + "! ", 1),
              _createElementVNode("div", {
                class: "success-btn button-click",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (emits('close')))
              }, _cache[1] || (_cache[1] = [
                _createElementVNode("span", null, "Понятно", -1)
              ]))
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})