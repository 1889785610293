<template>
  <div class="widgets who-late">
    <u-widget-header :headerText="'Фиксация'" @icon-clicked="emits('to-report')"/>
    <div class="who-late-candeles-body">
      <div class="candeles-body" v-if="!props.isLoading">
        <div class="candele">
          <span class="candele-info">{{ props.late }}</span>
          <div id="candele-late" :style="{ height: state.late + 'vw' }"></div>
        </div>
        <div class="candele">
          <span class="candele-info">{{ props.earlyLeave }}</span>
          <div id="candele-early-leave" :style="{ height: state.earlyLeave + 'vw' }"></div>
        </div>
      </div>
      <div class="who-late-spinner" v-else>
        <u-spinner />
      </div>
      <div class="info-rows">
        <div class="info-row">
          <div class="square" id="who-late"></div>
          <span>Опоздали</span>
        </div>
        <div class="info-row">
          <div class="square" id="who-leave"></div>
          <span>Ушли раньше</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onBeforeMount, reactive, watch } from 'vue'
import { delay } from '@/globalFunctions'

const props = defineProps({
  employsQty: {
    type: Number,
    default: 0,
    required: true
  },
  late: {
    type: Number,
    default: 0,
    required: true
  },
  earlyLeave: {
    type: Number,
    default: 0,
    required: true
  },
  isLoading: {
    type: Boolean,
    default: true,
    required: true
  }
})

const emits = defineEmits(['to-report'])

const state = reactive({
  late: 0,
  earlyLeave: 0
})

watch(() => props.late, async (newValue, oldValue) => {
  if (newValue !== oldValue) {
    await calcCandleSize()
  }
})

watch(() => props.earlyLeave, async (newValue, oldValue) => {
  if (newValue !== oldValue) {
    await calcCandleSize()
  }
})

onBeforeMount(async () => {
  await calcCandleSize()
})

async function calcCandleSize () {
  const minValue = 0.63
  const maxValue = 5.3
  const step = 0.05

  const maxCandleSize = Math.max(props.late, props.earlyLeave)
  let employs = props.employsQty
  if (maxCandleSize > props.employsQty) {
    employs = maxCandleSize
  } else {
    employs = props.employsQty
  }

  const onePercEmpl = employs / 100
  const onePercCandle = maxValue / 100

  const whoLateMax = props.late / onePercEmpl * onePercCandle
  const whoLeaveMax = props.earlyLeave / onePercEmpl * onePercCandle

  while (state.late < minValue) {
    state.late += step
    state.earlyLeave += step
    await delay(1)
  }
  state.late = 1
  state.earlyLeave = 1
  if (maxCandleSize !== 0 && employs !== 0) {
    while (state.late < whoLateMax + 1 || state.earlyLeave < whoLeaveMax + 1) {
      if (state.late < whoLateMax) {
        state.late += step
      }
      if (state.earlyLeave < whoLeaveMax) {
        state.earlyLeave += step
      }
      await delay(1)
    }
    state.late = whoLateMax + 1
    state.earlyLeave = whoLeaveMax + 1
  }
}
</script>

<style scoped>
#who-late, #candele-late {
  background-color: var(--primary-font-color-error);
}

#who-leave, #candele-early-leave {
  background-color: var(--primary-font-color-error-warn);
}

.who-late {
  display: flex;
  flex-direction: column;
}

.who-late-candeles-body {
  display: flex;
  flex-direction: column;
  height: -webkit-fill-available;
}

.candeles-body {
  display: flex;
  height: 100%;
  align-items: flex-end;
  gap: 1.04vw;
}

.candele {
  width: 2.86vw;
  display: flex;
  flex-direction: column;
  gap: 0.3vw;
}

.candele-info {
  font: 1.04vw 'RedHatText-SemiBold', 'OpenSans', sans-serif;
  color: var(--primary-font-color);
  align-self: center;
}

#candele-late, #candele-early-leave {
  border-radius: 0.42vw;
}

.who-late-spinner {
  margin-top: 2rem;
}
@media screen and (max-width: 850px) {
  .candeles-body {
    gap: 5.09vw;
  }
  .candele {
    width: 13.99vw;
    gap: 1.27vw;
  }
  #candele-late, #candele-early-leave {
    border-radius: 2.04vw;
  }
  .candele-info {
    font-size: 5.09vw;
  }
}
</style>
