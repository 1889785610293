import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue"

import { reactive, onUnmounted, onBeforeMount } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'UPopupMessage',
  props: {
  text: {
    type: String,
    required: true
  }
},
  emits: ['closed'],
  setup(__props, { emit: __emit }) {

const state = reactive({
  isVisible: false,
  timer: null as ReturnType<typeof setTimeout> | null
})

const emits = __emit

const props = __props

onBeforeMount(() => {
  showPopup()
  state.isVisible = true
})

onUnmounted(() => {
  if (state.timer) clearTimeout(state.timer)
})

function showPopup () {
  state.isVisible = true
  clearTimeout(state.timer as ReturnType<typeof setTimeout>)
  state.timer = setTimeout(() => {
    state.isVisible = false
    emits('closed')
  }, 3000)
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (state.isVisible)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "popup",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (emits('closed')))
            }, [
              _createElementVNode("span", null, _toDisplayString(props.text), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}
}

})