import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "udialog-overlay"
}
const _hoisted_2 = { class: "universal-wrapper" }
const _hoisted_3 = { class: "add-person-header" }
const _hoisted_4 = { class: "add-person-header-text" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "universal-body" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "button-click universal-row-check" }
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "photo-result" }

import { PropType, reactive, onBeforeMount } from 'vue'
import { Universal } from '@/models'
import checkActive from '@/assets/icon/check-box/active.svg'
import checkNonActive from '@/assets/icon/check-box/not-active.svg'
import cancel from '@/assets/icon/cancel-dialog.svg'
import { cloneDeep } from 'lodash'

export interface UniversalResult {
  added: string[],
  removed: string[]
}


export default /*@__PURE__*/_defineComponent({
  __name: 'UUniversalSelector',
  props: {
  type: {
    type: String,
    required: true
  },
  dataArray: {
    type: Array as PropType<Universal[]>,
    required: true
  },
  activeArray: {
    type: Array as PropType<string[]>,
    required: true
  }
},
  emits: ['dismiss', 'save'],
  setup(__props, { emit: __emit }) {

const props = __props

const emits = __emit

const state = reactive({
  added: [] as string[],
  removed: [] as string[]
})

onBeforeMount(() => {
  state.added = cloneDeep(props.activeArray)
})

function addOrRemove (id: string) {
  if (state.added.includes(id)) {
    state.added = state.added.filter(item => item !== id)
    if (props.activeArray.includes(id)) {
      state.removed.push(id)
    }
  } else {
    if (state.removed.includes(id)) {
      state.removed = state.removed.filter(item => item !== id)
    }
    state.added.push(id)
  }
}

function isChoosen (id: string) {
  return state.added.includes(id) && !state.removed.includes(id)
}

function save () {
  for (const addId of props.activeArray) {
    if (state.added.includes(addId)) {
      state.added = state.added.filter(item => item !== addId)
    }
  }
  emits('save', { added: state.added, removed: state.removed })
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Transition, { name: "udialog-fade" }, {
    default: _withCtx(() => [
      true
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("span", _hoisted_4, _toDisplayString(`Выберите ${props.type === 'departments' ? 'отдел' : 'должность'}`), 1),
                _createElementVNode("img", {
                  src: _unref(cancel),
                  class: "button-click",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (emits('dismiss')))
                }, null, 8, _hoisted_5)
              ]),
              _createElementVNode("div", _hoisted_6, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.dataArray, (item) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "universal-row button-click",
                    onClick: ($event: any) => (addOrRemove(item.id)),
                    key: item.id
                  }, [
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("img", {
                        src: isChoosen(item.id) ? _unref(checkActive) : _unref(checkNonActive)
                      }, null, 8, _hoisted_9)
                    ]),
                    _createElementVNode("span", null, _toDisplayString(item.name), 1)
                  ], 8, _hoisted_7))
                }), 128))
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", {
                  class: "photo-result-btn button-click",
                  id: "ok-btn",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (emits('save')))
                }, [
                  _createElementVNode("span", {
                    class: "photo-result-btn-text",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (save()))
                  }, " Сохранить ")
                ]),
                _createElementVNode("div", {
                  class: "photo-result-btn button-click",
                  id: "cancel-btn",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (emits('dismiss')))
                }, _cache[4] || (_cache[4] = [
                  _createElementVNode("span", { class: "photo-result-btn-text" }, "Отмена", -1)
                ]))
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})