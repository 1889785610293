import { defineStore } from 'pinia'
import { AxiosError } from 'axios'
import { axiosMain } from '@/axios-setup'
import { Organization, Departments, DepartmentsMap, FeeResult } from '@/models'

export const useOrganazationStore = defineStore('organization', {
  state: () => ({
    organization: {} as Organization,
    departments: [] as Departments
  }),
  actions: {
    async getOrganizationAction (orgId: string) {
      try {
        const response = await axiosMain.get<Organization>(`/Org/${orgId}`)
        this.organization = response.data
      } catch (error) {
        const axiosError = error as AxiosError
        console.error(axiosError.message)
      }
    },
    async getDepartmentsAction (orgId: string) {
      try {
        const response = await axiosMain.get<DepartmentsMap>(`/Dep/${orgId}`)
        this.departments = response.data.departments
      } catch (error) {
        const axiosError = error as AxiosError
        console.error(axiosError.message)
      }
    },
    async changePassword (orgId: string, password: string) {
      try {
        const response = await axiosMain.patch<FeeResult>(`/Org/${orgId}`, {
          params: {
            password
          }
        })
        return response.data
      } catch (error) {
        const axiosError = error as AxiosError
        console.error(axiosError.message)
      }
    }
  },
  getters: {
    getOrganization: (state) => state.organization,
    getDepartments: (state) => state.departments
  }
})
