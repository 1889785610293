<template>
    <transition name="udialog-fade">
      <div class="udialog-overlay" v-if="true">
        <div class="udialog">
          <img v-if="props.showButtons"
            :src="cancel"
            class="udialog-cancel-dialog button-click"
            @click="emits('click-res', 'cancel')"
          >
          <span class="udialog-header">{{ props.header }}</span>
          <div class="udialog-body" v-html="props.msgText"></div>
          <div class="udialog-buttons"
            v-if="props.showButtons && !props.isLoadind"
          >
            <button @click="emits('click-res', 'ok')" class="ures-btn button-click" id="ok-btn">Ок</button>
            <button v-if="props.cancelButton" @click="emits('click-res', 'cancel')" class="ures-btn button-click" id="cancel-btn">Отмена</button>
          </div>
          <u-spinner v-if="props.isLoadind"/>
        </div>
      </div>
    </transition>
</template>

<script setup lang="ts">
import cancel from '@/assets/icon/cancel-dialog.svg'

const props = defineProps({
  header: {
    type: String,
    required: true
  },
  msgText: {
    type: String,
    default: ''
  },
  cancelButton: {
    type: Boolean,
    default: false
  },
  showButtons: {
    type: Boolean,
    default: true
  },
  isLoadind: {
    type: Boolean,
    default: false
  }
})

const emits = defineEmits(['click-res'])

</script>

<style scoped>
.udialog-overlay {
  position: fixed;
  z-index: 3000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}

.udialog {
  width: 20.5rem;
  background-image: linear-gradient(245.9deg, #2D4B6B -40.66%, #042447 131.18%);
  padding: 1.5rem 2rem;
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.udialog-buttons {
  display: flex;
  flex-direction: column;
  margin-top: 5vh;
  align-items: center;
  gap: 0.8rem;
}

.ures-btn {
  width: 20rem;
  font: 0.9rem 'RedHatText-Bold', sans-serif;
  color: var(--primary-font-color);
  padding: 0.8rem;
  border-radius: 5vw;
  border: none;
}

#ok-btn {
  background-color: #042447;
  color: var(--primary-font-color);
}

#cancel-btn {
  background-color: #FFFFFF1A;
  color: var(--primary-font-color-a70);
}

.udialog-header {
  font: 1.35rem 'RedHatText-Bold', sans-serif;
  color: var(--primary-font-color);
  margin-bottom: 1rem;
}

.udialog-body {
  font: 0.8rem 'RedHatText-Regular', sans-serif;
  color: var(--primary-font-color);
}

.udialog-cancel-dialog {
  align-self: end;
}

@media screen and (max-width: 768px) {
  .udialog {
  width: 75vw;
  background-color: white;
  padding: 20px;
  border-radius: 8vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.udialog-buttons {
  display: flex;
  margin-top: 5vh;
  align-items: center;
  gap: 2vh;
}

.ures-btn {
  min-width: 30vw;
  font-family: 'RedHatText-SemiBold', 'OpenSans', sans-serif;
  font-size: 0.9em;
  color: #fff;
  padding: 2vw 3vw;
  border-radius: 5vw;
  width: fit-content;
  border: none;
}

.udialog-header {
  font-family: 'RedHatText-SemiBold', 'OpenSans', sans-serif;
  font-size: 1em;
  margin-bottom: 2vh;
}

.udialog-body {
  font-family: 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
  font-size: 0.8em;
}
}
</style>
