<template>
    <div class="auth-wrapper">
      <div class="auth-form">
        <span class="auth-form-header">Авторизация</span>
        <u-phone-number @change-country="changeCountry" @input-phone="inputPhone"/>
        <div class="pass-input inputs">
          <input type="password" v-model="state.password" placeholder="Пароль">
        </div>
        <u-button class="auth-btn button-click" @click="auth" :btnText="'Авторизация'" />
        <div class="auth-texts">
          <div>
            <span class="auth-text-act">Нет аккаунта? </span>
            <span class="auth-text-act-btn button-click"
              @click="emits('to-registration')"
            >
              Зарегистрироваться
            </span>
          </div>
          <span class="auth-text-act-btn button-click"
            @click="emits('to-pass-recovery')">
            Забыли пароль?
          </span>
        </div>
      </div>
      <div class="logo">
        <img :src="logo">
      </div>
    </div>
</template>

<script setup lang="ts">
import { reactive } from 'vue'
import { useRouter } from 'vue-router'
import { Organization, Country } from '@/models'
import { useOrganizationStore } from '@/stores/auth'
import { useCookesStore } from '@/stores/cookies'
import logo from '@/assets/images/logo.svg'

const authStore = useOrganizationStore()
const cookies = useCookesStore()
const router = useRouter()

const state = reactive({
  password: '',
  organization: {} as Organization | undefined | null,
  country: {} as Country,
  login: ''
})

const emits = defineEmits(['open-dialog', 'close-dialog', 'to-registration', 'to-pass-recovery'])

async function auth () {
  if (state.login.length === 0) {
    emits('open-dialog', { headerText: 'Ошибка', message: 'Логин не может быть пустым' })
    return
  } else if (state.login.length !== (state.country.country === 'kgz' ? 9 : 10)) {
    emits('open-dialog', { headerText: 'Ошибка', message: 'Логин введен не верно' })
    return
  }
  if (state.password.length === 0) {
    emits('open-dialog', { headerText: 'Ошибка', message: 'Пароль не может быть пустым' })
    return
  }
  emits('open-dialog', { headerText: 'Загрузка', message: 'Проверяем логин и пароль<br>Пожалуйста, подождите!', showButtons: false, loading: true })
  await authStore.checkNumberAction(state.country.code + state.login)
  const result = authStore.getCheckNumberResult
  if (!result || result?.status !== 'registered') {
    emits('open-dialog', { headerText: 'Ошибка', message: result?.message ?? 'Упс! Что-то пошло не так!' })
    return
  }
  const type = await authStore.sendAuthRequest(state.country.code + state.login, state.password)
  if (!type || type === 'error') {
    const result = authStore.getCheckNumberResult
    emits('open-dialog', { headerText: 'Ошибка', message: result?.status === 'unauthorized' ? 'Неверный пароль' : 'Упс! Что-то пошло не так!' })
    return
  } else {
    state.organization = authStore.getOrganization
  }

  if (state.organization?.orgId && state.organization?.orgId !== '') {
    const currentDate = new Date().getTime()
    await cookies.setCookie({
      token: state.organization?.orgId,
      orgId: state.organization?.orgId,
      expTime: currentDate + (24 * 60 * 60 * 1000)
    })
    router.push({ name: 'main' })
  } else {
    emits('close-dialog')
    emits('open-dialog', { headerText: 'Ошибка', message: 'Упс! Что-то пошло не так!' })
  }
}

function changeCountry (country: Country) {
  state.country = country
}

function inputPhone (login: string) {
  state.login = login
}
</script>
