import { defineComponent as _defineComponent } from 'vue'
import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "circle button-click" }
const _hoisted_2 = ["src"]

import { reactive } from 'vue'
import more from '@/assets/icon/more.svg'


export default /*@__PURE__*/_defineComponent({
  __name: 'UIconRounded',
  props: {
  type: {
    type: String,
    default: '',
    validator: (value: string) => ['more'].includes(value)
  },
  iconWidth: {
    type: Number,
    default: 0
  },
  iconUnit: {
    type: String,
    default: ''
  },
  iconMnTp: {
    type: Number,
    default: 0
  },
  iconMnBt: {
    type: Number,
    default: 0
  },
  iconMnLt: {
    type: Number,
    default: 0
  },
  iconMnRt: {
    type: Number,
    default: 0
  },
  iconMnUnit: {
    type: String,
    default: 'rem'
  }
},
  setup(__props) {

const props = __props

const iconsMap: Record<typeof props.type, string> = {
  more
}

const state = reactive({
  icon: iconsMap[props.type] || '',
  activeColor: ''
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      style: _normalizeStyle({ width: props.iconWidth + props.iconUnit,
                       marginTop: props.iconMnTp + props.iconMnUnit,
                       marginRight: props.iconMnRt + props.iconMnUnit,
                       marginBottom: props.iconMnBt + props.iconMnUnit,
                       marginLeft: props.iconMnLt + props.iconMnUnit }),
      src: state.icon,
      class: "icon"
    }, null, 12, _hoisted_2)
  ]))
}
}

})