import { defineStore } from 'pinia'
import { AxiosError } from 'axios'
import { axiosMain } from '@/axios-setup'
import { FeedbackResponse } from '@/models'

export const useFeedbackStore = defineStore('feedback', {
  state: () => ({
    feedbackResponse: {} as FeedbackResponse
  }),
  actions: {
    async sendFeedback (activeForm: string, orgId: string, message: string) {
      try {
        const response = await axiosMain.post<FeedbackResponse>(`/feedback/${orgId}`, {
          activeForm,
          orgId,
          message
        })
        this.feedbackResponse = response.data
      } catch (error) {
        const axiosError = error as AxiosError
        console.error(axiosError.message)
      }
    }
  },
  getters: {
    getFeedbackResponse: (state) => state.feedbackResponse
  }
})
