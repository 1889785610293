<template>
  <transition name="udialog-fade">
    <div class="udialog-overlay" v-if="true">
      <div class="u-filter-view-wrapper">
        <div class="u-filter-header">
          <span class="add-universal-header-text">
            Фильтры
          </span>
          <img :src="cancel" class="button-click" @click="emits('dismiss')">
        </div>
        <div class="u-filter-view-body">
          <u-drop-filters :label="'Отдел'"
            :type="'departments'"
            :universalVars="state.departments"
            :choosenIds="state.filters.departments"
            @filter-act="changeFilterData"
          />
          <u-drop-filters :label="'Должность'"
            :type="'positions'"
            :universalVars="state.positions"
            :choosenIds="state.filters.positions"
            @filter-act="changeFilterData"
          />
          <u-drop-filters :label="'Сотрудник'"
            :type="'persons'"
            :personsVars="state.persons"
            :choosenIds="state.filters.persons"
            :images="state.images"
            @filter-act="changeFilterData"
          />
        </div>
        <div class="u-filter-actions">
          <div class="u-filter-actions-btn button-click"
            id="ok-btn" @click="confirmFilters"
          >
            <span class="u-filter-actions-btn-text">Применить</span>
          </div>
          <div class="u-filter-actions-btn button-click" id="cancel-btn"
            @click="emits('dismiss')"
          >
            <span class="u-filter-actions-btn-text">Отмена</span>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
import { reactive, onBeforeMount, watch } from 'vue'
import { Photo } from '@/classes'
import cancel from '@/assets/icon/cancel-dialog.svg'
import { Employee, Organization, Universal } from '@/models'
import { ImagesState } from '@/components/widgets/UChoosePersons.vue'
import { useImagesYarosStore } from '@/stores/imagesYaros'

const emits = defineEmits(['dismiss', 'confirm'])
const imagesStore = useImagesYarosStore()

export interface FiltersData {
  departments: string[],
  positions: string[],
  persons: string[]
}

const state = reactive({
  positions: [] as Universal[],
  departments: [] as Universal[],
  organization: {} as Organization,
  persons: [] as Employee[],
  filters: {} as FiltersData,
  images: reactive({} as ImagesState)
})

onBeforeMount(() => {
  state.organization = JSON.parse(sessionStorage.getItem('organization') || '{}')
  state.departments = JSON.parse(sessionStorage.getItem('departments') || '[]')
  state.positions = JSON.parse(sessionStorage.getItem('positions') || '[]').positions
  state.persons = JSON.parse(sessionStorage.getItem('persons') || '[]')
  state.filters.departments = state.departments.map(item => item.id)
  state.filters.positions = state.positions.map(item => item.id)
  state.filters.persons = state.persons.map(item => item.id)
})

watch(() => state.persons, (newValue, oldValue) => {
  if (newValue !== oldValue && newValue.length > 0) {
    loadingImages()
  }
}, { deep: true })

function confirmFilters () {
  emits('confirm', state.filters)
  emits('dismiss')
}

function changeFilterData (args: Record<string, Array<string>>) {
  try {
    // @ts-expect-error - here is nothing to see
    state.filters[args.type as keyof typeof state.filters] = args.arr
  } catch {
  }
}

async function loadingImages () {
  for (const person of state.persons) {
    if (person.photo && !(person.photo in state.images)) {
      let photo = new Photo()
      const photoId = person.photo
      try {
        const cachedPhoto = sessionStorage.getItem(person.photo)
        if (cachedPhoto) {
          photo = new Photo(cachedPhoto, false, false)
        } else {
          const result = await imagesStore.getImage(state.organization.fixStorage, photoId)
          if (result) {
            sessionStorage.setItem(person.photo, result)
            photo = new Photo(result, false, false)
          }
        }
      } catch (error) {
        console.error(`Failed to load image ${photoId}:`, error)
        photo = new Photo('', false, true)
      } finally {
        state.images = { ...state.images, ...{ [photoId]: photo } }
      }
    }
  }
}
</script>

<style>
.u-filter-view-wrapper {
    display: flex;
    flex-direction: column;
    width: 20.834vw;
    background-image: linear-gradient(245.9deg, #2D4B6B -40.66%, #042447 131.18%);
    padding: 3.71vh 2.344vw;
    border-radius: 3.71vh;
}

.u-filter-view-body {
    display: flex;
    flex-direction: column;
    gap: 2.315vh;
}

.u-filter-header {
  width: -webkit-fill-available;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font: 700 2.04vh 'RedHatText-Regular', 'OpenSans', sans-serif;
  color: var(--primary-font-color);
  margin-bottom: 2.778vh;
}

.u-filter-actions {
  display: flex;
  gap: 0.799vw;
  margin-top: 4.629vh;
}

.u-filter-actions-btn-text {
  font: 700 1.49vh 'RedHatText-Regular', 'OpenSans', sans-serif;
  color: var(--primary-font-color);
}

.u-filter-actions-btn {
  width: 8.3334vw;
  height: 4.7223vh;
  border-radius: 5vw;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

#ok-btn {
  background-color: #042447;
}

#cancel-btn {
  background-color: #FFFFFF1A;
}
</style>
