import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "auth-wrapper" }
const _hoisted_2 = { class: "auth-form" }
const _hoisted_3 = { class: "sms-info" }
const _hoisted_4 = { class: "sms-info-number" }
const _hoisted_5 = { class: "input-group" }
const _hoisted_6 = { class: "auth-texts" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "auth-text-act" }

import { reactive, nextTick, ref, onBeforeMount } from 'vue'
import { OrgReg, Organization } from '@/models'
import { useCookesStore } from '@/stores/cookies'
import { useRouter } from 'vue-router'
import { useRegistrationStore } from '@/stores/registration'
import { OrganizationClass } from '@/objects'
import { RegistrationParams } from '@/views/AuthView.vue'
import { delay } from '@/globalFunctions'
import { useSMSStore } from '@/stores/sms'
import { useOrganazationStore } from '@/stores/organization'


export default /*@__PURE__*/_defineComponent({
  __name: 'UCheckSMS',
  props: {
  registrationInfo: {
    type: Object as () => RegistrationParams,
    required: true
  }
},
  emits: ['open-dialog', 'close-dialog', 'to-registration'],
  setup(__props, { emit: __emit }) {

const regStore = useRegistrationStore()
const smsStore = useSMSStore()

const props = __props

const state = reactive({
  smsInp: '',
  firstNum: '',
  secondNum: '',
  thirdNum: '',
  fourthNum: '',
  organizationResp: {} as OrgReg,
  lasttimeSMSSend: null,
  secondsLeft: 0,
  organization: {} as Organization
})

const emits = __emit
const cookies = useCookesStore()
const router = useRouter()
const organization = useOrganazationStore()

const inputRef1 = ref<HTMLInputElement | null>(null)
const inputRef2 = ref<HTMLInputElement | null>(null)
const inputRef3 = ref<HTMLInputElement | null>(null)
const inputRef4 = ref<HTMLInputElement | null>(null)

onBeforeMount(() => {
  const lasttimeSMS = localStorage.getItem('lasttimeSMSSend')
  const now = Math.floor(Date.now() / 1000)
  const dateDiff = now - Number(lasttimeSMS)
  if (lasttimeSMS && dateDiff <= 60) {
    state.secondsLeft = 60 - dateDiff
    counter()
  }
  inputRef1.value?.focus()
})

async function counter () {
  if (state.secondsLeft > 0) {
    state.secondsLeft -= 1
    await delay(1000)
    counter()
  }
}

function changeFocus (nextInput: number) {
  nextTick(() => {
    if (nextInput === 2 && state.firstNum.length > 0) {
      inputRef2.value?.focus()
    } else if (nextInput === 3 && state.secondNum.length > 0) {
      inputRef3.value?.focus()
    } else if (nextInput === 4 && state.thirdNum.length > 0) {
      inputRef4.value?.focus()
    } else if (nextInput === 0 && state.fourthNum.length > 0) {
      inputRef4.value?.blur()
    }
  })
}

async function confirmSMS () {
  state.smsInp = `${state.firstNum}${state.secondNum}${state.thirdNum}${state.fourthNum}`
  state.smsInp = state.smsInp.replace(' ', '')
  if (state.smsInp.length < 4) {
    emits('open-dialog', { headerText: 'Ошибка', message: 'Введите 4 цифры из СМС.' })
    return
  }

  const res = await smsStore.checkSMS(props.registrationInfo.login, state.smsInp)

  if (!res || res.status !== 'confirmed') {
    emits('open-dialog', { headerText: 'Ошибка', message: 'Неверный код.' })
    state.smsInp = ''
    state.firstNum = ''
    state.secondNum = ''
    state.thirdNum = ''
    state.fourthNum = ''
    inputRef1.value?.focus()
  } else {
    emits('open-dialog',
      {
        headerText: 'Регистрируем',
        message: 'Пожалуйста, подождите!',
        showButtons: false,
        loading: true
      })
    state.organizationResp = await registration()
    if (state.organizationResp.status === 'created') {
      await organization.getOrganizationAction(state.organizationResp.orgId)
      state.organization = organization.getOrganization
      const currentDate = new Date().getTime()
      await cookies.setCookie({
        token: state.organization?.orgId,
        orgId: state.organization?.orgId,
        expTime: currentDate + (24 * 60 * 60 * 1000)
      })
      router.push({ name: 'main' })
    } else {
      emits('close-dialog')
      emits('open-dialog', { headerText: 'Ошибка', message: state.organizationResp.message })
    }
  }
}

async function registration () {
  const registrationParams = new OrganizationClass(
    props.registrationInfo.orgName,
    props.registrationInfo.login,
    props.registrationInfo.password,
    state.smsInp
  )
  await regStore.sendRegRequest(registrationParams)
  return regStore.getOrganization
}

function formatPhoneNumber (phone: string): string {
  if (phone.startsWith('996')) {
    return `${phone.substring(0, 3)} (${phone.substring(3, 6)}) ${phone.substring(6, 9)} ${phone.substring(9)}`
  } else if (phone.startsWith('7')) {
    return `${phone.substring(0, 1)} (${phone.substring(1, 4)}) ${phone.substring(4, 7)} ${phone.substring(7)}`
  } else return phone
}

async function sendSMS () {
  localStorage.setItem('lasttimeSMSSend', Math.floor(Date.now() / 1000).toString())
  state.secondsLeft = 60
  counter()
}

return (_ctx: any,_cache: any) => {
  const _component_u_button = _resolveComponent("u-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[11] || (_cache[11] = _createElementVNode("span", { class: "auth-form-header" }, "Подтвержение", -1)),
      _createElementVNode("div", _hoisted_3, [
        _cache[9] || (_cache[9] = _createElementVNode("span", { class: "sms-info-text" }, "Мы отправили код на номер ", -1)),
        _createElementVNode("span", _hoisted_4, _toDisplayString(`+${formatPhoneNumber(props.registrationInfo.login)}`), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _withDirectives(_createElementVNode("input", {
          ref_key: "inputRef1",
          ref: inputRef1,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((state.firstNum) = $event)),
          class: "sms-input",
          maxlength: "1",
          onInput: _cache[1] || (_cache[1] = ($event: any) => (changeFocus(2))),
          id: "sms-input-1"
        }, null, 544), [
          [_vModelText, state.firstNum]
        ]),
        _withDirectives(_createElementVNode("input", {
          ref_key: "inputRef2",
          ref: inputRef2,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((state.secondNum) = $event)),
          class: "sms-input",
          maxlength: "1",
          onInput: _cache[3] || (_cache[3] = ($event: any) => (changeFocus(3)))
        }, null, 544), [
          [_vModelText, state.secondNum]
        ]),
        _withDirectives(_createElementVNode("input", {
          ref_key: "inputRef3",
          ref: inputRef3,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((state.thirdNum) = $event)),
          class: "sms-input",
          maxlength: "1",
          onInput: _cache[5] || (_cache[5] = ($event: any) => (changeFocus(4)))
        }, null, 544), [
          [_vModelText, state.thirdNum]
        ]),
        _withDirectives(_createElementVNode("input", {
          ref_key: "inputRef4",
          ref: inputRef4,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((state.fourthNum) = $event)),
          class: "sms-input",
          maxlength: "1",
          onInput: _cache[7] || (_cache[7] = ($event: any) => (changeFocus(0)))
        }, null, 544), [
          [_vModelText, state.fourthNum]
        ])
      ]),
      _createVNode(_component_u_button, {
        class: "auth-btn button-click",
        onClick: confirmSMS,
        btnText: 'Продолжить'
      }),
      _createElementVNode("div", _hoisted_6, [
        (state.secondsLeft === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _cache[10] || (_cache[10] = _createElementVNode("span", { class: "auth-text-act" }, "Не пришел код? ", -1)),
              _createElementVNode("span", {
                class: "auth-text-act-btn button-click",
                onClick: sendSMS
              }, "Отправить еще раз")
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createElementVNode("span", _hoisted_9, "СМС можно будет отправить повторно через " + _toDisplayString(state.secondsLeft) + " сек", 1)
            ])),
        _createElementVNode("span", {
          class: "auth-text-act sms-info-text button-click",
          onClick: _cache[8] || (_cache[8] = ($event: any) => (emits('to-registration')))
        }, " Вернуться к регистрации ")
      ])
    ])
  ]))
}
}

})