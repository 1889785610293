<template>
    <div v-if="state.dataLoaded" class="widgets-wrapper">
      <u-on-workplace :onPlace="state.onPlaceQty"
        :notOnPlace="state.notOnPlaceQty"
        :total="state.empoloysQty"
        @to-report="emits('to-report', 'onworkplace')"
        :isLoading="!state.dataLoaded"
      />
      <u-who-late :late="whoLateStates.late"
        :earlyLeave="whoLateStates.earlyLeave"
        :employsQty="state.empoloysQty"
        :isLoading="!whoLateStates.isLoaded"
        @to-report="emits('to-report', 'wholate')"
      />
      <u-fines :fineSum="finesStates.fineSum"
        :percent="finesStates.totalPercent"
        :periodPerc = "finesStates.prevMonthSumPeriod"
        :isLoading = !finesStates.isLoaded
        @to-report="emits('to-report', 'finesrep')"
      />
      <div class="last-widget-bottom"></div>
    </div>
</template>

<script setup lang="ts">
import { onBeforeMount, reactive, watch, onMounted } from 'vue'
import { useEmploysStore } from '../stores/employs'
import { Employee, Fix, FeeStatisticMap, Organization } from '@/models'
import { useCookesStore } from '@/stores/cookies'
import {
  getStartOfDayInMilliseconds,
  getEndOfDayInMilliseconds,
  getTimeStampOfCurrentTime,
  getMonthStartEnd,
  getLastMonthTimestamp
} from '@/globalFunctions'

const cookies = useCookesStore()

const employs = useEmploysStore()

const emits = defineEmits(['to-report'])

const state = reactive({
  orgId: '',
  employs: [] as Employee[] | null | undefined,
  empoloysQty: 0,
  notOnPlaceQty: 0,
  dataLoaded: false,
  onWPLoading: true,
  onPlaceQty: 0,
  fixToday: [] as Fix[]
})

const whoLateStates = reactive({
  late: 0,
  earlyLeave: 0,
  isLoaded: false
})

const finesStates = reactive({
  isLoaded: false,
  fineSum: 0,
  prevMonthSum: 0,
  totalPercent: 0,
  prevMonthSumPeriod: 0
})

const props = defineProps({
  reportData: {
    type: Array<Fix>,
    required: true
  },
  fines: {
    type: Array<FeeStatisticMap>,
    required: true
  },
  organization: {
    type: Object as () => Organization,
    required: true
  }
})

onBeforeMount(async () => {
  await cookies.getCookie()
  state.orgId = cookies.getCookies.orgId
  await getEmploysList(state.orgId)
})

onMounted(async () => {
  refreshWhoLateInfo()
})

async function getEmploysList (orgId: string) {
  await employs.getEmploysAction(orgId)
  state.employs = employs.getEmploys
  state.empoloysQty = state.employs.length
}

watch(() => state.dataLoaded, () => {
  state.notOnPlaceQty = state.empoloysQty - state.onPlaceQty
})

watch(() => props.reportData, async (newValue, oldValue) => {
  if (newValue !== oldValue) {
    await getEmploysList(props.organization.orgId)
    await prepareData()
    await refreshWhoLateInfo()
  }
})

watch(() => props.fines, (newValue) => {
  if (newValue.length > 0) {
    prepareDataForFines()
  }
  finesStates.isLoaded = true
})

async function prepareData () {
  state.dataLoaded = false
  const dayBegin = getStartOfDayInMilliseconds()
  const dayEnd = getEndOfDayInMilliseconds()
  state.fixToday = props.reportData.filter(item => item.time >= dayBegin && item.time <= dayEnd)
  whoLateStates.isLoaded = true
  state.onPlaceQty = 0
  for (const per of state.employs ?? []) {
    if (state.fixToday.find(item => item.perId === per.id && item.type === 'arrive') &&
        !state.fixToday.find(item => item.perId === per.id && item.type === 'leave')) {
      state.onPlaceQty += 1
    }
  }
  state.notOnPlaceQty = state.empoloysQty - state.onPlaceQty
  state.dataLoaded = true
}

async function refreshWhoLateInfo () {
  const currTime = getTimeStampOfCurrentTime()
  const now = new Date()
  const whoOnPlace = state.fixToday.filter(item => item.type === 'arrive')
  const whoLeave = state.fixToday.filter(item => item.type === 'leave')
  const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime()
  let lateQty = 0
  let leaveQty = 0
  for (const item of state.employs ?? []) {
    let fitPersTmp = whoOnPlace.find(fix => fix.perId === item.id)
    if ((!fitPersTmp && item.arrivalTime < currTime) ||
      (fitPersTmp && item.arrivalTime + startOfDay > fitPersTmp.time)) {
      lateQty += 1
    }
    fitPersTmp = whoLeave.find(fix => fix.perId === item.id)
    if (fitPersTmp && (item.leaveTime + startOfDay) < fitPersTmp.time) {
      leaveQty += 1
    }
  }
  whoLateStates.late = lateQty
  whoLateStates.earlyLeave = leaveQty
}

function prepareDataForFines () {
  const now = new Date()
  const prevMonthNum = now.getMonth()
  const currMonth = getMonthStartEnd()
  const prevMonthPerDay = getLastMonthTimestamp()
  const prevMonth = getMonthStartEnd(prevMonthNum, prevMonthNum === 12 ? now.getFullYear() - 1 : now.getFullYear())
  finesStates.fineSum = props.fines.filter(fine => fine.date >= currMonth[0] && fine.date <= currMonth[1]).reduce((sum, fine) => sum + fine.amount, 0)
  finesStates.prevMonthSum = props.fines.filter(fine => fine.date >= prevMonth[0] && fine.date <= prevMonth[1]).reduce((sum, fine) => sum + fine.amount, 0)
  finesStates.totalPercent = calcPercent(finesStates.fineSum, finesStates.prevMonthSum)
  const periodSum = props.fines.filter(fine => fine.date >= prevMonth[0] && fine.date <= prevMonthPerDay).reduce((sum, fine) => sum + fine.amount, 0)
  finesStates.prevMonthSumPeriod = calcPercent(finesStates.fineSum, periodSum)
}

function calcPercent (newValue: number, oldValue: number) {
  if (oldValue === 0) {
    return 100
  } else {
    return Number(((newValue - oldValue) / oldValue * 100).toFixed(2))
  }
}
</script>

<style>
.org-info {
  display: flex;
  justify-content: flex-end;
}

.widgets-wrapper {
  display: flex;
  gap: 2.60vw;
  flex-wrap: wrap;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
  max-height: 75vh;
}

@media (max-width: 950px) {
  .widgets-wrapper {
    padding-top: 0;
    max-height: 70vh;
  }

  .last-widget-bottom {
    margin-bottom: 0.3rem;
  }
}
</style>
