import { defineStore } from 'pinia'
import { AxiosError } from 'axios'
import { axiosMain } from '@/axios-setup'
import { OrgReg, CheckSMS } from '@/models'
import { OrganizationClass } from '@/objects'
import { v4 as uuidv4 } from 'uuid'

export const useRegistrationStore = defineStore('registration', {
  state: () => ({
    organizationRes: {} as OrgReg
  }),
  actions: {
    async sendRegRequest (organization: OrganizationClass) {
      try {
        const orgId = uuidv4()
        const response = await axiosMain.post<CheckSMS>(`/Org/${orgId}`, {
          name: organization.name,
          phoneNumber: organization.phoneNumber,
          confirmationCodeBySMS: organization.confirmationCode,
          timezone: organization.timezone,
          password: organization.password,
          email: organization.email,
          logo: organization.logo,
          chatId: organization.chatId,
          timeReport: organization.timeReport,
          firebaseAuthToken: organization.firebaseAuthToken,
          lockPassword: organization.lockPassword
        })
        this.organizationRes = { ...response.data, orgId }
      } catch (error) {
        const axiosError = error as AxiosError
        console.error(axiosError.message)
      }
    }
  },
  getters: {
    getOrganization: (state) => state.organizationRes
  }
})
