import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "button-click"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "choose-person-info" }
const _hoisted_6 = { class: "choose-person-info-name" }
const _hoisted_7 = { class: "choose-person-info-pos" }
const _hoisted_8 = ["src"]

import { PropType } from 'vue'
import noPhoto from '@/assets/images/no_profile.svg'
import { Employee } from '@/models'
import checkActive from '@/assets/icon/check-box/active.svg'
import checkNonActive from '@/assets/icon/check-box/not-active.svg'
import del from '@/assets/icon/del.svg'


export default /*@__PURE__*/_defineComponent({
  __name: 'UPersonRow',
  props: {
  person: {
    type: Object as PropType<Employee>,
    required: true
  },
  posName: {
    type: String,
    required: true
  },
  isChoosen: {
    type: Boolean,
    default: false
  },
  imgSrc: {
    type: String,
    required: true
  },
  isDel: {
    type: Boolean,
    required: false
  }
},
  emits: ['row-click', 'del-person'],
  setup(__props, { emit: __emit }) {

const props = __props

const emits = __emit

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["choose-person-row", { 'always-hover': props.isDel, 'button-click': !props.isDel }]),
    onClick: _cache[1] || (_cache[1] = ($event: any) => (emits('row-click'))),
    style: _normalizeStyle({ 'padding': `0 ${props.isDel ? 0.521 : 0.782}vw`})
  }, [
    (!__props.isDel)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("img", {
            src: props.isChoosen ? _unref(checkActive) : _unref(checkNonActive)
          }, null, 8, _hoisted_2)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["choose-person-item-image", { 'choose-person-item-image-left': !props.isDel }])
    }, [
      (props.imgSrc)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: `data:image/jpeg;base64, ${props.imgSrc}`
          }, null, 8, _hoisted_3))
        : (_openBlock(), _createElementBlock("img", {
            key: 1,
            src: _unref(noPhoto)
          }, null, 8, _hoisted_4))
    ], 2),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("span", _hoisted_6, _toDisplayString(props.person.name), 1),
      _createElementVNode("span", _hoisted_7, _toDisplayString(props.posName), 1)
    ]),
    (props.isDel)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "choose-person-del button-click",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (emits('del-person')))
        }, [
          _createElementVNode("img", { src: _unref(del) }, null, 8, _hoisted_8)
        ]))
      : _createCommentVNode("", true)
  ], 6))
}
}

})