import { defineStore } from 'pinia'
import { AxiosError } from 'axios'
import { axiosMain } from '@/axios-setup'
import { FeeStatistic, FeeStatisticList } from '../models'

export const useFinesStore = defineStore('fines', {
  state: () => ({
    feeStatistic: {} as FeeStatistic,
    feeStatisticList: [] as FeeStatisticList
  }),
  actions: {
    async getFeeAction (orgId: string,
      dateFrom: number,
      dateTo: number) {
      try {
        const response = await axiosMain.get<FeeStatistic>(`/feeStatistic/${orgId}`, {
          params: {
            dateFrom,
            dateTo
          }
        })
        this.feeStatistic = response.data
        this.feeStatisticList = this.feeStatistic.fee
      } catch (error) {
        const axiosError = error as AxiosError
        console.error(axiosError.message)
      }
    }
  },
  getters: {
    getFeeStatisticList: (state) => state.feeStatisticList
  }
})
