<template>
    <div class="auth-wrapper">
      <div class="auth-form">
        <span class="auth-form-header">Регистрация</span>
        <div class="pass-input inputs">
          <input type="text" v-model="state.orgName" placeholder="Название организации">
        </div>
        <u-phone-number @change-country="changeCountry" @input-phone="inputPhone"/>
        <div class="pass-input inputs">
          <input type="password" v-model="state.password" placeholder="Пароль">
        </div>
        <u-button class="auth-btn button-click" @click="registration" :btnText="'Зарегистрироваться'" />
        <div class="auth-texts">
          <div>
            <span class="auth-text-act">Есть аккаунт? </span>
            <span class="auth-text-act-btn button-click" @click="emits('login')">Войти</span>
          </div>
        </div>
      </div>
      <div class="logo">
        <img :src="logo">
      </div>
    </div>
</template>

<script setup lang="ts">
import { reactive } from 'vue'
import { Country } from '@/models'
import logo from '@/assets/images/logo.svg'
import { useSMSStore } from '@/stores/sms'
import { countriesList } from '@/objects'

const smsStore = useSMSStore()

const state = reactive({
  orgName: '',
  password: '',
  country: countriesList[0],
  login: '',
  sms: ''
})

const emits = defineEmits(['login', 'to-check-number', 'open-dialog', 'close-dialog'])

async function registration () {
  if (!state.orgName || state.orgName === '') {
    emits('open-dialog', { headerText: 'Ошибка', message: 'Введите название организации.' })
    return
  }
  if (!state.login || state.login.length < 9) {
    emits('open-dialog', { headerText: 'Ошибка', message: 'Введите Ваш номер телефона.' })
    return
  }
  if (!state.password || state.password.length < 6) {
    emits('open-dialog', { headerText: 'Ошибка', message: 'Пароль не может быть короче 6-ти символов.' })
    return
  }
  const reqBody = {
    sender: process.env.VUE_APP_SMS_SENDER,
    text: '',
    phone_number: [`${state.country.code}${state.login}`],
    secret_code_generate: true,
    is_test: false
  }

  emits('open-dialog', { headerText: 'Отправляем СМС', message: 'Пожалуйста, подождите!', showButtons: false, loading: true })
  await smsStore.sendSMSRequest(reqBody)
  const smsResponse = smsStore.getSMSResponse
  emits('close-dialog')

  if (smsResponse?.status === 'success') {
    localStorage.setItem('lasttimeSMSSend', Math.floor(Date.now() / 1000).toString())
    emits('to-check-number', {
      orgName: state.orgName,
      login: `${state.country.code}${state.login}`,
      password: state.password
    })
  } else {
    emits('open-dialog', { headerText: 'Ошибка', message: smsResponse?.error_msg })
  }
}

function changeCountry (country: Country) {
  state.country = country
}

function inputPhone (login: string) {
  state.login = login
}
</script>
