<template>
  <transition name="udialog-fade">
      <div class="udialog-overlay" v-if="true">
          <div class="photo-result-wrapper">
            <div class="add-person-header">
              <span class="add-person-header-text">
                Сохранить изображение?
              </span>
              <img :src="cancel" class="button-click" @click="emits('dismiss')">
            </div>
            <img class="photo-result-base64"
              :src="props.photo"
            >
            <div class="photo-result">
              <div class="photo-result-btn button-click"
                id="ok-btn"
                @click="emits('save')"
              >
                <span class="photo-result-btn-text">Сохранить</span>
              </div>
              <div class="photo-result-btn button-click" id="cancel-btn"
                @click="emits('dismiss')"
              >
                <span class="photo-result-btn-text">Отмена</span>
              </div>
            </div>
          </div>
      </div>
  </transition>
</template>

<script setup lang="ts">
import cancel from '@/assets/icon/cancel-dialog.svg'

const emits = defineEmits(['dismiss', 'save'])

const props = defineProps({
  photo: {
    type: String,
    required: true
  }
})
</script>

<style>
.photo-result {
  display: flex;
  gap: 0.799vw;
  margin-top: 4.629vh;
}

.photo-result-btn-text {
  font: 700 1.49vh 'RedHatText-Regular', 'OpenSans', sans-serif;
  color: var(--primary-font-color);
}

#ok-btn {
  background-color: #042447;
}

#cancel-btn {
  background-color: #FFFFFF1A;
}

.photo-result-btn {
  width: 8.3334vw;
  height: 4.7223vh;
  border-radius: 5vw;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.photo-result-wrapper {
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(245.9deg, #2D4B6B -40.66%, #042447 131.18%);
  padding: 3.71vh 2.345vw;
  border-radius: 3.71vh;
  align-items: center;
  width: 20.831vw;
}

.photo-result-base64 {
  width: 13.889vh;
  height: 13.889vh;
  object-fit: cover;
  border-radius: 50%;
}
</style>
