import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, resolveDirective as _resolveDirective } from "vue"

const _hoisted_1 = { class: "u-dropdown-filters-wrapper" }
const _hoisted_2 = { class: "u-dropdown-filters-body" }
const _hoisted_3 = { class: "u-dropdown-filters-label" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "u-dropdown-universal-wrapper" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["src"]
const _hoisted_8 = {
  key: 0,
  class: "u-dropdown-universal-row-img"
}
const _hoisted_9 = ["src"]
const _hoisted_10 = ["src"]

import { reactive, PropType, watch } from 'vue'
import { Universal, Employee } from '@/models'
import back from '@/assets/icon/back.svg'
import checkActive from '@/assets/icon/check-box/active.svg'
import checkNonActive from '@/assets/icon/check-box/not-active.svg'
import noPhoto from '@/assets/images/no_profile.svg'
import { ImagesState } from '@/components/widgets/UChoosePersons.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'UDropFilters',
  props: {
  label: {
    type: String,
    required: true
  },
  type: {
    type: String,
    required: true
  },
  universalVars: {
    type: Array as PropType<Universal[]>,
    default: () => []
  },
  personsVars: {
    type: Array as PropType<Employee[]>,
    default: () => []
  },
  choosenIds: {
    type: Array as PropType<string[]>,
    required: true
  },
  images: {
    type: Object as PropType<ImagesState>,
    default: () => ({})
  }
},
  emits: ['filter-act'],
  setup(__props, { emit: __emit }) {

const props = __props

const state = reactive({
  popupIsOpen: false,
  choosenList: props.choosenIds,
  images: reactive({} as ImagesState)
})

const emits = __emit

watch(() => props.images, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    state.images = newValue
  }
})

watch(() => state.choosenList, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    emits('filter-act', { type: props.type, arr: newValue })
  }
})

function clickOutside () {
  if (state.popupIsOpen) {
    state.popupIsOpen = false
  }
}

function addOrRemove (id: string) {
  if (state.choosenList.includes(id)) {
    state.choosenList = state.choosenList.filter(item => item !== id)
  } else {
    state.choosenList.push(id)
  }
  emits('filter-act')
}

function filterString () {
  let resultString = 'Все'
  const tmpArr = props.type === 'persons' ? props.personsVars : props.universalVars
  if (state.choosenList.length === tmpArr.length) {
    return resultString
  } else if (state.choosenList.length === 0) {
    return 'Не выбрано'
  } else {
    resultString = ''
    for (const id of state.choosenList) {
      resultString += `${tmpArr.find(item => item.id === id)?.name}, `
    }
    return resultString.trim().slice(0, -1)
  }
}

return (_ctx: any,_cache: any) => {
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(props.label), 1),
      _createElementVNode("div", {
        class: "u-dropdown-filter",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (state.popupIsOpen = !state.popupIsOpen))
      }, [
        _createElementVNode("span", null, _toDisplayString(filterString()), 1),
        _createElementVNode("img", {
          class: "u-dropdown-icon",
          src: _unref(back)
        }, null, 8, _hoisted_4)
      ])
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.type === 'persons' ? props.personsVars : props.universalVars, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "u-dropdown-universal-row button-click",
          key: item.id,
          onClick: ($event: any) => (addOrRemove(item.id))
        }, [
          _createElementVNode("img", {
            src: state.choosenList.includes(item.id) ?
                    _unref(checkActive) : _unref(checkNonActive)
          }, null, 8, _hoisted_7),
          (props.type === 'persons')
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                (!state.images[(item as Employee).photo]?.imageSrc || state.images[(item as Employee).photo]?.imageSrc === '')
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      src: _unref(noPhoto)
                    }, null, 8, _hoisted_9))
                  : (_openBlock(), _createElementBlock("img", {
                      key: 1,
                      src: `data:image/jpeg;base64, ${state.images[(item as Employee).photo]!.imageSrc}`
                    }, null, 8, _hoisted_10))
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("span", null, _toDisplayString(item.name), 1)
        ], 8, _hoisted_6))
      }), 128))
    ], 512), [
      [_vShow, state.popupIsOpen]
    ])
  ])), [
    [
      _directive_click_outside,
      clickOutside,
      void 0,
      { stop: true }
    ]
  ])
}
}

})