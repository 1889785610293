<template>
    <div class="choose-person-row"
        @click="emits('row-click')"
        :style="{ 'padding': `0 ${props.isDel ? 0.521 : 0.782}vw`}"
        :class="{ 'always-hover': props.isDel, 'button-click': !props.isDel }"
    >
        <div class="button-click" v-if="!isDel">
            <img :src="props.isChoosen ? checkActive : checkNonActive" />
        </div>
        <div class="choose-person-item-image"
            :class="{ 'choose-person-item-image-left': !props.isDel }"
        >
            <img v-if="props.imgSrc"
                :src="`data:image/jpeg;base64, ${props.imgSrc}`"
            >
            <img :src="noPhoto" v-else>
        </div>
        <div class="choose-person-info">
            <span class="choose-person-info-name">{{ props.person.name }}</span>
            <span class="choose-person-info-pos">
                {{ props.posName }}
            </span>
        </div>
        <div v-if="props.isDel" class="choose-person-del button-click"
            @click="emits('del-person')"
        >
            <img :src="del">
        </div>
    </div>
</template>

<script setup lang="ts">
import { PropType } from 'vue'
import noPhoto from '@/assets/images/no_profile.svg'
import { Employee } from '@/models'
import checkActive from '@/assets/icon/check-box/active.svg'
import checkNonActive from '@/assets/icon/check-box/not-active.svg'
import del from '@/assets/icon/del.svg'

const props = defineProps({
  person: {
    type: Object as PropType<Employee>,
    required: true
  },
  posName: {
    type: String,
    required: true
  },
  isChoosen: {
    type: Boolean,
    default: false
  },
  imgSrc: {
    type: String,
    required: true
  },
  isDel: {
    type: Boolean,
    required: false
  }
})

const emits = defineEmits(['row-click', 'del-person'])
</script>

<style>
.choose-person-row {
    display: flex;
    height: 4.7223vh;
    align-items: center;
    width: fit-content;
    border-radius: 9.3vh;
    transition: background-color 0.3s;
}

.choose-person-row:hover {
    background-color: var(--primary-font-color-a10);
}

.always-hover {
    background-color: var(--primary-font-color-a10);
}

.choose-person-item-image {
    width: 3.241vh;
    height: 3.241vh;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.521vw;
}

.choose-person-item-image-left {
    margin-left: 0.7813vw;
}

.choose-person-item-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.choose-person-info {
    display: flex;
    flex-direction: column;
    height: 3.241vh;
    justify-content: space-between;
}

.choose-person-info-name {
    font: 500 1.3889vh 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
    color: var(--primary-font-color);
}

.choose-person-info-pos {
    font: 400 0.925vh 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
    color: var(--primary-font-color-a70);
}

.choose-person-del {
    margin-left: 0.782vw;
}
</style>
