<template>
    <div class="mainpage-wrapper">
        <div class="side-menu" v-if="!isMobile">
            <div class="upside-menu">
                <div class="tabs">
                  <img :src="logo" class="logo-img button-click"
                    @click="state.tab = 'dashboard'"
                  />
                  <u-icon-button class="tab" :type="'dashboard'"
                    @icon-btn-clicked="changeTab('dashboard')"
                    :isActive="state.tab === 'dashboard'"
                  />
                  <u-icon-button class="tab" :type="'reports'"
                    @icon-btn-clicked="changeTab('reports')"
                    :isActive="state.tab === 'reports' || state.tab.includes('@report-')"
                  />
                  <u-icon-button class="tab" :type="'organization'"
                    @icon-btn-clicked="changeTab('organization')"
                    :isActive="state.tab === 'organization'"
                  />
                  <u-icon-button class="tab" :type="'plans'"
                    @icon-btn-clicked="changeTab('plans')"
                    :isActive="['payments', 'plans', 'pay-steps'].includes(state.tab)"
                  />
                  <u-icon-button class="tab" :type="'settings'"
                    @icon-btn-clicked="changeTab('settings')"
                    :isActive="state.tab === 'settings'"
                  />
                </div>
                <div>
                  <u-icon-button :type="'logOut'" @click="logOut"/>
                </div>
            </div>
        </div>
        <div class="main-delimeter" v-if="!isMobile"></div>
        <div v-if="isMobile" class="mobile-menu" @click="state.showMobMenu = true">
          <img :src="mobileMenu">
          <span>{{ state.tab !== '' ? iconMap[state.tab as keyof typeof iconMap].text : '' }}</span>
        </div>
        <div class="tab-wrapper">
          <div class="main-header" v-show="!['organization', 'settings'].includes(state.tab)">
            <u-payments-header v-if="['pay-steps', 'payments'].includes(state.tab)"
              :currentRout = payInfo.payRout
              @to-prev="toPrev()"
            />
            <u-report-header v-else-if="state.tab.startsWith('@report-')"
              :reportName="state.reportName"
              :persons="state.persons" :departments="state.departments"
              @to-dashboard="state.tab = 'dashboard'"
            />
            <span class="lasttime-data" v-else-if="state.dataUpdLastTime.length > 0">
              {{ `Данные на ${state.dataUpdLastTime}` }}
            </span>
            <div class="main-header-org-info"
              v-show="state.tab.indexOf('report') === -1"
            >
              <u-org-pay-info v-if="!['pay-steps', 'payments'].includes(state.tab)"
                :sum="state.organization?.balance ?? 0"
              />
              <u-org-info v-if="state.organization && Object.keys(state.organization).length > 0"
                class="org-info"
              />
            </div>
          </div>
          <Dashboards v-show="state.tab === 'dashboard'"
            @to-report="toReport"
            :reportData="state.todayFixData"
            :fines="state.fines"
            :organization = "state.organization"
          />
          <ReportView v-if="state.tab === 'reports'"
            @to-report="toReport"
          />
          <OrganizationView v-if="state.tab === 'organization'" />
          <PlansView v-if="state.tab === 'plans'"
            @to-payments="state.tab = 'payments'"
          />
          <PaymentsView v-if="state.tab === 'payments'" />
          <PaySteps v-if="state.tab === 'pay-steps'"
            :type="payInfo.type"
            :summ="payInfo.summ"
            @to-prev="state.tab = 'payments'"
          />
          <Settings v-if="state.tab === 'settings'" />
          <state.report v-if="state.tab.includes('@report')" />
        </div>
        <div class="main-small-menu">
          <img :src="reviewIcon"
            class="main-small-menu-icon button-click"
            @click="state.showReview = true"
          >
        </div>
    </div>
    <transition
      @before-leave="beforeFadeLeave"
      @after-leave="afterFadeLeave"
      name="mobile-menu-fade">
      <div class="mobile-menu-overlay" v-show="state.showMobMenu">
        <div class="mobile-menu-wrapper">
          <div>
          <img :src="mobileMenu" class="mob-menu-icon"
            @click.stop="state.showMobMenu = false">
          <u-icon-button :type="'dashboard'" @icon-btn-clicked="changeTab('dashboard')"
            :isActive="state.tab === 'dashboard'"
            @click.stop="state.showMobMenu = false"
          />
          <u-icon-button :type="'reports'" @icon-btn-clicked="changeTab('reports')"
            :isActive="state.tab === 'reports' || state.tab.includes('@report-')"
            @click.stop="state.showMobMenu = false"
          />
          <u-icon-button :type="'settings'" @icon-btn-clicked="changeTab('settings')"
            :isActive="state.tab === 'settings'"
            @click.stop="state.showMobMenu = false"
          />
          </div>
          <u-icon-button :type="'logOut'" @click="logOut"/>
        </div>
      </div>
    </transition>
    <u-dialog v-if="state.showDialog"
      :header="state.dialogHeader"
      :cancelButton="state.dialogCancelBtn"
      :showButtons="state.dialogShowBtns"
      :isLoadind="state.loadingDialog"
      @click-res="dialogRes"
    />
    <u-review v-if="state.showReview"
      @close-review="state.showReview = false"
      :orgId="state.organization.orgId"
      :activeForm="state.tab"
    />
    <u-pin-input v-if="state.showPinVue"
      :lockPassword="state.organization.lockPassword"
      @close-pin-input="state.showPinVue = false"
      @to-settings="toSettings"
    />
</template>

<script setup lang="ts">
import { onBeforeMount, reactive, watch, onMounted, markRaw, provide } from 'vue'
import Dashboards from './DashboardsView.vue'
import Settings from './SettingsView.vue'
import ReportView from './ReportView.vue'
import OrganizationView from './OrganizationView.vue'
import PlansView from './PlansView.vue'
import PaymentsView from './PaymentsView.vue'
import PaySteps from './PaySteps.vue'
import logo from '@/assets/images/logo.svg'
import mobileMenu from '@/assets/icon/mob-menu.svg'
import { iconMap } from '@/maps'
import reviewIcon from '@/assets/icon/review.svg'
// models
import {
  Cookies,
  Fix,
  Departments,
  FeeStatisticList,
  Organization,
  Employee,
  GenericObject
} from '@/models'
// stores
import { useFixesStore } from '@/stores/fixes'
import { useCookesStore } from '@/stores/cookies'
import { useOrganazationStore } from '@/stores/organization'
import { useEmploysStore } from '@/stores/employs'
import { useSettingsStore } from '@/stores/settings'
// reports
import FixReport from '@/reports/FixRep.vue'
import OnWorkplace from '@/reports/OnWorkplace.vue'
import WhoLate from '@/reports/WhoLate.vue'
import FinesRep from '@/reports/FinesRep.vue'
import {
  delay,
  getMonthStartEnd,
  getStartOfDayInMilliseconds,
  getEndOfDayInMilliseconds,
  getPreviousMonthInfo,
  dataUpdTime
} from '@/globalFunctions'
import router from '@/router'
import { useFinesStore } from '@/stores/fines'
import { ArrayMap } from '@/components/UI/UDropdownFilter.vue'
import { eventBus } from '@/eventBus'

const cookies = useCookesStore()
const fixes = useFixesStore()
const organization = useOrganazationStore()
const employs = useEmploysStore()
const settingStore = useSettingsStore()
const finesStore = useFinesStore()

const state = reactive({
  orgId: '',
  showDialog: false,
  tab: 'dashboard',
  cookies: {} as Cookies,
  fixData: [] as Fix[],
  todayFixData: [] as Fix[],
  departments: [] as Departments,
  dataLoaded: false,
  tabTmp: '',
  dialogHeader: '',
  dialogShowBtns: false,
  dialogCancelBtn: false,
  loadingDialog: false,
  operType: '',
  showMobMenu: false,
  fines: [] as FeeStatisticList,
  report: markRaw(FixReport),
  organization: {} as Organization,
  showReview: false,
  persons: [] as Employee[],
  reportName: '',
  dataUpdLastTime: '',
  showPinVue: false
})

const payInfo = reactive({
  payRout: '',
  summ: '',
  type: ''
})

const reportsMap = {
  fix: { page: markRaw(FixReport), name: 'Фикс-отчет' },
  onworkplace: { page: markRaw(OnWorkplace), name: 'На месте' },
  wholate: { page: markRaw(WhoLate), name: 'Фиксация' },
  finesrep: { page: markRaw(FinesRep), name: 'Штрафы' }
}

const isMobile = window.innerWidth < 800

const days: ArrayMap[] = [
  { text: 'Сегодня', value: 'today' },
  { text: 'Вчера', value: 'yesterday' },
  { text: 'Текущая неделя', value: 'currentWeek' },
  { text: 'Прошлая неделя', value: 'prevWeek' },
  { text: 'Текущий месяц', value: 'currentMonth' },
  { text: 'Прошлый месяц', value: 'prevMonth' }
]

provide('days', days)

onBeforeMount(async () => {
  state.dataUpdLastTime = dataUpdTime()
  openDialog('', false, false, true)
  await cookies.getCookie()
  state.cookies = cookies.getCookies
  state.orgId = state.cookies.orgId
  await getOrgInfo()
  await prepareData()
  closeDialog()
  state.dataUpdLastTime = dataUpdTime()
  state.dataLoaded = true
})

onMounted(() => {
  refreshTodayFix()
})

async function getOrgInfo () {
  await organization.getOrganizationAction(state.orgId)
  state.organization = organization.getOrganization
  sessionStorage.setItem('organization', JSON.stringify(state.organization))
  await organization.getDepartmentsAction(state.orgId)
  state.departments = organization.getDepartments
  sessionStorage.setItem('departments', JSON.stringify(state.departments))
  await employs.getPositionsAction(state.orgId)
  sessionStorage.setItem('positions', JSON.stringify(employs.getPositions))
  await employs.getEmploysAction(state.orgId)
  state.persons = employs.getEmploys
  sessionStorage.setItem('persons', JSON.stringify(state.persons))
  await getFinesInfo()
  await settingStore.getFeeAction(state.orgId)
  sessionStorage.setItem('fees', JSON.stringify(settingStore.getFeeList))
}

async function getFinesInfo () {
  const currYear = new Date().getFullYear()
  const prevMonth = getPreviousMonthInfo()[1]

  const prevMonthTimestamps = getMonthStartEnd(prevMonth, prevMonth === 12 ? currYear - 1 : currYear)
  const timestamps = getMonthStartEnd()
  await finesStore.getFeeAction(state.orgId, prevMonthTimestamps[0], timestamps[1])
  state.fines = finesStore.feeStatisticList
  sessionStorage.setItem('fee-statistic', JSON.stringify(state.fines))
}

async function prepareData () {
  const timestamps = getMonthStartEnd()
  await fixes.getFixesAction(state.orgId, null, timestamps[0], timestamps[1])
  state.fixData = fixes.getFixes
  state.todayFixData = state.fixData.filter(item => item.time > getStartOfDayInMilliseconds() && item.time < getEndOfDayInMilliseconds())
  sessionStorage.setItem('fixes', JSON.stringify(state.fixData))
}

async function refreshTodayFix () {
  await delay(60000)
  await fixes.getFixesAction(state.orgId, null, getStartOfDayInMilliseconds(), getEndOfDayInMilliseconds())
  state.todayFixData = fixes.getFixes
  state.dataUpdLastTime = dataUpdTime()
  refreshTodayFix()
}

function toSettings () {
  state.showPinVue = false
  state.tab = 'settings'
}

function openDialog (
  header: string,
  showBtns: boolean = true,
  showCancelBtn: boolean = true,
  isLoadind: boolean = false
) {
  state.dialogHeader = header
  state.dialogShowBtns = showBtns
  state.dialogCancelBtn = showCancelBtn
  state.loadingDialog = isLoadind
  state.showDialog = true
}

function closeDialog () {
  state.showDialog = false
}

function changeTab (arg: string, isReport: boolean = false) {
  if (isReport) {
    state.tab = `@report-${arg}`
  } else {
    if (arg === 'settings') {
      state.showPinVue = true
    } else {
      state.tab = arg
    }
  }
}

function logOut () {
  state.operType = 'log-out'
  openDialog('Выйти?')
}

async function dialogRes (arg: string) {
  if (arg === 'ok') {
    if (state.operType === 'log-out') {
      await cookies.getCookie()
      const cookiesLast = cookies.getCookies
      const currentDate = new Date().getTime()
      await cookies.setCookie({
        token: cookiesLast.orgId,
        orgId: cookiesLast.orgId,
        expTime: currentDate - (24 * 60 * 60 * 100)
      })
      router.push({ name: 'auth' })
    }
  }
  state.showDialog = false
  state.operType = ''
}

function toReport (arg: string) {
  changeTab(arg, true)
  state.report = reportsMap[arg as keyof typeof reportsMap]?.page
  state.reportName = reportsMap[arg as keyof typeof reportsMap]?.name
}

watch(() => state.tab, (newValue, oldValue) => {
  if (!state.dataLoaded) {
    state.tab = oldValue
    if (state.tabTmp === '') {
      state.tabTmp = newValue
    }
    openDialog('Загружаем данные', false, false, true)
  }
})

watch(() => state.todayFixData, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    sessionStorage.setItem('today-fix', JSON.stringify(newValue))
  }
})

watch(() => state.dataLoaded, (newValue) => {
  if (newValue && state.tabTmp !== '') {
    state.tab = state.tabTmp
    state.tabTmp = ''
    closeDialog()
  }
})

async function beforeFadeLeave (el: Element) {
  el.classList.add('mobile-menu-fade-out')
  await delay(500)
}

function afterFadeLeave (el: Element) {
  el.classList.remove('mobile-menu-fade-out')
}

eventBus.on('to-payments', () => {
  state.tab = 'payments'
})

eventBus.on('update-plan', () => {
  state.organization = JSON.parse(sessionStorage.getItem('organization') || '{}')
})

eventBus.on('to-pay-steps', (obj: GenericObject | Record<string, string> | null) => {
  if (!obj) {
    return
  }
  state.tab = 'pay-steps'
  payInfo.payRout = String(obj.routText)
  payInfo.summ = String(obj.summ)
  payInfo.type = String(obj.type)
})

function toPrev () {
  if (state.tab === 'pay-steps') {
    payInfo.payRout = ''
    payInfo.type = ''
    state.tab = 'payments'
  } else {
    state.tab = 'plans'
  }
}
</script>

<style>
.mainpage-wrapper {
  display: flex;
  height: 100vh;
  width: 100vw;
}

.side-menu {
  /* width: 13vw; */
  color: var(--primary-font-color);
  font: 1.04vw 'RedHatDisplay-Regular', 'OpenSans Regular', sans-serif;
  /* margin: 3rem 1rem 1rem 1rem; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upside-menu {
  margin: 3.39vw 2vw 1vw 2vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 11.5vw;
  height: 100%;
}

.logo-img {
  margin-bottom: 2.86vw;
  margin-left: 1.1vw;
  width: 8.85vw;
}

.tab {
  margin-bottom: 0.78vw;
}

.tab-wrapper {
  display: flex;
  flex-direction: column;
  gap: 2.86vw;
  width: 130%;
  padding: 3.2vw 2.60vw;
}

.main-delimeter {
  border: 2px solid #2D4B6B;
}

.mobile-menu-overlay {
  position: fixed;
  z-index: 3000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000040;
  display: flex;
  justify-content: left;
  animation: fadeIn 0.5s forwards;
}

.lasttime-data {
  font: 1.04vw 'RedHatDisplay-Regular', 'OpenSans Regular', sans-serif;
  color: var(--primary-font-color);
}

.mobile-menu-wrapper {
  display: flex;
  flex-direction: column;
  width: 65%;
  background-image: linear-gradient(245.9deg, #2D4B6B -40.66%, #042447 131.18%);
  font: 5.60vw 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
  color: var(--primary-font-color);
  border-radius: 0 2rem 2rem 0;
  padding: 8.91vw 6.36vw;
  justify-content: space-between;
}

.mob-menu-icon {
  width: 6.36vw;
  height: auto;
  margin-bottom: 7.63vw;
}

.mobile-menu-fade-out {
  animation: fadeOut 0.5s forwards;
}

.mobile-menu img {
  width: 6.36vw;
}

.org-name-menu {
  display: flex;
  justify-content: center;
  color: var(--primary-font-color);
  font: 1.2rem 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
}

.main-small-menu {
  display: flex;
  flex-direction: column;
  padding: 0.8rem;
  background-color: #2D4B6B;
  border-radius: 50%;
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 1000;
}

.main-small-menu-icon {
  width: 2rem;
  height: auto;
}

.main-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.org-info {
  justify-content: flex-end;
}

.main-header-org-info {
  display: flex;
  gap: 2.6041vw;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@media (max-width: 950px) {
  .mainpage-wrapper {
    flex-direction: column;
    justify-content: flex-start;
  }

  .tab-wrapper {
    padding: 0 6.36vw;
    width: fit-content;
  }

  .mobile-menu {
    align-items: center;
    display: flex;
    gap: 1rem;
    font: 5.60vw 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
    color: var(--primary-font-color);
    /* margin-bottom: 7.63vw; */
    padding: 7.5vw 6.36vw;
  }
  .main-header {
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 1vw;
  }
  .lasttime-data {
    font-size: 3vw;
    margin-top: 2vw;
    margin-bottom: 4vw;
  }
  .main-small-menu {
    bottom: 4vw;
    right: auto;
    left: 2vw;
  }
}
</style>
