import { defineStore } from 'pinia'
import { AxiosError } from 'axios'
import { axiosMain } from '@/axios-setup'

export const useImagesYarosStore = defineStore('imagesYaros', {
  state: () => ({
    image: '' as string
  }),
  actions: {
    async getImage (store: string, id: string) {
      try {
        const encodedStore = encodeURIComponent(store)
        const response = await axiosMain.get<string>('/@image_store', {
          params: {
            store: encodedStore,
            id
          }
        })
        return response.data ? response.data : ''
      } catch (error) {
        const axiosError = error as AxiosError
        console.error(axiosError.message)
      }
    },
    async postImageAction (blob: BinaryData) {
      try {
        const response = await axiosMain.post<string | undefined>('/@image_store',
          blob, {
            headers: {
              'Content-Type': 'application/octet-stream'
            }
          })
        if (response.status === 200) {
          return response.data
        }
      } catch (error) {
        const axiosError = error as AxiosError
        console.error(axiosError.message)
      }
    }
  }
})
