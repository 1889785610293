import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "udialog-overlay"
}
const _hoisted_2 = { class: "udialog" }
const _hoisted_3 = { class: "add-universal-header" }
const _hoisted_4 = { class: "add-universal-header-text" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "add-universal-body" }
const _hoisted_7 = { class: "add-universal-body-input-wrapper" }
const _hoisted_8 = { class: "add-universal-body-text" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { class: "add-universal-persons" }
const _hoisted_11 = ["src"]
const _hoisted_12 = { class: "add-universal-actions" }

import { reactive, PropType, onBeforeMount, watch } from 'vue'
import cancel from '@/assets/icon/cancel-dialog.svg'
import employers from '@/assets/icon/org_item/employers.svg'
import { Employee, Positions, Organization } from '@/models'
import { Photo } from '@/classes'
import { useImagesYarosStore } from '@/stores/imagesYaros'
import { v4 as uuidv4 } from 'uuid'
import { eventBus } from '@/eventBus'

interface ImagesState {
  [key: string]: Photo;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'UAddUniversal',
  props: {
  type: {
    type: String,
    required: true
  },
  text: {
    type: String,
    default: ''
  },
  id: {
    type: String,
    default: ''
  },
  persons: {
    type: Array as PropType<Employee[]>,
    default: () => []
  }
},
  emits: ['dismiss', 'open-choose-person', 'save', 'del-person'],
  setup(__props, { emit: __emit }) {

const props = __props

const state = reactive({
  name: props.text,
  organization: {} as Organization,
  persons: [] as Employee[],
  positions: [] as Positions,
  images: reactive({} as ImagesState),
  toRemove: []
})

const emits = __emit
const imagesStore = useImagesYarosStore()

onBeforeMount(() => {
  state.organization = JSON.parse(sessionStorage.getItem('organization') || '{}')
  getPersons()
  state.positions = JSON.parse(sessionStorage.getItem('positions') || '[]').positions
})

function getPersons () {
  const personsTmp = JSON.parse(sessionStorage.getItem('persons') || '[]') as Employee[]
  state.persons = personsTmp.filter(item => {
    if (props.type === 'departments' && item.depArr.includes(props.id)) {
      return true
    } else if (props.type === 'positions' && item.posArr.includes(props.id)) {
      return true
    }
    return false
  })
}

watch(() => state.persons, (newValue, oldValue) => {
  if (newValue !== oldValue && newValue.length > 0) {
    loadingImages()
  }
}, { deep: true })

async function loadingImages () {
  for (const person of state.persons) {
    if (person.photo && !(person.photo in state.images)) {
      let photo = new Photo()
      const photoId = person.photo
      try {
        const cachedPhoto = sessionStorage.getItem(person.photo)
        if (cachedPhoto) {
          photo = new Photo(cachedPhoto, false, false)
        } else {
          const result = await imagesStore.getImage(state.organization.fixStorage, photoId)
          if (result) {
            sessionStorage.setItem(person.photo, result)
            photo = new Photo(result, false, false)
          }
        }
      } catch (error) {
        console.error(`Failed to load image ${photoId}:`, error)
        photo = new Photo('', false, true)
      } finally {
        state.images = { ...state.images, ...{ [photoId]: photo } }
      }
    }
  }
}

function saveData () {
  const actType = props.id !== '' ? 'patch' : 'post'
  if (actType === 'post' && state.name.length === 0) {
    return
  }
  const data = {
    type: actType,
    data: {
      id: props.id !== '' ? props.id !== '' : uuidv4(),
      name: state.name
    }
  }
  emits('save', data)
}

eventBus.on('updatePersons', () => {
  getPersons()
})

return (_ctx: any,_cache: any) => {
  const _component_u_person_row = _resolveComponent("u-person-row")!

  return (_openBlock(), _createBlock(_Transition, { name: "udialog-fade" }, {
    default: _withCtx(() => [
      true
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("span", _hoisted_4, _toDisplayString(props.id != '' ? 'Редактировать' : 'Добавить') + " " + _toDisplayString(props.type === 'departments' ? 'отдел' : 'должность'), 1),
                _createElementVNode("img", {
                  src: _unref(cancel),
                  class: "button-click",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (emits('dismiss')))
                }, null, 8, _hoisted_5)
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("span", _hoisted_8, " Название " + _toDisplayString(props.type === 'departments' ? 'отдела' : 'должности'), 1),
                  _withDirectives(_createElementVNode("input", {
                    class: "add-universal-input",
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((state.name) = $event))
                  }, null, 512), [
                    [_vModelText, state.name]
                  ]),
                  (props.id != '')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        _cache[6] || (_cache[6] = _createElementVNode("span", { class: "add-universal-body-text" }, "Сотрудники", -1)),
                        _createElementVNode("div", _hoisted_10, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(state.persons, (person) => {
                            return (_openBlock(), _createBlock(_component_u_person_row, {
                              key: person.id,
                              person: person,
                              imgSrc: state.images[person.photo]?.imageSrc?? '',
                              posName: state.positions.find(item => item.id === person.posArr[0])?.name,
                              isDel: true,
                              onDelPerson: ($event: any) => (emits('del-person', person.id))
                            }, null, 8, ["person", "imgSrc", "posName", "onDelPerson"]))
                          }), 128))
                        ]),
                        _createElementVNode("div", {
                          class: "add-universal-add-person button-click",
                          onClick: _cache[2] || (_cache[2] = ($event: any) => (emits('open-choose-person')))
                        }, [
                          _createElementVNode("img", { src: _unref(employers) }, null, 8, _hoisted_11),
                          _cache[5] || (_cache[5] = _createElementVNode("span", null, "Выбрать", -1))
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("div", {
                    class: "add-universal-actions-btn button-click",
                    id: "ok-btn",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (saveData()))
                  }, _cache[7] || (_cache[7] = [
                    _createElementVNode("span", { class: "add-universal-actions-btn-text" }, "Сохранить", -1)
                  ])),
                  _createElementVNode("div", {
                    class: "add-universal-actions-btn button-click",
                    id: "cancel-btn",
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (emits('dismiss')))
                  }, _cache[8] || (_cache[8] = [
                    _createElementVNode("span", { class: "add-universal-actions-btn-text" }, "Отмена", -1)
                  ]))
                ])
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})