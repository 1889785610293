import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "widgets fines-widget" }
const _hoisted_2 = {
  key: 0,
  class: "fines-body"
}
const _hoisted_3 = {
  class: "fines-widget-block",
  id: "fines-widget-block-top"
}
const _hoisted_4 = { class: "fine-sum" }
const _hoisted_5 = { class: "fines-widget-block" }
const _hoisted_6 = { class: "fine-info-panel" }
const _hoisted_7 = { class: "fine-info-up-low-percent" }
const _hoisted_8 = { class: "fine-up-low-percent" }
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "fine-percent-part" }
const _hoisted_11 = ["id"]
const _hoisted_12 = {
  key: 1,
  class: "fines-spinner-wrapper"
}

import fineLow from '@/assets/icon/fine_low.svg'
import fineUp from '@/assets/icon/fine_up.svg'


export default /*@__PURE__*/_defineComponent({
  __name: 'UFines',
  props: {
  fineSum: {
    type: Number,
    default: 0,
    required: true
  },
  percent: {
    type: Number,
    default: 0,
    required: true
  },
  periodPerc: {
    type: Number,
    default: 0,
    required: true
  },
  isLoading: {
    type: Boolean,
    default: true,
    required: true
  }
},
  emits: ['to-report'],
  setup(__props, { emit: __emit }) {

const props = __props

const emits = __emit

return (_ctx: any,_cache: any) => {
  const _component_u_widget_header = _resolveComponent("u-widget-header")!
  const _component_u_spinner = _resolveComponent("u-spinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_u_widget_header, {
      class: "fine-widget-header",
      headerText: 'Штрафы',
      showMonthStartDate: true
    }),
    (!props.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("span", _hoisted_4, _toDisplayString(props.fineSum) + "KGS", 1),
            _cache[1] || (_cache[1] = _createElementVNode("span", { class: "fine-info" }, " Сэкономлено на штрафах за текущий месяц ", -1))
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("span", _hoisted_8, _toDisplayString(props.periodPerc) + "%", 1),
                _createElementVNode("img", {
                  src: props.periodPerc >= 0 ? _unref(fineUp) : _unref(fineLow)
                }, null, 8, _hoisted_9)
              ]),
              _cache[3] || (_cache[3] = _createElementVNode("div", { class: "delimeter" }, null, -1)),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("span", {
                  class: "fine-percent-number",
                  id: props.percent >= 0 ? 'percent-up' : 'percent-low'
                }, _toDisplayString(`${props.percent >= 0 ? '+' : '-'} ${props.percent}%`), 9, _hoisted_11),
                _cache[2] || (_cache[2] = _createElementVNode("span", { class: "fine-label" }, "С прошлого месяца", -1))
              ])
            ]),
            _createElementVNode("button", {
              class: "button-click report-button",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (emits('to-report', 'finesrep')))
            }, " Отчет ")
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_12, [
          _createVNode(_component_u_spinner)
        ]))
  ]))
}
}

})