<template>
    <div class="auth-wrapper">
      <div class="auth-form">
        <span class="auth-form-header">Подтвержение</span>
        <div class="sms-info">
            <span class="sms-info-text">Мы отправили код на номер </span>
            <span class="sms-info-number">{{ `+${formatPhoneNumber(props.registrationInfo.login)}` }}</span>
        </div>
        <div class="input-group">
            <input ref="inputRef1"
                v-model="state.firstNum"
                class="sms-input"
                maxlength="1"
                @input="changeFocus(2)"
                id="sms-input-1"
            >
            <input ref="inputRef2"
                v-model="state.secondNum"
                class="sms-input"
                maxlength="1"
                @input="changeFocus(3)"
            >
            <input ref="inputRef3"
                v-model="state.thirdNum"
                class="sms-input"
                maxlength="1"
                @input="changeFocus(4)"
            >
            <input ref="inputRef4"
                v-model="state.fourthNum"
                class="sms-input"
                maxlength="1"
                @input="changeFocus(0)"
            >
        </div>
        <u-button class="auth-btn button-click" @click="confirmSMS" :btnText="'Продолжить'" />
        <div class="auth-texts">
            <div v-if="state.secondsLeft === 0">
                <span class="auth-text-act">Не пришел код? </span>
                <span class="auth-text-act-btn button-click" @click="sendSMS">Отправить еще раз</span>
            </div>
            <div v-else>
              <span class="auth-text-act">СМС можно будет отправить повторно через {{ state.secondsLeft }} сек</span>
            </div>
            <span class="auth-text-act sms-info-text button-click"
                @click="emits('to-registration')">
                Вернуться к регистрации
            </span>
        </div>
      </div>
    </div>
</template>

<script setup lang="ts">
import { reactive, nextTick, ref, onBeforeMount } from 'vue'
import { OrgReg, Organization } from '@/models'
import { useCookesStore } from '@/stores/cookies'
import { useRouter } from 'vue-router'
import { useRegistrationStore } from '@/stores/registration'
import { OrganizationClass } from '@/objects'
import { RegistrationParams } from '@/views/AuthView.vue'
import { delay } from '@/globalFunctions'
import { useSMSStore } from '@/stores/sms'
import { useOrganazationStore } from '@/stores/organization'

const regStore = useRegistrationStore()
const smsStore = useSMSStore()

const props = defineProps({
  registrationInfo: {
    type: Object as () => RegistrationParams,
    required: true
  }
})

const state = reactive({
  smsInp: '',
  firstNum: '',
  secondNum: '',
  thirdNum: '',
  fourthNum: '',
  organizationResp: {} as OrgReg,
  lasttimeSMSSend: null,
  secondsLeft: 0,
  organization: {} as Organization
})

const emits = defineEmits(['open-dialog', 'close-dialog', 'to-registration'])
const cookies = useCookesStore()
const router = useRouter()
const organization = useOrganazationStore()

const inputRef1 = ref<HTMLInputElement | null>(null)
const inputRef2 = ref<HTMLInputElement | null>(null)
const inputRef3 = ref<HTMLInputElement | null>(null)
const inputRef4 = ref<HTMLInputElement | null>(null)

onBeforeMount(() => {
  const lasttimeSMS = localStorage.getItem('lasttimeSMSSend')
  const now = Math.floor(Date.now() / 1000)
  const dateDiff = now - Number(lasttimeSMS)
  if (lasttimeSMS && dateDiff <= 60) {
    state.secondsLeft = 60 - dateDiff
    counter()
  }
  inputRef1.value?.focus()
})

async function counter () {
  if (state.secondsLeft > 0) {
    state.secondsLeft -= 1
    await delay(1000)
    counter()
  }
}

function changeFocus (nextInput: number) {
  nextTick(() => {
    if (nextInput === 2 && state.firstNum.length > 0) {
      inputRef2.value?.focus()
    } else if (nextInput === 3 && state.secondNum.length > 0) {
      inputRef3.value?.focus()
    } else if (nextInput === 4 && state.thirdNum.length > 0) {
      inputRef4.value?.focus()
    } else if (nextInput === 0 && state.fourthNum.length > 0) {
      inputRef4.value?.blur()
    }
  })
}

async function confirmSMS () {
  state.smsInp = `${state.firstNum}${state.secondNum}${state.thirdNum}${state.fourthNum}`
  state.smsInp = state.smsInp.replace(' ', '')
  if (state.smsInp.length < 4) {
    emits('open-dialog', { headerText: 'Ошибка', message: 'Введите 4 цифры из СМС.' })
    return
  }

  const res = await smsStore.checkSMS(props.registrationInfo.login, state.smsInp)

  if (!res || res.status !== 'confirmed') {
    emits('open-dialog', { headerText: 'Ошибка', message: 'Неверный код.' })
    state.smsInp = ''
    state.firstNum = ''
    state.secondNum = ''
    state.thirdNum = ''
    state.fourthNum = ''
    inputRef1.value?.focus()
  } else {
    emits('open-dialog',
      {
        headerText: 'Регистрируем',
        message: 'Пожалуйста, подождите!',
        showButtons: false,
        loading: true
      })
    state.organizationResp = await registration()
    if (state.organizationResp.status === 'created') {
      await organization.getOrganizationAction(state.organizationResp.orgId)
      state.organization = organization.getOrganization
      const currentDate = new Date().getTime()
      await cookies.setCookie({
        token: state.organization?.orgId,
        orgId: state.organization?.orgId,
        expTime: currentDate + (24 * 60 * 60 * 1000)
      })
      router.push({ name: 'main' })
    } else {
      emits('close-dialog')
      emits('open-dialog', { headerText: 'Ошибка', message: state.organizationResp.message })
    }
  }
}

async function registration () {
  const registrationParams = new OrganizationClass(
    props.registrationInfo.orgName,
    props.registrationInfo.login,
    props.registrationInfo.password,
    state.smsInp
  )
  await regStore.sendRegRequest(registrationParams)
  return regStore.getOrganization
}

function formatPhoneNumber (phone: string): string {
  if (phone.startsWith('996')) {
    return `${phone.substring(0, 3)} (${phone.substring(3, 6)}) ${phone.substring(6, 9)} ${phone.substring(9)}`
  } else if (phone.startsWith('7')) {
    return `${phone.substring(0, 1)} (${phone.substring(1, 4)}) ${phone.substring(4, 7)} ${phone.substring(7)}`
  } else return phone
}

async function sendSMS () {
  localStorage.setItem('lasttimeSMSSend', Math.floor(Date.now() / 1000).toString())
  state.secondsLeft = 60
  counter()
}
</script>

<style>
.input-group {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.sms-input {
  width: 20vw;
  padding: 1.20vw 1.82vw;
  border-radius: 20vw;
  background-color: var(--main-color);
  border-width: 1px;
  border-color: 1px solid linear-gradient(90deg, rgba(25,211,229,0) 0%, rgba(25,211,229,1) 100%);
  transition: 0.3s ease-in-out;
  font: 1.5rem 'RedHatText-Regular', 'OpenSans Regular' sans-serif;
  color: var(--primary-font-color);
  border: none;
  font-weight: 700;
  text-align: center;
}

.sms-input input:focus {
  outline: none;
  color: var(--primary-font-color);
}

.sms-info {
  font: 1.15vw 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
  margin-bottom: 1.56vw;
}

.sms-info-text {
  color: #FFFFFF80;
}

.sms-info-number {
  color: var(--primary-font-color);
}

@media screen and (max-width: 950px) {
  .sms-info-text {
    font-size: 4.07vw;
    margin-bottom: 5.09vw;
  }
}
</style>
