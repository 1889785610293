import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { key: 0 }

import back from '@/assets/icon/back.svg'


export default /*@__PURE__*/_defineComponent({
  __name: 'UPaymentsHeader',
  props: {
  currentRout: {
    type: String,
    default: ''
  }
},
  emits: ['to-prev'],
  setup(__props, { emit: __emit }) {

const props = __props

const emits = __emit

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "payments-header-wrapper button-click",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (emits('to-prev')))
  }, [
    _createElementVNode("img", { src: _unref(back) }, null, 8, _hoisted_1),
    _createElementVNode("div", null, [
      _createElementVNode("span", {
        class: _normalizeClass({'payments-header-a70': props.currentRout !== ''})
      }, " Пополнение баланса ", 2),
      (props.currentRout !== '')
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(` / ${props.currentRout}`), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})