import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "visa-main-wrapper" }
const _hoisted_2 = { class: "visa-wrapper" }
const _hoisted_3 = { class: "pay-info-panel-wrapper-item" }
const _hoisted_4 = { class: "pay-info-panel-item-text" }
const _hoisted_5 = { class: "visa-instructions" }
const _hoisted_6 = { class: "visa-footer-btn-wrapper" }

import { onBeforeMount, reactive } from 'vue'
import { Organization } from '../../../models'
import { PayOrder } from '../../../classes'
import { usePaymentStore } from '../../../stores/pays'


export default /*@__PURE__*/_defineComponent({
  __name: 'VisaPay',
  props: {
  summ: {
    type: String,
    required: true
  }
},
  emits: ['show-loader', 'close-loader', 'show-dialog'],
  setup(__props, { emit: __emit }) {

const payStore = usePaymentStore()

const emits = __emit

const state = reactive({
  organization: {} as Organization,
  link: '',
  url: ''
})

const props = __props

const steps = [
  'По кнопке “Открыть страницу оплаты” ниже, перейдите на страницу оплаты.',
  'Введите данные вашей карты',
  'Подтвердите оплату'
]

onBeforeMount(async () => {
  state.organization = JSON.parse(sessionStorage.getItem('organization') || '{}')
})

function showDialog (body: string) {
  emits('show-dialog', { error: true, body })
}

async function openPayFrame () {
  emits('show-loader')
  const payBody = new PayOrder(state.organization.orgId, Number(props.summ))
  const result = await payStore.getOTPQpay('paylercom', payBody)
  if (result.status === 'OK') {
    state.link = result.link
    state.url = result.url
    emits('close-loader')
    window.location.href = state.url
  } else {
    showDialog(result.info)
  }
}

return (_ctx: any,_cache: any) => {
  const _component_u_instruction_step = _resolveComponent("u-instruction-step")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[1] || (_cache[1] = _createElementVNode("span", { class: "pay-info-panel-item-header" }, " К оплате ", -1)),
        _createElementVNode("span", _hoisted_4, _toDisplayString(`${props.summ} сом`), 1)
      ]),
      _cache[2] || (_cache[2] = _createElementVNode("span", { class: "pay-instruction-label visa-instr-label" }, " Инструкция по оплате ", -1)),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(steps, (item, index) => {
          return _createVNode(_component_u_instruction_step, {
            key: index,
            step: String(index + 1),
            text: item
          }, null, 8, ["step", "text"])
        }), 64))
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", {
        class: "visa-footer-btn button-click",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (openPayFrame()))
      }, _cache[3] || (_cache[3] = [
        _createElementVNode("span", null, "Продолжить", -1)
      ]))
    ])
  ]))
}
}

})