<template>
  <div class="widgets">
    <div class="doughnut-wrapper" v-if="reactiveVars.notOnPlace + props.onPlace > 0">
      <u-widget-header :headerText="'На месте'" @icon-clicked="emits('to-report')"/>
      <div class="doughnut-container" v-if="!props.isLoading && reactiveVars.refreshed">
        <div class="doughnut-counter">
          <span id="not-on-place-count">{{ `${reactiveVars.notOnPlace}/` }}</span>
          <span id="on-place-count">{{ props.onPlace }}</span>
        </div>
        <Doughnut :data="state.data" :options="state.options"/>
      </div>
      <div v-else>
        <u-spinner />
      </div>
      <div class="info-rows">
        <div class="info-row">
          <div class="square" id="not-on-place"></div>
          <span>Отсутствуют</span>
        </div>
        <div class="info-row">
          <div class="square" id="on-place"></div>
          <span>На месте</span>
        </div>
      </div>
    </div>
    <span v-else>Нет данных</span>
  </div>
</template>

<script setup lang="ts">
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { reactive, watchEffect } from 'vue'
import { Doughnut } from 'vue-chartjs'
import { delay } from '@/globalFunctions'

ChartJS.register(ArcElement, Tooltip, Legend)

const props = defineProps({
  onPlace: {
    type: Number,
    default: 0,
    required: true
  },
  notOnPlace: {
    type: Number,
    default: 0,
    required: true
  },
  total: {
    type: Number,
    default: 0,
    required: true
  },
  isLoading: {
    type: Boolean,
    default: true,
    required: true
  }
})

const emits = defineEmits(['to-report'])

const reactiveVars = reactive({
  spacing: 1,
  notOnPlace: 0,
  onPlace: 0,
  refreshed: false
})

const state = reactive({
  options: {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      }
    },
    cutout: '75%'
  },
  data: {
    labels: ['Отсутствуют', 'На месте'],
    datasets: [
      {
        backgroundColor: ['#FFFFFFB2', '#2D4B6B', '#16FAFC', '#2D4B6B'],
        data: [props.notOnPlace, reactiveVars.spacing, props.onPlace, reactiveVars.spacing],
        borderRadius: 10,
        borderWidth: 0
      }
    ]
  }
})

watchEffect(async () => {
  reactiveVars.notOnPlace = props.notOnPlace
  reactiveVars.onPlace = props.onPlace
  if (reactiveVars.notOnPlace * reactiveVars.onPlace !== 0) {
    reactiveVars.spacing = (props.notOnPlace + props.onPlace) / 50
    state.data.datasets[0].backgroundColor = ['#FFFFFFB2', '#2D4B6B', '#16FAFC', '#2D4B6B']
    state.data.datasets[0].data = [reactiveVars.notOnPlace, reactiveVars.spacing, reactiveVars.onPlace, reactiveVars.spacing]
    state.data.datasets[0].borderRadius = 10
  } else {
    reactiveVars.spacing = 0
    state.data.datasets[0].backgroundColor = ['#FFFFFFB2', '#16FAFC']
    state.data.datasets[0].data = [reactiveVars.notOnPlace, reactiveVars.onPlace]
    state.data.datasets[0].borderRadius = 0
  }
  reactiveVars.refreshed = false
  await delay(1)
  reactiveVars.refreshed = true
})
</script>

<style>
.doughnut-container {
  position: relative;
  width: 6.77vw;
  height: 6.77vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.doughnut-counter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font: 1.04vw 'RedHatText-Medium', 'OpenSans Medium', sans-serif;
}

#not-on-place {
  background-color: var(--primary-font-color-a70);
}

#on-place {
  background-color: var(--primary-font-color-blue);
}

#not-on-place-count {
  color: var(--primary-font-color-a70);
}

#on-place-count {
  color: var(--primary-font-color);
}

.info-row {
  display: flex;
  font: 0.83vw 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
  color: var(--primary-font-color);
  align-items: center;
  gap: 0.52vw;
}

.info-rows {
  display: flex;
  flex-direction: column;
  gap: 0.63vw;
  margin-top: 1.30vw;
}

.doughnut-wrapper {
  height: -webkit-fill-available;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}
.square {
  width: 1.30vw;
  height: 1.30vw;
}

@media screen and (max-width: 850px) {
  .doughnut-container {
    width: 33.08vw;
    height: 33.08vw;
  }
  .doughnut-counter {
    font-size: 5.09vw;
  }
  .info-rows {
    gap: 2.29vw;
    margin-top: 6.36vw;
  }
  .info-row {
    font-size: 4.07vw;
    gap: 2.54vw;
  }
  .square {
    width: 6.36vw;
    height: 6.36vw;
  }
}
</style>
