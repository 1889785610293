export class Photo {
  imageSrc: string
  isLoading: boolean
  isError: boolean

  constructor (imageSrc: string = '', isLoading: boolean = true, isError: boolean = false) {
    this.imageSrc = imageSrc
    this.isLoading = isLoading
    this.isError = isError
  }
}

export class PayOrder {
  account: string
  service: string
  phone: string
  info: string
  amount: number

  constructor (
    account: string,
    amount: number,
    phone: string = '',
    service: string = 'office_pay',
    info: string = 'Оплата подписки Office Time'
  ) {
    this.account = account
    this.amount = amount
    this.phone = phone
    this.service = service
    this.info = info
  }
}
