import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "dropdown" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "country-code" }
const _hoisted_4 = {
  key: 0,
  class: "dropdown-content"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "dropdown-images" }
const _hoisted_7 = ["src"]

import { reactive, onBeforeMount } from 'vue'
import { Country } from '@/models'
import { countriesList } from '@/objects'


export default /*@__PURE__*/_defineComponent({
  __name: 'UDropdown',
  emits: ['dropdown-choosen'],
  setup(__props, { emit: __emit }) {

const emits = __emit

const countries = countriesList

const state = reactive({
  isOpen: false,
  country: countries[0]
})

onBeforeMount(async () => {
  emits('dropdown-choosen', state.country)
})

function selectOption (option: Country) {
  state.country = option
  state.isOpen = false
  emits('dropdown-choosen', option)
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "dropdown-header",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (state.isOpen = !state.isOpen))
    }, [
      _createElementVNode("img", {
        src: state.country.img
      }, null, 8, _hoisted_2),
      _createElementVNode("span", _hoisted_3, _toDisplayString(state.country.code), 1)
    ]),
    (state.isOpen)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(countries), (country) => {
            return (_openBlock(), _createElementBlock("div", {
              key: country.country,
              onClick: ($event: any) => (selectOption(country))
            }, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("img", {
                  src: country.img
                }, null, 8, _hoisted_7),
                _createElementVNode("span", null, _toDisplayString(country.code), 1)
              ])
            ], 8, _hoisted_5))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})