<template>
    <div class="circle button-click">
        <img :style="{ width: props.iconWidth + props.iconUnit,
                       marginTop: props.iconMnTp + props.iconMnUnit,
                       marginRight: props.iconMnRt + props.iconMnUnit,
                       marginBottom: props.iconMnBt + props.iconMnUnit,
                       marginLeft: props.iconMnLt + props.iconMnUnit }"
             :src="state.icon" class="icon">
    </div>
</template>

<script setup lang="ts">
import { reactive } from 'vue'
import more from '@/assets/icon/more.svg'

const props = defineProps({
  type: {
    type: String,
    default: '',
    validator: (value: string) => ['more'].includes(value)
  },
  iconWidth: {
    type: Number,
    default: 0
  },
  iconUnit: {
    type: String,
    default: ''
  },
  iconMnTp: {
    type: Number,
    default: 0
  },
  iconMnBt: {
    type: Number,
    default: 0
  },
  iconMnLt: {
    type: Number,
    default: 0
  },
  iconMnRt: {
    type: Number,
    default: 0
  },
  iconMnUnit: {
    type: String,
    default: 'rem'
  }
})

const iconsMap: Record<typeof props.type, string> = {
  more
}

const state = reactive({
  icon: iconsMap[props.type] || '',
  activeColor: ''
})
</script>

<style scoped>
.circle {
  background-color: #FFFFFF66;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 0.83vw; */
  width: 2.34vw;
  height: 2.34vw;
}
@media screen and (max-width: 850px) {
  .circle {
    width: 11.45vw;
    height: 11.45vw;
  }
}
</style>
