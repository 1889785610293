<template>
    <div>
      <div class="organization-header">
        <div class="organization-header-tabs">
          <div class="organization-tabs-controller">
            <span class="button-click" @click="state.tab = 'persons'"
              :id="state.tab === 'persons' ? 'organization-tabs-controller-active' : ''">Сотрудники</span>
            <span class="button-click" @click="state.tab = 'departments'"
              :id="state.tab === 'departments' ? 'organization-tabs-controller-active' : ''">Отделы</span>
            <span class="button-click" @click="state.tab = 'positions'"
              :id="state.tab === 'positions' ? 'organization-tabs-controller-active' : ''">Должности</span>
          </div>
          <u-org-pay-info :sum="state.organization.balance"/>
        </div>
        <div class="organization-header-search">
          <div class="org-add-btn button-click"
            @click="openUnversalAdd()"
          >
            <img :src="add">
            <span class="org-add-btn-text">Добавить</span>
          </div>
          <div class="org-add-search-wrapper">
            <input class="org-add-search-string" placeholder="Поиск..."
              v-model="state.searchString"
            >
            <img class="org-add-search-img" :src="search">
          </div>
          <div class="org-add-filters"
            @click="state.filtersIsOpen = true"
          >
            <img :src="filter">
          </div>
        </div>
      </div>
        <div class="organization-wrapper">
          <u-organization-item
            v-show="['departments', 'positions'].includes(state.tab) &&
              (state.searchString === '' ||
              item.name.toLocaleLowerCase().indexOf(state.searchString.toLocaleLowerCase()) > -1) &&
              filterUniversal(item)"
            @action="itemAction"
            v-for="item in state.tab === 'departments' ? state.departments : state.positions"
            :key="item.id"
            :type="state.tab" :name="item.name" :id="item.id"
            :qty="calcPersons(item.id)"
          />
          <u-person-item v-show="state.tab === 'persons' &&
              searchPerson(item)"
            v-for="item in state.persons" :key="item.id"
            :imageStorage="state.organization.photoStorage"
            :person="item"
            :positions="state.positions ?? []"
            :departments="state.departments ?? []"
            @action="personAction"
          />
        </div>
        <div class="no-results-main-wrapper">
          <div v-if="visibleItemsCount === 0"
            class="no-results-wrapper"
          >
            <img class="no-results-wrapper-img" :src="noResults" >
            <span class="no-results-wrapper-header">
              Нет результатов
            </span>
            <span class="no-results-wrapper-body">
              Удостоверьтесь в правильности введенных вами данных или сформулируйте запрос иначе.
            </span>
          </div>
        </div>
    </div>
    <u-add-universal v-if="state.addUniversalIsOpen"
      :type="state.tab"
      :text="unversalAddState.name"
      :id="unversalAddState.id"
      @open-choose-person="state.addUniversalToPersonIsOpen = true"
      @dismiss="state.addUniversalIsOpen = false"
      @save="saveUniversal"
      @del-person="delPerson"
    />
    <u-person-data v-if="personData.addPersonIsOpen"
      @dismiss="personDataDismiss"
      @show-dialog="personDataDialog"
      @show-loader="showLoader()"
      @success-updated="updatePersons()"
      :person="personData.person"
    />
    <u-choose-persons v-if="state.addUniversalToPersonIsOpen"
      @dismiss="state.addUniversalToPersonIsOpen = false"
      @show-loader="showLoader()"
      @show-dialog="showResDialog"
      :type="state.tab" :id="unversalAddState.id"
    />
    <u-success-added v-if="state.isSuccess"
      :type="state.tab"
      @close="closeSuccessDialog"
    />
    <u-dialog v-if="dialogState.showDialog"
      :header="dialogState.dialogHeader"
      :msgText="dialogState.dialogText"
      :cancelButton="dialogState.dialogCancelBtn"
      :showButtons="dialogState.dialogShowBtns"
      :isLoadind="dialogState.loadingDialog"
      @click-res="dialogResult"
    />
    <u-filters-view v-show="state.filtersIsOpen"
      @dismiss="state.filtersIsOpen = false"
      @confirm="addFilters"
    />
</template>

<script setup lang="ts">
import { Universal, Employee, Organization } from '@/models'
import { reactive, onBeforeMount, watch, computed } from 'vue'
import add from '@/assets/icon/org_item/add.svg'
import filter from '@/assets/icon/filters.svg'
import search from '@/assets/icon/search.svg'
import { UniversalRedArgs } from '@/components/widgets/UOrganizationItem.vue'
import { useUniversalStore, PostUniversal } from '@/stores/universal'
import { useEmploysStore } from '@/stores/employs'
import { useOrganazationStore } from '@/stores/organization'
import { eventBus } from '@/eventBus'
import { useAzureStore } from '@/stores/azure'
import { FiltersData } from '@/components/widgets/UFiltersView.vue'
import { cloneDeep } from 'lodash'
import noResults from '@/assets/icon/no_results.svg'

const universalStore = useUniversalStore()
const employsStore = useEmploysStore()
const orgStore = useOrganazationStore()
const azureStore = useAzureStore()

const state = reactive({
  tab: 'persons',
  organization: {} as Organization,
  departments: [] as Universal[],
  positions: [] as Universal[],
  persons: [] as Employee[],
  addUniversalIsOpen: false,
  addUniversalToPersonIsOpen: false,
  isSuccess: false,
  operType: '',
  filtersIsOpen: false,
  searchString: '',
  filtersOrg: {} as FiltersData
})

const dialogState = reactive({
  dialogHeader: '',
  dialogText: '',
  dialogShowBtns: true,
  dialogCancelBtn: false,
  loadingDialog: false,
  showDialog: false
})

const personData = reactive({
  addPersonIsOpen: false,
  person: null as Employee | null,
  id: ''
})

function personDataDismiss () {
  personData.addPersonIsOpen = false
  personData.person = null
  personData.id = ''
}

export interface UniversalSaveData {
  type: string
  data: PostUniversal
}

interface DialogInfo {
  header: string
  text: string
  isSuccess: boolean
}

const unversalAddState = reactive({
  name: '',
  id: '',
  perId: ''
})

onBeforeMount(() => {
  state.organization = JSON.parse(sessionStorage.getItem('organization') || '{}')
  state.departments = JSON.parse(sessionStorage.getItem('departments') || '[]')
  state.positions = JSON.parse(sessionStorage.getItem('positions') || '[]').positions
  state.persons = JSON.parse(sessionStorage.getItem('persons') || '[]')
  state.filtersOrg.departments = state.departments.map(item => item.id)
  state.filtersOrg.positions = state.positions.map(item => item.id)
  state.filtersOrg.persons = state.persons.map(item => item.id)
})

function updatePersons () {
  state.persons = JSON.parse(sessionStorage.getItem('persons') || '[]')
  state.filtersOrg.positions = state.positions.map(item => item.id)
  personDataDismiss()
}

function addFilters (filters: FiltersData) {
  state.filtersOrg = cloneDeep(filters)
}

const visibleItemsCount = computed(() => {
  if (state.tab === 'persons') {
    return state.persons.filter(item => searchPerson(item)).length
  } else {
    const items = state.tab === 'departments' ? state.departments : state.positions
    return items.filter(item =>
      item.name.toLowerCase().includes(state.searchString.toLowerCase()) &&
      filterUniversal(item)
    ).length
  }
})

function openUnversalAdd (name: string = '', id: string = '') {
  if (['departments', 'positions'].includes(state.tab)) {
    unversalAddState.name = name
    unversalAddState.id = id
    state.addUniversalIsOpen = true
  } else {
    personData.addPersonIsOpen = true
  }
}

function itemAction (args: UniversalRedArgs) {
  state.operType = args.type
  if (args.type === 'red') {
    openUnversalAdd(args.name, args.id)
  } else {
    unversalAddState.id = args.id
    openDialog('Удалить?',
    `Вы уверены, что хотите удалить ${args.name}?`,
    true, true
    )
  }
}

function personDataDialog (args: Record<string, string>) {
  state.persons = JSON.parse(sessionStorage.getItem('persons') || '[]')
  openDialog(args.header, args.message, true, false)
}

function calcPersons (id: string) {
  let count = 0
  for (const person of state.persons) {
    switch (state.tab) {
      case 'departments':
        if (person.depArr.includes(id)) {
          count += 1
        }
        break
      case 'positions':
        if (person.posArr.includes(id)) {
          count += 1
        }
        break
      default:
        count += 0
    }
  }
  return count
}

async function saveUniversal (data: UniversalSaveData) {
  if (data.type === 'post') {
    showLoader()
    const result = await universalStore.postUniversal(state.organization.orgId, state.tab, data.data)
    if (result && result.status === 'created') {
      await updateOrgData()
      closeDialog()
      state.addUniversalIsOpen = false
      state.isSuccess = true
    }
  }
}

interface PatchData {
  [key: string]: string[] | string;
}

async function dialogResult (res: string) {
  try {
    if (res === 'cancel') {
      closeDialog()
    } else {
      showLoader()
      if (state.operType === 'del') {
        const res = await universalStore.delUniversal(state.organization.orgId, state.tab, unversalAddState.id)
        if (res?.status === 'updated') {
          await updateOrgData()
          openDialog('Успех', res.message, true, false)
        } else {
          openDialog('Ошибка', 'Упс! Что-то пошло не так!', true, false)
        }
      } else if (state.operType === 'deleteUniversalPerson') {
        const tmpArrName = state.tab === 'departments' ? 'depArr' : 'posArr'
        const tmpArgName = state.tab === 'departments' ? 'depId' : 'posOrg'
        const tmpArr = state.persons.find(person => unversalAddState.perId === person.id)![tmpArrName].filter(unId => unId !== unversalAddState.id)
        let patchData: PatchData = {
          id: unversalAddState.perId,
          [tmpArrName]: tmpArr
        }
        if (tmpArr.length === 0) {
          patchData = { ...patchData, ...{ [tmpArgName]: '' } }
        }
        const res = await employsStore.patchPerson(state.organization.orgId, [patchData])
        if (res?.status === 'complete') {
          await updateOrgData(true)
          eventBus.emit('updatePersons')
          openDialog('Успех', res.message, true, false)
        } else {
          openDialog('Ошибка', 'Упс! Что-то пошло не так!', true, false)
        }
      } else if (state.operType === 'deletePerson') {
        const result = await employsStore.deletePerson(state.organization.orgId, personData.id)
        await azureStore.delPersonCompanyAzure(personData.person!)
        await employsStore.getEmploysAction(state.organization.orgId)
        state.persons = employsStore.getEmploys
        sessionStorage.setItem('persons', JSON.stringify(state.persons))
        if (result!.status === 'delete') {
          openDialog('Успех', result?.message, true, false)
        } else {
          openDialog('Ошибка', 'Упс! Что-то пошло не так!', true, false)
        }
      } else {
        closeDialog()
        personData.id = ''
        personData.person = null
      }
    }
  } catch {
    openDialog('Ошибка', 'Упс! Что-то пошло не так!', true, false)
  } finally {
    state.operType = ''
  }
}

function showLoader () {
  openDialog('', '', false, false, true)
}

function openDialog (
  header: string,
  text: string = '',
  showBtns: boolean = true,
  showCancelBtn: boolean = true,
  isLoadind: boolean = false
) {
  dialogState.dialogHeader = header
  dialogState.dialogText = text
  dialogState.dialogShowBtns = showBtns
  dialogState.dialogCancelBtn = showCancelBtn
  dialogState.loadingDialog = isLoadind
  dialogState.showDialog = true
}

async function showResDialog (res: DialogInfo) {
  if (res.isSuccess) {
    state.persons = JSON.parse(sessionStorage.getItem('persons') || '[]')
  }
  openDialog(res.header, res.text, true, false)
}

function closeDialog () {
  dialogState.showDialog = false
}

async function updateOrgData (withPersons: boolean = false) {
  await orgStore.getDepartmentsAction(state.organization.orgId)
  state.departments = orgStore.getDepartments
  sessionStorage.setItem('departments', JSON.stringify(state.departments))
  await employsStore.getPositionsAction(state.organization.orgId)
  sessionStorage.setItem('positions', JSON.stringify(employsStore.getPositions))
  state.departments = JSON.parse(sessionStorage.getItem('departments') || '[]')
  state.positions = JSON.parse(sessionStorage.getItem('positions') || '[]').positions
  if (withPersons) {
    await employsStore.getEmploysAction(state.organization.orgId)
    state.persons = employsStore.getEmploys
    sessionStorage.setItem('persons', JSON.stringify(state.persons))
  }
}

async function closeSuccessDialog () {
  state.isSuccess = false
}

function delPerson (perId: string) {
  state.operType = 'deleteUniversalPerson'
  unversalAddState.perId = perId
  let text = ''
  if (state.tab === 'departments') {
    text = `исключить ${state.persons.find(person => person.id === perId)?.name} из ${state.departments.find(dep => dep.id === unversalAddState.id)?.name}?`
  } else if (state.tab === 'positions') {
    text = `убрать ${state.departments.find(pos => pos.id === unversalAddState.id)?.name} из должностей ${state.persons.find(person => person.id === perId)?.name}?`
  }
  openDialog('Исключить?', `Уверены, что хотите ${text}`)
}

function personAction (args: Record<string, string>) {
  personData.person = state.persons.find(per => per.id === args.id)!
  if (args.type === 'red') {
    personData.addPersonIsOpen = true
  } else {
    state.operType = 'deletePerson'
    personData.id = args.id
    openDialog('Удалить?', `Уверены, что хотите удалить ${state.persons.find(per => per.id === args.id)?.name}?`)
  }
}

watch(() => state.tab, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    state.searchString = ''
  }
})

function filterUniversal (item: Universal) {
  return state.filtersOrg[state.tab as keyof typeof state.filtersOrg].includes(item.id)
}

function searchPerson (person: Employee) {
  if (!state.filtersOrg.persons.includes(person.id)) {
    return false
  }
  let i = 0
  for (const dep of person.depArr) {
    if (!state.filtersOrg.departments.includes(dep)) {
      i += 1
    }
  }
  if (person.depArr.length === i) {
    return false
  }
  i = 0
  for (const dep of person.posArr) {
    if (!state.filtersOrg.positions.includes(dep)) {
      i += 1
    }
  }
  if (person.posArr.length === i) {
    return false
  }
  if (state.searchString === '' || person.name.toLowerCase().indexOf(state.searchString.toLocaleLowerCase()) > -1) {
    return true
  }
  for (const dep of person.depArr) {
    if (!state.filtersOrg.departments.includes(dep)) {
      i += 1
    }
    const depName = state.departments.find(item => item.id === dep)?.name
    if (depName && depName
      .toLowerCase()
      .indexOf(state.searchString.toLowerCase()) > -1
    ) {
      return true
    }
  }
  for (const pos of person.posArr) {
    const posName = state.positions.find(item => item.id === pos)?.name
    if (posName && posName
      .toLowerCase()
      .indexOf(state.searchString.toLowerCase()) > -1
    ) {
      return true
    }
  }
  return false
}
</script>

<style>
.organization-header {
  display: flex;
  flex-direction: column;
  margin-bottom: 4.63vh;
  gap: 2.315vh;
}

.organization-header-tabs {
  display: flex;
  justify-content: space-between;
}

.organization-header-search {
  display: flex;
  height: 3.704vh;
  gap: 1.8519vh

}

.organization-tabs-controller {
  font: 0.83vw 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
  color: var(--primary-font-color-a70);
  display: flex;
  gap: 2.34vw;
  align-items: center;
}

#organization-tabs-controller-active {
  color: var(--primary-font-color);
  text-decoration: underline;
  text-decoration-color: var(--primary-font-color-blue);
  text-decoration-thickness: 1px;
  text-underline-offset: 0.7vw;
}

.org-add-btn {
  display: flex;
  gap: 0.926vh;
  width: 18.2292vw;
  background-color: #042447;
  border-radius: 13.89vh;
  align-items: center;
  justify-content: center;
}

.org-add-btn-text {
  font: 400 1.389vh 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
  color: var(--primary-font-color);
}

.organization-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-items: center;
  grid-row-gap: 3vh;
}

.org-add-filters {
  display: flex;
  background-color: #2D4B6B;
  border-radius: 9.2593vh;
  width: 3.1771vw;
  align-items: center;
  justify-content: center;
}

.org-add-search-wrapper {
  display: flex;
  position: relative;
  width: -webkit-fill-available;
  align-items: center;
}

.org-add-search-string {
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  background-color: #2D4B6B;
  border: none;
  border-radius: 13.89vh;
  padding-left: 1.0417vw;
  padding-right: 3vw;
  font: 400 1.389vh 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
  color: var(--primary-font-color);
}

.org-add-search-img {
  position: absolute;
  right: 1.0417vw;
}

.no-results-wrapper {
  display: flex;
  flex-direction: column;
  width: 31.771vw;
  align-items: center;
}

.no-results-wrapper-img {
  width: 4.6875vw;
  margin-bottom: 1.8519vh;
}

.no-results-wrapper-header {
  font: 600 2.315vh 'RedHatText-Bold', 'OpenSans', sans-serif;
  color: var(--primary-font-color);
  margin-bottom: 0.7vh;
}

.no-results-wrapper-body {
  font: 400 1.8519vh 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
  color: var(--primary-font-color-a70);
  text-align: center;
}

.no-results-main-wrapper {
  width: -webkit-fill-available;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
