<template>
    <div class="dropdown-timezone">
        <div class='dropdown-timezone-header' @click="state.isOpen = !state.isOpen">
            <div class="hours-info">
                <span class="hours-info-city">{{ state.clientTimezone?.city }}</span>
                <span v-if="state.clientTimezone" class="hours-info-country">{{ `${state.clientTimezone?.country}, GMT ${state.clientTimezone?.offsetString}` }}</span>
                <span v-else></span>
            </div>
            <img :src="dropDown" class="button-click">
        </div>
        <div v-if="state.isOpen" class="dropdown-timezone-content">
            <div v-for="(item, index) in sortedHours" :key="index" @click="selectOption(item)">
                <div class="dropdown-timezone-items">
                    <span class="hours-info-city">{{ item.city }}</span>
                    <span class="hours-info-country">{{ `${item.country}, GMT ${item.offsetString}` }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { reactive, watch, computed } from 'vue'
import dropDown from '@/assets/icon/dropdown-open.svg'
import { hours, HoursItem } from '@/stores/hours'

const emits = defineEmits(['change-timezone'])

const sortedHours = computed(() => {
  return [...hours].sort((a, b) => a.offsetNumeric - b.offsetNumeric)
})

const state = reactive({
  isOpen: false,
  clientTimezone: null as HoursItem | null | undefined
})

const props = defineProps({
  timezone: {
    type: Number,
    required: false
  }
})

watch(() => props.timezone, () => {
  state.clientTimezone = getClientTimezone()
})

function getClientTimezone () {
  if (props.timezone) {
    return hours.find(item => item.offsetNumeric === props.timezone)
  } else return null
}

function selectOption (item: HoursItem) {
  state.isOpen = false
  emits('change-timezone', item.offsetNumeric)
}
</script>

<style>
.hours-info {
  display: flex;
  flex-direction: column;
}

.dropdown-timezone {
  position: relative;
  height: 2.60vw;
  width: 23vw;
  align-self: center;
  border-radius: 1.4rem;
  padding: 0 1vw;
  background-color: #2D4B6B;
  color: #FFFFFF80;
  font-family: 'RedHatDisplay-Regular', 'OpenSans Regular', sans-serif;
  display: flex;
  align-items: center;
}

.dropdown-timezone-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dropdown-timezone-header img {
  width: 0.9vw;
}

.hours-info-city {
  font-size: 0.78vw;
}

.hours-info-country {
  font-size: 0.52vw;
}

.dropdown-timezone-content {
  background-color: #2D4B6B;
  width: 21.5vw;
  z-index: 1;
  position: absolute;
  bottom: 100%;
  right: 0;
  margin-bottom: 0.5vw;
  border-radius: 1.4rem;
  padding: 1vw;
  display: flex;
  flex-direction: column;
  gap: 1vw;
  max-height: 40vh;
  overflow-y: auto;
}

.dropdown-timezone-items {
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 850px) {
  .dropdown-timezone-header img {
    width: 4vw;
  }
  .hours-info-city {
    font-size: 3vw;
  }

  .hours-info-country {
    font-size: 2vw;
  }
  .dropdown-timezone-content {
    width: 77vw;
    padding: 4vw 0 4vw 4vw;
    gap: 2vw;
  }
}
</style>
