import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "udialog-overlay" }
const _hoisted_2 = { class: "choose-photo-type" }
const _hoisted_3 = { class: "add-person-header" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "choose-photo-type-actions" }
const _hoisted_6 = ["src"]
const _hoisted_7 = ["src"]

import cancel from '@/assets/icon/cancel-dialog.svg'
import galery from '@/assets/icon/galery.svg'
import photo from '@/assets/icon/photo.svg'
import { ref, Ref } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'UChoosePhotoType',
  emits: ['dismiss', 'result', 'getted-photo'],
  setup(__props, { emit: __emit }) {

const emits = __emit
const fileInput = ref() as Ref<HTMLInputElement>

function triggerFileInput () {
  fileInput.value?.click()
}

function handleFileChange (event: Event) {
  const input = event.target as HTMLInputElement
  if (input.files && input.files[0]) {
    const file = input.files[0]
    const reader = new FileReader()
    reader.onload = (e) => {
      const imageData = e.target?.result
      if (imageData) {
        emits('getted-photo', imageData)
      }
    }
    reader.readAsDataURL(file)
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Transition, { name: "udialog-fade" }, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _cache[2] || (_cache[2] = _createElementVNode("span", { class: "add-person-header-text" }, " Добавить изображение профиля ", -1)),
            _createElementVNode("img", {
              src: _unref(cancel),
              class: "button-click",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (emits('dismiss')))
            }, null, 8, _hoisted_4)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", {
              class: "choose-photo-type-btn button-click",
              onClick: triggerFileInput
            }, [
              _createElementVNode("img", {
                class: "choose-photo-type-img",
                src: _unref(galery)
              }, null, 8, _hoisted_6),
              _cache[3] || (_cache[3] = _createElementVNode("div", { class: "upload-button" }, [
                _createElementVNode("span", null, "Выбрать из галереи")
              ], -1)),
              _createElementVNode("input", {
                type: "file",
                onChange: handleFileChange,
                ref_key: "fileInput",
                ref: fileInput,
                style: {"display":"none"}
              }, null, 544)
            ]),
            _createElementVNode("div", {
              class: "choose-photo-type-btn button-click",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (emits('result', 'photo')))
            }, [
              _createElementVNode("img", {
                class: "choose-photo-type-img",
                src: _unref(photo)
              }, null, 8, _hoisted_7),
              _cache[4] || (_cache[4] = _createElementVNode("span", null, "Сфотографировать", -1))
            ])
          ])
        ])
      ], 512), [
        [_vShow, true]
      ])
    ]),
    _: 1
  }))
}
}

})