import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "phone-input inputs" }

import { reactive, watch } from 'vue'
import { Country } from '@/models'


export default /*@__PURE__*/_defineComponent({
  __name: 'UPhoneNumber',
  emits: ['change-country', 'input-phone'],
  setup(__props, { emit: __emit }) {

const state = reactive({
  login: '',
  country: {} as Country,
  rawPhoneNumber: ''
})

const emits = __emit

function countryCodeChoosen (country: Country) {
  state.country = country
  emits('change-country', state.country)
}

function formatPhoneNumber () {
  const numbers = state.rawPhoneNumber.replace(/\D/g, '')
  const clippedNumbers = numbers.slice(0, state.country.country === 'kgz' ? 9 : 10)

  state.login = clippedNumbers
    .split('')
    .reduce((acc, num) => {
      return `${acc}${num}`
    }, '')
}

watch(() => state.login, (newValue) => {
  state.rawPhoneNumber = newValue.replace(/\D/g, '')
  emits('input-phone', state.rawPhoneNumber)
})

return (_ctx: any,_cache: any) => {
  const _component_u_dropdown = _resolveComponent("u-dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_u_dropdown, {
      class: "inp-dropdown",
      onDropdownChoosen: countryCodeChoosen
    }),
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "inp-delimeter" }, null, -1)),
    _withDirectives(_createElementVNode("input", {
      type: "tel",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((state.login) = $event)),
      onInput: formatPhoneNumber,
      placeholder: "Номер телефона"
    }, null, 544), [
      [_vModelText, state.login]
    ])
  ]))
}
}

})