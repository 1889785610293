<template>
    <div class="report-miniature-wrapper">
        <div class="report-miniature-actions">
            <img class="report-icon button-click" :src="reportsMap[props.type as keyof typeof reportsMap]?.img">
            <span class="report-name">{{ reportsMap[props.type as keyof typeof reportsMap]?.text }}</span>
            <button class="to-report-btn button-click"
              @click="emits('to-report', reportsMap[props.type as keyof typeof reportsMap]?.code)">
              Перейти к отчету
            </button>
        </div>
        <img :src="ellipse" class="ellipse">
    </div>
</template>

<script setup lang="ts">
import fixReport from '@/assets/icon/fix-report.svg'
import journalReport from '@/assets/icon/journal-report.svg'
import ellipse from '@/assets/images/ellipse.svg'

const emits = defineEmits(['to-report'])

const props = defineProps({
  type: {
    type: String,
    default: '',
    required: true
  }
})

const reportsMap = {
  fix: { img: fixReport, text: 'Фикс-отчет', code: 'fix' },
  journal: { img: journalReport, text: 'Журналы', code: 'journal' }
}
</script>

<style>
.report-miniature-wrapper {
  display: flex;
  width: 13.02vw;
  height: 8.85vw;
  border-radius: 1.30vw;
  background-color: #2D4B6B;
}
.report-miniature-actions {
  display: flex;
  flex-direction: column;
  padding: 1.30vw;
  width: 100%;
  justify-content: space-between;
}

.report-name {
  font: 1.04vw 'RedHatText-SemiBold', 'OpenSans', sans-serif;
  color: var(--primary-font-color);
  margin-bottom: 1.04vw;
}

.to-report-btn {
  font: 0.52vw 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
  color: var(--primary-font-color-blue);
  background-color: #ffffff0d;
  padding: 0.36vw 0.63vw;
  border-radius: 2vw;
  width: fit-content;
  border: none;
}

.report-icon {
  margin-bottom: 0.63vw;
  width: 1.82vw;
  height: 1.82vw;
}

.ellipse {
  width: 3.91vw;
  height: 3.91vw;
}
@media screen and (max-width: 850px) {
  .report-miniature-wrapper {
    width: 63.61vw;
    height: 43.26vw;
    border-radius: 6.36vw;
  }
  .report-miniature-actions {
    padding: 6.36vw;
  }
  .report-name {
    font-size: 5.09vw;
    margin-bottom: 5.34vw;
  }
  .to-report-btn {
    padding: 1.78vw 3.05vw;
    font-size: 2.54vw;
    border-radius: 10vw;
  }
  .report-icon {
    margin-bottom: 3.05vw;
    width: 8.91vw;
    height: 8.91vw;
  }
  .ellipse {
    width: 19.08vw;
    height: 19.08vw;
  }
}
</style>
