import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "pin-input-overlay"
}
const _hoisted_2 = { class: "pin-input-pad" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "pin-working-pad" }

import { reactive, onBeforeMount } from 'vue'
import cancel from '@/assets/icon/cancel-dialog.svg'


export default /*@__PURE__*/_defineComponent({
  __name: 'UPinInput',
  props: {
  lockPassword: {
    type: String,
    default: '',
    required: false
  }
},
  emits: ['close-pin-input', 'to-settings'],
  setup(__props, { emit: __emit }) {

const emits = __emit

const state = reactive({
  pin: '',
  isError: false,
  infoText: 'Доступ к настройкам ограничен. Для перехода  на страницу введите пароль.',
  errorText: 'Пароль не верный!'
})

const props = __props

onBeforeMount(() => {
  if (props.lockPassword.length === 0) {
    emits('to-settings')
  }
})

function checkPin () {
  if (state.pin === props.lockPassword) {
    emits('to-settings')
  } else {
    state.isError = true
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Transition, { name: "pin-input-fade" }, {
    default: _withCtx(() => [
      true
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("img", {
                src: _unref(cancel),
                class: "pin-input-close button-click",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (emits('close-pin-input')))
              }, null, 8, _hoisted_3),
              _createElementVNode("div", _hoisted_4, [
                _cache[2] || (_cache[2] = _createElementVNode("span", { class: "pin-input-header" }, " Подтверждение ", -1)),
                _createElementVNode("span", {
                  class: _normalizeClass(state.isError ? 'pin-input-error' : 'pin-input-info')
                }, _toDisplayString(state.isError ? state.errorText : state.infoText), 3),
                _withDirectives(_createElementVNode("input", {
                  type: "password",
                  placeholder: "Введите пароль",
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((state.pin) = $event)),
                  class: _normalizeClass(state.pin.length > 0 ? 'pin-is-not-empty' : 'pin-input-password')
                }, null, 2), [
                  [_vModelText, state.pin]
                ]),
                _createElementVNode("button", {
                  class: "pin-input-button button-click",
                  onClick: checkPin
                }, " Подтвердить ")
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})