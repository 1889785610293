import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, withModifiers as _withModifiers, Transition as _Transition, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "mainpage-wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "side-menu"
}
const _hoisted_3 = { class: "upside-menu" }
const _hoisted_4 = { class: "tabs" }
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 1,
  class: "main-delimeter"
}
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "tab-wrapper" }
const _hoisted_9 = { class: "main-header" }
const _hoisted_10 = {
  key: 2,
  class: "lasttime-data"
}
const _hoisted_11 = { class: "main-header-org-info" }
const _hoisted_12 = { class: "main-small-menu" }
const _hoisted_13 = ["src"]
const _hoisted_14 = { class: "mobile-menu-overlay" }
const _hoisted_15 = { class: "mobile-menu-wrapper" }
const _hoisted_16 = ["src"]

import { onBeforeMount, reactive, watch, onMounted, markRaw, provide } from 'vue'
import Dashboards from './DashboardsView.vue'
import Settings from './SettingsView.vue'
import ReportView from './ReportView.vue'
import OrganizationView from './OrganizationView.vue'
import PlansView from './PlansView.vue'
import PaymentsView from './PaymentsView.vue'
import PaySteps from './PaySteps.vue'
import logo from '@/assets/images/logo.svg'
import mobileMenu from '@/assets/icon/mob-menu.svg'
import { iconMap } from '@/maps'
import reviewIcon from '@/assets/icon/review.svg'
// models
import {
  Cookies,
  Fix,
  Departments,
  FeeStatisticList,
  Organization,
  Employee,
  GenericObject
} from '@/models'
// stores
import { useFixesStore } from '@/stores/fixes'
import { useCookesStore } from '@/stores/cookies'
import { useOrganazationStore } from '@/stores/organization'
import { useEmploysStore } from '@/stores/employs'
import { useSettingsStore } from '@/stores/settings'
// reports
import FixReport from '@/reports/FixRep.vue'
import OnWorkplace from '@/reports/OnWorkplace.vue'
import WhoLate from '@/reports/WhoLate.vue'
import FinesRep from '@/reports/FinesRep.vue'
import {
  delay,
  getMonthStartEnd,
  getStartOfDayInMilliseconds,
  getEndOfDayInMilliseconds,
  getPreviousMonthInfo,
  dataUpdTime
} from '@/globalFunctions'
import router from '@/router'
import { useFinesStore } from '@/stores/fines'
import { ArrayMap } from '@/components/UI/UDropdownFilter.vue'
import { eventBus } from '@/eventBus'


export default /*@__PURE__*/_defineComponent({
  __name: 'MainView',
  setup(__props) {

const cookies = useCookesStore()
const fixes = useFixesStore()
const organization = useOrganazationStore()
const employs = useEmploysStore()
const settingStore = useSettingsStore()
const finesStore = useFinesStore()

const state = reactive({
  orgId: '',
  showDialog: false,
  tab: 'dashboard',
  cookies: {} as Cookies,
  fixData: [] as Fix[],
  todayFixData: [] as Fix[],
  departments: [] as Departments,
  dataLoaded: false,
  tabTmp: '',
  dialogHeader: '',
  dialogShowBtns: false,
  dialogCancelBtn: false,
  loadingDialog: false,
  operType: '',
  showMobMenu: false,
  fines: [] as FeeStatisticList,
  report: markRaw(FixReport),
  organization: {} as Organization,
  showReview: false,
  persons: [] as Employee[],
  reportName: '',
  dataUpdLastTime: '',
  showPinVue: false
})

const payInfo = reactive({
  payRout: '',
  summ: '',
  type: ''
})

const reportsMap = {
  fix: { page: markRaw(FixReport), name: 'Фикс-отчет' },
  onworkplace: { page: markRaw(OnWorkplace), name: 'На месте' },
  wholate: { page: markRaw(WhoLate), name: 'Фиксация' },
  finesrep: { page: markRaw(FinesRep), name: 'Штрафы' }
}

const isMobile = window.innerWidth < 800

const days: ArrayMap[] = [
  { text: 'Сегодня', value: 'today' },
  { text: 'Вчера', value: 'yesterday' },
  { text: 'Текущая неделя', value: 'currentWeek' },
  { text: 'Прошлая неделя', value: 'prevWeek' },
  { text: 'Текущий месяц', value: 'currentMonth' },
  { text: 'Прошлый месяц', value: 'prevMonth' }
]

provide('days', days)

onBeforeMount(async () => {
  state.dataUpdLastTime = dataUpdTime()
  openDialog('', false, false, true)
  await cookies.getCookie()
  state.cookies = cookies.getCookies
  state.orgId = state.cookies.orgId
  await getOrgInfo()
  await prepareData()
  closeDialog()
  state.dataUpdLastTime = dataUpdTime()
  state.dataLoaded = true
})

onMounted(() => {
  refreshTodayFix()
})

async function getOrgInfo () {
  await organization.getOrganizationAction(state.orgId)
  state.organization = organization.getOrganization
  sessionStorage.setItem('organization', JSON.stringify(state.organization))
  await organization.getDepartmentsAction(state.orgId)
  state.departments = organization.getDepartments
  sessionStorage.setItem('departments', JSON.stringify(state.departments))
  await employs.getPositionsAction(state.orgId)
  sessionStorage.setItem('positions', JSON.stringify(employs.getPositions))
  await employs.getEmploysAction(state.orgId)
  state.persons = employs.getEmploys
  sessionStorage.setItem('persons', JSON.stringify(state.persons))
  await getFinesInfo()
  await settingStore.getFeeAction(state.orgId)
  sessionStorage.setItem('fees', JSON.stringify(settingStore.getFeeList))
}

async function getFinesInfo () {
  const currYear = new Date().getFullYear()
  const prevMonth = getPreviousMonthInfo()[1]

  const prevMonthTimestamps = getMonthStartEnd(prevMonth, prevMonth === 12 ? currYear - 1 : currYear)
  const timestamps = getMonthStartEnd()
  await finesStore.getFeeAction(state.orgId, prevMonthTimestamps[0], timestamps[1])
  state.fines = finesStore.feeStatisticList
  sessionStorage.setItem('fee-statistic', JSON.stringify(state.fines))
}

async function prepareData () {
  const timestamps = getMonthStartEnd()
  await fixes.getFixesAction(state.orgId, null, timestamps[0], timestamps[1])
  state.fixData = fixes.getFixes
  state.todayFixData = state.fixData.filter(item => item.time > getStartOfDayInMilliseconds() && item.time < getEndOfDayInMilliseconds())
  sessionStorage.setItem('fixes', JSON.stringify(state.fixData))
}

async function refreshTodayFix () {
  await delay(60000)
  await fixes.getFixesAction(state.orgId, null, getStartOfDayInMilliseconds(), getEndOfDayInMilliseconds())
  state.todayFixData = fixes.getFixes
  state.dataUpdLastTime = dataUpdTime()
  refreshTodayFix()
}

function toSettings () {
  state.showPinVue = false
  state.tab = 'settings'
}

function openDialog (
  header: string,
  showBtns: boolean = true,
  showCancelBtn: boolean = true,
  isLoadind: boolean = false
) {
  state.dialogHeader = header
  state.dialogShowBtns = showBtns
  state.dialogCancelBtn = showCancelBtn
  state.loadingDialog = isLoadind
  state.showDialog = true
}

function closeDialog () {
  state.showDialog = false
}

function changeTab (arg: string, isReport: boolean = false) {
  if (isReport) {
    state.tab = `@report-${arg}`
  } else {
    if (arg === 'settings') {
      state.showPinVue = true
    } else {
      state.tab = arg
    }
  }
}

function logOut () {
  state.operType = 'log-out'
  openDialog('Выйти?')
}

async function dialogRes (arg: string) {
  if (arg === 'ok') {
    if (state.operType === 'log-out') {
      await cookies.getCookie()
      const cookiesLast = cookies.getCookies
      const currentDate = new Date().getTime()
      await cookies.setCookie({
        token: cookiesLast.orgId,
        orgId: cookiesLast.orgId,
        expTime: currentDate - (24 * 60 * 60 * 100)
      })
      router.push({ name: 'auth' })
    }
  }
  state.showDialog = false
  state.operType = ''
}

function toReport (arg: string) {
  changeTab(arg, true)
  state.report = reportsMap[arg as keyof typeof reportsMap]?.page
  state.reportName = reportsMap[arg as keyof typeof reportsMap]?.name
}

watch(() => state.tab, (newValue, oldValue) => {
  if (!state.dataLoaded) {
    state.tab = oldValue
    if (state.tabTmp === '') {
      state.tabTmp = newValue
    }
    openDialog('Загружаем данные', false, false, true)
  }
})

watch(() => state.todayFixData, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    sessionStorage.setItem('today-fix', JSON.stringify(newValue))
  }
})

watch(() => state.dataLoaded, (newValue) => {
  if (newValue && state.tabTmp !== '') {
    state.tab = state.tabTmp
    state.tabTmp = ''
    closeDialog()
  }
})

async function beforeFadeLeave (el: Element) {
  el.classList.add('mobile-menu-fade-out')
  await delay(500)
}

function afterFadeLeave (el: Element) {
  el.classList.remove('mobile-menu-fade-out')
}

eventBus.on('to-payments', () => {
  state.tab = 'payments'
})

eventBus.on('update-plan', () => {
  state.organization = JSON.parse(sessionStorage.getItem('organization') || '{}')
})

eventBus.on('to-pay-steps', (obj: GenericObject | Record<string, string> | null) => {
  if (!obj) {
    return
  }
  state.tab = 'pay-steps'
  payInfo.payRout = String(obj.routText)
  payInfo.summ = String(obj.summ)
  payInfo.type = String(obj.type)
})

function toPrev () {
  if (state.tab === 'pay-steps') {
    payInfo.payRout = ''
    payInfo.type = ''
    state.tab = 'payments'
  } else {
    state.tab = 'plans'
  }
}

return (_ctx: any,_cache: any) => {
  const _component_u_icon_button = _resolveComponent("u-icon-button")!
  const _component_u_payments_header = _resolveComponent("u-payments-header")!
  const _component_u_report_header = _resolveComponent("u-report-header")!
  const _component_u_org_pay_info = _resolveComponent("u-org-pay-info")!
  const _component_u_org_info = _resolveComponent("u-org-info")!
  const _component_u_dialog = _resolveComponent("u-dialog")!
  const _component_u_review = _resolveComponent("u-review")!
  const _component_u_pin_input = _resolveComponent("u-pin-input")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (!isMobile)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("img", {
                  src: _unref(logo),
                  class: "logo-img button-click",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (state.tab = 'dashboard'))
                }, null, 8, _hoisted_5),
                _createVNode(_component_u_icon_button, {
                  class: "tab",
                  type: 'dashboard',
                  onIconBtnClicked: _cache[1] || (_cache[1] = ($event: any) => (changeTab('dashboard'))),
                  isActive: state.tab === 'dashboard'
                }, null, 8, ["isActive"]),
                _createVNode(_component_u_icon_button, {
                  class: "tab",
                  type: 'reports',
                  onIconBtnClicked: _cache[2] || (_cache[2] = ($event: any) => (changeTab('reports'))),
                  isActive: state.tab === 'reports' || state.tab.includes('@report-')
                }, null, 8, ["isActive"]),
                _createVNode(_component_u_icon_button, {
                  class: "tab",
                  type: 'organization',
                  onIconBtnClicked: _cache[3] || (_cache[3] = ($event: any) => (changeTab('organization'))),
                  isActive: state.tab === 'organization'
                }, null, 8, ["isActive"]),
                _createVNode(_component_u_icon_button, {
                  class: "tab",
                  type: 'plans',
                  onIconBtnClicked: _cache[4] || (_cache[4] = ($event: any) => (changeTab('plans'))),
                  isActive: ['payments', 'plans', 'pay-steps'].includes(state.tab)
                }, null, 8, ["isActive"]),
                _createVNode(_component_u_icon_button, {
                  class: "tab",
                  type: 'settings',
                  onIconBtnClicked: _cache[5] || (_cache[5] = ($event: any) => (changeTab('settings'))),
                  isActive: state.tab === 'settings'
                }, null, 8, ["isActive"])
              ]),
              _createElementVNode("div", null, [
                _createVNode(_component_u_icon_button, {
                  type: 'logOut',
                  onClick: logOut
                })
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      (!isMobile)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6))
        : _createCommentVNode("", true),
      isMobile
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: "mobile-menu",
            onClick: _cache[6] || (_cache[6] = ($event: any) => (state.showMobMenu = true))
          }, [
            _createElementVNode("img", { src: _unref(mobileMenu) }, null, 8, _hoisted_7),
            _createElementVNode("span", null, _toDisplayString(state.tab !== '' ? _unref(iconMap)[state.tab as keyof typeof iconMap].text : ''), 1)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_8, [
        _withDirectives(_createElementVNode("div", _hoisted_9, [
          (['pay-steps', 'payments'].includes(state.tab))
            ? (_openBlock(), _createBlock(_component_u_payments_header, {
                key: 0,
                currentRout: payInfo.payRout,
                onToPrev: _cache[7] || (_cache[7] = ($event: any) => (toPrev()))
              }, null, 8, ["currentRout"]))
            : (state.tab.startsWith('@report-'))
              ? (_openBlock(), _createBlock(_component_u_report_header, {
                  key: 1,
                  reportName: state.reportName,
                  persons: state.persons,
                  departments: state.departments,
                  onToDashboard: _cache[8] || (_cache[8] = ($event: any) => (state.tab = 'dashboard'))
                }, null, 8, ["reportName", "persons", "departments"]))
              : (state.dataUpdLastTime.length > 0)
                ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(`Данные на ${state.dataUpdLastTime}`), 1))
                : _createCommentVNode("", true),
          _withDirectives(_createElementVNode("div", _hoisted_11, [
            (!['pay-steps', 'payments'].includes(state.tab))
              ? (_openBlock(), _createBlock(_component_u_org_pay_info, {
                  key: 0,
                  sum: state.organization?.balance ?? 0
                }, null, 8, ["sum"]))
              : _createCommentVNode("", true),
            (state.organization && Object.keys(state.organization).length > 0)
              ? (_openBlock(), _createBlock(_component_u_org_info, {
                  key: 1,
                  class: "org-info"
                }))
              : _createCommentVNode("", true)
          ], 512), [
            [_vShow, state.tab.indexOf('report') === -1]
          ])
        ], 512), [
          [_vShow, !['organization', 'settings'].includes(state.tab)]
        ]),
        _withDirectives(_createVNode(Dashboards, {
          onToReport: toReport,
          reportData: state.todayFixData,
          fines: state.fines,
          organization: state.organization
        }, null, 8, ["reportData", "fines", "organization"]), [
          [_vShow, state.tab === 'dashboard']
        ]),
        (state.tab === 'reports')
          ? (_openBlock(), _createBlock(ReportView, {
              key: 0,
              onToReport: toReport
            }))
          : _createCommentVNode("", true),
        (state.tab === 'organization')
          ? (_openBlock(), _createBlock(OrganizationView, { key: 1 }))
          : _createCommentVNode("", true),
        (state.tab === 'plans')
          ? (_openBlock(), _createBlock(PlansView, {
              key: 2,
              onToPayments: _cache[9] || (_cache[9] = ($event: any) => (state.tab = 'payments'))
            }))
          : _createCommentVNode("", true),
        (state.tab === 'payments')
          ? (_openBlock(), _createBlock(PaymentsView, { key: 3 }))
          : _createCommentVNode("", true),
        (state.tab === 'pay-steps')
          ? (_openBlock(), _createBlock(PaySteps, {
              key: 4,
              type: payInfo.type,
              summ: payInfo.summ,
              onToPrev: _cache[10] || (_cache[10] = ($event: any) => (state.tab = 'payments'))
            }, null, 8, ["type", "summ"]))
          : _createCommentVNode("", true),
        (state.tab === 'settings')
          ? (_openBlock(), _createBlock(Settings, { key: 5 }))
          : _createCommentVNode("", true),
        (state.tab.includes('@report'))
          ? (_openBlock(), _createBlock(state.report, { key: 6 }))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("img", {
          src: _unref(reviewIcon),
          class: "main-small-menu-icon button-click",
          onClick: _cache[11] || (_cache[11] = ($event: any) => (state.showReview = true))
        }, null, 8, _hoisted_13)
      ])
    ]),
    _createVNode(_Transition, {
      onBeforeLeave: beforeFadeLeave,
      onAfterLeave: afterFadeLeave,
      name: "mobile-menu-fade"
    }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("div", null, [
              _createElementVNode("img", {
                src: _unref(mobileMenu),
                class: "mob-menu-icon",
                onClick: _cache[12] || (_cache[12] = _withModifiers(($event: any) => (state.showMobMenu = false), ["stop"]))
              }, null, 8, _hoisted_16),
              _createVNode(_component_u_icon_button, {
                type: 'dashboard',
                onIconBtnClicked: _cache[13] || (_cache[13] = ($event: any) => (changeTab('dashboard'))),
                isActive: state.tab === 'dashboard',
                onClick: _cache[14] || (_cache[14] = _withModifiers(($event: any) => (state.showMobMenu = false), ["stop"]))
              }, null, 8, ["isActive"]),
              _createVNode(_component_u_icon_button, {
                type: 'reports',
                onIconBtnClicked: _cache[15] || (_cache[15] = ($event: any) => (changeTab('reports'))),
                isActive: state.tab === 'reports' || state.tab.includes('@report-'),
                onClick: _cache[16] || (_cache[16] = _withModifiers(($event: any) => (state.showMobMenu = false), ["stop"]))
              }, null, 8, ["isActive"]),
              _createVNode(_component_u_icon_button, {
                type: 'settings',
                onIconBtnClicked: _cache[17] || (_cache[17] = ($event: any) => (changeTab('settings'))),
                isActive: state.tab === 'settings',
                onClick: _cache[18] || (_cache[18] = _withModifiers(($event: any) => (state.showMobMenu = false), ["stop"]))
              }, null, 8, ["isActive"])
            ]),
            _createVNode(_component_u_icon_button, {
              type: 'logOut',
              onClick: logOut
            })
          ])
        ], 512), [
          [_vShow, state.showMobMenu]
        ])
      ]),
      _: 1
    }),
    (state.showDialog)
      ? (_openBlock(), _createBlock(_component_u_dialog, {
          key: 0,
          header: state.dialogHeader,
          cancelButton: state.dialogCancelBtn,
          showButtons: state.dialogShowBtns,
          isLoadind: state.loadingDialog,
          onClickRes: dialogRes
        }, null, 8, ["header", "cancelButton", "showButtons", "isLoadind"]))
      : _createCommentVNode("", true),
    (state.showReview)
      ? (_openBlock(), _createBlock(_component_u_review, {
          key: 1,
          onCloseReview: _cache[19] || (_cache[19] = ($event: any) => (state.showReview = false)),
          orgId: state.organization.orgId,
          activeForm: state.tab
        }, null, 8, ["orgId", "activeForm"]))
      : _createCommentVNode("", true),
    (state.showPinVue)
      ? (_openBlock(), _createBlock(_component_u_pin_input, {
          key: 2,
          lockPassword: state.organization.lockPassword,
          onClosePinInput: _cache[20] || (_cache[20] = ($event: any) => (state.showPinVue = false)),
          onToSettings: toSettings
        }, null, 8, ["lockPassword"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})