import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "udialog-overlay"
}
const _hoisted_2 = { class: "udialog" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "udialog-header" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = {
  key: 1,
  class: "udialog-buttons"
}

import cancel from '@/assets/icon/cancel-dialog.svg'


export default /*@__PURE__*/_defineComponent({
  __name: 'UDialog',
  props: {
  header: {
    type: String,
    required: true
  },
  msgText: {
    type: String,
    default: ''
  },
  cancelButton: {
    type: Boolean,
    default: false
  },
  showButtons: {
    type: Boolean,
    default: true
  },
  isLoadind: {
    type: Boolean,
    default: false
  }
},
  emits: ['click-res'],
  setup(__props, { emit: __emit }) {

const props = __props

const emits = __emit


return (_ctx: any,_cache: any) => {
  const _component_u_spinner = _resolveComponent("u-spinner")!

  return (_openBlock(), _createBlock(_Transition, { name: "udialog-fade" }, {
    default: _withCtx(() => [
      true
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              (props.showButtons)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    src: _unref(cancel),
                    class: "udialog-cancel-dialog button-click",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (emits('click-res', 'cancel')))
                  }, null, 8, _hoisted_3))
                : _createCommentVNode("", true),
              _createElementVNode("span", _hoisted_4, _toDisplayString(props.header), 1),
              _createElementVNode("div", {
                class: "udialog-body",
                innerHTML: props.msgText
              }, null, 8, _hoisted_5),
              (props.showButtons && !props.isLoadind)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createElementVNode("button", {
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (emits('click-res', 'ok'))),
                      class: "ures-btn button-click",
                      id: "ok-btn"
                    }, "Ок"),
                    (props.cancelButton)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 0,
                          onClick: _cache[2] || (_cache[2] = ($event: any) => (emits('click-res', 'cancel'))),
                          class: "ures-btn button-click",
                          id: "cancel-btn"
                        }, "Отмена"))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true),
              (props.isLoadind)
                ? (_openBlock(), _createBlock(_component_u_spinner, { key: 2 }))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})