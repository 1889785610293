<template>
    <div class="u-filter-obj">
        <span>{{ props.label }}</span>
        <img :src="cancel" class="button-click" @click="delFilter">
    </div>
</template>

<script setup lang="ts">
import cancel from '@/assets/icon/cancel-icon.svg'

const emits = defineEmits(['del-filter'])

const props = defineProps({
  label: {
    type: String,
    required: true
  },
  filterKey: {
    type: String,
    required: true
  }
})

function delFilter () {
  emits('del-filter', props.filterKey)
}
</script>

<style>
.u-filter-obj {
    display: flex;
    gap: 0.8rem;
    background-color: #FFFFFF0D;
    font: 0.95rem 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
    color: #FFFFFFB2;
    padding: 0.2rem 0.8rem;
    border-radius: 2rem;
    align-items: center;
}
</style>
