<template>
    <div class="org-pay-info-wrapper">
        <div class="org-pay-info-panel">
            <span class="org-pay-info-panel-info">Ваш баланс</span>
            <span class="org-pay-info-panel-sum">{{ props.sum }} сом</span>
        </div>
        <div class="org-pay-info-btn button-click" @click="eventBus.emit('to-payments')">
            <span>Пополнить</span>
        </div>
    </div>
</template>

<script setup lang="ts">
import { eventBus } from '@/eventBus'

const props = defineProps({
  sum: {
    type: Number,
    required: true
  }
})
</script>

<style>
.org-pay-info-wrapper {
    display: flex;
    gap: 1.042vw;
}

.org-pay-info-panel {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.org-pay-info-btn {
    display: flex;
    background: linear-gradient(90deg, #16FAFC 0%, #246DA9 100%);
    font: 600 0.78vw 'RedHatText-SemiBold', 'OpenSans-SemiBold', sans-serif;
    color: var(--primary-font-color);
    align-items: center;
    justify-content: center;
    width: 7.188vw;
    border-radius: 3.71vh;
}

.org-pay-info-panel-info {
    font: 500 0.6vw 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
    color: var(--primary-font-color);
}

.org-pay-info-panel-sum {
    font: 700 0.8vw 'RedHatText-Bold', 'OpenSans', sans-serif;
    color: var(--primary-font-color);
}
</style>
