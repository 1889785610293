<template>
    <button>
        {{ props.btnText }}
    </button>
</template>

<script setup lang="ts">
const props = defineProps({
  btnText: {
    type: String,
    required: true
  }
})
</script>

<style>
</style>
