import { DirectiveBinding } from 'vue'

interface HTMLElementWithClickOutside extends HTMLElement {
  clickOutsideEvent?: EventListenerOrEventListenerObject;
}

export const clickOutsideDirective = {
  beforeMount (el: HTMLElementWithClickOutside, binding: DirectiveBinding) {
    el.clickOutsideEvent = (event: Event) => {
      if (!(el === event.target || el.contains(event.target as Node))) {
        binding.value(event)
      }
    }
    document.addEventListener('click', el.clickOutsideEvent)
  },
  unmounted (el: HTMLElementWithClickOutside) {
    if (el.clickOutsideEvent) {
      document.removeEventListener('click', el.clickOutsideEvent)
    }
  }
}
