<template>
    <div class="rep-onworkplace-wrapper">
        <div class="rep-onworkplace-header">
            <div class="rep-onworkplace-column-name rep-onworkplace-column-item">
                <span>Имя</span>
            </div>
            <div class="rep-onworkplace-column-is-on-place rep-onworkplace-column-item rep-onworkplace-header-block"
                @click="filterWhoOnPlace(filter.onPlace.type)"
                >
                <span>Опоздали</span>
                <span :class="[{'rep-onworkplace-row-on-place': filter.onPlace.type === 'no'}]">{{ filter.onPlace.text }}</span>
            </div>
            <div class="rep-onworkplace-column-fine rep-onworkplace-column-item">
                <span>Время прихода</span>
            </div>
            <div class="rep-onworkplace-column-is-on-place rep-onworkplace-column-item rep-onworkplace-header-block"
                @click="filterWhoOnPlace(filter.onPlace.type)"
                >
                <span>Ушли раньше</span>
                <span :class="[{'rep-onworkplace-row-on-place': filter.onPlace.type === 'no'}]">{{ filter.onPlace.text }}</span>
            </div>
            <div class="rep-onworkplace-column-fine rep-onworkplace-column-item">
                <span>Время ухода</span>
            </div>
        </div>
        <div class="rep-onworkplace-table">
            <div v-for="person in state.persons" :key="person.id" v-show="showRow(person.id)">
                <div :class="['rep-onworkplace-table-row button-click', { 'rep-onworkplace-row-active': person.id === state.id, 'rep-onworkplace-row-on-place': !isOnPlace(person.id) }]">
                    <div class="rep-onworkplace-column-name rep-onworkplace-column-item">
                        <span>{{ person.name.trim() }}</span>
                    </div>
                    <div class="rep-onworkplace-column-is-on-place rep-onworkplace-column-item">
                        <span>{{ timeFine(person.id, 'arrive') ?? 0 <= 0 ? '-' : timeFine(person.id, 'arrive') }}</span>
                    </div>
                    <div class="rep-onworkplace-column-fine rep-onworkplace-column-item">
                        {{ isOnPlace(person.id)
                            ? unitTimestampConvert(
                              state.todayFixData?.find(item => item.perId === person.id && item.type === 'arrive')?.time,
                              'datetime')
                            : '-' }}
                    </div>
                    <div class="rep-onworkplace-column-is-on-place rep-onworkplace-column-item">
                        <span>{{ timeFine(person.id, 'leave') ?? 0 < 0 ? '-' : timeFine(person.id, 'leave') }}</span>
                    </div>
                    <div class="rep-onworkplace-column-fine rep-onworkplace-column-item">
                        {{ isOnPlace(person.id)
                            ? unitTimestampConvert(
                              state.todayFixData.find(item => item.perId === person.id && item.type === 'leave')?.time,
                              'datetime')
                            : '-' }}
                    </div>
                </div>
                <div class="row-delimeter"></div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { onBeforeMount, reactive } from 'vue'
import { Employee, FeeList, Fix } from '@/models'
import { unitTimestampConvert } from '@/globalFunctions'

const state = reactive({
  persons: [] as Employee[],
  id: '',
  todayFixData: [] as Fix[],
  fee: [] as FeeList,
  startOfDay: 0
})

const filterSteps = [{ type: 'all', text: 'Все' }, { type: 'no', text: 'Нет' }, { type: 'yes', text: 'Да' }]

const filter = reactive({
  onPlace: filterSteps[0]
})

onBeforeMount(async () => {
  const now = new Date()
  state.startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime()
  state.persons = JSON.parse(sessionStorage.getItem('persons') || '[]')
  state.todayFixData = JSON.parse(sessionStorage.getItem('today-fix') || '[]')
  state.fee = JSON.parse(sessionStorage.getItem('fee') || '[]')
  console.log(getAloudLateTimeMS('5ad3711b-aed6-4b3a-b111-c3fdcd819383'))
})

function isOnPlace (id: string) {
  const index = state.todayFixData.findIndex(item => item.perId === id)
  return index > -1
}

function filterWhoOnPlace (type: string) {
  const index = filterSteps.findIndex(item => item.type === type)
  filter.onPlace = filterSteps[filterSteps.length - 1 !== index ? index + 1 : 0]
}

function showRow (id: string) {
  if (filter.onPlace.type === 'all') {
    return true
  } else {
    const onPlace = isOnPlace(id)
    if (filter.onPlace.type === 'no') {
      return !onPlace
    } else {
      return onPlace
    }
  }
}

function timeFine (id: string, type: string) {
  const item = state.todayFixData.filter(fix => fix.perId === id)
  const totalTime = item.find(fix => fix.type === type)?.time
  if (type === 'arrive') {
    const aloudLate = (state.fee.find(fee => fee.subjectId === id && fee.subjectType === 'per')?.lateTime ?? 0) * 60 * 1000
    const perStartDay = state.persons.find(per => per.id === id)?.arrivalTime ?? 0
    const timeToArrive = state.startOfDay + aloudLate + perStartDay
    if (!totalTime || totalTime === 0) {
      return Math.ceil((new Date().getTime() - timeToArrive) / 6000)
    } else {
      return Math.ceil((totalTime - timeToArrive) / 6000)
    }
  }
  if (type === 'leave') {
    const aloudLeave = state.fee.find(fee => fee.subjectId === id && fee.subjectType === 'per')?.earlyLeave ?? 0 * 60 * 1000
    const perEndDay = state.persons.find(per => per.id === id)?.leaveTime ?? 0
    const timeToLeave = state.startOfDay + perEndDay - aloudLeave
    if (!totalTime || totalTime === 0) {
      return 0
    } else {
      return Math.ceil((totalTime - timeToLeave))
    }
  }
}

function getAloudLateTimeMS (id: string) {
//   const perInfo = state.persons.find(per => per.id === id)
  const freeTime = 24 * 60 * 61 * 1000
  const perFee = state.fee.find(fee => fee.subjectId === id)
  if (perFee) {
    if (perFee.free) {
      return freeTime
    } else if (perFee.lateTime) {
      return perFee.lateTime
    }
  }
//   const perPos = perInfo?.posArr ?? []
//   if (perPos.length > 0) {
//     const posFee = state.fee.find(fee => fee.subjectId === id)
//     for (const pos)
//   }
}
</script>

<style>
.rep-onworkplace-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: fit-content;
}

.rep-onworkplace-header, .rep-onworkplace-table {
    display: flex;
    font-family: 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
    color: var(--primary-font-color);
    background-color: #2D4B6B;
    padding: 0.8rem 0 0.8rem 0;
    border-radius: 1.2rem;
}

.rep-onworkplace-table {
    max-height: 70vh;
}

.rep-onworkplace-header {
    font-size: 0.8rem;
}

.rep-onworkplace-table {
    display: flex;
    flex-direction: column;
}

.rep-onworkplace-column-name {
    width: 10rem;
}

.rep-onworkplace-column-is-on-place {
    width: 5rem;
}

.rep-onworkplace-header-block {
    display: flex;
    height: 100%;
    flex-direction: column;
    font-size: 0.6rem;
    background-color: #FFFFFF0D;
    padding: 0 0 0 0.6rem;
    border-radius: 0.8rem;
    gap: 0.4rem;
    justify-content: center;
}

.rep-onworkplace-column-fine {
    width: 10rem;
}

.rep-onworkplace-column-item {
    padding: 0.3rem 1.2rem 0.3rem 1.2rem;
    align-self: center;
}

.rep-onworkplace-table-row {
    display: flex;
    height: 2.8rem;
    font-size: 0.76rem;
}

.rep-onworkplace-row-active {
    background-color: #FFFFFF0D;
    border-radius: 1rem;
    box-sizing: border-box;
}

.rep-onworkplace-row-on-place {
    color: var(--primary-font-color-error);
}
</style>
