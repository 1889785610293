import { reactive, watchEffect, WatchStopHandle } from 'vue'
import { GenericObject } from '@/models'

type EventCallback = (obj: GenericObject | Record<string, string> | null) => void

class EventBus {
  private state = reactive({ emitName: '', obj: null as GenericObject | Record<string, string> | null })

  emit (event: string, obj: GenericObject | Record<string, string> | null = null) {
    this.state.emitName = event
    this.state.obj = obj
    setTimeout(() => {
      this.state.emitName = ''
      this.state.obj = null
    }, 0)
  }

  on (event: string, callback: EventCallback): WatchStopHandle {
    return watchEffect(() => {
      if (this.state.emitName !== '' && this.state.emitName === event) {
        callback(this.state.obj)
      }
    })
  }
}

export const eventBus = new EventBus()
