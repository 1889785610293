<template>
  <div class="visa-main-wrapper">
    <div class="visa-wrapper">
        <div class="pay-info-panel-wrapper-item">
        <span class="pay-info-panel-item-header">
            К оплате
        </span>
        <span class="pay-info-panel-item-text">
            {{ `${props.summ} сом` }}
        </span>
        </div>
        <span class="pay-instruction-label visa-instr-label">
            Инструкция по оплате
        </span>
        <div class="visa-instructions">
            <u-instruction-step v-for="(item, index) in steps" :key="index"
                :step="String(index + 1)"
                :text="item"
            />
        </div>
    </div>
    <div class="visa-footer-btn-wrapper">
        <div class="visa-footer-btn button-click"
            @click="openPayFrame()"
        >
            <span>Продолжить</span>
        </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onBeforeMount, reactive } from 'vue'
import { Organization } from '../../../models'
import { PayOrder } from '../../../classes'
import { usePaymentStore } from '../../../stores/pays'

const payStore = usePaymentStore()

const emits = defineEmits(['show-loader', 'close-loader', 'show-dialog'])

const state = reactive({
  organization: {} as Organization,
  link: '',
  url: ''
})

const props = defineProps({
  summ: {
    type: String,
    required: true
  }
})

const steps = [
  'По кнопке “Открыть страницу оплаты” ниже, перейдите на страницу оплаты.',
  'Введите данные вашей карты',
  'Подтвердите оплату'
]

onBeforeMount(async () => {
  state.organization = JSON.parse(sessionStorage.getItem('organization') || '{}')
})

function showDialog (body: string) {
  emits('show-dialog', { error: true, body })
}

async function openPayFrame () {
  emits('show-loader')
  const payBody = new PayOrder(state.organization.orgId, Number(props.summ))
  const result = await payStore.getOTPQpay('paylercom', payBody)
  if (result.status === 'OK') {
    state.link = result.link
    state.url = result.url
    emits('close-loader')
    window.location.href = state.url
  } else {
    showDialog(result.info)
  }
}
</script>

<style>
.visa-wrapper {
    display: flex;
    flex-direction: column;
}

.visa-instructions {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.visa-instr-label {
    padding-top: 4.63vh;
    padding-bottom: 2.78vh;
}

.visa-footer-btn {
    background-color: #031C38;
    font: 700 1.482vh 'RedHatText-Bold', 'OpenSans', sans-serif;
    color: var(--primary-font-color);
    padding: 1.343vh 1.823vw;
    border-radius: 3.8vh;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 17.188vw;
}

.visa-main-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}

.visa-footer-btn-wrapper {
    display: flex;
    justify-content: center;
}

.visa-pay-frame {
    z-index: 1000;
    position: absolute;
    left: 50%;
    top: 50%;
}
</style>
