import { defineStore } from 'pinia'
import { AxiosError } from 'axios'
import { axiosMain } from '@/axios-setup'
import { FeeList, Fee, FeePatchList, FeeResult } from '@/models'

export const useSettingsStore = defineStore('settings', {
  state: () => ({
    fee: {} as Fee,
    feeList: [] as FeeList
  }),
  actions: {
    async getFeeAction (orgId: string) {
      try {
        const response = await axiosMain.get<Fee>(`/fee/${orgId}`)
        this.fee = response.data
        this.feeList = this.fee.fee
      } catch (error) {
        const axiosError = error as AxiosError
        console.error(axiosError.message)
      }
    },
    async updateFeeAction (orgId: string, updateData: FeePatchList) {
      try {
        const data = {
          orgId,
          updateData
        }
        const response = await axiosMain.post<FeeResult>(`/fee/${orgId}`, data)
        return response.data
      } catch (error) {
        const axiosError = error as AxiosError
        console.error(axiosError.message)
      }
    },
    async updateMainSettingsAction (orgId: string, updateData: { [key: string] : string }) {
      try {
        const data = {
          orgId,
          updateData
        }
        const response = await axiosMain.patch<FeeResult>(`/Org/${orgId}`, data)
        return response.data
      } catch (error) {
        const axiosError = error as AxiosError
        console.error(axiosError.message)
      }
    }
  },
  getters: {
    getFee: (state) => state.fee,
    getFeeList: (state) => state.feeList
  }
})
