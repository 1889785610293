import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "auth-wrapper" }
const _hoisted_2 = { class: "auth-form" }
const _hoisted_3 = { class: "pass-input inputs" }
const _hoisted_4 = { class: "pass-input inputs" }
const _hoisted_5 = { class: "auth-texts" }
const _hoisted_6 = { class: "logo" }
const _hoisted_7 = ["src"]

import { reactive } from 'vue'
import { Country } from '@/models'
import logo from '@/assets/images/logo.svg'
import { useSMSStore } from '@/stores/sms'
import { countriesList } from '@/objects'


export default /*@__PURE__*/_defineComponent({
  __name: 'URegistration',
  emits: ['login', 'to-check-number', 'open-dialog', 'close-dialog'],
  setup(__props, { emit: __emit }) {

const smsStore = useSMSStore()

const state = reactive({
  orgName: '',
  password: '',
  country: countriesList[0],
  login: '',
  sms: ''
})

const emits = __emit

async function registration () {
  if (!state.orgName || state.orgName === '') {
    emits('open-dialog', { headerText: 'Ошибка', message: 'Введите название организации.' })
    return
  }
  if (!state.login || state.login.length < 9) {
    emits('open-dialog', { headerText: 'Ошибка', message: 'Введите Ваш номер телефона.' })
    return
  }
  if (!state.password || state.password.length < 6) {
    emits('open-dialog', { headerText: 'Ошибка', message: 'Пароль не может быть короче 6-ти символов.' })
    return
  }
  const reqBody = {
    sender: process.env.VUE_APP_SMS_SENDER,
    text: '',
    phone_number: [`${state.country.code}${state.login}`],
    secret_code_generate: true,
    is_test: false
  }

  emits('open-dialog', { headerText: 'Отправляем СМС', message: 'Пожалуйста, подождите!', showButtons: false, loading: true })
  await smsStore.sendSMSRequest(reqBody)
  const smsResponse = smsStore.getSMSResponse
  emits('close-dialog')

  if (smsResponse?.status === 'success') {
    localStorage.setItem('lasttimeSMSSend', Math.floor(Date.now() / 1000).toString())
    emits('to-check-number', {
      orgName: state.orgName,
      login: `${state.country.code}${state.login}`,
      password: state.password
    })
  } else {
    emits('open-dialog', { headerText: 'Ошибка', message: smsResponse?.error_msg })
  }
}

function changeCountry (country: Country) {
  state.country = country
}

function inputPhone (login: string) {
  state.login = login
}

return (_ctx: any,_cache: any) => {
  const _component_u_phone_number = _resolveComponent("u-phone-number")!
  const _component_u_button = _resolveComponent("u-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[4] || (_cache[4] = _createElementVNode("span", { class: "auth-form-header" }, "Регистрация", -1)),
      _createElementVNode("div", _hoisted_3, [
        _withDirectives(_createElementVNode("input", {
          type: "text",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((state.orgName) = $event)),
          placeholder: "Название организации"
        }, null, 512), [
          [_vModelText, state.orgName]
        ])
      ]),
      _createVNode(_component_u_phone_number, {
        onChangeCountry: changeCountry,
        onInputPhone: inputPhone
      }),
      _createElementVNode("div", _hoisted_4, [
        _withDirectives(_createElementVNode("input", {
          type: "password",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((state.password) = $event)),
          placeholder: "Пароль"
        }, null, 512), [
          [_vModelText, state.password]
        ])
      ]),
      _createVNode(_component_u_button, {
        class: "auth-btn button-click",
        onClick: registration,
        btnText: 'Зарегистрироваться'
      }),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", null, [
          _cache[3] || (_cache[3] = _createElementVNode("span", { class: "auth-text-act" }, "Есть аккаунт? ", -1)),
          _createElementVNode("span", {
            class: "auth-text-act-btn button-click",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (emits('login')))
          }, "Войти")
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("img", { src: _unref(logo) }, null, 8, _hoisted_7)
    ])
  ]))
}
}

})