import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createVNode as _createVNode, withModifiers as _withModifiers, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "filter-header" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "filter-items-wrapper" }
const _hoisted_4 = {
  key: 0,
  class: "filter-item-wrapper"
}
const _hoisted_5 = {
  key: 1,
  class: "filter-item-wrapper"
}
const _hoisted_6 = {
  key: 2,
  class: "filter-item-wrapper"
}
const _hoisted_7 = { class: "filter-act-btns" }

import cancel from '@/assets/icon/cancel-dialog.svg'
import { ArrayMap } from '@/components/UI/UDropdownFilter.vue'
import { reactive, ref, onBeforeMount, inject } from 'vue'
import { formatRange } from '@/globalFunctions'
import { eventBus } from '@/eventBus'
import { Departments, Employee } from '@/models'

export interface FilterParams {
  date: string | Array<number>;
  person: string;
  department: string;
}

export interface DatesMap {
  [key: string]: Array<number>
}

type StateKeys = 'periodIsOpen' | 'employesIsOpen' | 'departmentsIsOpen'


export default /*@__PURE__*/_defineComponent({
  __name: 'UFilters',
  props: {
  filters: {
    type: Object as () => Record<string, boolean>,
    default: null,
    required: false
  }
},
  emits: ['click-res', 'close', 'filter-set'],
  setup(__props, { emit: __emit }) {

const emits = __emit

const date = ref([new Date(), new Date()])

const days: ArrayMap[] = inject('days', [])

const props = __props

const state = reactive({
  periodIsOpen: false,
  periodEl: 0,
  departmentsIsOpen: false,
  departmentEl: -1,
  departmentId: '',
  employesIsOpen: false,
  employeeEl: -1,
  employeeId: '',
  datePreset: true,
  dateSwitcherInfo: 'Задать промежуток',
  departments: [] as Departments,
  departmentsDropArr: [] as ArrayMap[],
  employes: [] as Employee[],
  employesDropArr: [] as ArrayMap[]
})

onBeforeMount(() => {
  const departmentsData = sessionStorage.getItem('departments')
  if (departmentsData) {
    state.departments = JSON.parse(departmentsData)
    state.departmentsDropArr = state.departments.map(item => ({
      text: item.name,
      value: item.id
    }))
  }
  const persons = sessionStorage.getItem('persons')
  if (persons) {
    state.employes = JSON.parse(persons)
    state.employesDropArr = state.employes.map(item => ({
      text: item.name,
      value: item.id
    }))
  }
})

function changeDateTypeFilter () {
  state.datePreset = !state.datePreset
  if (state.datePreset) {
    state.dateSwitcherInfo = 'Задать промежуток'
  } else {
    state.dateSwitcherInfo = 'Выбрать вручную'
  }
}

function changePeriod (period: number) {
  state.periodEl = period
}

function changeDepartment (index: number) {
  state.departmentEl = index
  if (state.departmentEl > -1) {
    state.departmentId = state.departmentsDropArr[index - 1].value.toString()
  } else {
    state.departmentId = ''
  }
}

function changeEmployee (index: number) {
  state.employeeEl = index
  if (state.employeeEl > -1) {
    state.employeeId = state.employesDropArr[index - 1].value.toString()
  } else {
    state.employeeId = ''
  }
}

function returnResults () {
  let period
  if (state.datePreset) {
    period = state.periodEl - 1 < 0 ? '' : days[state.periodEl - 1].value
  } else {
    const seconDate = date.value[1] == null ? new Date(date.value[0].getFullYear(), date.value[0].getMonth(), date.value[0].getDate(), 23, 59, 59) : date.value[1]
    period = [date.value[0].getTime(), seconDate.getTime()]
  }
  const filterParams = {
    date: period,
    person: state.employeeId,
    department: state.departmentId
  }
  eventBus.emit('filter', filterParams)
  emits('filter-set', filterParams)
  emits('close')
}

function closeAllDropDowns () {
  state.periodIsOpen = false
  state.employesIsOpen = false
  state.departmentsIsOpen = false
}

function toggleDropDown (property: StateKeys) {
  const prevState = state[property]
  closeAllDropDowns()
  state[property] = !prevState
}


return (_ctx: any,_cache: any) => {
  const _component_u_dropdown_filter = _resolveComponent("u-dropdown-filter")!
  const _component_VueDatePicker = _resolveComponent("VueDatePicker")!

  return (_openBlock(), _createBlock(_Transition, { name: "udialog-fade" }, {
    default: _withCtx(() => [
      true
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "filter-overlay",
            onClick: _cache[8] || (_cache[8] = _withModifiers(($event: any) => (closeAllDropDowns()), ["self"]))
          }, [
            _createElementVNode("div", {
              class: "filter-pad",
              onClick: _cache[7] || (_cache[7] = _withModifiers(($event: any) => (closeAllDropDowns()), ["self"]))
            }, [
              _createElementVNode("div", _hoisted_1, [
                _cache[9] || (_cache[9] = _createElementVNode("span", { class: "filter-header-text" }, "Фильтры", -1)),
                _createElementVNode("img", {
                  src: _unref(cancel),
                  class: "filter-cancel button-click",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (emits('close')))
                }, null, 8, _hoisted_2)
              ]),
              _createElementVNode("div", _hoisted_3, [
                (!props.filters || props.filters.date)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _cache[10] || (_cache[10] = _createElementVNode("span", { class: "filter-label" }, "Период", -1)),
                      (state.datePreset)
                        ? (_openBlock(), _createBlock(_component_u_dropdown_filter, {
                            key: 0,
                            array: _unref(days),
                            isOpen: state.periodIsOpen,
                            selected: state.periodEl,
                            onClickEvent: _cache[1] || (_cache[1] = ($event: any) => (toggleDropDown('periodIsOpen'))),
                            onChooseRow: changePeriod
                          }, null, 8, ["array", "isOpen", "selected"]))
                        : (_openBlock(), _createBlock(_component_VueDatePicker, {
                            key: 1,
                            range: "",
                            validate: "",
                            modelValue: date.value,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((date).value = $event)),
                            locale: "ru",
                            "cancel-text": "Отмена",
                            "select-text": "Ок",
                            format: _unref(formatRange),
                            class: "vue-datepicker",
                            placeholder: "Выберите даты"
                          }, null, 8, ["modelValue", "format"])),
                      _createElementVNode("span", {
                        class: "filter-open-picker button-click",
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (changeDateTypeFilter()))
                      }, _toDisplayString(state.dateSwitcherInfo), 1)
                    ]))
                  : _createCommentVNode("", true),
                (!props.filters || props.filters.department)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _cache[11] || (_cache[11] = _createElementVNode("span", { class: "filter-label" }, "По отделам", -1)),
                      _createVNode(_component_u_dropdown_filter, {
                        array: state.departmentsDropArr,
                        isOpen: state.departmentsIsOpen,
                        selected: state.departmentEl,
                        onClickEvent: _cache[4] || (_cache[4] = ($event: any) => (toggleDropDown('departmentsIsOpen'))),
                        onChooseRow: changeDepartment
                      }, null, 8, ["array", "isOpen", "selected"])
                    ]))
                  : _createCommentVNode("", true),
                (!props.filters || props.filters.employs)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _cache[12] || (_cache[12] = _createElementVNode("span", { class: "filter-label" }, "По сотрудникам", -1)),
                      _createVNode(_component_u_dropdown_filter, {
                        array: state.employesDropArr,
                        isOpen: state.employesIsOpen,
                        selected: state.employeeEl,
                        onClickEvent: _cache[5] || (_cache[5] = ($event: any) => (toggleDropDown('employesIsOpen'))),
                        onChooseRow: changeEmployee
                      }, null, 8, ["array", "isOpen", "selected"])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("button", {
                  class: "settings-action-btn button-click",
                  id: "save-btn",
                  onClick: returnResults
                }, " Применить "),
                _createElementVNode("button", {
                  class: "settings-action-btn button-click",
                  id: "cancel-btn",
                  onClick: _cache[6] || (_cache[6] = ($event: any) => (emits('close')))
                }, " Отменить ")
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})