import { getTimezoneOffsetInHours } from './globalFunctions'
import kgIcon from '@/assets/images/KG.svg'
import ruIcon from '@/assets/images/RU.svg'
import kzIcon from '@/assets/images/KZ.svg'

export const countriesList = [
  { code: '996', img: kgIcon, country: 'kgz' },
  { code: '7', img: ruIcon, country: 'rus' },
  { code: '7', img: kzIcon, country: 'kaz' }
]

export class OrganizationClass {
  name: string
  phoneNumber: string
  confirmationCode: string
  timezone: number
  password: string
  email: string
  logo: string
  chatId: string
  timeReport: number | null
  firebaseAuthToken: string
  lockPassword: string

  constructor (name: string, phoneNumber: string, password: string, code: string) {
    this.name = name
    this.phoneNumber = phoneNumber
    this.confirmationCode = code
    this.timezone = getTimezoneOffsetInHours()
    this.password = password
    this.email = ''
    this.logo = ''
    this.chatId = ''
    this.timeReport = 14400
    this.firebaseAuthToken = ''
    this.lockPassword = ''
  }
}
