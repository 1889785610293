import { App } from 'vue'
// Widgets
import UDialog from './widgets/UDialog.vue'
import UOnWorkplace from './widgets/UOnWorkplace.vue'
import UWhoLate from './widgets/UWhoLate.vue'
import UFines from './widgets/UFines.vue'
import UReportMiniature from './widgets/UReportMiniature.vue'
import UReview from './widgets/UReview.vue'
import UAuthView from './widgets/UAuthView.vue'
import URegistration from './widgets/URegistration.vue'
import UCheckSMS from './widgets/UCheckSMS.vue'
import UPassRecovery from './widgets/UPassRecovery.vue'
import UFilters from './widgets/UFilters.vue'
import UPinInput from './widgets/UPinInput.vue'
import UPersonData from './widgets/UPersonData.vue'
import UPhotoFrame from './widgets/UPhotoFrame.vue'
import UOrganizationItem from './widgets/UOrganizationItem.vue'
import UAddUniversal from './widgets/UAddUniversal.vue'
import UPersonItem from './widgets/UPersonItem.vue'
import UChoosePersons from './widgets/UChoosePersons.vue'
import USuccessAdded from './widgets/USuccessAdded.vue'
import UChoosePhotoType from './widgets/UChoosePhotoType.vue'
import USavePhotoDialog from './widgets/USavePhotoDialog.vue'
import UUniversalSelector from './widgets/UUniversalSelector.vue'
import UFiltersView from './widgets/UFiltersView.vue'
import UPopupMessage from './widgets/UPopupMessage.vue'
import UPlan from './widgets/UPlan.vue'
import UChoosePlan from './widgets/UChoosePlan.vue'
// UI
import UButton from './UI/UButton.vue'
import UDropdown from './UI/UDropdown.vue'
import UIcon from './UI/UIcon.vue'
import UWidgetHeader from './UI/UWidgetHeader.vue'
import UIconRounded from './UI/UIconRounded.vue'
import UIconButton from './UI/UIconButton.vue'
import UCounter from './UI/UCounter.vue'
import USpinner from './UI/USpinner.vue'
import UOrgInfo from './UI/UOrgInfo.vue'
import UPhoneNumber from './UI/UPhoneNumber.vue'
import UReportsHeader from './UI/UReportsHeader.vue'
import UDropdownFilter from './UI/UDropdownFilter.vue'
import UFilterObj from './UI/UFilterObj.vue'
import UDropDownHours from './UI/UDropDownHours.vue'
import UOrgPayInfo from './UI/UOrgPayInfo.vue'
import UPersonRow from './UI/UPersonRow.vue'
import UUniversalRow from './UI/UUniversalRow.vue'
import UDropFilters from './UI/UDropFilters.vue'
import UPaymentsHeader from './UI/UPaymentsHeader.vue'
import UInstructionStep from './UI/UInstructionStep.vue'

const components = [
  {
    name: 'u-button',
    component: UButton
  },
  {
    name: 'u-dropdown',
    component: UDropdown
  },
  {
    name: 'u-icon',
    component: UIcon
  },
  {
    name: 'u-dialog',
    component: UDialog
  },
  {
    name: 'u-widget-header',
    component: UWidgetHeader
  },
  {
    name: 'u-on-workplace',
    component: UOnWorkplace
  },
  {
    name: 'u-who-late',
    component: UWhoLate
  },
  {
    name: 'u-icon-rounded',
    component: UIconRounded
  },
  {
    name: 'u-icon-button',
    component: UIconButton
  },
  {
    name: 'u-fines',
    component: UFines
  },
  {
    name: 'u-counter',
    component: UCounter
  },
  {
    name: 'u-report-miniature',
    component: UReportMiniature
  },
  {
    name: 'u-spinner',
    component: USpinner
  },
  {
    name: 'u-review',
    component: UReview
  },
  {
    name: 'u-org-info',
    component: UOrgInfo
  },
  {
    name: 'u-auth',
    component: UAuthView
  },
  {
    name: 'u-phone-number',
    component: UPhoneNumber
  },
  {
    name: 'u-registration',
    component: URegistration
  },
  {
    name: 'u-check-sms',
    component: UCheckSMS
  },
  {
    name: 'u-pass-recovery',
    component: UPassRecovery
  },
  {
    name: 'u-report-header',
    component: UReportsHeader
  },
  {
    name: 'u-filters',
    component: UFilters
  },
  {
    name: 'u-dropdown-filter',
    component: UDropdownFilter
  },
  {
    name: 'u-filter-obj',
    component: UFilterObj
  },
  {
    name: 'u-dropdown-hours',
    component: UDropDownHours
  },
  {
    name: 'u-pin-input',
    component: UPinInput
  },
  {
    name: 'u-person-data',
    component: UPersonData
  },
  {
    name: 'u-photo-frame',
    component: UPhotoFrame
  },
  {
    name: 'u-organization-item',
    component: UOrganizationItem
  },
  {
    name: 'u-add-universal',
    component: UAddUniversal
  },
  {
    name: 'u-person-item',
    component: UPersonItem
  },
  {
    name: 'u-org-pay-info',
    component: UOrgPayInfo
  },
  {
    name: 'u-choose-persons',
    component: UChoosePersons
  },
  {
    name: 'u-person-row',
    component: UPersonRow
  },
  {
    name: 'u-success-added',
    component: USuccessAdded
  },
  {
    name: 'u-choose-photo-type',
    component: UChoosePhotoType
  },
  {
    name: 'u-save-photo-dialog',
    component: USavePhotoDialog
  },
  {
    name: 'u-universal-selector',
    component: UUniversalSelector
  },
  {
    name: 'u-universal-row',
    component: UUniversalRow
  },
  {
    name: 'u-filters-view',
    component: UFiltersView
  },
  {
    name: 'u-drop-filters',
    component: UDropFilters
  },
  {
    name: 'u-payments-header',
    component: UPaymentsHeader
  },
  {
    name: 'u-popup-message',
    component: UPopupMessage
  },
  {
    name: 'u-instruction-step',
    component: UInstructionStep
  },
  {
    name: 'u-plan',
    component: UPlan
  },
  {
    name: 'u-choose-plan',
    component: UChoosePlan
  }
]

export function registerGlobalComponents (app: App) {
  components.forEach(({ name, component }) => {
    app.component(name, component)
  })
}
