import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "payments-pay-wrapper" }
const _hoisted_2 = { class: "payments-pay-info-panel" }
const _hoisted_3 = { class: "plan-pay-info-panel-sum" }
const _hoisted_4 = { class: "payments-input-summ-wrapper" }
const _hoisted_5 = { class: "payments-input-summs" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "payments-items-wrapper" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = ["src"]
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "payments-info-block" }
const _hoisted_12 = { class: "payments-info-block-type" }
const _hoisted_13 = { class: "payments-info-block-label" }
const _hoisted_14 = { class: "payments-footer" }

import { reactive, onBeforeMount } from 'vue'
import { Organization } from '@/models'
import { payments } from '../payments'
import radio from '@/assets/icon/radio.svg'
import radioAct from '@/assets/icon/radio-act.svg'
import { eventBus } from '@/eventBus'


export default /*@__PURE__*/_defineComponent({
  __name: 'PaymentsView',
  setup(__props) {

const state = reactive({
  organization: {} as Organization,
  summ: '',
  choosen: -1,
  showDialog: false
})

const summs = ['1000', '2000', '3000', '5000']

onBeforeMount(() => {
  state.organization = JSON.parse(sessionStorage.getItem('organization') || '{}')
})

function choosePayType (index: number) {
  state.choosen = index
}

function toPay () {
  if (state.summ === '' || state.summ === '0') {
    state.showDialog = true
    return
  }
  eventBus.emit('to-pay-steps', {
    routText: payments[state.choosen].label,
    summ: state.summ,
    type: payments[state.choosen].code
  })
}

return (_ctx: any,_cache: any) => {
  const _component_u_dialog = _resolveComponent("u-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[3] || (_cache[3] = _createElementVNode("span", { class: "plan-pay-info-panel-info" }, "Ваш баланс", -1)),
          _createElementVNode("span", _hoisted_3, _toDisplayString(state.organization.balance) + " сом", 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _cache[4] || (_cache[4] = _createElementVNode("span", { class: "plan-pay-info-panel-info" }, "Введите сумму", -1)),
          _withDirectives(_createElementVNode("input", {
            class: "payments-input",
            placeholder: "0",
            type: "number",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((state.summ) = $event))
          }, null, 512), [
            [_vModelText, state.summ]
          ]),
          _createElementVNode("div", _hoisted_5, [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(summs, (summ) => {
              return _createElementVNode("div", {
                class: "payments-input-summ-btn button-click",
                key: summ,
                onClick: ($event: any) => (state.summ = summ)
              }, [
                _createElementVNode("span", null, _toDisplayString(summ), 1)
              ], 8, _hoisted_6)
            }), 64))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(payments), (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: _normalizeClass(["payments-item button-click", {'payments-item-choosen': state.choosen === index}]),
            onClick: ($event: any) => (choosePayType(index))
          }, [
            _createElementVNode("img", {
              src: state.choosen === index ? _unref(radioAct) : _unref(radio)
            }, null, 8, _hoisted_9),
            _createElementVNode("img", {
              class: "payments-item-icon",
              src: item.icon
            }, null, 8, _hoisted_10),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("span", _hoisted_12, _toDisplayString(item.type), 1),
              _createElementVNode("span", _hoisted_13, _toDisplayString(item.label), 1)
            ])
          ], 10, _hoisted_8))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_14, [
        (state.choosen > -1)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "payments-footer-btn button-click",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (toPay()))
            }, _cache[5] || (_cache[5] = [
              _createElementVNode("span", null, "Продолжить", -1)
            ])))
          : _createCommentVNode("", true)
      ])
    ]),
    (state.showDialog)
      ? (_openBlock(), _createBlock(_component_u_dialog, {
          key: 0,
          header: 'Ошибка',
          msgText: 'Введите сумму платежа',
          onClickRes: _cache[2] || (_cache[2] = ($event: any) => (state.showDialog = false))
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})