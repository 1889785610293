import { defineStore } from 'pinia'
import { AxiosError } from 'axios'
import { Organization } from '../models'
import { axiosMain } from '@/axios-setup'

export interface CheckPhoneResult {
  status: string
  message: string
}

export const useOrganizationStore = defineStore('org', {
  state: () => ({
    organization: {} as Organization | undefined | null,
    checkNumberResult: {} as CheckPhoneResult | undefined | null
  }),
  actions: {
    async sendAuthRequest (phoneNumber: string, password: string) {
      try {
        const response = await axiosMain.post<Organization | CheckPhoneResult>('/Auth', {
          phoneNumber,
          password
        })
        if ('status' in response.data) {
          this.checkNumberResult = response.data as CheckPhoneResult
          return 'error'
        } else {
          this.organization = response.data as Organization
          return 'organization'
        }
      } catch (error) {
        const axiosError = error as AxiosError
        console.error(axiosError.message)
      }
    },
    async checkNumberAction (phoneNumber: string) {
      try {
        const response = await axiosMain.get<CheckPhoneResult>(`/CheckPhone/${phoneNumber}`)
        this.checkNumberResult = response.data
      } catch (error) {
        const axiosError = error as AxiosError
        console.error(axiosError.message)
      }
    }
  },
  getters: {
    getOrganization: (state) => state.organization,
    getCheckNumberResult: (state) => state.checkNumberResult
  }
})
