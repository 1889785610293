<template>
    <div class="auth-wrapper">
      <div class="auth-form" v-if="state.step === 'passSendSMS'">
        <span class="auth-form-header">Забыли пароль?</span>
        <span class="sms-info sms-info-text">Мы отправим код на ваш номер телефона.</span>
        <u-phone-number @change-country="changeCountry" @input-phone="inputPhone"/>
        <u-button class="auth-btn button-click" @click="sendSMS" :btnText="'Восстановить пароль'" />
        <div>
          <span class="back-login-btn"
            @click="emits('to-auth')">Вернуться ко входу</span>
        </div>
      </div>
      <div class="auth-form" v-if="state.step === 'passConfirm'">
        <span class="auth-form-header">Новый пароль</span>
        <span class="sms-info sms-info-text">Пароль должен содержать минимум 6 символов</span>
        <div class="pass-input inputs">
          <input type="password" v-model="state.passwordFirst" placeholder="Новый пароль">
        </div>
        <div class="pass-input inputs">
          <input type="password" v-model="state.passwordSecond" placeholder="Подтверждение пароля">
        </div>
        <u-button class="auth-btn button-click" @click="checkPass" :btnText="'Сменить пароль'" />
        <div>
          <span class="back-login-btn"
            @click="emits('to-auth')">Вернуться ко входу</span>
        </div>
      </div>
      <div class="logo">
        <img :src="logo">
      </div>
    </div>
</template>

<script setup lang="ts">
import { reactive } from 'vue'
import { Country } from '@/models'
import logo from '@/assets/images/logo.svg'
import { useSMSStore } from '@/stores/sms'
// import { useOrganizationStore } from '@/stores/auth';

const smsStore = useSMSStore()
// const organizationStore = useOrganizationStore()

const emits = defineEmits(['to-check-number', 'open-dialog', 'to-auth', 'close-dialog'])

const state = reactive({
  step: 'passSendSMS',
  login: '',
  country: {} as Country,
  passwordFirst: '',
  passwordSecond: ''
})

function changeCountry (country: Country) {
  state.country = country
}

function inputPhone (login: string) {
  state.login = login
}

async function sendSMS () {
  if (!state.login || state.login.length < 9) {
    emits('open-dialog', { headerText: 'Ошибка', message: 'Введите Ваш номер телефона.' })
    return
  }
  emits('open-dialog', { headerText: 'Отправляем СМС', message: 'Пожалуйста, подождите!', showButtons: false, loading: true })

  const isTest = process.env.VUE_APP_SMS_IS_TEST === 'true'

  const reqBody = {
    sender: process.env.VUE_APP_SMS_SENDER,
    text: '',
    phone_number: [state.login],
    secret_code_generate: true,
    is_test: isTest
  }

  await smsStore.sendSMSRequest(reqBody)
  const smsResponse = smsStore.getSMSResponse
  emits('close-dialog')

  if (smsResponse?.status === 'success' && smsResponse?.code.length === 4) {
    localStorage.setItem('lasttimeSMSSend', Math.floor(Date.now() / 1000).toString())
    emits('to-check-number', { login: `${state.country.code}${state.login}`, sms: smsResponse?.code })
  } else {
    emits('open-dialog', { headerText: 'Ошибка', message: 'Попробуйте позже!' })
  }
}

async function checkPass () {
  if (state.passwordFirst.length >= 6 && state.passwordFirst === state.passwordSecond) {
    // const result = await organizationStore.changePassword(1111, state.passwordFirst)
    // if (result.status === ) {

    // }
  } else if (state.passwordFirst.length <= 6) {
    emits('open-dialog', { headerText: 'Ошибка', message: 'Пароль должен быть минимум 6 символов.' })
  } else if (state.passwordFirst !== state.passwordSecond) {
    emits('open-dialog', { headerText: 'Ошибка', message: 'Введенные пароли не совпадают.' })
  }
}
</script>

<style>
.back-login-btn {
  font: 0.83vw 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
  color: var(--primary-font-color-a70);
}
@media screen and (max-width: 950px) {
  .back-login-btn {
    font-size: 3.05vw;
  }
}
</style>
