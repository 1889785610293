<template>
    <div class="instr-step-wrapper"
        :style="{width: props.width > 0 ? `${props.width}vw` : '21.69vw'}"
    >
        <div class="instr-step-rounded-step">
            <span>{{ step }}</span>
        </div>
        <div class="instr-step-info">
            <span class="instr-step-info-label">
                {{ `Шаг ${step}` }}
            </span>
            <span class="instr-step-info-text">
                {{ props.text }}
            </span>
        </div>
    </div>
</template>

<script setup lang="ts">
const props = defineProps({
  step: {
    type: String,
    required: true
  },
  text: {
    type: String,
    required: true
  },
  width: {
    type: Number,
    default: 0
  }
})
</script>

<style>
.instr-step-wrapper {
    display: flex;
    padding: 2.315vh;
    background-color: #2D4B6B;
    border-radius: 1.86vh;
    gap: 1.389vh;
}

.instr-step-info {
    margin-top: 0.6vh;
    display: flex;
    flex-direction: column;
    gap: 0.463vh;
    width: calc(21.69vw - 2.315vh * 2 - 1.389vh);
}

.instr-step-rounded-step,
.instr-step-info-label {
    font: 500 1.852vh 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
    color: var(--primary-font-color);
}

.instr-step-rounded-step {
    display: flex;
    background-color: var(--primary-font-color-a10);
    height: 3.704vh;
    width: 3.704vh;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
}

.instr-step-info-text {
    font: 500 1.389vh 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
    color: var(--primary-font-color-a70);
}
</style>
